import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { nurseCountReport } from '../../services/NurseReportService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchNurseReportsCount = createAsyncThunk('fetchNurseReportsCount', (initData) => {
    return nurseCountReport(initData)
    .then((response) =>  response.data.data)
})


const nurseReportsCountSlice = createSlice({
    name: 'nurseReportsCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchNurseReportsCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchNurseReportsCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default nurseReportsCountSlice.reducer 