import React from "react";
import "../SoleraCss/Responsive.css";
import "../SoleraCss/Solera.css";
import { Bars } from "react-loader-spinner";
import moment from "moment";

function PrescriptionDisplay({ list, loading }) {
  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };
  return (
    <div>
      <div className="Report-Display-Note">
        <div className="Report-lIST">List prescription from the doctor</div>

        {loading ? (
          <div className="d-flex justify-content-center">
            <Bars
              heigth="30"
              width="30"
              color="gray"
              ariaLabel="loading-indicator"
            />
          </div>
        ) : null}
      </div>
      <div className="Solera__Headings__Note12">
        {list?.map((item, index) => (
          <div className="Vital-Box-Result mt-2">
            <div className="Vital-Box-Heading">
              <div className="FormSect">Prescription {index + 1}</div>
            </div>
            <div>
              <div className="FormSect-Note">
                Drug Name - Dose - Route - Quantity
              </div>
              <div className="FormSect-Note5">
                <span>{item?.name}</span>
              </div>

              <br />
              <div className="FormSect-Note">
                Date : &nbsp;<span>{showDate(item?.created_at)}</span>
              </div>
              <div className="FormSect-Note">
                Doctor : &nbsp;<span>{item?.user?.full_name}</span>
              </div>
            </div>
            <br />
          </div>
        ))}
        {/* <div className="Vital-Box-Result mt-2">
            <div className="Vital-Box-Heading">
              <div className="FormSect">Receiver's Name</div>
            </div>
            <div>
                <div className="Report-Date4">
                  <div>{list[0]?.receiver_name}</div>
                </div>
            </div>
          </div> */}
        <br />
        <div className="Vital-Box-Result mt-2">
          <div className="Vital-Box-Heading">
            <div className="FormSect">Doctor's Note</div>
          </div>
          <div>
            {/* <div className="FormSect-Note">
                  <span>{list[0]?.note}</span>
                </div> */}

            <div className="Report-Date4">
              <div dangerouslySetInnerHTML={{ __html: list[0]?.note }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrescriptionDisplay;
