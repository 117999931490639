import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { patientLapsCount } from '../../../services/PatientLapsService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchAllTestCount = createAsyncThunk('fetchAllTestCount', (initData) => {
    return patientLapsCount(initData)
    .then((response) =>  response.data.data)
})


const allTestCountSlice = createSlice({
    name: 'allTestCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchAllTestCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchAllTestCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default allTestCountSlice.reducer 