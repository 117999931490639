import { React, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../SoleraCss/Responsive.css";
import "../../../SoleraCss/Solera.css";
import "../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Swal from "sweetalert2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { updatePatient } from "../../../services/PatientService";
import { fetchPatients } from "../../../features/patients/patientsSlice";
import { useDispatch } from "react-redux";
import { getUser } from "../../../services/AuthService";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import RichTextEditor from 'react-rte';  // Copy Editor 

function EditPatientDetails({ show, onClose, selected }) {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue()); // Copy Editor

  const dispatch = useDispatch();
  const user = getUser();

  useEffect(() => {
    setOtherName(selected?.first_name)
    setLastName(selected?.last_name)
    setGender(selected?.gender)
    setAge(selected?.age)
    setEmail(selected?.email)
    setPhone(selected?.phone)
    setAddress(selected?.address)
    setCity(selected?.city)
    setSateN(selected?.state)
    setFatherOccupation(selected?.father_occupation)
    setMotherOccupation(selected?.mother_occupation)
    setCaregiverName(selected?.caregiver_name)
    setCaregiverOccupation(selected?.caregiver_occupation)
    setCaregiverMarital(selected?.caregiver_marital)
    setDateOfBirth(selected?.date_of_birth)
    setLanguage(selected?.language)
    setIsAdult(selected?.is_adult)
    setMaritalStatus(selected?.marital_status)
    setIsAdultOccupation(selected?.is_adult_occupation)
    setModeAdmission(selected?.mode_admission)
    setDateDiscovery(selected?.date_discovery)
    setReferredBy(selected?.referred_by)
    setNutritionalStatus(selected?.nutritional_status)
  }, [show])

  const [otherName, setOtherName] = useState("");
  const [errOtherName, setErrOtherName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errlastName, setErrLastName] = useState("");
  const [gender, setGender] = useState("");
  const [errGender, setErrGender] = useState("");
  const [age, setAge] = useState("");
  const [errAge, setErrAge] = useState("");
  const [email, setEmail] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errPhone, setErrPhone] = useState("");

  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [stateN, setSateN] = useState("");
  const [fatherOccupation, setFatherOccupation] = useState("");
  const [motherOccupation, setMotherOccupation] = useState("");
  const [caregiverName, setCaregiverName] = useState("");
  const [caregiverOccupation, setCaregiverOccupation] = useState("");
  const [caregiverMarital, setCaregiverMarital] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [loading, setLoading] = useState("");
  const [language, setLanguage] = useState("");
  const [isAdult, setIsAdult] = useState(false);
  const [maritalStatus, setMaritalStatus] = useState("");
  const [isAdultOccupation, setIsAdultOccupation] = useState();
  const [modeAdmission, setModeAdmission] = useState("");
  const [dateDiscovery, setDateDiscovery] = useState("");
  const [referredBy, setReferredBy] = useState("");
  const [nutritionalStatus, setNutritionalStatus] = useState("");
  const [note, setNote] = useState("");


  const [editPatientDetailsShow, setEditPatientDetailsShow] = useState(false);
  const handleEditPatientDetails = () => setEditPatientDetailsShow(false);

  const admission = [
    {
      value: "Came on their own",
      label: "Came on their own",
    },
    {
      value: "Discover during sanitization program",
      label: "Discover during sanitization program",
    },
    {
      value: "Referred",
      label: "Referred",
    },

    {
      value: "Others",
      label: "Others",
    },
  ];

  const nutritional = [
    {
      value: "Normal",
      label: "Normal",
    },
    {
      value: "Moderate malnutrition",
      label: "Moderate malnutrition",
    },
    {
      value: "Severe malnutrition",
      label: "Severe malnutrition",
    
    },
  ];

  const handleUpdate = () => {
    if (!otherName) {
      setErrOtherName("First name is required");
      return;
    }
    if (!lastName) {
      setErrLastName("Last name is required");
      return;
    }
    if (!age) {
      setErrAge("Age is required");
      return;
    }
    if (!gender) {
      setErrGender("Gender is required");
      return;
    }

    if (!email) {
      setErrEmail("Email is required");
      return;
    }

    if (!phone) {
      setErrPhone("Phone is required");
      return;
    }

    setLoading(true);
    const data = {
      id: selected?._id,
      user: user?._id,
      first_name: otherName,
      last_name: lastName,
      age: age,
      gender: gender,
      email: email,
      phone: phone,
      address: address,
      city: city,
      state: stateN,
      date_of_birth: dateOfBirth || selected?.date_of_birth,
      father_occupation: fatherOccupation,
      mother_occupation: motherOccupation,
      caregiver_name: caregiverName,
      caregiver_occupation: caregiverOccupation,
      caregiver_marital: caregiverMarital,
      language: language,
      note: note,
      is_adult: isAdult,
      marital_status: maritalStatus,
      is_adult_occupation: isAdultOccupation,
      mode_admission: modeAdmission,
      date_discovery: dateDiscovery,
      referred_by: referredBy,
      nutritional_status: nutritionalStatus
    };
    console.log(data);
    updatePatient(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          setEditPatientDetailsShow(false);
          dispatch(fetchPatients({}));
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const sex = [
    {
      value: "Gender",
      label: "Gender",
    },
    {
      value: "male",
      label: "Male",
    },
    {
      value: "female",
      label: "Female",
    },
  ];
  const status = [
    {
      value: "Single",
      label: "Single",
    },
    {
      value: "Married",
      label: "Married",
    },
  ];

  const languages = [
    {
      value: "PG",
      label: "PG",
    },
    {
      value: "HA",
      label: "HA",
    },
    {
      value: "IG",
      label: "IG",
    },
    {
      value: "YO",
      label: "YO",
    },
    {
      value: "FUL",
      label: "FUL",
    },
    {
      value: "OTHERS",
      label: "OTHERS",
    },
  ];

 {/* Copy Editor */}
 const onChange = (value) => {
  setValue(value);
  value.toString('html')
  setNote(value.toString('html'))
};


  return (
    <div>
      {" "}
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Solera__ModalHeadings">
              <MdClose className="Solera__Close" onClick={onClose} />
            </div>

            <div className="Solera__ModalHeadings"></div>
            <div className="Solera-Modal-Container">
              <div className="Solera__Headings">
                <div className="Log__Cont">
                  <img
                    src="/assets/lg.png"
                    alt=""
                    className="Log__Img__Modal"
                  />
                </div>
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">
                    Update Patient's Medical Record
                  </div>
                  <div className="Solera-Settings-Note">
                    Register your patient here
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="FormSect-Contianer">
                <div className="FormSect">Patient Personal Details</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Surname"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={lastName}
                        onChange={(event) => {
                          if (event.target.value.length) {
                            setErrLastName("");
                          } else {
                            setErrLastName("Last name is required");
                          }
                          setLastName(event.target.value);
                        }}
                      />
                      {errlastName && (
                        <div className="input_error">{errlastName}</div>
                      )}
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="First Name"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={otherName}
                        onChange={(event) => {
                          if (event.target.value.length) {
                            setOtherName("");
                          } else {
                            setOtherName("Name is required");
                          }
                          setOtherName(event.target.value);
                        }}
                      />
                      {errOtherName && (
                        <div className="input_error">{errOtherName}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <div>
                        <TextField
                          fullWidth
                          id="outlined-select-currency"
                          select
                          label="Select Gender"
                          defaultValue="Gender"
                          value={gender}
                          onChange={(event) => {
                            if (event.target.value.length) {
                              setErrGender("");
                            } else {
                              setErrGender("Gender is required");
                            }
                            setGender(event.target.value);
                          }}
                        >
                          {sex.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              selected={option.value === gender}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        {errGender && (
                          <div className="input_error">{errGender}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        id="outlined-select-currency"
                        select
                        label="Select Language"
                        defaultValue="Language"
                        value={language}
                        onChange={(event) => {
                          setLanguage(event.target.value);
                        }}
                      >
                        {languages.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            selected={option.value === language}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    {" "}
                    <div className="">Date of Birth</div>
                  </div>
                </div>{" "}
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <div>
                        <TextField
                          fullWidth
                          // label="Date of Birth"
                          id="fullWidth"
                          type="date"
                          className="Solera-Login-Input mb-3"
                          selected={dateOfBirth}
                          onChange={async (date) => {
                            await setDateOfBirth(date.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Age"
                        id="fullWidth"
                        type="number"
                        className="Solera-Login-Input mb-3"
                        value={age}
                        onChange={(event) => {
                          if (event.target.value.length) {
                            setErrAge("");
                          } else {
                            setErrAge("Age is required");
                          }
                          setAge(event.target.value);
                        }}
                      />
                      {errAge && <div className="input_error">{errAge}</div>}
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <div>
                        <TextField
                          fullWidth
                          label="Father's occupation"
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          value={fatherOccupation}
                          onChange={(event) => {
                            setFatherOccupation(event.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Mother's occupation"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={motherOccupation}
                        onChange={(event) => {
                          setMotherOccupation(event.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Address"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={address}
                        onChange={(event) => {
                          setAddress(event.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="City"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={city}
                        onChange={(event) => {
                          setCity(event.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="State"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={stateN}
                        onChange={(event) => {
                          setSateN(event.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">If Adult
                </div>
                <div className="FormSect-Note">
                  Please check the button below if the patient is an adult.
                </div>
                <TextField
                  type="checkbox"
                  className="ml-3"
                  id="outlined-select-currency"
                  label="Select Marital status"
                  value={isAdult}
                  onChange={(event) => {
                    setIsAdult(event.target.checked)
                  }}
                />
                <div className="container-fluid p-0 FormInput">
                  
                    
                    {
                      isAdult ? (
                        <div className="row gx-4">
                          <div className="col-lg">
                          <TextField
                              fullWidth
                              id="outlined-select-currency"
                              select
                              label="Select Marital status"
                              value={maritalStatus}
                              onChange={(event) => {
                                setMaritalStatus(event.target.value)
                              }}
                            >
                              {status.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Occupation"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              value={isAdultOccupation}
                              onChange={(event) => {
                                setIsAdultOccupation(event.target.value)
                              }}
                            />
                          </div>
                        </div>   
                      ) : null
                    }
                </div>
                
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Caregiver's Details</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Name"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={caregiverName}
                        onChange={(event) => {
                          setCaregiverName(event.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Occupation"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={caregiverOccupation}
                        onChange={(event) => {
                          setCaregiverOccupation(event.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Phone Number"
                        id="fullWidth"
                        type="number"
                        className="Solera-Login-Input mb-3"
                        value={phone}
                        onChange={(event) => {
                          if (event.target.value.length) {
                            setErrPhone("");
                          } else {
                            setErrPhone("Phone is required");
                          }
                          setPhone(event.target.value);
                        }}
                      />
                      {errPhone && (
                        <div className="input_error">{errPhone}</div>
                      )}
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Email"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={email}
                        onChange={(event) => {
                          if (event.target.value.length) {
                            setErrEmail("");
                          } else {
                            setErrEmail("Email is required");
                          }
                          setEmail(event.target.value);
                        }}
                      />
                      {errEmail && (
                        <div className="input_error">{errEmail}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col">
                      <TextField
                        fullWidth
                        id="outlined-select-currency"
                        select
                        label="Select Marital status"
                        defaultValue="status"
                        value={caregiverMarital}
                        onChange={(event) => {
                          setCaregiverMarital(event.target.value);
                        }}
                      >
                        {status.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            selected={option.value === caregiverMarital}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                </div>
                <div className="FormSect-Contianer">
                <div className="FormSect">Admission</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                    <div className="">Mode of admission</div>
                    <br/>
                    <TextField
                        fullWidth
                        id="outlined-select-currency"
                        select
                        label="Mode of admission and discovery"
                        defaultValue="status"
                        value={modeAdmission}
                        onChange={(event) => {
                          setModeAdmission(event.target.value)
                        }}
                      >
                        {admission.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      
                    </div>
                    <div className="col-lg">
                    <div className="">Date of Discovery</div>
                    <br/>
                      <TextField
                        fullWidth
                        // label="Date of discovery"
                        id="fullWidth"
                        type="date"
                        className="Solera-Login-Input mb-3"
                        value={dateDiscovery}
                        onChange={(event) => {
                          setDateDiscovery(event.target.value)
                        }}
                      />
                      
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="If referred? Enter referral center"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={referredBy}
                        onChange={(event) => {
                          setReferredBy(event.target.value)
                        }}
                      />
                    </div>
                    <div className="col-lg">
                    <TextField
                        fullWidth
                        id="outlined-select-currency"
                        select
                        label="Nutritional status"
                        value={nutritionalStatus}
                        onChange={(event) => {
                          setNutritionalStatus(event.target.value)
                        }}
                      >
                        {nutritional.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                </div>
               
                 <br />
                <div className="FormSect">Other Details</div>
                <br />
                <div >
                {/* Copy Editor */}
                <RichTextEditor
                  className="editor"
                  value={value} onChange={onChange} 
                />
                </div>
              </div>
              </div>
              {loading ? (
                <Bars
                  heigth="100"
                  width="100"
                  color="gray"
                  ariaLabel="loading-indicator"
                />
              ) : (
                <button className="Add-Button" onClick={handleUpdate}>
                  Update Patient
                </button>
              )}
              <br /> <br /> <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default EditPatientDetails;
