import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import "../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Swal from "sweetalert2";
import AddDoctorsComment from "./AddDoctorsComment";
import { getUser } from "../../services/AuthService";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addPatientLapComments } from "../../services/PatientLapsCommentService";
import { fetchPatientLapComments } from "../../features/patients/patientLapCommentsSlice";
import { Bars } from "react-loader-spinner";
import RichTextEditor from 'react-rte';  // Copy Editor 


function AddCommentModal({ show, onClose, onOpen, selected }) {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());// Copy Editor 


  const dispatch = useDispatch()
  const user = getUser();

  console.log("COMMENTS", selected)
  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);

  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);

  const [addcomentShow, setAddcomentShow] = useState(false);
  const handleAddcoment = () => setAddcomentShow(false);

  const handleAdd = () => {

    setLoading(true);
    const data = {
      patient: selected?.patient_infos[0]?._id,
      treatment: selected?.treatment_infos[0]?._id,
      comment: comment,
    };
    console.log(data);
    addPatientLapComments(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(fetchPatientLapComments({treatment: selected?.treatment_infos[0]?._id}))
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

    {/* Copy Editor */}
    const onChange = (value) => {
      setValue(value);
      value.toString('html')
      setComment(value.toString('html'))
    };

  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">Add your comment</div>
                    <div className="Solera-Settings-Note">
                    Make a comment on this test
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div className="FormSect-Contianer">
                  <div>
                    <div className="Text-Result-Display">
                      Comment
                    </div>
                    <div className="container-fluid p-0 FormInput">
                      <div className="col">
                        {/* <TextField
                          fullWidth
                          id="outlined-multiline-static "
                          label="Make a comment on this Test result"
                          multiline
                          rows={10}
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                          // defaultValue="Default Value"
                        /> */}

                         {/* Copy Editor */}
                 <RichTextEditor 
                  className="editor"
                  value={value} onChange={onChange} 
                />
                      </div>
                    </div>
                  </div>
                </div>
                {
                  loading ? (
                  <Bars
                      heigth="30"
                      width="30"
                      color="gray"
                      ariaLabel="loading-indicator"
                  />
                  ) : (
                  <button disabled={comment.length < 1} className="Add-Button" onClick={handleAdd}>
                      Comment
                  </button>
                  ) 
                }
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default AddCommentModal;
