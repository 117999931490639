import React, { useEffect, useState } from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import Staff from "./Staff";
import { useDispatch, useSelector } from "react-redux";
import { fetchStaffs } from "../../features/staff/staffsSlice";
import { Bars } from "react-loader-spinner";
import Pagination from "../../Component/Pagination";

function StaffTable() {
  const dispatch = useDispatch()
  const {staffs, paginator, loading} = useSelector((state) => state.staffs)

  const [search2, setSearch2] = useState('');
  const [page, setPage] = useState(paginator?.currentPage || 1);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(paginator?.pageCount || 0);

  useEffect((  ) => {
    handleFetchStaffs()
  }, [])


  const handleFetchStaffs = (search = "", limit = 10, page = 1) => {
    dispatch(fetchStaffs({ name: search, page_size: limit, page:page }))
  }

  const handleFetchUpdate = (search = search2, limit = pageLimit, page = 1) => {
    dispatch(fetchStaffs({ name: search, page_size: limit, page:page }));
  }
  return (
    <div className="Patient-Table">
      <div className="Patient-Search">
        <input
          type="Search"
          className="form-control SearchesInput22"
          placeholder="Search"
          aria-label="OTP"
          aria-describedby="basic-addon1"
        />
        <div className="Searchesbtn">Search</div>
      </div>
      <div>
        <div> <Pagination /></div>
        <div>
        {loading ? (
              <div className="d-flex justify-content-center">
                <Bars
                  heigth="30"
                  width="30"
                  color="gray"
                  ariaLabel="loading-indicator"
                />
              </div>
          ) : null}
          {staffs?.map((staff, index) => (
            <Staff key={index} staff={staff} />
          ))}
        </div>
        <div><Pagination /></div>
      </div>
    </div>
  );
}

export default StaffTable;
