import axios from '../axiosinstance';

      

//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

export function listConsumables(init){
        return axios.get('/consumables', 
        { params: 
                { 
                        name: init?.name, 
                        type: init?.type, 
                        expired: init?.expired, 
                        outoff: init?.outoff 
                } 
        })
}

export function searchConsumables(search){
        return axios.get('/consumables/find/by-search', { params: { search: search } })
}

export function AddConsumables(data){
        return axios.post('/consumables', data)
}

export function getConsumables(initData){
        return axios.get('/consumables/'+initData?.id)
}

export function updateConsumables(initData){
        return axios.patch('/consumables/'+initData.id, initData)
}

export function consumablesCount(search){
        return axios.get('/consumables/stat/count', { params: search })
}
