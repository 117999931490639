import { React, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import FluidModal from "./FluidModal";
import VitalsModal from "../Vitals/VitalsModal";
import { getSys } from "../../../../services/AuthService";

function AddVitals() {
  let sys = getSys();

  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);
  return (
    <div>
      <div>
        {sys?.role === "sys-admin" ||
        sys?.role === "sys-nurse" ||
        sys?.role === "sys-matron" ? (
          <button
            className="Add-Patient-Report"
            onClick={() => setAddReportShow(true)}
          >
            Add Fluid Chart
          </button>
        ) : null}

        <FluidModal
          show={addReportShow}
          onClose={handleAddReport}
          onOpen={() => setAddReportShow(true)}
        />
      </div>
    </div>
  );
}

export default AddVitals;
