import { React, useEffect, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import AddPreOpNote from "../ClinicalNote/AddPreOpNote";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import Dropdown from "react-bootstrap/Dropdown";
import AddVitals from "./AddVitals";
import ViewPostOpModal from "../ClinicalNote/ViewPostOpModal";
import EditPostOpModal from "../ClinicalNote/EditPostOpModal";
import ViewVitalsModals from "./ViewVitalsModals";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientVitals } from "../../../../features/patients/patientVitalsSlice";
import { Bars } from "react-loader-spinner";
import moment from "moment";
import { getSys } from "../../../../services/AuthService";

function Vitals() {

  const sys = getSys();


  let { id } = useParams();
  const dispatch = useDispatch();
  const { vitals, loading, isSuccessful } = useSelector(
    (state) => state.patientVitals
  );

  const [selected, setSelected] = useState({});

  const [viewvitaldetailsShow, setViewvitaldetailsShow] = useState(false);
  const handleViewvitaldetails = () => setViewvitaldetailsShow(false);

  useEffect(() => {
    if (id) {
      dispatch(fetchPatientVitals({ patientId: id }));
    }
  }, [id]);

  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };
  return (
    <div>
      <div className="Report-Notes">
        <div className="ReportHeader">
          <div className="Report-Heading">Vitals </div>
          <div className="Report-Count">
            <span>0</span>&nbsp;Vitals
          </div>

          {sys?.role === "sys-admin" ||
          sys?.role === "sys-nurse" ||
          sys?.role === "sys-matron" ? (
            <AddVitals />
          ) : null}
        </div>
      </div>
      <br />
      {loading ? (
        <div className="d-flex justify-content-center">
          <Bars
            heigth="30"
            width="30"
            color="gray"
            ariaLabel="loading-indicator"
          />
        </div>
      ) : null}
      {vitals?.map((item, index) => (
        <div className="Patient-Record-Container">
          <div key={index} className="Patient-Record-Wrapper">
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Vital Number</div>
              <div className="Wallet-Ref-Result">{item?.code}</div>
            </div>
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Nurse's Name</div>
              <div className="Wallet-Ref-Result">{item?.user?.full_name}</div>
            </div>
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Vital Subject</div>
              <div className="Wallet-Ref-Result">{item?.subject}</div>
            </div>
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Date</div>
              <div className="Wallet-Ref-Result">
                {showDate(item?.created_at)}
              </div>
            </div>
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Status</div>
              <div className="Wallet-Ref-Result">{item?.status}</div>
            </div>
            <div className="Action-Drop-Down">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
                  Manage Vitals
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setSelected(item);
                      setViewvitaldetailsShow(true);
                    }}
                    className="Solera-Patients"
                  >
                    View Vitals
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      ))}

      <div>
        <ViewVitalsModals
          selected={selected}
          show={viewvitaldetailsShow}
          onClose={handleViewvitaldetails}
          onOpen={() => setViewvitaldetailsShow(true)}
        />
      </div>
    </div>
  );
}

export default Vitals;
