import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listDrugs } from '../../services/DrugService'

const initialState = {
    loading: false,
    outOfStockDrugs: [],
    paginator: {},
    isSuccessful: false
}

export const fetchoutOfStockDrugs = createAsyncThunk('fetchoutOfStockDrugs', (initData) => {
    return listDrugs(initData)
    .then((response) => response.data.data)
})


const outOfStockDrugsSlice = createSlice({
    name: 'outOfStockDrugs',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchoutOfStockDrugs.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchoutOfStockDrugs.fulfilled, (state, action ) => {
        state.loading = false
        state.outOfStockDrugs = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchoutOfStockDrugs.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default outOfStockDrugsSlice.reducer 