import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listFluidCharts } from '../../../services/FluidChartService'

const initialState = {
    loading: false,
    fluids: [],
    isSuccessful: false
}

export const fetchfluidCharts = createAsyncThunk('fetchfluidCharts', (initData) => {
    return listFluidCharts(initData)
    .then((response) => response.data.data)
})


const fluidChartsSlice = createSlice({
    name: 'fluidCharts',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchfluidCharts.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchfluidCharts.fulfilled, (state, action ) => {
        state.loading = false
        state.fluids = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchfluidCharts.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default fluidChartsSlice.reducer 