import axios from '../axiosinstance';

      

//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

export function listStaff(search){
        return axios.get('/staff', { params: { name: search } })
}

export function addStaff(data){
        return axios.post('/staff', data)
}

export function getStaff(initData){
        return axios.get('/staff/'+initData?.id)
}

export function updateStaff(initData){
        return axios.patch('/staff/'+initData.id, initData)
}

export function staffCount(search){
        return axios.get('/staff/stat/count', { params: { name: search } })
}

