import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import "../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Swal from "sweetalert2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { getUser } from "../../services/AuthService";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import { fetchPatients } from "../../features/patients/patientsSlice";
import { useDispatch } from "react-redux";
import { addPatient } from "../../services/PatientService";
import RichTextEditor from 'react-rte';  // Copy Editor 


function AddPatientModal({ show, onClose, props }) {
  const dispatch = useDispatch()
  const user = getUser();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());// Copy Editor 
  const [note, setNote] = useState("");


  const [otherName, setOtherName] = useState('');
  const [errOtherName, setErrOtherName] = useState("");
  const [lastName, setLastName] = useState('');
  const [errlastName, setErrLastName] = useState("");
  const [gender, setGender] = useState('');
  const [errGender, setErrGender] = useState("");
  const [age, setAge] = useState('');
  const [errAge, setErrAge] = useState("");
  const [email, setEmail] = useState('');
  const [errEmail, setErrEmail] = useState("");
  const [phone, setPhone] = useState('');
  const [errPhone, setErrPhone] = useState("");

  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [stateN, setSateN] = useState('');
  const [fatherOccupation, setFatherOccupation] = useState('');
  const [motherOccupation, setMotherOccupation] = useState('');
  const [caregiverName, setCaregiverName] = useState('');
  const [caregiverOccupation, setCaregiverOccupation] = useState('');
  const [caregiverMarital, setCaregiverMarital] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [language, setLanguage] = useState("");
  const [isAdult, setIsAdult] = useState(false);
  const [maritalStatus, setMaritalStatus] = useState("");
  const [isAdultOccupation, setIsAdultOccupation] = useState("");
  const [modeAdmission, setModeAdmission] = useState("");
  const [dateDiscovery, setDateDiscovery] = useState("");
  const [referredBy, setReferredBy] = useState("");
  const [nutritionalStatus, setNutritionalStatus] = useState("");
  

  const [selectedFileConcent, setSelectedFileConcent] = useState('')
  const [selectedFileRefLetter, setSelectedFileRefLetter] = useState('')
  const [selectedFileRefForm, setSelectedFileRefForm] = useState('')

  const [addPatientShow, setAddPatientShow] = useState(false);
  const handleAddPatient = () => setAddPatientShow(false);

  const handleAdd = () => {
    if (!otherName) {
      setErrOtherName("First name is required");
      return;
    }
    if (!lastName) {
      setErrLastName("Last name is required");
      return;
    }
    if (!age) {
      setErrAge("Age is required");
      return;
    }
    if (!gender) {
      setErrGender("Gender is required");
      return;
    }

    if (!email) {
      setErrEmail("Email is required");
      return;
    }

    if (!phone) {
      setErrPhone("Phone is required");
      return;
    }
    setLoading(true);

    let formData = new FormData(); 

    formData.append('user', user?._id);  
    formData.append('first_name', otherName);
    formData.append('last_name', lastName);  
    formData.append('age', age);
    formData.append('gender', gender);  
    formData.append('email', email);
    formData.append('phone', phone);  
    formData.append('address', address);
    formData.append('city', city);  
    formData.append('state', stateN);
    formData.append('language', language);  
    formData.append('date_of_birth', dateOfBirth);

    formData.append('father_occupation', fatherOccupation);  
    formData.append('mother_occupation', motherOccupation);
    formData.append('caregiver_name', caregiverName);  
    formData.append('caregiver_occupation', caregiverOccupation);
    formData.append('caregiver_marital', caregiverMarital);  
    formData.append('note', note);
    formData.append('is_adult', isAdult);  
    formData.append('marital_status', maritalStatus);
    formData.append('is_adult_occupation', isAdultOccupation);  
    formData.append('mode_admission', modeAdmission);
    formData.append('date_discovery', dateDiscovery);  
    formData.append('referred_by', referredBy);
    formData.append('nutritional_status', nutritionalStatus);

    formData.append('photo', selectedFileRefForm);
    formData.append('concent', selectedFileConcent);
    formData.append('letter', selectedFileRefLetter);

    addPatient(formData)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          setAddPatientShow(false);
          dispatch(fetchPatients({}))
          clearData()
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  const onSelectFile = (e, name) => {
    if (!e.target.files || e.target.files.length === 0) {
        if(name === 'ref-form') {setSelectedFileRefForm(undefined); return}
        if(name === 'ref-letter') {setSelectedFileRefLetter(undefined); return}
        if(name === 'concent') {setSelectedFileConcent(undefined); return}
    }
    // if(name === 'ref-form') setSelectedFileRefForm(e.target.files[0])
    // if(name === 'ref-letter') setSelectedFileRefLetter(e.target.files[0])
    // if(name === 'concent') setSelectedFileConcent(e.target.files[0]);

    if(name === 'ref-form') console.log('ref-form', e.target.files[0])
    if(name === 'ref-letter') console.log('ref-letter', e.target.files[0])
    if(name === 'concent') console.log('concent', e.target.files[0]);
}

const onSelectFileRefForm = e => {
  if (!e.target.files || e.target.files.length === 0) {
      setSelectedFileRefForm(undefined)
      return
  }
  // I've kept this example simple by using the first image instead of multiple
  setSelectedFileRefForm(e.target.files[0])
}

const onSelectFileRefLetter = e => {
  if (!e.target.files || e.target.files.length === 0) {
      setSelectedFileRefLetter(undefined)
      return
  }
  // I've kept this example simple by using the first image instead of multiple
  setSelectedFileRefLetter(e.target.files[0])
}

const onSelectFileConcent = e => {
  if (!e.target.files || e.target.files.length === 0) {
      setSelectedFileConcent(undefined)
      return
  }
  // I've kept this example simple by using the first image instead of multiple
  setSelectedFileConcent(e.target.files[0])
}

  const sex = [
    {
      value: "Gender",
      label: "Gender",
    },
    {
      value: "Male",
      label: "Male",
    },
    {
      value: "Female",
      label: "Female",
    },
  ];
  const status = [
    {
      value: "Single",
      label: "Single",
    },
    {
      value: "Married",
      label: "Married",
    },
  ];

  const adult = [
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    },
  ];

  const admission = [
    {
      value: "Came on their own",
      label: "Came on their own",
    },
    {
      value: "Discover during sanitization program",
      label: "Discover during sanitization program",
    },
    {
      value: "Referred",
      label: "Referred",
    },

    {
      value: "Others",
      label: "Others",
    },
  ];

  const nutritional = [
    {
      value: "Normal",
      label: "Normal",
    },
    {
      value: "Moderate malnutrition",
      label: "Moderate malnutrition",
    },
    {
      value: "Severe malnutrition",
      label: "Severe malnutrition",
    
    },
  ];

  


  const languages = [
    {
      value: "PG",
      label: "PG",
    },
    {
      value: "HA",
      label: "HA",
    },
    {
      value: "IG",
      label: "IG",
    },
    {
      value: "YO",
      label: "YO",
    },
    {
      value: "FUL",
      label: "FUL",
    },
    {
      value: "OTHERS",
      label: "OTHERS",
    },
  ];

  {/* Copy Editor */}
  const onChange = (value) => {
    setValue(value);
    value.toString('html')
    setNote(value.toString('html'))
  };

  const clearData = () => {
    setOtherName("")
    setLastName("")
    setGender("")
    setAge("")
    setEmail("")
    setPhone("")
    setAddress("")
    setCity("")
    setSateN("")
    setFatherOccupation("")
    setMotherOccupation("")
    setCaregiverName("")
    setCaregiverOccupation("")
    setCaregiverMarital("")
    setDateOfBirth("")
    setLanguage("")
    setLanguage("")
    setIsAdult(false)
    setMaritalStatus("")
    setIsAdultOccupation("")
    setModeAdmission("")
    setDateDiscovery("")
    setReferredBy("")
    setNutritionalStatus("")
  }

  return (
    <div>
      {" "}
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Solera__ModalHeadings">
              <MdClose className="Solera__Close" onClick={onClose} />
            </div>

            <div className="Solera__ModalHeadings"></div>
            <div className="Solera-Modal-Container">
              <div className="Solera__Headings">
                <div className="Log__Cont">
                  <img
                    src="/assets/lg.png"
                    alt=""
                    className="Log__Img__Modal"
                  />
                </div>
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">
                    Register a patient
                  </div>
                  <div className="Solera-Settings-Note">
                    Register your patient here
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="FormSect-Contianer">
                <div className="FormSect">Patient Personal Details</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Surname"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={lastName}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setErrLastName("")
                          }else{
                            setErrLastName("Last name is required")
                          }
                          setLastName(event.target.value)
                        }}
                      />
                      {(errlastName) && <div className='input_error'>{errlastName}</div>}
                      
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Other Names"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={otherName}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setOtherName("")
                          }else{
                            setOtherName("Name is required")
                          }
                          setOtherName(event.target.value)
                        }}
                      />
                      {(errOtherName) && <div className='input_error'>{errOtherName}</div>}
                      
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <div>
                        <TextField
                          fullWidth
                          id="outlined-select-currency"
                          select
                          label="Select Gender"
                          defaultValue="Gender"
                          value={gender}
                          onChange={(event) => {
                            if(event.target.value.length){
                              setErrGender("")
                            }else{
                              setErrGender("Gender is required")
                            }
                            setGender(event.target.value)
                          }}
                        >
                          {sex.map((option, index) => (
                            <MenuItem key={index} value={option?.value || ""}>
                              {option.label}
                            </MenuItem>
                          ))}
                          {(errGender) && <div className='input_error'>{errGender}</div>}

                        </TextField>
                      </div>
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        id="outlined-select-currency"
                        select
                        label="Select Language"
                        defaultValue="Language"
                        value={language}
                        onChange={(event) => {
                          setLanguage(event.target.value)
                        }}
                      >
                        {languages.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    {" "}
                    <div className="">Date of Birth</div>
                  </div>
                </div>{" "}
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <div>
                        <TextField
                          fullWidth
                          // label="Date of Birth"
                          id="fullWidth"
                          type="date"
                          className="Solera-Login-Input mb-3"
                          selected={dateOfBirth} 
                          onChange={async(date) => {
                            await setDateOfBirth(date.target.value)
                          }} 
                        />
                      </div>
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Age"
                        id="fullWidth"
                        type="number"
                        className="Solera-Login-Input mb-3"
                        value={age}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setErrAge("")
                          }else{
                            setErrAge("Age is required")
                          }
                          setAge(event.target.value)
                        }}
                      />
                      {(errAge) && <div className='input_error'>{errAge}</div>}
                      
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <div>
                        <TextField
                          fullWidth
                          label="Father's occupation"
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          value={fatherOccupation}
                          onChange={(event) => {
                            setFatherOccupation(event.target.value)
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Mother's occupation"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={motherOccupation}
                        onChange={(event) => {
                          setMotherOccupation(event.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                {/* <div className="FormSect">Patient Contact Details</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div> */}
               
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Address"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={address}
                        onChange={(event) => {
                          setAddress(event.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="City"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={city}
                        onChange={(event) => {
                          setCity(event.target.value)
                        }}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="State"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={stateN}
                        onChange={(event) => {
                          setSateN(event.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="FormSect-Contianer">
                <div className="FormSect">If Adult
                </div>
                <div className="FormSect-Note">
                  Please check the button below if the patient is an adult.
                </div>
                <TextField
                  type="checkbox"
                  className="ml-3"
                  id="outlined-select-currency"
                  label="Select Marital status"
                  value={isAdult}
                  onChange={(event) => {
                    setIsAdult(event.target.checked)
                  }}
                />
                <div className="container-fluid p-0 FormInput">
                  
                    
                    {
                      isAdult ? (
                        <div className="row gx-4">
                          <div className="col-lg">
                          <TextField
                              fullWidth
                              id="outlined-select-currency"
                              select
                              label="Select Marital status"
                              value={maritalStatus}
                              onChange={(event) => {
                                setMaritalStatus(event.target.value)
                              }}
                            >
                              {status.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Occupation"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              value={isAdultOccupation}
                              onChange={(event) => {
                                setIsAdultOccupation(event.target.value)
                              }}
                            />
                          </div>
                        </div>   
                      ) : null
                    }
                </div>
                
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Caregiver's Details</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Name"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={caregiverName}
                        onChange={(event) => {
                          setCaregiverName(event.target.value)
                        }}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Occupation"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={caregiverOccupation}
                        onChange={(event) => {
                          setCaregiverOccupation(event.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col">
                      <TextField
                        fullWidth
                        id="outlined-select-currency"
                        select
                        label="Select Marital status"
                        defaultValue="status"
                        onChange={(event) => {
                          setCaregiverMarital(event.target.value)
                        }}
                      >
                        {status.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Phone Number"
                        id="fullWidth"
                        type="number"
                        className="Solera-Login-Input mb-3"
                        value={phone}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setErrPhone("")
                          }else{
                            setErrPhone("Phone is required")
                          }
                          setPhone(event.target.value)
                        }}
                      />
                      {(errPhone) && <div className='input_error'>{errPhone}</div>}
                      
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Email"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={email}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setErrEmail("")
                          }else{
                            setErrEmail("Email is required")
                          }
                          setEmail(event.target.value)
                        }}
                      />
                      {(errEmail) && <div className='input_error'>{errEmail}</div>}
                      
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Admission</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                    <div className="">Mode of admission</div>
                    <br/>
                    <TextField
                        fullWidth
                        id="outlined-select-currency"
                        select
                        label="Mode of admission and discovery"
                        defaultValue="status"
                        value={modeAdmission}
                        onChange={(event) => {
                          setModeAdmission(event.target.value)
                        }}
                      >
                        {admission.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      
                    </div>
                    <div className="col-lg">
                    <div className="">'Date of Scouting</div>
                    <br/>
                      <TextField
                        fullWidth
                        // label="Date of discovery"
                        id="fullWidth"
                        type="date"
                        className="Solera-Login-Input mb-3"
                        value={dateDiscovery}
                        onChange={(event) => {
                          setDateDiscovery(event.target.value)
                        }}
                      />
                      
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="If referred? Enter referral center"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={referredBy}
                        onChange={(event) => {
                          setReferredBy(event.target.value)
                        }}
                      />
                    </div>
                    <div className="col-lg">
                    <TextField
                        fullWidth
                        id="outlined-select-currency"
                        select
                        label="Nutritional status"
                        value={nutritionalStatus}
                        onChange={(event) => {
                          setNutritionalStatus(event.target.value)
                        }}
                      >
                        {nutritional.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                </div>
               
                 <br />
                <div className="FormSect">Other Details</div>
                <br />
                <div >
                {/* Copy Editor */}
                <RichTextEditor
                  className="editor"
                  value={value} onChange={onChange} 
                />
                </div>
              </div>
              {
                loading ? (
                  <Bars
                    heigth="30"
                    width="30"
                    color="gray"
                    ariaLabel="loading-indicator"
                  />
                ) : (
                  <button className="Add-Button" onClick={handleAdd}>
                    Register Patient
                  </button>
                ) 
              }
              
              <br /><br /> <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default AddPatientModal;
