
import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import moment from "moment";
import {  Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

function ViewDietician({ show, onClose, selected }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [addDoctorShow, setAddDoctorShow] = useState(false);
  const handleAddDoctor = () => setAddDoctorShow(false);

  const handleSaveDoctor = () => {
    onClose();
    setAddDoctorShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }

  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera-Modal-Container">
                <br />
                <div className="Report-Display-Heading">Dietician's Note</div>
                <div className="Report-Tittle">Dietician's Note</div>
                <div className="Solera-Modal-Container">
                  <div className="Note-Modal-Cont">
                  <div className="Doctors-Name">Doctor's Name</div>
                      <div className="Doctors-Name1">{selected?.user?.full_name}</div>
                      <div className="Doctors-Name">Date</div>
                      <div className="Doctors-Name1">{showDate(selected?.created_at)}</div>
                      <div className="Doctors-Name">Number</div>
                      <div className="Doctors-Name1">{selected?.code}</div>
                      
                      <br /><br />
                    <div>
                      <div className="Doctors-Name">Note</div>
                      {/* <div className="Doctors-Name1">{selected?.note}</div> */}
                      <div className="Report-Date4">
                      <div dangerouslySetInnerHTML={{__html:selected?.note}}></div>
                      </div>
                    </div>
                    <br />
                    {
                      selected?.url?.length ? (
                        selected?.fileType === "application/pdf" ? (
                          <div className="pdf-div">
                            <Viewer
                                fileUrl={selected?.url}
                                plugins={[defaultLayoutPluginInstance]}
                            />
                          </div>
                          
                        ) : (
                          <div>
                            <img width={900} height={1000} src={selected?.url} />
                          </div>
                        )
                        
                      ): null
                    }
                  </div>
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ViewDietician;

