import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { patientCount } from '../../services/PatientService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchPatientDischargedCount = createAsyncThunk('fetchPatientDischargedCount', (initData) => {
    return patientCount(initData)
    .then((response) =>  response.data.data)
})


const patientDischargedCountSlice = createSlice({
    name: 'patientDischargedCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientDischargedCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatientDischargedCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default patientDischargedCountSlice.reducer 