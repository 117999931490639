import { React } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import "../../Component/Modals/Modal.css";
import moment from "moment";

function PatientDetails({ show, setImage, onClose, selected, setImageName }) {
  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };

  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                      Patient Medical Details
                    </div>
                    <div>
                      <div className="FormSect-Note">
                        Patient Number : {selected?.cus_code?.toUpperCase()}
                      </div>
                      <div className="FormSect-Note">
                        Patient Registration Date : &nbsp;
                        <span>{showDate(selected?.created_at)}</span>
                      </div>
                      <div className="FormSect-Note">
                        Patient Admission Status : &nbsp;
                        <span>{selected?.status}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="container-fluid p-0 overflow-hidden Vital-Container">
                  <div className="row g-2">
                    <div className="col-lg-6">
                      <div className="Patient-View-Box">
                        <div className="Vital-Box-2">
                          <div className="Vital-Box-Heading">
                            <div className="FormSect">
                              Patient Personal Details
                            </div>{" "}
                          </div>
                        </div>
                        <div className="Vital-Box-Result">
                          <div>
                            <div>
                              <div className="FormSect-Note">
                                Surname : &nbsp;
                                <span>{selected?.last_name}</span>
                              </div>
                              <div className="FormSect-Note">
                                First Name: &nbsp;
                                <span>{selected?.first_name}</span>
                              </div>
                              <div className="FormSect-Note">
                                Gender : &nbsp;<span>{selected?.gender}</span>
                              </div>
                              <div className="FormSect-Note">
                                Language :&nbsp;
                                <span>
                                  {selected?.language
                                    ? selected?.language
                                    : "none"}
                                </span>
                              </div>
                              <div className="FormSect-Note">
                                Date of Birth :&nbsp;
                                <span>{showDate(selected?.date_of_birth)}</span>
                              </div>
                              <div className="FormSect-Note">
                                Age :&nbsp;<span>{selected?.age}</span>
                              </div>
                              <div className="FormSect-Note">
                                Father's occupation :&nbsp;
                                <span>{selected?.father_occupation}</span>
                              </div>
                              <div className="FormSect-Note">
                                Mother's occupation :&nbsp;
                                <span>{selected?.mother_occupation}</span>
                              </div>
                              <div className="FormSect-Note">
                                Address :&nbsp;<span>{selected?.address}</span>
                              </div>
                              <div className="FormSect-Note">
                                State:&nbsp;<span>{selected?.state}</span>
                              </div>
                              <div className="FormSect-Note">
                                City:&nbsp;<span>{selected?.city}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="Patient-View-Box">
                        <div className="Vital-Box-2">
                          <div className="Vital-Box-Heading">
                            {" "}
                            <div className="FormSect">
                              Patient Contact Details
                            </div>{" "}
                          </div>
                        </div>
                       
                      </div> */}
                    </div>
                    <div className="col-lg-6">
                      <div className="Patient-View-Box">
                        <div className="Vital-Box-2">
                          <div className="Vital-Box-Heading">
                            {" "}
                            <div className="FormSect">
                              Patient Caregiver Details
                            </div>{" "}
                          </div>
                        </div>
                        <div className="Vital-Box-Result">
                          <div>
                            <div className="FormSect-Note">
                              Name :&nbsp;
                              <span>{selected?.caregiver_name}</span>
                            </div>
                            <div className="FormSect-Note">
                              Occupation :&nbsp;
                              <span>{selected?.caregiver_occupation}</span>
                            </div>
                            <div className="FormSect-Note">
                              Marital Status:&nbsp;
                              <span>{selected?.caregiver_marital}</span>
                            </div>
                            <div className="FormSect-Note">
                              Phone Number :&nbsp;<span>{selected?.phone}</span>
                            </div>
                            <div className="FormSect-Note">
                              Email:&nbsp;<span>{selected?.email}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="Patient-View-Box">
                        <div className="Vital-Box-2">
                          <div className="Vital-Box-Heading">
                            {" "}
                            <div className="FormSect">Admission</div>{" "}
                          </div>
                        </div>
                        <div className="Vital-Box-Result">
                          <div>
                            <div className="FormSect-Note">
                              Mode of admission :&nbsp;
                              <span>{selected?.mode_admission}</span>
                            </div>
                            <div className="FormSect-Note">
                              Date if discovery:&nbsp;
                              <span>{showDate(selected?.date_discovery)}</span>
                            </div>
                            <div className="FormSect-Note">
                              Referral Center :&nbsp;
                              <span>{selected?.referred_by}</span>
                            </div>
                            <div className="FormSect-Note">
                              Nutritional Status:&nbsp;
                              <span>{selected?.nutritional_status}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br /> <br />
                      <div className="Patient-View-Box">
                        <div className="Vital-Box-2">
                          <div className="Vital-Box-Heading">
                            {" "}
                            <div className="FormSect">If Adult</div>{" "}
                          </div>
                        </div>
                        {selected?.is_adult ? (
                          <div className="Vital-Box-Result">
                            <div>
                              <div className="FormSect-Note">
                                Marital Status :&nbsp;
                                <span>{selected?.marital_status}</span>
                              </div>
                              <div className="FormSect-Note">
                                Occupation:&nbsp;
                                <span>{selected?.is_adult_occupation}</span>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>{" "}
                  </div>{" "}
                </div>
                <br />
                <br />
                {/* <div className="container-fluid p-0 overflow-hidden Vital-Container">
                  <div className="row g-2">
                    <div className="col-lg-6">
                      <div className="Patient-View-Box">
                        <div className="Vital-Box-2">
                          <div className="Vital-Box-Heading">
                            <div className="FormSect">Admission</div>
                          </div>
                        </div>
                        <div className="Vital-Box-Result">
                          <div>
                            <div className="FormSect-Note">
                              Mode of admission of discovery :&nbsp;
                              <span>Mode of admission of discovery</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="Patient-View-Box">
                        <div className="Vital-Box-2">
                          <div className="Vital-Box-Heading">
                            {" "}
                            <div className="FormSect">
                              History of the disease
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="FormSect-Note">
                            Main Pathology:&nbsp;<span>Main Pathology</span>
                          </div>
                          <div className="FormSect-Note">
                            Stage:&nbsp;<span>Stage</span>
                          </div>
                        </div>{" "}
                      </div>
                    </div>{" "}
                  </div>{" "}
                </div> */}
                {/* <div className="container-fluid p-0 overflow-hidden Vital-Container">
                  <div className="row g-2">
                    <div className="col-lg-6">
                      <div className="Patient-View-Box">
                        <div className="Vital-Box-2">
                          <div className="Vital-Box-Heading">
                            <div className="FormSect">HIV Status</div>
                          </div>
                        </div>
                        <div className="Vital-Box-Result">
                          <div>
                            <div className="FormSect-Note">Negative</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="Patient-View-Box">
                        <div className="Vital-Box-2">
                          <div className="Vital-Box-Heading">
                            {" "}
                            <div className="FormSect">Hepatitis</div>
                          </div>
                        </div>
                        <div>
                          <div>
                            <div className="FormSect-Note">Negative</div>
                          </div>{" "}
                        </div>{" "}
                      </div>
                    </div>{" "}
                  </div>{" "}
                </div> */}
                <div className="container-fluid p-0 overflow-hidden Vital-Container">
                  <div className="FormHeadings">View all forms here</div>
                  <br /> <br />
                  <div className="Patient-View-Box">
                    <div className="Vital-Box-2">
                      <div className="Vital-Box-Heading">
                        {" "}
                        <div className="FormSect">Consent Form</div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <button
                          onClick={() => {
                            setImageName("concent");
                            setImage(true);
                          }}
                          className="Add-Button"
                        >
                          View Consent form
                        </button>
                      </div>{" "}
                    </div>
                  </div>{" "}
                  <br />
                  <div className="Patient-View-Box">
                    <div className="Vital-Box-2">
                      <div className="Vital-Box-Heading">
                        {" "}
                        <div className="FormSect">Referral Form</div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <button
                          onClick={() => {
                            setImageName("ref-from");
                            setImage(true);
                          }}
                          className="Add-Button"
                        >
                          View Referral form
                        </button>
                      </div>{" "}
                    </div>
                  </div>
                  <br />
                  <div className="Patient-View-Box">
                    <div className="Vital-Box-2">
                      <div className="Vital-Box-Heading">
                        {" "}
                        <div className="FormSect">LAMA Form</div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <button
                          onClick={() => {
                            setImageName("ref-letter");
                            setImage(true);
                          }}
                          className="Add-Button"
                        >
                          View LAMA
                        </button>
                      </div>{" "}
                    </div>
                  </div>{" "}
                  <br />
                  <div className="Patient-View-Box">
                    <div className="Vital-Box-2">
                      <div className="Vital-Box-Heading">
                        {" "}
                        <div className="FormSect">Release form</div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <button
                          onClick={() => {
                            setImageName("release-form");
                            setImage(true);
                          }}
                          className="Add-Button"
                        >
                          View Release form
                        </button>
                      </div>{" "}
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 overflow-hidden Vital-Container">
                  <div className="row g-2">
                    <div className="col-lg">
                      <div className="Patient-View-Box">
                        <div className="Vital-Box-2">
                          <div className="Vital-Box-Heading">
                            <div className="FormSect">Others</div>
                          </div>
                        </div>
                        <div className="Vital-Box-Result">
                          <div
                            dangerouslySetInnerHTML={{ __html: selected?.note }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default PatientDetails;
