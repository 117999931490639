import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listConsumables } from '../../services/ConsumableService'

const initialState = {
    loading: false,
    expiredNonConsumes: [],
    paginator: {},
    isSuccessful: false
}

export const fetchExpiryNonConsumables = createAsyncThunk('fetchExpiryNonConsumables', (initData) => {
    return listConsumables(initData)
    .then((response) => response.data.data)
})


const expiryNonConsumablesSlice = createSlice({
    name: 'expiryNonConsumables',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchExpiryNonConsumables.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchExpiryNonConsumables.fulfilled, (state, action ) => {
        state.loading = false
        state.expiredNonConsumes = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchExpiryNonConsumables.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default expiryNonConsumablesSlice.reducer 