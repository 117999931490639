import { React, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import "../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import MenuItem from "@mui/material/MenuItem";
import { updateUser } from "../../services/UserService";
import { fetchUsers } from "../../features/admins/usersSlice";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import { useDispatch } from "react-redux";

function EditAdmin({ show, onClose, onOpen, selected }) {
  const dispatch = useDispatch()

  const [editAdminShow, setEditAdminShow] = useState(false);
  const handleEditAdmin = () => setEditAdminShow(false);
  const [loading, setLoading] = useState(false);


  const [otherName, setOtherName] = useState("");
  const [errOtherName, setErrOtherName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errlastName, setErrLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [errPhone, setErrPhone] = useState("");

  useEffect(() => {
    if(selected?._id){
      setOtherName(selected?.user?.first_name)
      setLastName(selected?.user?.last_name)
      setPhone(selected?.user?.phone)
    }
  }, [show])


  const handleUpdate = () => {
    
    setLoading(true);
    const data = {
      id: selected?.user?._id,
      first_name: otherName,
      last_name: lastName,
      phone: phone,
    };
    updateUser(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(fetchUsers({}))
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  return (
    <div>
      {" "}
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Solera__ModalHeadings">
              <MdClose className="Solera__Close" onClick={onClose} />
            </div>

            <div className="Solera__ModalHeadings"></div>
            <div className="Solera-Modal-Container">
              <div className="Solera__Headings">
                <div className="Log__Cont">
                  <img
                    src="/assets/lg.png"
                    alt=""
                    className="Log__Img__Modal"
                  />
                </div>
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">
                    Edit Admin Account
                  </div>
                  <div className="Solera-Settings-Note">
                    Edit your admin account and assign roles.
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="container-fluid p-0 FormInput">
                <div className="row gx-4">
                  <div className="col-lg">
                    <TextField
                      fullWidth
                      label="First Name"
                      id="fullWidth"
                      type="text"
                      className="Solera-Login-Input mb-3"
                      value={otherName}
                      onChange={(event) => {
                        if(event.target.value.length){
                          setErrOtherName("")
                        }else{
                          setErrOtherName("First name is required")
                        }
                        setOtherName(event.target.value)
                      }}
                    />
                    {(errOtherName) && <div className='input_error'>{errOtherName}</div>}
                  </div>
                  <div className="col-lg">
                    <TextField
                      fullWidth
                      label="Last name"
                      id="fullWidth"
                      type="text"
                      className="Solera-Login-Input mb-3"
                      value={lastName}
                      onChange={(event) => {
                        if(event.target.value.length){
                          setErrLastName("")
                        }else{
                          setErrLastName("Last name is required")
                        }
                        setLastName(event.target.value)
                      }}
                    />
                    {(errlastName) && <div className='input_error'>{errlastName}</div>}
                  </div>
                </div>
              </div>
              {/* <div className="container-fluid p-0 FormInput">
                <div className="row gx-4">
                  <div className="col-lg">
                    <div>
                      <TextField
                        fullWidth
                        id="outlined-select-currency"
                        select
                        label="Occupation"
                        defaultValue="Occupation"
                      >
                        {occupation.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="container-fluid p-0 FormInput">
                <div className="row gx-4">
                  <div className="col-lg">
                    <TextField
                      fullWidth
                      label="Phone number"
                      id="fullWidth"
                      type="number"
                      className="Solera-Login-Input mb-3"
                      value={phone}
                      onChange={(event) => {
                        if(event.target.value.length){
                          setErrPhone("")
                        }else{
                          setErrPhone("Phone is required")
                        }
                        setPhone(event.target.value)
                      }}
                    />
                    {(errPhone) && <div className='input_error'>{errPhone}</div>}
                  </div>
                  {/* <div className="col-lg">
                    <TextField
                      fullWidth
                      label="Password"
                      id="fullWidth"
                      type="password"
                      className="Solera-Login-Input mb-3"
                    />
                  </div> */}
                </div>
              </div>
              {
                loading ? (
                  <Bars
                    heigth="30"
                    width="30"
                    color="gray"
                    ariaLabel="loading-indicator"
                  />
                ) : (
                  <button className="Add-Button" onClick={handleUpdate}>
                    Save Admin
                  </button>
                ) 
              }
              
              <br /> <br /> <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default EditAdmin;
