


import { React } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import "../../Component/Modals/Modal.css";
import moment from "moment";


function NonMedListView({ show, onClose, onOpen, selected }) {
  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }
  return (
    <div>
    {" "}
    <div>
      {" "}
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Solera__ModalHeadings">
              <MdClose className="Solera__Close" onClick={onClose} />
            </div>

            <div className="Solera__ModalHeadings"></div>
            <div className="Solera-Modal-Container">
              <div className="Solera__Headings">
                <div className="Log__Cont">
                  <img
                    src="/assets/lg.png"
                    alt=""
                    className="Log__Img__Modal"
                  />
                </div>
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">Medical Item Details</div>
                 
                </div>
              </div>
              <br />
              <br />
              <div>
                <div className="FormSect">Item Details</div>

                <div>
                  <div className="DisplayItem-Note">
                Item Name :{" "}
                    <span className="FormSect-Note "> {selected?.name}</span>
                  </div>
                  <div className="DisplayItem-Note">Quantity: <span className="FormSect-Note "> {selected?.qty}</span></div>
                  <div className="DisplayItem-Note">Expiry date : 
                    <span className="FormSect-Note "> 
                    {showDate(selected?.expiry_date)}
                    
                    {/* {showDate(selected?.created_at)}   */}
                    </span></div>
                  <div className="DisplayItem-Note">Status : <span className="FormSect-Note "> {selected?.status}</span></div>
                  <div className="DisplayItem-Note">Item Number : <span className="FormSect-Note "> {selected?.code}</span></div>
                </div>
              </div>
              <br />
              <br />
              <div>
                <div className="FormSect">Supplier's Contact Details</div>
                <div>
                  <div className="DisplayItem-Note">Name : <span className="FormSect-Note "> {selected?.supplier_name}</span></div>
                  <div className="DisplayItem-Note">Phone Number: <span className="FormSect-Note ">{selected?.supplier_phone}</span></div>
                  <div className="DisplayItem-Note">Date of Supply : <span className="FormSect-Note "> {showDate(selected?.created_at)}</span></div>
                </div>
              </div>{" "}
              <br />
              <br />
              <div>
                <div className="FormSect">Receiver</div>
                <div className="DisplayItem-Note">Name : <span className="FormSect-Note ">{selected?.user?.full_name}</span></div>
              </div>
              <br />
              <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  </div>
  )
}

export default NonMedListView