import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { consumablesCount } from '../../services/ConsumableService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchConsumableExpiredCount = createAsyncThunk('fetchConsumableExpiredCount', (initData) => {
    return consumablesCount(initData)
    .then((response) =>  response.data.data)
})


const consumableExpiredCountSlice = createSlice({
    name: 'consumableExpiredCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchConsumableExpiredCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchConsumableExpiredCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default consumableExpiredCountSlice.reducer 