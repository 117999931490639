import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import moment from "moment";

function ViewProgressNote({ show, onClose, selected }) {
  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);

  const handleSubComment = () => {
    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }

  const handleSub = () => {
    onClose();
    setViewPreOpNoteDetailsShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };
  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>
     
              <div className="Report-Display-Heading">Progress Note</div>
              <div className="Report-Tittle">Progress Note</div>
              <div className="Report-Notes2">
                <div>
                  <div className="Doctors-Name">
                    Social welfare's name: &nbsp;&nbsp;
                    <span>{selected?.user?.full_name}</span>
                  </div>
                  <div className="Report-Date">
                    Date : &nbsp;&nbsp;<span>{showDate(selected?.created_at)}</span>
                  </div>
                  <div className="Report-Date">
                    Assessment Number : &nbsp;&nbsp;<span> {selected?.code}</span>
                  </div>
                </div>
                <div className="Report-Display">Therapeutic Modalities</div>
                
                <div className="Report-Date4">
                      <div dangerouslySetInnerHTML={{__html: selected?.theraputic_modalities}}></div>
                      </div>
                <br />
                <div className="Report-Display">Session Frequency</div>
              
                <div className="Report-Date4">
                      <div dangerouslySetInnerHTML={{__html: selected?.session_frequency}}></div>
                      </div>
                <br />
                <div className="Report-Display">Session Summary</div>
            
                <div className="Report-Date4">
                      <div dangerouslySetInnerHTML={{__html: selected?.session_summary}}></div>
                      </div>
                <br />
                <div className="Report-Display">
                  Patient response to treatment
                </div>
            
                <div className="Report-Date4">
                      <div dangerouslySetInnerHTML={{__html: selected?.response_to_treatment}}></div>
                      </div>
                <br />
                <div className="Report-Display">Changes in treatment goals</div>
             
                <div className="Report-Date4">
                      <div dangerouslySetInnerHTML={{__html: selected?.treatment_goals}}></div>
                      </div>
                <br />
                <div className="Report-Display">
                  Changes in treatment interventions
                </div>
             
                <div className="Report-Date4">
                  <div dangerouslySetInnerHTML={{__html: selected?.treatment_interventions}}></div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ViewProgressNote;
