import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import { MdAddCircle, MdClose, MdRemoveCircle } from "react-icons/md";

function EditPrescription({ show, onClose }) {
  const [editPreOpNoteDetailsShow, setEditPreOpNoteDetailsShow] =
    useState(false);
  const handleEditPreOpNoteDetails = () => setEditPreOpNoteDetailsShow(false);

  const [serviceList, setServiceList] = useState([{ service: "" }]);

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;
    setServiceList(list);
  };

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };

  const handleServiceAdd = () => {
    setServiceList([...serviceList, { service: "" }]);
  };

  const handleSub = () => {
    onClose();
    setEditPreOpNoteDetailsShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };
  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                      Update Doctor's Prescription
                    </div>
                    <div className="Solera-Settings-Note">
                      Kindy prescribe drugs for this patient.
                    </div>
                  </div>
                </div>
                <br />
                <br />
                {serviceList &&
                  serviceList.map((singleService, index) => (
                    <ul key={index}>
                      {singleService.service && (
                        <li className="ListIt">{singleService.service}</li>
                      )}
                    </ul>
                  ))}
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {serviceList.map((singleService, index) => (
                        <div key={index} className="services">
                          <div className="first-division">
                            <TextField
                              fullWidth
                              label="Add Drug"
                              name="service"
                              type="text"
                              id="fullWidth"
                              value={singleService.service}
                              onChange={(e) => handleServiceChange(e, index)}
                              required
                              placeholder="Add Test"
                              className="Solera-Login-Input mb-3"
                            />
                          </div>
                          <div className="RemoveAndAddBtn">
                            <div className="second-division">
                              {serviceList.length !== 1 && (
                                <MdRemoveCircle
                                  className="CounterIcon"
                                  type="button"
                                  onClick={() => handleServiceRemove(index)}
                                />
                              )}
                            </div>
                            <div>
                              {serviceList.length - 1 === index &&
                                serviceList.length < 100 && (
                                  <MdAddCircle
                                    className="CounterIcon"
                                    type="button"
                                    onClick={handleServiceAdd}
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="FormSect-Contianer">
                    <div className="container-fluid p-0 FormInput">
                      <div className="col">
                        <TextField
                          fullWidth
                          id="outlined-multiline-static "
                          label="Make a note for this Drugs"
                          multiline
                          rows={15}
                          // defaultValue="Default Value"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="FormUpload">
                  <button className="Add-Button" onClick={handleSub}>
                    Prescribe Drugs
                  </button>
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default EditPrescription;
