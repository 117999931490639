import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientProgressNotes } from '../../../services/PatientProgressNoteService'

const initialState = {
    loading: false,
    notes: [],
    isSuccessful: false
}

export const fetchProgressNotes = createAsyncThunk('fetchProgressNotes', (initData) => {
    return listPatientProgressNotes(initData)
    .then((response) => response.data.data)
})


const progressNoteSlice = createSlice({
    name: 'progressNotes',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchProgressNotes.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchProgressNotes.fulfilled, (state, action ) => {
        state.loading = false
        state.notes = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchProgressNotes.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default progressNoteSlice.reducer 