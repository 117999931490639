import { React, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import "../../Component/Modals/Modal.css";
import TestToConduct from "./TestToConduct";
import TreatementNumber from "../../Component/TreatementNumber";
import SampleNumber from "../../Component/SampleNumber";
import PatientNumber from "../PatientPage/PatientNumber";
import PrescribedBy from "../../Component/PrescribedBy";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientTreatmentLaps } from "../../features/patients/patientTreatmentLapsSlice";
import moment from "moment";

function LabTestModal({ show, onClose, selected }) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { patientLaps, loading, isSuccessful } = useSelector(
    (state) => state.patientTreatmentLaps
  );

  useEffect(() => {
    if (show) {
      dispatch(
        fetchPatientTreatmentLaps({
          patientId: id,
          treatmentId: selected?.treatment_infos[0]?._id,
        })
      );
    }
  }, [show]);

  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };

  return (
    <div>
      <div>
        {" "}
        <div>
          {" "}
          <div>
            <Modal
              size="xl"
              show={show}
              onHide={onClose}
              backdrop="static"
              aria-labelledby="example-modal-sizes-title-lg"
              keyboard={false}
              animation={true}
            >
              <Modal.Body>
                <div className="Solera__ModalHeadings">
                  <MdClose className="Solera__Close" onClick={onClose} />
                </div>

                <div className="Solera__ModalHeadings"></div>
                <div className="Solera-Modal-Container">
                  <div className="Solera__Headings">
                    <div className="Log__Cont">
                      <img
                        src="/assets/lg.png"
                        alt=""
                        className="Log__Img__Modal"
                      />
                    </div>
                    <div className="Solera__Headings__Note">
                      <div className="Solera-Settings-Header">
                        Patient Laboratory Test To Conduct
                      </div>

                      <br />
                      <div className="Vital-Box-Heading">
                        <div className="FormSect">Patient's Lab Test</div>
                      </div>
                      <div>
                        <div className="FormSect-Note">
                          Patient Number : &nbsp;
                          <span>
                            {show ? (
                              <PatientNumber
                                pId={selected?.patient_infos[0]?.cus_code}
                              />
                            ) : null}
                          </span>
                        </div>
                        {/* <div className="FormSect-Note">
                          Sample Number : &nbsp;
                          <span>
                            <SampleNumber />
                          </span>
                        </div> */}
                        <div className="FormSect-Note">
                          Treatement Number : &nbsp;
                          <span>
                            {show ? (
                              <TreatementNumber
                                treamentNum={selected?.treatment_infos[0]?.code}
                              />
                            ) : null}
                          </span>
                        </div>
                        <br />
                      </div>

                      <br />
                      <div className="Vital-Box-Heading">
                        <div className="FormSect">Initiating Doctor's Name</div>
                      </div>

                      <div>
                        <div className="FormSect-Note">
                          Doctor name : &nbsp;
                          <span>
                            {show ? (
                              <PrescribedBy
                                name={selected?.user_infos[0]?.last_name+" "+selected?.user_infos[0]?.last_name}
                              />
                            ) : null}
                          </span>
                        </div>
                        {/* <div className="FormSect-Note">
                          Number : &nbsp;
                          <span> {show ? selected?.user_infos[0]?.cus_code?.toUpperCase() : null}</span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="container-fluid p-0 overflow-hidden ">
                    <div className="row g-2">
                      <div className="col-lg-6">
                        <div className="Patient-View-Box">
                          <div className="Vital-Box-2">
                            <div className="Vital-Box-Heading">
                              <div className="FormSect">Test Details</div>{" "}
                            </div>
                          </div>
                          {loading ? <div>Loading...</div> : null}
                          {patientLaps?.map((item, index) => (
                            <div className="Solera__Headings__Note">
                              <div className="Vital-Box-Heading">
                                <div className="FormSect">Test {index + 1}</div>
                              </div>
                              <div>
                                <div className="FormSect-Note">
                                  Test Name : &nbsp;
                                  <span>{item?.name}</span>
                                </div>
                                {/* <div className="FormSect-Note">
                                    Test Code : &nbsp;
                                    <span>
                                      {item?.code}
                                    </span>
                                  </div> */}
                                {/* <div className="FormSect-Note">
                                    Treatement Number : &nbsp;
                                    <span>
                                      {show ? <TreatementNumber treamentNum={item?.treatment?.code.toUpperCase()} /> : null}
                                    </span>
                                  </div> */}
                                {/* <div className="FormSect-Note">
                                    Result : &nbsp;
                                    <span>
                                      {show ? <TreatementNumber treamentNum={item?.result} /> : null}
                                    </span>
                                  </div> */}
                                <div className="FormSect-Note">
                                  Date : &nbsp;
                                  <span>{showDate(item?.created_at)}</span>
                                </div>
                              </div>
                              <br />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="container-fluid p-0 overflow-hidden Vital-Container">
                    <div className="row g-2">
                      <div className="col-lg">
                        <div className="Patient-View-Box">
                          <div className="Vital-Box-2">
                            <div className="Vital-Box-Heading">
                              <div className="FormSect">Doctor' Comment</div>
                            </div>
                          </div>
                          <div className="Vital-Box-Result">
                            {/* <div>
                              <div className="FormSect-Note">{selected?.note}</div>
                            </div> */}

                            <div className="Report-Date4">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: selected?.note,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <br /> <br /> <br />
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LabTestModal;
