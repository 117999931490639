import { React, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import { getUser } from "../../../../services/AuthService";
import { useDispatch } from "react-redux";
import { addPatientTheatre } from "../../../../services/PatientTheatreService";
import { toast } from "react-toastify";
import { fetchPatientAnasethetic } from "../../../../features/patients/patientAnasetheticSlice";
import { Bars } from "react-loader-spinner";
import Form from "react-bootstrap/Form";
import RichTextEditor from "react-rte"; // Copy Editor

function UpdateAnaethticModal({ show, onClose }) {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue()); // Copy Editor
  const [value2, setValue2] = useState(RichTextEditor.createEmptyValue());
  const [value3, setValue3] = useState(RichTextEditor.createEmptyValue());
  const [value4, setValue4] = useState(RichTextEditor.createEmptyValue());
  const [value5, setValue5] = useState(RichTextEditor.createEmptyValue());

  let { id } = useParams();
  const user = getUser();
  const dispatch = useDispatch();
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);

  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);

  const handleAdd = () => {
    setLoading(true);
    const data = {
      user: user?._id,
      patient: id,
      note: note,
      type: "anasethetic",
    };
    console.log(data);
    addPatientTheatre(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(
            fetchPatientAnasethetic({ patientId: id, type: "anasethetic" })
          );
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  {
    /* Copy Editor */
  }
  const onChange = (value) => {
    setValue(value);
    value.toString("html");
    setNote(value.toString("html"));
  };

  const onChange2 = (value) => {
    setValue2(value);
    value.toString("html");
    setNote(value.toString("html"));
  };

  /* Copy Editor */

  const onChange3 = (value) => {
    setValue3(value);
    value.toString("html");
    setNote(value.toString("html"));
  };

  const onChange4 = (value) => {
    setValue4(value);
    value.toString("html");
    setNote(value.toString("html"));
  };
  const onChange5 = (value) => {
    setValue5(value);
    value.toString("html");
    setNote(value.toString("html"));
  };

  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                      Update Pre-Anaesthetic Note
                    </div>
                    <div className="Solera-Settings-Note">Add a Note</div>
                  </div>
                </div>
                <br />
                <br />
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">DIAGNOSTICS</div>
                </div>
                <div>
                  <div className="Solera-Settings-Header44">
                    Surgical-Anaesthetic
                  </div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <RichTextEditor
                          className="editor"
                          value={value}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">
                    PLANNED INTERVENTION
                  </div>
                </div>
                <div>
                  <div className="Solera-Settings-Header44">Treaatments</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <RichTextEditor
                          className="editor"
                          value={value2}
                          onChange={onChange2}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="Solera-Settings-Header44">SYSTEM</div>
                <br />
                <div className="Solera-Settings-Header44">RESPIRATORY</div>
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Asthma
"
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Bronchitis" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Pheumonia" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Sinusitis" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Others
"
                          />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                <br />
                <div className="Solera-Settings-Header44">CARDIOVASCULAR</div>
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Htn" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Cardiomiophathy" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Rhythm Disorder
"
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Palpitations" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Endocarditis" />
                        </div>

                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Heart Failure
"
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Others" />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                <br />
                <div className="Solera-Settings-Header44">HEPATO/GASTRO</div>
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Obesity" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Epigastralgies
"
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Hepatities" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Cirrrhosis" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Pancreatitis" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Others
"
                          />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                <div className="Solera-Settings-Header44">
                  {" "}
                  NEURO/MUSCULO-SKELETAL
                </div>
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Anthritis" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Scouosis" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Migranne/Headache" />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                <br /> <br />
                <br />
                <div className="Solera-Settings-Header44">EXAMINATION</div>
                <br />
                <br />
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="Solera-Settings-Header44">CONJUCITIVA</div>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Normal
"
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Mildly Red" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Red" />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                  <Form>
                    <div className="Solera-Settings-Header44">
                      PULMONARY AUSCULTATION
                    </div>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Normal
"
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Abnormal " />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Red" />
                        </div>
                        <div className="container-fluid p-0 FormInput">
                          <div className="row gx-4">
                            <div className="col-lg">
                              <TextField
                                fullWidth
                                label="If Yes , Nature"
                                id="fullWidth"
                                type="text"
                                className="Solera-Login-Input mb-3"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                  <Form>
                    <div className="Solera-Settings-Header44">
                      CARDIAC AUSCULTATION
                    </div>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Normal
"
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Abnormal" />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                  <Form>
                    <div className="Solera-Settings-Header44">
                      VENOUS ACCESS
                    </div>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Accessible
"
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Difficult" />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                <div className="Solera-Settings-Header44">
                  AIRWAY EXAMINATION
                </div>
                <Form>
                  <div className="Solera-Settings-Header44">MOUTH OPENING</div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Normal
"
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Psmall" />
                      </div>
                      <div className="container-fluid p-0 FormInput">
                        <div className="row gx-4">
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Others"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </Form>
                <br />
                <div className="Solera-Settings-Header44">CARDIOVASCULAR</div>
                <div className="Solera-Settings-Header44">
                  PULMONARY AUSULTATION
                </div>
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="LA" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="General with mask" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="General with intubation
"
                          />
                        </div>

                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check label="ORAL L " />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check label="ORAL D" />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    label="NASAL L
"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    label="NASAL D
"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>

                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Normal Intubation" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Intunation by the video laryngoscopy" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Intubation by fibroscopy" />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                <br />
                <div className="Solera-Settings-Header44">
                  ASA CLASSIFICATION
                </div>
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="I" />
                        </div>
                     
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="II" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="III" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="IV" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="V
"
                          />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>{" "}
                <br /> <br />
                <div className="Solera-Settings-Header44"> ADDITIONAL TESTS</div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <RichTextEditor
                          className="editor"
                          value={value4}
                          onChange={onChange4}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                {/* <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Procedures and Therapies"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Complications"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div> */}
                <div className="FormUpload">
                  {loading ? (
                    <Bars
                      heigth="50"
                      width="50"
                      color="gray"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    <button className="Add-Button" onClick={handleAdd}>
                      Add Note
                    </button>
                  )}
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}



export default UpdateAnaethticModal