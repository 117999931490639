import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientClinicalNote } from '../../services/PatientClinicalNoteService'

const initialState = {
    loading: false,
    preOps: [],
    isSuccessful: false
}

export const fetchPreOps = createAsyncThunk('fetchPreOps', (initData) => {
    return listPatientClinicalNote(initData)
    .then((response) => response.data.data)
})


const preOpSlice = createSlice({
    name: 'preOpsList',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPreOps.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchPreOps.fulfilled, (state, action ) => {
        state.loading = false
        state.preOps = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchPreOps.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default preOpSlice.reducer 