import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listReleaseConsumables } from '../../services/ReleaseConsumableService'

const initialState = {
    loading: false,
    releaseConsumes: [],
    paginator: {},
    isSuccessful: false
}

export const fetchReleaseConsumables = createAsyncThunk('fetchReleaseConsumables', (initData) => {
    return listReleaseConsumables(initData)
    .then((response) => response.data.data)
})


const releaseConsumablesSlice = createSlice({
    name: 'releaseConsumables',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchReleaseConsumables.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchReleaseConsumables.fulfilled, (state, action ) => {
        state.loading = false
        state.releaseConsumes = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchReleaseConsumables.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default releaseConsumablesSlice.reducer 