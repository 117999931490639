import axios from '../axiosinstance';

      
export function listPatientTheatre(initData){
        return axios.get('/patient-theatres', { params: { name: initData?.search, type: initData?.type, patient: initData?.patientId } })
}

export function addPatientTheatre(data){
        return axios.post('/patient-theatres', data)
}

export function getPatientTheatre(initData){
        return axios.get('/patient-theatres/'+initData?.id)
}

export function updatePatientTheatre(id, initData){
        return axios.patch('/patient-theatres/'+id, initData)
}

export function patientTheatreCount(search){
        return axios.get('/patient-theatres/stat/count', { params: search })
}

