import { React, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ViewNonMedReleaseItems from "./ViewNonMedReleaseItems";
import moment from "moment";

function NonMedReleaseList({nonrelease}) {
  const [drugsDetailsShow, setDrugsDetailsShow] = useState(false);
  const handleDrugsDetails = () => setDrugsDetailsShow(false);

  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);

  const [selected, setSelected] = useState({});

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }
  return (
    <div>
      <div className="Patient-Displays-Details2">
        <div className="PatientNumber-C">
          <div className="Wallet-Ref-Heading">Item Name</div>
          <div className="Wallet-Ref-Result">{nonrelease?.consume?.name}</div>
        </div>

        <div className="Position-Cont-P">
          <div className="Wallet-Ref-Heading">Released Date</div>
          <div className="Wallet-Ref-Result">{showDate(nonrelease?.created_at)}</div>
        </div>
        <div className="Role-Cont-P">
          <div className="Wallet-Ref-Heading">Quantity</div>
          <div className="Wallet-Ref-Result">{nonrelease?.qty}</div>
        </div>
        <div className="Dept-Cont-P">
          <div className="Wallet-Ref-Heading">Status</div>
          <div className="Wallet-Ref-Result">{nonrelease?.status}</div>
        </div>

        <div className="Action-Drop-Down">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
              Manage Item
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                className="Solera-Patients"
                onClick={() => {setSelected(nonrelease); setDrugsDetailsShow(true)}}
              >
                View Item's Details
              </Dropdown.Item>

            
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div>
        <ViewNonMedReleaseItems
          selected={selected}
          show={drugsDetailsShow}
          onClose={handleDrugsDetails}
          onOpen={() => setDrugsDetailsShow(true)}
        />

      
      </div>
    </div>
  );
}

export default NonMedReleaseList;
