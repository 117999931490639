import { React, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import Swal from "sweetalert2";
import ViewNurse from "./ViewNurse";
import EditNurse from "./EditNurse";
import StaffNumber from "../StaffPage/StaffNumber";
import { updateNurse } from "../../services/NurseService";
import { fetchNurses } from "../../features/nurses/nursesSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

function Nurses({nurse}) {
  const dispatch = useDispatch()

  const [editStaffShow, setEditStaffShow] = useState(false);
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);

  const handleEditStaff = () => setEditStaffShow(false);

  const [viewStaffShow, setViewStaffShow] = useState(false);
  const handleViewStaff = () => setViewStaffShow(false);

  const handleRemoveStaff = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to remove this Nurse?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#000000",
      cancelButtonColor: "#0078d4",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleUpdate()
      }
    });
  };

  const handleUpdate = () => {
    setLoading(true);
    const data = {
      id: nurse?._id,
      status: 'deleted',
    };
    updateNurse(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          dispatch(fetchNurses({}))
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  return (
    <div>
      <div className="Patient-Displays-Details2">
        <div className="PatientNumber-C">
          <div className="Wallet-Ref-Heading">Nurse's Name</div>
          <div className="Wallet-Ref-Result">{nurse?.full_name}</div>
        </div>
        <div className="StaffNumber">
          <div className="Wallet-Ref-Heading">Staff Number</div>
          <div className="Wallet-Ref-Result">
            <StaffNumber num={nurse?.cus_code} />
          </div>
        </div>
        <div className="AdminType-C">
          <div className="Wallet-Ref-Heading">Phone Number</div>
          <div className="Wallet-Ref-Result">{nurse?.phone}</div>
        </div>
       
         {/* < <div className="AdminType-C">
          <div className="Wallet-Ref-Heading">Admin Type</div>
          <div className="Wallet-Ref-Result">Super Admin</div>
        </div>*/}

        
        {/* <div className="Position-Cont-P">
          <div className="Wallet-Ref-Heading">Position</div>
          <div className="Wallet-Ref-Result">Nurse</div>
        </div>*/}
        {/* <div className="Dept-Cont-P">
          <div className="Wallet-Ref-Heading">Department</div>
          <div className="Wallet-Ref-Result">Nurse</div>
        </div>*/}
        
        
        <div className="Role-Cont-P">
          <div className="Wallet-Ref-Heading">Role</div>
          <div className="Wallet-Ref-Result">{nurse?.role}</div>
        </div>
       

        <div className="Action-Drop-Down">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
              Manage Nurse
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => {setSelected(nurse); setViewStaffShow(true)}} className="Solera-Patients">
                View Nurse
              </Dropdown.Item>
              <Dropdown.Item onClick={() => {setSelected(nurse); setEditStaffShow(true)}} className="Solera-Patients">
              Update Nurse
              </Dropdown.Item>

              <Dropdown.Item onClick={handleRemoveStaff} className="Solera-Patients">
                Remove Nurse
              </Dropdown.Item>
            </Dropdown.Menu> 
          </Dropdown>
        </div>
      </div>
      <div>
        <EditNurse
          selected={selected}
          show={editStaffShow}
          onClose={handleEditStaff}
          onOpen={() => setEditStaffShow(true)}
        />

        <ViewNurse
          selected={selected}
          show={viewStaffShow}
          onClose={handleViewStaff}
          onOpen={() => setViewStaffShow(true)}
        />
      </div>
    </div>
  );
}

export default Nurses;
