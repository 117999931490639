import React, { useEffect } from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import StaffGreetings from "../../Component/StaffGreetings";
import { FiUsers } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { fetchNurseCount } from "../../features/nurses/nurseCountSlice";
import { getSys } from "../../services/AuthService";
import NursesReportTable from "./NursesReportTable";
import AddNurseReport from "./AddNurseReport";
import { fetchNurseReports } from "../../features/nurses/nursesReportsSlice";
import { fetchNurseReportsCount } from "../../features/nurses/nurseReportsCountSlice";


function NursesReportPage() {
  const sys = getSys();

  const dispatch = useDispatch();
  const nurseReportsCount = useSelector((state) => state.nurseReportsCount);

  useEffect(() => {
    dispatch(fetchNurseReports({}));
    dispatch(fetchNurseReportsCount({}));
  }, []);
  return (
    <div className="Solera-Main-Container">
      <div className="NavBarContainer">
        <nav className="navbar navbar-expand-lg Solera-Nav">
          <div className="container-fluid">
            <div className="Solera-Admin-Heading">Reports</div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarText">
              <div className="navbar-nav me-auto mb-2 mb-lg-0"></div>
              <span className="navbar-text">
                <StaffGreetings />
              </span>
            </div>
          </div>
        </nav>
        <div className="Solera-Main-Dash">
          <div>
            <div>
              <div className="container-fluid overflow-hidden Solera-Dash-section1">
                <div className="container-fluid Patients-Bar">
                  <div className="Solera-Patient-Activities">
                    Nurse's Reports
                  </div>

                  {sys?.role === "sys-admin" || sys?.role === "sys-nurse" ? (
                    <div className="Solera-Welcome-Note40">
                      <AddNurseReport /> 
                    </div>
                  ) : null}
                </div>

                <div className="container-fluid ">
                  <div className="row g-5 ">
                    <div className="col">
                      <div className="Admin-Heading-Container">
                        <div className="DashBoard-Cont">
                          <div className="Admin-Heading">Reports</div>

                          <div className="Logo-Admin-Heading">
                            <FiUsers className="Data-Icon" />
                          </div>
                        </div>
                        <div className="Count-Result">{nurseReportsCount?.loading ? "loading..." : nurseReportsCount?.count }</div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="container-fluid overflow-visible Solera-Dash-section2">
                <div className="row g-5 ">
                  <div className="col-12">
                    <div className="Solera-Activity-Table">
                      <div className="Patient-Notification">
                        <NursesReportTable />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NursesReportPage;
