import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listStaff } from '../../services/StaffService'

const initialState = {
    patients: false,
    staffs: [],
    paginator: {},
    isSuccessful: false
}

export const fetchStaffs = createAsyncThunk('fetchStaffs', (initData) => {
    return listStaff(initData)
    .then((response) => response.data.data)
})


const staffsSlice = createSlice({
    name: 'staffs',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchStaffs.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchStaffs.fulfilled, (state, action ) => {
        state.loading = false
        state.staffs = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchStaffs.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default staffsSlice.reducer 