import { React } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import "../../Component/Modals/Modal.css";
import moment from "moment";

function ViewReport({ show, onClose, selected }) {
  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };
  return (
    <div>
      <div>
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">REPORT DETAILS</div>
                  </div>
                </div>
                <br />
                <br />
                <div>
                  <div className="FormSect">NURSE'S DETAILS</div>

                  <div>
                    <div className="FormSect-Note">
                      Nurse's name : {selected?.user?.full_name}
                    </div>
                    <div className="FormSect-Note">
                      Nurse's Number: {selected?.user?.cus_code?.toUpperCase()}
                    </div>
                    <div className="FormSect-Note">
                      Nurse's Role: Role display
                    </div>
                    <div className="FormSect-Note">
                      Date: {showDate(selected?.created_at)}
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div>
                  {/* <div className="ReportHeading">Report</div>
                  <br /> */}

                  <div>
                    <div className="ReportHeading">SUBJECT</div>
                    <br />
                    <div className="FormSect-Note">{selected?.subject}</div>
                    <br />
                    <br />
                    <div className="ReportHeading">REPORT NOTE:</div>
                    <div
                      className="ReportNoteDisplay"
                      dangerouslySetInnerHTML={{ __html: selected?.note }}
                    ></div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ViewReport;
