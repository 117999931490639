import { React, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import "../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import MenuItem from "@mui/material/MenuItem";
import { getUser } from "../../services/AuthService";
import { useDispatch } from "react-redux";
import { updateStaff } from "../../services/StaffService";
import { fetchStaffs } from "../../features/staff/staffsSlice";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import { fetchStaffCount } from "../../features/staff/staffCountSlice";

function EditStaff({ show, onClose, selected }) {
  const user = getUser();
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false);
  const [editStaffShow, setEditStaffShow] = useState(false);
  const handleEditStaff = () => setEditStaffShow(false);
  const [otherName, setOtherName] = useState("");
  const [errOtherName, setErrOtherName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errlastName, setErrLastName] = useState("");
  const [gender, setGender] = useState("");
  const [errGender, setErrGender] = useState("");
  const [phone, setPhone] = useState("");
  const [errPhone, setErrPhone] = useState("");
  const [role, setRole] = useState('');
  const [note, setNote] = useState('');
  

  useEffect(() => {
    if(selected?._id){
      setOtherName(selected?.first_name);
      setLastName(selected?.last_name);
      setGender(selected?.gender);
      setPhone(selected?.phone)
      setRole(selected?.role)

    }
  }, [show])

  // const handleSaveEditStaff = () => {
  //   onClose();
  //   setEditStaffShow(false);

  //   Swal.fire({
  //     customClass: {
  //       title: "title-class",
  //       icon: "icon-class",
  //     },

  //     icon: "success",
  //     title: "Successful",
  //     showConfirmButton: false,
  //     timer: 2000,
  //   });
  // };
  const sex = [
    {
      value: "Gender",
      label: "Gender",
    },
    {
      value: "Male",
      label: "Male",
    },
    {
      value: "Female",
      label: "Female",
    },
  ];

  // const tasks = [
  //   {
  //     value: "doctor",
  //     label: "Doctor",
  //   },
  //   {
  //     value: "nurse",
  //     label: "Nurse",
  //   },
  //   {
  //     value: "matron",
  //     label: "Matron",
  //   },
  //   {
  //     value: "la scientist",
  //     label: "Lab Scientist",
  //   },
  //   {
  //     value: "pharmacist",
  //     label: "Pharmacist",
  //   },

  //   {
  //     value: "radiographer",
  //     label: "Radiographer",
  //   },
  // ];

  // const departments = [
  //   {
  //     value: "",
  //     label: "Department",
  //   },
  //   {
  //     value: "general",
  //     label: "General",
  //   },
  //   {
  //     value: "laboratory",
  //     label: "Laboratory",
  //   },
  //   {
  //     value: "emergency",
  //     label: "Emergency",
  //   },
  //   {
  //     value: "pediatric",
  //     label: "Pediatric",
  //   },
  // ];

  const handleUpdate = () => {
    if (!otherName) {
      setErrOtherName("First name is required");
      return;
    }
    if (!lastName) {
      setErrLastName("Last name is required");
      return;
    }
  
    if (!gender) {
      setErrGender("Gender is required");
      return;
    }

    if (!phone) {
      setErrPhone("Phone is required");
      return;
    }

    setLoading(true);
    const data = {
      id: selected?._id,
      user: user?._id,
      first_name: otherName,
      last_name: lastName,
      gender: gender,
      phone: phone,
      role: role,
    };
    updateStaff(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(fetchStaffs({}))
          dispatch(fetchStaffCount({}))
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  return (
    <div>
      {" "}
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Solera__ModalHeadings">
              <MdClose className="Solera__Close" onClick={onClose} />
            </div>

            <div className="Solera__ModalHeadings"></div>
            <div className="Solera-Modal-Container">
              <div className="Solera__Headings">
                {/* <div className="Log__Cont">
                  <img
                    src="/assets/lg.png"
                    alt=""
                    className="Log__Img__Modal"
                  />
                </div> */}
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">Update Staff</div>
                  <div className="Solera-Settings-Note">
                    Kindly Update staff information here
                  </div>
                </div>
              </div>
              <br />
              
              <div className="FormSect-Contianer">
                <div className="FormSect">Staff Personal Details</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>

                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                  <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Other Names"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={otherName}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setOtherName("")
                          }else{
                            setOtherName("Name is required")
                          }
                          setOtherName(event.target.value)
                        }}
                      />
                      {(errOtherName) && <div className='input_error'>{errOtherName}</div>}
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Last Name"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={lastName}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setErrLastName("")
                          }else{
                            setErrLastName("Last name is required")
                          }
                          setLastName(event.target.value)
                        }}
                      />
                      {(errlastName) && <div className='input_error'>{errlastName}</div>}
                    </div>
                  </div>
                </div>

                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <div>
                        <TextField
                          fullWidth
                          id="outlined-select-currency"
                          select
                          label="Select Gender"
                          defaultValue="Gender"
                          value={gender}
                          onChange={(event) => {
                            if(event.target.value.length){
                              setErrGender("")
                            }else{
                              setErrGender("Gender is required")
                            }
                            setGender(event.target.value)
                          }}
                        >
                          {sex.map((option) => (
                            <MenuItem key={option.value} selected={option.value === selected?.gender} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Phone Number"
                        id="fullWidth"
                        type="number"
                        className="Solera-Login-Input mb-3"
                        value={phone}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setErrPhone("")
                          }else{
                            setErrPhone("Phone is required")
                          }
                          setPhone(event.target.value)
                        }}
                      />
                      {(errPhone) && <div className='input_error'>{errPhone}</div>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Staff's Role</div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Role"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={role}
                        onChange={(event) => {
                          setRole(event.target.value)
                        }}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Staff Number"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                       
                      />
                    </div>
                  </div>
                </div>
                
              </div>
              
              
              <div className="FormSect-Contianer">
                <div className="FormSect">Staff Note</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <div>
                        <TextField
                          fullWidth
                          id="outlined-multiline-static "
                          label="Make a note for this staff"
                          multiline
                          rows={10}
                          value={note}
                          onChange={(event) => {
                            setNote(event.target.value)
                          }}
                          // defaultValue="Default Value"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                loading ? (
                  <Bars
                    heigth="30"
                    width="30"
                    color="gray"
                    ariaLabel="loading-indicator"
                  />
                ) : (
                  <button className="Add-Button" onClick={handleUpdate}>
                    Update Staff
                  </button>
                ) 
              }
              <br /> <br /> <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default EditStaff;
