import { React, useEffect, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import Dropdown from "react-bootstrap/Dropdown";
import ViewFluidDetails from "./ViewFluidDetails";
import AddFluid from "./AddFluid";
import UpdateFluidChart from "./UpdateFluidChart";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchfluidCharts } from "../../../../features/patients/fluids/fluidChartsSlice";
import moment from "moment";
import { Bars } from "react-loader-spinner";
import { fetchfluidChartsCount } from "../../../../features/patients/fluids/fluidChartsCountSlice";
import { getSys } from "../../../../services/AuthService";

function PatientFluid() {

  const sys = getSys();


  let { id } = useParams();
  const dispatch = useDispatch();
  const { fluids, loading, isSuccessful } = useSelector(
    (state) => state.fluidCharts
  );
  const fluidChartsCount = useSelector((state) => state.fluidChartsCount);

  const [selected, setSelected] = useState({});

  useEffect(() => {
    if (id) {
      dispatch(fetchfluidCharts({ patient: id }));
      dispatch(fetchfluidChartsCount({ patient: id }));
    }
  }, [id]);

  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };

  const [viewvitaldetailsShow, setViewvitaldetailsShow] = useState(false);
  const handleViewvitaldetails = () => setViewvitaldetailsShow(false);

  const [updateFluidChartShow, setUpdateFluidChartShow] = useState(false);
  const handleUpdateFluidChart = () => setUpdateFluidChartShow(false);

  return (
    <div>
      <div className="Report-Notes">
        <div className="ReportHeader">
          <div className="Report-Heading">Fluid Chart </div>
          <div className="Report-Count">
            <span>
              {fluidChartsCount?.loading
                ? "Loading..."
                : fluidChartsCount?.count}
            </span>
            &nbsp;Fluid Chart
          </div>

          {sys?.role === "sys-admin" ||
          sys?.role === "sys-nurse" ||
          sys?.role === "sys-matron" ? (
            <AddFluid />
          ) : null}
        </div>
      </div>
      <br />
      {loading ? (
        <div className="d-flex justify-content-center">
          <Bars
            heigth="30"
            width="30"
            color="gray"
            ariaLabel="loading-indicator"
          />
        </div>
      ) : null}
      {fluids?.map((item, index) => (
        <div key={index} className="Patient-Record-Container">
          <div className="Patient-Record-Wrapper">
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Fluid Chart Number</div>
              <div className="Wallet-Ref-Result">{item?.code}</div>
            </div>
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Nurses's Name</div>
              <div className="Wallet-Ref-Result">{item?.user?.full_name}</div>
            </div>
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Date</div>
              <div className="Wallet-Ref-Result">
                {showDate(item?.created_at)}
              </div>
            </div>
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Status</div>
              <div className="Wallet-Ref-Result">{item?.status}</div>
            </div>
            <div className="Action-Drop-Down">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
                  Manage fluid chart
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setSelected(item);
                      setViewvitaldetailsShow(true);
                    }}
                    className="Solera-Patients"
                  >
                    View fluid chart
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelected(item);
                      setUpdateFluidChartShow(true);
                    }}
                    className="Solera-Patients"
                  >
                    Update Fluid Chart
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      ))}

      <div>
        <ViewFluidDetails
          selected={selected}
          show={viewvitaldetailsShow}
          onClose={handleViewvitaldetails}
          onOpen={() => setViewvitaldetailsShow(true)}
        />

        <UpdateFluidChart
          selected={selected}
          show={updateFluidChartShow}
          onClose={handleUpdateFluidChart}
          onOpen={() => setUpdateFluidChartShow(true)}
        />
      </div>
    </div>
  );
}

export default PatientFluid;
