import React from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import PharmacyActivities from "./PharmacyActivities";
import PharmacyTable from "./PharmacyTable";
import StaffGreetings from "../../Component/StaffGreetings";
import SideBar from "../../Component/SideBar";
import AddDrugInventory from "./AddDrugInventory";
import Inventory from "./Inventory";
// import AddDrugInventory from "../Pharmacy/AddDrugInventory"

function Pharmacy() {
  return (
    <div className="Solera-Main-Container">
    <div className="NavBarContainer">
      <nav className="navbar navbar-expand-lg Solera-Nav">
        <div className="container-fluid">
          <div className="Solera-Admin-Heading">Pharmacy</div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <div className="navbar-nav me-auto mb-2 mb-lg-0"></div>
            <span className="navbar-text">
              <StaffGreetings />
            </span>
          </div>
        </div>
      </nav>
      <div className="Solera-Main-Dash">
        <div>
          <div>
            <div className="container-fluid overflow-hidden Solera-Dash-section1">
              <div className="container-fluid Patients-Bar">
                <div className="Solera-Patient-Activities">
                  Pharmacy Activities
                </div>
                <div className="Solera-Welcome-Note">
               <Inventory/>
                </div>
              </div>

              <PharmacyActivities />
            </div>
            <br />
            <div className="container-fluid overflow-hidden Solera-Dash-section1">
              <div className="container-fluid ">
                <div className="row g-5 ">
                  <div className="col-12">
                    <div className="Solera-Activity-Table">
                      <div className="Patient-Notification">
                        <PharmacyTable />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Pharmacy;
