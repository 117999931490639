import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { drugsCount } from '../../services/DrugService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchOutDrugCount = createAsyncThunk('fetchOutDrugCount', (initData) => {
    return drugsCount(initData)
    .then((response) =>  response.data.data)
})


const drugOutCountSlice = createSlice({
    name: 'drugOutCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchOutDrugCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchOutDrugCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default drugOutCountSlice.reducer 