import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import moment from "moment";
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

function ViewAnaModal({ show, onClose, selected }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [addDoctorShow, setAddDoctorShow] = useState(false);
  const handleAddDoctor = () => setAddDoctorShow(false);

  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };
  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera-Modal-Container">
                <br />
                <div className="Report-Display-Heading">
                  Patient's Pre-Anaesthetic
                </div>
                <div className="Report-Tittle">Patient's Anasethetic</div>
                <div className="Solera-Modal-Container">
                  <div className="Note-Modal-Cont">
                    <div>
                      <div className="Doctors-Name">Doctor's Name</div>
                      <div className="Doctors-Name1">
                        {selected?.user?.full_name}
                      </div>
                      <div className="Doctors-Name">Date</div>
                      <div className="Doctors-Name1">
                        {showDate(selected?.created_at)}
                      </div>
                      <div className="Doctors-Name">Number</div>
                      <div className="Doctors-Name1">{selected?.code}</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">DIAGNOSTICS</div>
                      <div className="Report30">Surgical-Anaesthetic :</div>
                      <div className="Report40">
                        Surgical-Anaesthetic Display
                      </div>
                      <br />
                      <br />
                      <div className="Doctors-Name">PLANNED INTERVENTION</div>
                      <div className="Report30">Treaatments:</div>
                      <div className="Report40">Treaatments Note Display</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">SYSTEM</div> <br />
                      <div className="Doctors-Name">RESPIRATORY</div>
                      <div className="Report40">Asthma Display</div>
                      <div className="Report40">Bronchitis Display</div>
                      <div className="Report40">Pheumonia Display</div>
                      <div className="Report40">Sinusitis Display</div>
                      <div className="Report40">Others Display</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">CARDIOVASCULAR</div>
                      <div className="Report40">Htn</div>
                      <div className="Report40">Cardiomiophathy</div>
                      <div className="Report40">Rhythm Disorder</div>
                      <div className="Report40">Palpitations</div>
                      <div className="Report40">Endocarditis</div>
                      <div className="Report40">Heart Failure</div>
                      <div className="Report40">Others</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">HEPATO/GASTRO</div>
                      <div className="Report40">Obesity</div>
                      <div className="Report40">Epigastralgies</div>
                      <div className="Report40">Hepatities</div>
                      <div className="Report40">Cirrrhosis</div>
                      <div className="Report40">Pancreatitis</div>
                      <div className="Report40">Others</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">
                        HNEURO/MUSCULO-SKELETAL
                      </div>
                      <div className="Report40">Anthritis</div>
                      <div className="Report40">Scouosis</div>
                      <div className="Report40">Migranne/Headache</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">EXAMINATION</div> <br />
                      <div className="Doctors-Name">CONJUCITIVA</div>
                      <div className="Report40">Normal</div>
                      <div className="Report40">Mildly Red</div>
                      <div className="Report40">Red</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">PULMONARY AUSCULTATION</div>
                      <div className="Report40">Normal</div>
                      <div className="Report40">Abnormal</div>
                      <div className="Report40">Red</div>
                      <div className="Report30">
                        Nature :{" "}
                        <span className="Report40"> Nature Display</span>
                      </div>
                      <br />
                      <br />
                      <div className="Doctors-Name">CARDIAC AUSCULTATION</div>
                      <div className="Report40">Normal</div>
                      <div className="Report40">Abnormal</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">VENOUS ACCESS</div>
                      <div className="Report40">Accessible</div>
                      <div className="Report40">Difficult</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">
                        AIRWAY EXAMINATION
                      </div>{" "}
                      <br />
                      <div className="Doctors-Name">MOUTH OPENING</div>
                      <div className="Report40">Normal</div>
                      <div className="Report40">Psmall</div>
                      <div className="Report30">
                        Others :{" "}
                        <span className="Report40"> Others Display</span>
                      </div>
                      <br />
                      <br />
                      <div className="Doctors-Name">CARDIOVASCULAR</div> <br />
                      <div className="Doctors-Name">PULMONARY AUSULTATION</div>
                      <div className="Report40">LA</div>
                      <div className="Report40">General with mask</div>
                      <div className="Report40">General with intubation</div>
                      <div className="Report40">ORAL L</div>
                      <div className="Report40">ORAL D</div>
                      <div className="Report40">NASAL L</div>
                      <div className="Report40">NASAL D</div>
                      <div className="Report40">Normal Intubation</div>
                      <div className="Report40">
                        Intunation by the video laryngoscopy
                      </div>
                      <div className="Report40">Intubation by fibroscopy</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">ASA CLASSIFICATION</div>
                      <div className="Report40">I</div>
                      <div className="Report40">II</div>
                      <div className="Report40">III</div>
                      <div className="Report40">IV</div>
                      <div className="Report40">V</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">ADDITIONAL TESTS</div>
                      <div className="Report40">Additional test display</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">
                        {" "}
                        ADDITIONAL TESTS/ Pre-Anaesthetic Note
                      </div>
                      <div className="Report-Date4">
                        <div
                          dangerouslySetInnerHTML={{ __html: selected?.note }}
                        ></div>
                      </div>
                      <br />
                      {selected?.url?.length ? (
                        selected?.fileType === "application/pdf" ? (
                          <div className="pdf-div">
                            <Viewer
                              fileUrl={selected?.url}
                              plugins={[defaultLayoutPluginInstance]}
                            />
                          </div>
                        ) : (
                          <div>
                            <img
                              width={900}
                              height={1000}
                              src={selected?.url}
                            />
                          </div>
                        )
                      ) : null}
                    </div>
                  </div>
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ViewAnaModal;
