import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import moment from "moment";
import {  Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

function ViewUploadModal({ show, onClose, selected }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }

  console.log("LAPAPPAPAPA", selected)

  return (
    <div>
    {" "}
    <div>
      {" "}
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Solera__ModalHeadings">
              <MdClose className="Solera__Close" onClick={onClose} />
            </div>

            <div className="Solera-Modal-Container">
              <br />
              <div className="Report-Display-Heading">Lab Upload</div>
              <div className="Report-Tittle">Lab Upload Display</div>
              <div className="Solera-Modal-Container">
                <div className="Note-Modal-Cont">
                  <div>
                    <div className="Doctors-Name">
                      Doctors's name : &nbsp;<span>{ selected?.user_infos ? selected?.user_infos[0]?.first_name+" "+selected?.user_infos[0]?.last_name : selected?.user?.first_name +" "+ selected?.user?.last_name}</span>
                    </div>
                    <div className="Report-Date">
                      Report Date: &nbsp;<span>{showDate(selected?.created_at)}</span>
                    </div>
                    {/* <div className="Report-Date">
                      Report Number: &nbsp;<span>{selected?.code}</span>
                    </div> */}
                  </div>
                  <br />        
                </div>
              </div>
              
              
              <br /> <br /> <br />
              {
                selected?.url?.length ? (
                  selected?.fileType === "application/pdf" ? (
                    <div className="pdf-div">
                      <Viewer
                          fileUrl={selected?.url}
                          plugins={[defaultLayoutPluginInstance]}
                      />
                    </div>
                    
                  ) : (
                    <div>
                      <img width={900} height={1000} src={selected?.url} />
                    </div>
                  )
                  
                ): null
              }
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  </div>
  );
}

export default ViewUploadModal;
