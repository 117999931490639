import { React, useState } from "react";
// import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
// import "../../../../SoleraCss/Responsive.css";
// import "../../../../SoleraCss/Solera.css";
// import "../../../../Component/Modals/Modal.css";
// import TextField from "@mui/material/TextField";
// import { useParams } from "react-router-dom";
// import { getUser } from "../../../../services/AuthService";
// import { useDispatch } from "react-redux";
// import { addPatientClinicalNote } from "../../../../services/PatientClinicalNoteService";
// import { toast } from "react-toastify";
// import { fetchPostOps } from "../../../../features/patients/postOpSlice";
import { Bars } from "react-loader-spinner";
import Form from "react-bootstrap/Form";
import RichTextEditor from "react-rte"; // Copy Editor
import Swal from "sweetalert2";

function UpdteSheet({ show, onClose }) {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue()); // Copy Editor

  //   let { id } = useParams();
  //   const user = getUser();
  //   const dispatch = useDispatch();
  //   const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);

  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);

  const sex = [
    {
      value: "Gender",
      label: "Gender",
    },
    {
      value: "Male",
      label: "Male",
    },
    {
      value: "Female",
      label: "Female",
    },
  ];
  const status = [
    {
      value: "Single",
      label: "Single",
    },
    {
      value: "Married",
      label: "Married",
    },
  ];

  const language = [
    {
      value: "PG",
      label: "PG",
    },
    {
      value: "HA",
      label: "HA",
    },
    {
      value: "IG",
      label: "IG",
    },
    {
      value: "YO",
      label: "YO",
    },
    {
      value: "FUL",
      label: "FUL",
    },
    {
      value: "OTHERS",
      label: "OTHERS",
    },
  ];

  const handleSub = () => {
    onClose();
    setAddReportShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  //   const handleSub = () => {
  //     onClose();
  //     setAddReportShow(false);

  //     Swal.fire({
  //       customClass: {
  //         title: "title-class",
  //         icon: "icon-class",
  //       },

  //       icon: "success",
  //       title: "Successful",
  //       showConfirmButton: false,
  //       timer: 2000,
  //     });
  //   };

  //   const handleAdd = () => {
  //     setLoading(true);
  //     const data = {
  //       user: user?._id,
  //       patient: id,
  //       note: note,
  //       type: 'post Op',
  //     };
  //     console.log(data);
  //     addPatientClinicalNote(data)
  //       .then((response) => {
  //         setLoading(false);
  //         if (response.data.success) {
  //           //closeRef.current.click()
  //           toast.success(
  //             response.data.message
  //               ? response.data.message
  //               : "Successfully Created"
  //           );
  //           onClose();
  //           dispatch(fetchPostOps({patientId: id, type: 'post Op'}))
  //           Swal.fire({
  //             customClass: {
  //               title: "title-class",
  //               icon: "icon-class",
  //             },

  //             icon: "success",
  //             title: "Successful",
  //             showConfirmButton: false,
  //             timer: 2000,
  //           });

  //         }
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //         console.log(err);
  //       });
  //   }

  {
    /* Copy Editor */
  }
  const onChange = (value) => {
    setValue(value);
    value.toString("html");
    // setPresentingConcern(value.toString("html"));
  };

  return (
    <div>
      <div>
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container30">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                     Update Intraoperative Sheet
                    </div>
                    <div className="Solera-Settings-Note">
                      Intraoperative Sheet
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div>
                  <div className="FormSect">MONITORING</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="PNI" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="cardioscope" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="SpO2" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Monitoring ET CO2" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Monitoring Halogenes" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Neurostimulator" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Sonde vesicale" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Gastric tube
"
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Rechauffeur" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Sonde temperature" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Others" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Revaluation pre-Operation
"
                          />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                <br />
                <br />
                <div>
                  <div className="FormSect">
                    VOIE VEINEUSE PERIPHERIQUE/PERIPHERAL VENOUS WAY
                  </div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Lieu Type"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        //     value={lastName}
                        //     onChange={(event) => {
                        //       if (event.target.value.length) {
                        //         setErrLastName("");
                        //       } else {
                        //         setErrLastName("Last name is required");
                        //       }
                        //       setLastName(event.target.value);
                        //     }}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Others"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        //     value={otherName}
                        //     onChange={(event) => {
                        //       if (event.target.value.length) {
                        //         setOtherName("");
                        //       } else {
                        //         setOtherName("Name is required");
                        //       }
                        //       setOtherName(event.target.value);
                        //     }}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div>
                  <div className="FormSect">TYPE CHIRURGIE/SURGERY TYPE</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Own" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="clean-contaminated" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Contaminate" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Sale" />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                <br />
                <br />
                <div>
                  <div className="FormSect">Position Patient</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Declive" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Proclive" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="DD" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="DV" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="DLG" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="DLD" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Gyneco" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Genu pectoral
"
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Billot" />
                        </div>
                        {/* <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Sonde temperature" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Others" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Revaluation pre-Operation
"
                          />
                        </div> */}
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                <br />
                <br />
                <div>
                  <div className="FormSect">TYPE ANESTHESIE</div>
                  <div className="FormSect">Loco - regionale</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Rachi" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Peridurale" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="ALRIV" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Peribulbaire" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Bloc" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="Locale" />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                <br />
                <br />
                <div>
                  <div className="FormSect">Generale</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="AG sans incubation" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="AG avec incubation" />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                <br />
                <br />
                <div>
                  <div className="FormSect">INCUBATION</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg-3">
                        <TextField
                          fullWidth
                          label="OT"
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                        />
                      </div>
                      <div className="col-lg-3">
                        <TextField
                          fullWidth
                          label="NT"
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                        />
                      </div>
                      <div className="col-lg-3">
                        <TextField
                          fullWidth
                          label="N"
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                        />
                      </div>
                      <div className="col-lg-3">
                        <TextField
                          fullWidth
                          label="Difficile"
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-6 CheckSheet">
                      {" "}
                      <div>
                        <div className="FormSect">MASQUE LARYNGE</div>
                        <div className="FormSect-Note">
                          {/* Please fill all the required field */}
                        </div>
                      </div>
                      <div className="FormSect-Contianer">
                        <div className="container-fluid p-0 FormInput">
                          <div className="row gx-4">
                            <div className="col-lg">
                              <TextField
                                fullWidth
                                label="N"
                                id="fullWidth"
                                type="text"
                                className="Solera-Login-Input mb-3"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 CheckSheet">
                      {" "}
                      <div>
                        <div className="FormSect">FIBROSCOPIE</div>
                        <div className="FormSect-Note">
                          {/* Please fill all the required field */}
                        </div>
                      </div>
                      <div className="FormSect-Contianer">
                        <div className="container-fluid p-0 FormInput">
                          <div className="row gx-4">
                            <div className="col-lg">
                              <TextField
                                fullWidth
                                label="DR"
                                id="fullWidth"
                                type="text"
                                className="Solera-Login-Input mb-3"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-6 CheckSheet">
                      <div>
                        <div className="FormSect">VENTILATION</div>
                        <div className="FormSect-Note">
                          {/* Please fill all the required field */}
                        </div>
                      </div>
                      <div className="FormSect-Contianer">
                        {" "}
                        <Form>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row g-2">
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check label="Spontanee" />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check label="Masque" />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check label="Autre" />
                              </div>
                            </div>
                          </div>{" "}
                        </Form>
                      </div>
                    </div>
                    <div className="col-lg-6 ckSheet">
                      <div>
                        <div className="FormSect">OYGENOTHERAPIE</div>
                        <div className="FormSect-Note">
                          {/* Please fill all the required field */}
                        </div>
                      </div>
                      <div className="FormSect-Contianer">
                        {" "}
                        <Form>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row g-2">
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check label="I/MIN" />
                              </div>
                            </div>
                          </div>{" "}
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="FormSect">RESPIRATEUR</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg-6">
                        <TextField
                          fullWidth
                          label="VT Xf"
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                        />
                      </div>
                      <div className="col-lg-6">
                        <TextField
                          fullWidth
                          label="FIO2/N20"
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-6 CheckSheet">
                      <div>
                        <div className="FormSect">Circuit</div>
                        <div className="FormSect-Note">
                          {/* Please fill all the required field */}
                        </div>
                      </div>
                      <div className="FormSect-Contianer">
                        {" "}
                        <Form>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row g-2">
                              <div className="col-lg-6 CheckSheet">
                                <Form.Check label="Ouvert" />
                              </div>
                              <div className="col-lg-6 CheckSheet">
                                <Form.Check label="Ferme" />
                              </div>
                            </div>
                          </div>{" "}
                        </Form>
                      </div>
                    </div>
                    <div className="col-lg-6 ckSheet">
                      <div>
                        <div className="FormSect">SALLE DE REVEIL</div>
                        <div className="FormSect-Note">
                          {/* Please fill all the required field */}
                        </div>
                      </div>
                      <div className="FormSect-Contianer">
                        {" "}
                        <Form>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row g-2">
                              <div className="col-lg-6 CheckSheet">
                                <Form.Check label="Reveil sample" />
                              </div>
                              <div className="col-lg-6 CheckSheet">
                                <Form.Check label="Reveil monitore" />
                              </div>
                            </div>
                          </div>{" "}
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <div>
                  <div className="FormSect">Temps Operatoire</div>
                  <div className="FormSect">ANESTHESIE</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="200" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="40" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="20" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="150" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="38" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="15" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="100" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="37" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="10" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="50" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="37" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="5" />
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
                <br /> <br />
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="SPO2" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="ET CO2" />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check label="HALOGENES" />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                {/* 
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <div>
                        <TextField
                          fullWidth
                          id="outlined-select-currency"
                          select
                          label="Select Gender"
                          defaultValue="Gender"
                        >
                          {sex.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        id="outlined-select-currency"
                        select
                        label="Select Language"
                        defaultValue="Language"
                      >
                        {language.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                </div> */}
                <div className="FormSect-Contianer">
                  <div className="FormSect">Note</div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Make a note for this patient"
                        multiline
                        rows={15}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        // defaultValue="Default Value"
                      />  */}

                      <RichTextEditor
                        className="editor"
                        value={value}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="FormUpload">
                  {loading ? (
                    <Bars
                      heigth="50"
                      width="50"
                      color="gray"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    <button className="Add-Button" onClick={handleSub}>
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}




export default UpdteSheet