import axios from '../axiosinstance';

      

//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

export function listDoctor(search){
        return axios.get('/doctors', { params: search })
}

export function addDoctor(data){
        return axios.post('/doctors', data)
}

export function getDoctor(initData){
        return axios.get('/doctors/'+initData?.id)
}

export function updateDoctor(initData){
        return axios.patch('/doctors/'+initData.id, initData)
}

export function doctorCount(search){
        return axios.get('/doctors/stat/count', { params: { name: search } })
}

