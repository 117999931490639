import { React, useEffect, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import AddPreOpNote from "../ClinicalNote/AddPreOpNote";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import Dropdown from "react-bootstrap/Dropdown";
// import ViewPreOpNoteDetails from "./ViewPreOpNoteDetailsModal";
// import ViewPreOpNoteDetailsModal from "./ViewPreOpNoteDetailsModal";
// import EditPreOpNoteDetailsModal from "./EditPreOpNoteDetailsModal";
import AddNoteModal from "./AddNoteModal";
import ViewPostOpModal from "../ClinicalNote/ViewPostOpModal";
import AddClincalReport from "../ClinicalNote/AddClincalReport";
import ViewSugeon from "./ViewSugeon";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientSurgeon } from "../../../../features/patients/patientSurgeonSlice";
import moment from "moment";
import { Bars } from "react-loader-spinner";
import { fetchPatientSurgeonCount } from "../../../../features/patients/counts/patientSurgeonCountSlice";
import UploadNote from "./UploadNote";

function SurgeonNote() {
  let { id } = useParams();
  const dispatch = useDispatch()
  const {surgeons, loading, isSuccessful} = useSelector((state) => state.patientSurgeon)
  const patientSurgeonCount = useSelector((state) => state.patientSurgeonCount)

  const [selected, setSelected] = useState({});
  const [active, setActive] = useState("SurgeonNote");

  useEffect(() => {
    if (id) {
      dispatch(fetchPatientSurgeon({patientId: id, type: "surgeon"}))
      dispatch(fetchPatientSurgeonCount({patient: id, type: "surgeon"}))
    }
  }, [id]);

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }
  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);

  const [uploadNotesShow, setUploadNotesShow] =
    useState(false);
  const handleUploadNoteDetails = () => setUploadNotesShow(false);

  // const [editPreOpNoteDetailsShow, setEditPreOpNoteDetailsShow] =
  //   useState(false);
  // const handleEditPreOpNoteDetails = () => setEditPreOpNoteDetailsShow(false);

  // const handleSubComment = () => {
  //   Swal.fire({
  //     customClass: {
  //       title: "title-class",
  //       icon: "icon-class",
  //     },

  //     icon: "success",
  //     title: "Successful",
  //     showConfirmButton: false,
  //     timer: 2000,
  //   });
  // };
  return (
    <div>
      <div className="Report-Notes">
        <div className="ReportHeader">
          <div className="Report-Heading">Surgeon Note</div>
          <div className="Report-Count">
            <span>{patientSurgeonCount?.loading ? "Loading..." : patientSurgeonCount?.count}</span>&nbsp;Surgeon Note
          </div>
          <AddClincalReport />
        </div>
      </div>
      <br />
      {loading ? (
            <div className="d-flex justify-content-center">
              <Bars
                heigth="30"
                width="30"
                color="gray"
                ariaLabel="loading-indicator"
              />
            </div>
        ) : null
      }
      {
        surgeons?.map((item, index) => (
          <div key={index} className="Patient-Record-Container">
            <div className="Patient-Record-Wrapper">
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Note Number</div>
                <div className="Wallet-Ref-Result">{item?.code}</div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Doctor's Name</div>
                <div className="Wallet-Ref-Result">{item?.user?.full_name}</div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Date</div>
                <div className="Wallet-Ref-Result">{showDate(item?.created_at)}</div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Status</div>
                <div className="Wallet-Ref-Result">{item?.status}</div>
              </div>
              <div className="Action-Drop-Down">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
                    Manage Note
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {setSelected(item); setViewPreOpNoteDetailsShow(true)}}
                      className="Solera-Patients"
                    >
                      View Note
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {setSelected(item); setUploadNotesShow(true)}}
                      className="Solera-Patients"
                    >
                      Upload Note
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      // onClick={() => {setSelected(item); setViewPreOpNoteDetailsShow(true)}}
                      className="Solera-Patients"
                    >
                      View uploaded Note
                    </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        ))
      }

      <div>
        <ViewSugeon
          selected={selected}
          show={viewPreOpNoteDetailsShow}
          onClose={handleViewPreOpNoteDetails}
          onOpen={() => setViewPreOpNoteDetailsShow(true)}
        />

        <UploadNote 
          named={'surgeon'}
          selected={selected}
          show={uploadNotesShow}
          onClose={handleUploadNoteDetails}
          onOpen={() => setUploadNotesShow(true)}
        />  
      </div>
    </div>
  );
}

export default SurgeonNote;
