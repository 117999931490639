import axios from '../axiosinstance';

      
export function listPatientClinicalNote(initData){
        return axios.get('/patient-clinical-notes', { params: { name: initData?.search, patient: initData?.patientId, type: initData?.type } })
}

export function addPatientClinicalNote(data){
        return axios.post('/patient-clinical-notes', data)
}

export function getPatientClinicalNote(initData){
        return axios.get('/patient-clinical-notes/'+initData?.id)
}

export function updatePatientClinicalNote(id, initData){
        return axios.patch('/patient-clinical-notes/'+id, initData)
}

export function patientClinicalNoteCount(search){
        return axios.get('/patient-clinical-notes/stat/count', { params: search })
}