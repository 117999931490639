import React, { useEffect } from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import { FiUserCheck, FiUserPlus, FiUsers } from "react-icons/fi";
import NumberOfDispensedDrugs from "../Pharmacy/NumberOfDispensedDrugs";
import NumberofPatients from "../PatientPage/NumberofPatients";
import NumberOfDoctors from "../DoctorsPage/NumberOfDoctors";
import NumberOfNurses from "../NursesPage/NumberOfNurses";
import NumberOfQurantined from "../PatientPage/NumberOfQurantined";
import NumberOfAvailableDrugs from "../Pharmacy/NumberOfAvailableDrugs";
import NumberOfExpiredDrugs from "../Pharmacy/NumberOfExpiredDrugs";
import NumberOfOutOfStock from "../Pharmacy/NumberOfOutOfStock";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientCount } from "../../features/patients/patientCountSlice";
import { fetchDoctorCount } from "../../features/doctors/doctorCountSlice";
import { fetchNurseCount } from "../../features/nurses/nurseCountSlice";
import { getSys, getUser } from "../../services/AuthService";
import { fetchPatientAdmissionCount } from "../../features/patients/patientAdmissionCountSlice";
import { fetchPatientDischargedCount } from "../../features/patients/patientDischargedCountSlice";
import { fetchPatientQuarantineCount } from "../../features/patients/patientQuarantineCountSlice";
import { fetchDrugCount } from "../../features/pharmacy/drugCountSlice";
import { fetchExpiredDrugCount } from "../../features/pharmacy/drugExpiredCountSlice";
import { fetchOutDrugCount } from "../../features/pharmacy/drugOutCountSlice";
import { fetchPatientDrugsSum } from "../../features/patients/stats/patientDrugsSumSlice";

function DashboardContainer() {
  const user = getUser();
  const sys = getSys();
  const dispatch = useDispatch();
  const patientCount = useSelector((state) => state.patientCount);
  const doctorCount = useSelector((state) => state.doctorCount);
  const nurseCount = useSelector((state) => state.nurseCount);

  const patientAdmissionCount = useSelector(
    (state) => state.patientAdmissionCount
  );
  const patientDischargedCount = useSelector(
    (state) => state.patientDischargedCount
  );
  const patientQuarantineCount = useSelector(
    (state) => state.patientQuarantineCount
  );

  const drugCount = useSelector((state) => state.drugCount);
  const drugExpiredCount = useSelector((state) => state.drugExpiredCount);
  const drugOutCount = useSelector((state) => state.drugOutCount);
  const patientDrugsSum = useSelector((state) => state.patientDrugsSum);

  useEffect(() => {
    dispatch(fetchPatientCount({}));
    dispatch(fetchDoctorCount({}));
    dispatch(fetchNurseCount({}));

    dispatch(fetchPatientAdmissionCount({ status: "admitted" }));
    dispatch(fetchPatientDischargedCount({ status: "discharged" }));
    dispatch(fetchPatientQuarantineCount({ status: "quarantined" }));

    dispatch(fetchDrugCount({ avaliable: true }));
    dispatch(fetchExpiredDrugCount({ expired: true }));
    dispatch(fetchOutDrugCount({ outoff: true }));
    dispatch(fetchPatientDrugsSum({ status: "active" }));
  }, []);

  const date = new Date();
  const hour = date.getHours();

  return (
    <div>
      <div className="container-fluid overflow-hidden Solera-Dash-section1">
        <div className="container-fluid">
          <div className="Solera-Welcome-Note">
            <div>
              <span className="Solera-Greetings2">Hello!</span>

              <div className="Solera-Name-Display">
                <span></span>&nbsp;{user?.full_name}
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid ">
          <div className="row g-2 ">
            <div className="col-lg-4">
              <div className="Admin-Heading-Container">
                <div className="DashBoard-Cont">
                  <div>
                    <div className="Admin-Heading">Patients</div>
                  </div>
                  <div className="Logo-Admin-Heading">
                    <FiUsers className="Data-Icon" />
                  </div>
                </div>

                <NumberofPatients
                  num={patientCount.count}
                  loading={patientCount.loading}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="Admin-Heading-Container">
                <div className="DashBoard-Cont">
                  <div>
                    <div className="Admin-Heading">Doctors</div>
                  </div>
                  <div className="Logo-Admin-Heading">
                    <FiUserCheck className="Data-Icon" />
                  </div>
                </div>

                <NumberOfDoctors
                  dnum={doctorCount?.count}
                  dloading={doctorCount?.loading}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="Admin-Heading-Container">
                <div className="DashBoard-Cont">
                  <div>
                    <div className="Admin-Heading">Nurses</div>
                  </div>
                  <div className="Logo-Admin-Heading">
                    <FiUserPlus className="Data-Icon" />
                  </div>
                </div>

                <NumberOfNurses
                  nurseNum={nurseCount?.count}
                  nurseLoading={nurseCount?.loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      
     
      {sys?.role === "sys-admin" || sys?.role === "sys-pharmacy" || sys?.role === "sys-doctor" ? (
         <div className="container-fluid overflow-hidden Solera-Dash-section1">
         <div className="container-fluid ">
           <div className="Solera-Patient-Activities">Patient Activities</div>
         </div>
 
         <div className="container-fluid ">
           <div className="row g-2 ">
             <div className="col-lg-4">
               <div className="Admin-Heading-Container">
                 <div className="DashBoard-Cont">
                   <div>
                     <div className="Admin-Heading">Patients on Addmission</div>
                   </div>
                   <div className="Logo-Admin-Heading">
                     <FiUsers className="Data-Icon" />
                   </div>
                 </div>
 
                 <NumberofPatients
                   num={patientAdmissionCount?.count}
                   loading={patientAdmissionCount?.loading}
                 />
               </div>
             </div>
             <div className="col-lg-4">
               <div className="Admin-Heading-Container">
                 <div className="DashBoard-Cont">
                   <div>
                     <div className="Admin-Heading">Discharged Patients</div>
                   </div>
                   <div className="Logo-Admin-Heading">
                     <FiUserCheck className="Data-Icon" />
                   </div>
                 </div>
 
                 <NumberOfDoctors
                   dnum={patientDischargedCount?.count}
                   dloading={patientDischargedCount?.loading}
                 />
               </div>
             </div>
             <div className="col-lg-4">
               <div className="Admin-Heading-Container">
                 <div className="DashBoard-Cont">
                   <div>
                     <div className="Admin-Heading">Quarantined Patients</div>
                   </div>
                   <div className="Logo-Admin-Heading">
                     <FiUserPlus className="Data-Icon" />
                   </div>
                 </div>
 
                 <NumberOfQurantined
                   num={patientQuarantineCount?.count}
                   loading={patientQuarantineCount?.loading}
                 />
               </div>
             </div>
           </div>
         </div>
       </div>
      ) : null}
      <br />
    
      {sys?.role === "sys-admin" ||  sys?.role === "sys-pharmacy" || sys?.role === "sys-doctor" ? (
        <div className="container-fluid overflow-hidden Solera-Dash-section1">
          <div className="container-fluid ">
            <div className="Solera-Patient-Activities">Pharmacy Activities</div>
          </div>

          <div className="container-fluid ">
            <div className="row g-2 ">
              <div className="col-lg">
                <div className="Admin-Heading-Container">
                  <div className="DashBoard-Cont">
                    <div>
                      <div className="Admin-Heading">Dispensed Drugs</div>
                    </div>
                    <div className="Logo-Admin-Heading">
                      <FiUsers className="Data-Icon" />
                    </div>
                  </div>

                  <NumberOfDispensedDrugs
                    num={patientDrugsSum?.count}
                    loading={patientDrugsSum?.loading}
                  />
                </div>
              </div>
              <div className="col-lg">
                <div className="Admin-Heading-Container">
                  <div className="DashBoard-Cont">
                    <div>
                      <div className="Admin-Heading">Available Drugs</div>
                    </div>
                    <div className="Logo-Admin-Heading">
                      <FiUserCheck className="Data-Icon" />
                    </div>
                  </div>

                  <NumberOfAvailableDrugs
                    num={drugCount?.count}
                    loading={drugCount?.loading}
                  />
                </div>
              </div>
              <div className="col-lg">
                <div className="Admin-Heading-Container">
                  <div className="DashBoard-Cont">
                    <div>
                      <div className="Admin-Heading">Expired Drugs</div>
                    </div>
                    <div className="Logo-Admin-Heading">
                      <FiUserCheck className="Data-Icon" />
                    </div>
                  </div>

                  <NumberOfExpiredDrugs
                    num={drugExpiredCount?.count}
                    loading={drugExpiredCount?.loading}
                  />
                </div>
              </div>
              <div className="col-lg">
                <div className="Admin-Heading-Container">
                  <div className="DashBoard-Cont">
                    <div>
                      <div className="Admin-Heading">Out Of Stock</div>
                    </div>
                    <div className="Logo-Admin-Heading">
                      <FiUserCheck className="Data-Icon" />
                    </div>
                  </div>
                  <NumberOfOutOfStock
                    num={drugOutCount?.count}
                    loading={drugOutCount?.loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default DashboardContainer;
