import React from 'react'
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";

function NumberOfTestConducted({num, loading}) {
  return (
    <div className="Count-Result">{loading ? "loading..." : num }</div>
  )
}

export default NumberOfTestConducted