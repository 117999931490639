import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientScanDocs } from '../../../services/PatientScanDocService'

const initialState = {
    loading: false,
    listUltrasounds: [],
    paginator: {},
    isSuccessful: false
}

export const fetchPatientUltrasounds = createAsyncThunk('fetchPatientUltrasounds', (initData) => {
    return listPatientScanDocs(initData)
    .then((response) => response.data.data)
})


const patientUltrasoundSlice = createSlice({
    name: 'patientUltrasounds',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientUltrasounds.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatientUltrasounds.fulfilled, (state, action ) => {
        state.loading = false
        state.listUltrasounds = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientUltrasounds.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientUltrasoundSlice.reducer 