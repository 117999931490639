import { React, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import PrescriptionDisplay from "../../../../Component/PrescriptionDisplay";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientPrescriptionsTreatment } from "../../../../features/patients/patientPrescriptionsTreatmentSlice";

function ViewPrescriptionModal({ show, onClose, selected }) {
  const dispatch = useDispatch()
  const {listPrescriptionsTreatment, loading, isSuccessful} = useSelector((state) => state.patientPrescriptionsTreatment)

  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);

  const handleSub = () => {
    onClose();
    setViewPreOpNoteDetailsShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  useEffect(() => {
    if (selected?._id) {
      dispatch(fetchPatientPrescriptionsTreatment({treatmentId: selected?.treatment_infos[0]?.length > 0 ? selected?.treatment_infos[0]?.length : selected?.treatment}))
    }
    // if (selected?._id) {
    //   dispatch(fetchPatientPrescriptionsTreatment({treatmentId: selected?.treatment}))
    // }
    
  }, [show]);
  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                      Doctor's Prescription
                    </div>
                    <div className="Solera-Settings-Note"></div>
                  </div>
                </div>
                <br />
                <PrescriptionDisplay loading={loading} list={listPrescriptionsTreatment} />
                <br />
                <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ViewPrescriptionModal;
