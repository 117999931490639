import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listConsumables } from '../../services/ConsumableService'

const initialState = {
    loading: false,
    consumes: [],
    paginator: {},
    isSuccessful: false
}

export const fetchConsumables = createAsyncThunk('fetchConsumables', (initData) => {
    return listConsumables(initData)
    .then((response) => response.data.data)
})


const consumablesSlice = createSlice({
    name: 'consumables',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchConsumables.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchConsumables.fulfilled, (state, action ) => {
        state.loading = false
        state.consumes = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchConsumables.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default consumablesSlice.reducer 