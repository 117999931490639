import React, { useEffect } from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import { Link } from "react-router-dom";
import { FiUsers } from "react-icons/fi";
import NumberofPatients from "./NumberofPatients";
import NumberOfAdmitted from "../DashboardPage/NumberOfAdmitted";
import NumberOfDischarged from "../DashboardPage/NumberOfDischarged";
import NumberOfEmergency from "../DashboardPage/NumberOfEmergency";
import NumberOfQurantined from "./NumberOfQurantined";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientCount } from "../../features/patients/patientCountSlice";
import { fetchPatientAdmissionCount } from "../../features/patients/patientAdmissionCountSlice";
import { fetchPatientDischargedCount } from "../../features/patients/patientDischargedCountSlice";
import { fetchPatientQuarantineCount } from "../../features/patients/patientQuarantineCountSlice";


function PatientActivities() {
  const dispatch = useDispatch()
  const patientCount = useSelector((state) => state.patientCount)
  const patientAdmissionCount = useSelector((state) => state.patientAdmissionCount)
  const patientDischargedCount = useSelector((state) => state.patientDischargedCount)
  const patientQuarantineCount = useSelector((state) => state.patientQuarantineCount)

  useEffect((  ) => {
    dispatch(fetchPatientCount({}))
    dispatch(fetchPatientAdmissionCount({status: "admitted"}))
    dispatch(fetchPatientDischargedCount({status: "discharged"}))
    dispatch(fetchPatientQuarantineCount({status: "quarantined"}))
  }, [])
  return (
    <div>
      {" "}
      <div className="container-fluid ">
        <div className="row g-2 ">
          <div className="col-xl-3">
          
              <div className="Admin-Heading-Container">
                <div className="DashBoard-Cont">
                  <div className="Admin-Heading">Patients</div>

                  <div className="Logo-Admin-Heading">
                    <FiUsers className="Data-Icon" />
                  </div>
                </div>

                <NumberofPatients num={patientCount?.count} loading={patientCount?.loading} />
              </div>{" "}
         
          </div>
          <div className="col-xl-3">
            
              <div className="Admin-Heading-Container">
                <div className="DashBoard-Cont">
                  <div className="Admin-Heading">Admission</div>

                  <div className="Logo-Admin-Heading">
                    <FiUsers className="Data-Icon" />
                  </div>
                </div>

                <NumberOfAdmitted num={patientAdmissionCount?.count} loading={patientAdmissionCount?.loading} />
              </div>{" "}
      
          </div>
          <div className="col-xl-3">
         
         
              <div className="Admin-Heading-Container">
                <div className="DashBoard-Cont">
                  <div className="Admin-Heading">Discharged</div>

                  <div className="Logo-Admin-Heading">
                    <FiUsers className="Data-Icon" />
                  </div>
                </div>

                <NumberOfDischarged num={patientDischargedCount?.count} loading={patientDischargedCount?.loading} />
              </div>{" "}
          
          </div>
          <div className="col-xl-3">
        
              <div className="Admin-Heading-Container">
                <div className="DashBoard-Cont">
                  <div className="Admin-Heading">Quarantined </div>

                  <div className="Logo-Admin-Heading">
                    <FiUsers className="Data-Icon" />
                  </div>
                </div>

                <NumberOfQurantined num={patientQuarantineCount?.count} loading={patientQuarantineCount?.loading} />
              </div>{" "}
         
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientActivities;
