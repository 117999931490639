import { React, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import Swal from "sweetalert2";
import EditAdmin from "./EditAdmin";
import StaffNumber from "../StaffPage/StaffNumber";
import { findRole } from "../../services/extras";
import EditAdminPwd from "./EditAdminPwd";
import { updateSysUser } from "../../services/UserService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { fetchUsers } from "../../features/admins/usersSlice";
import { getSys } from "../../services/AuthService";

function Admin({ sysUsers }) {
  const dispatch = useDispatch();
  const sys = getSys();
  const [editAdminShow, setEditAdminShow] = useState(false);
  const handleEditAdmin = () => setEditAdminShow(false);

  const [editAdminPwdShow, setEditAdminPwdShow] = useState(false);
  const handleEditAdminPwd = () => setEditAdminPwdShow(false);

  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);

  const handleRemoveAdmin = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to remove this Admin?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#000000",
      cancelButtonColor: "#0078d4",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleRemove();
      }
    });
  };

  const handleUpdate = (role) => {
    setLoading(true);
    const data = {
      id: sysUsers?._id,
      role: role,
    };
    updateSysUser(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          dispatch(fetchUsers({}));
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleRemove = () => {
    setLoading(true);
    const data = {
      id: sysUsers?._id,
      status: "deleted",
    };
    updateSysUser(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Removed"
          );
          dispatch(fetchUsers({}));
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div>
      <div className="Patient-Displays-Details2">
        <div className="PatientNumber-C">
          <div className="Wallet-Ref-Heading">Name</div>
          <div className="Wallet-Ref-Result">{sysUsers?.user?.full_name}</div>
        </div>
        <div className="StaffNumber">
          <div className="Wallet-Ref-Heading">Number</div>
          <div className="Wallet-Ref-Result">
            <StaffNumber num={sysUsers?.user?.phone} />
          </div>
        </div>
        <div className="StaffEmail">
          <div className="Wallet-Ref-Heading">Email</div>
          <div className="Wallet-Ref-Result">
            <StaffNumber num={sysUsers?.user?.email} />
          </div>
        </div>
        <div className="AdminTypex">
          <div className="Wallet-Ref-Heading">Admin Type</div>
          <div className="Wallet-Ref-Result">{findRole(sysUsers?.name)}</div>
        </div>
        {/* <div className="Position-Cont-P">
          <div className="Wallet-Ref-Heading">Position</div>
          <div className="Wallet-Ref-Result">Nurse</div>
        </div> */}

        {/* <div className="Dept-Cont-P">
          <div className="Wallet-Ref-Heading">Department</div>
          <div className="Wallet-Ref-Result">Nurse</div>
        </div> */}

        <div className="Action-Drop-Down">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
              {loading ? "Loading..." : "Manage Admin"}
            </Dropdown.Toggle>
            {sys?.name ? (
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => handleUpdate("sys-admin")}
                  className="Solera-Patients"
                >
                  Super Admin
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleUpdate("sys-doctor")}
                  className="Solera-Patients"
                >
                  Doctor
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleUpdate("sys-nurse")}
                  className="Solera-Patients"
                >
                  Nurse
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleUpdate("sys-matron")}
                  className="Solera-Patients"
                >
                  Clinical Matron
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleUpdate("sys-lap")}
                  className="Solera-Patients"
                >
                  Lab Scientist
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleUpdate("sys-pharmacy")}
                  className="Solera-Patients"
                >
                  Pharmacist
                </Dropdown.Item>
                {/* <Dropdown.Item
                  onClick={() => handleUpdate("sys-dentist")}
                  className="Solera-Patients"
                >
                  Dentist
                </Dropdown.Item> */}
                <Dropdown.Item
                  onClick={() => handleUpdate("sys-physiotherapist")}
                  className="Solera-Patients"
                >
                  Physiotherapist
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleUpdate("sys-dietician")}
                  className="Solera-Patients"
                >
                  Dietician
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => handleUpdate("sys-anaesthetist")}
                  className="Solera-Patients"
                >
                  Anaesthetist
                </Dropdown.Item>

               
                <Dropdown.Item
                  onClick={() => handleUpdate("sys-clinicalsychologist")}
                  className="Solera-Patients"
                >
                  Clinical Psychologist
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleUpdate("sys-socialworker")}
                  className="Solera-Patients"
                >
                  Social Worker
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleUpdate("sys-store")}
                  className="Solera-Patients"
                >
                  Store
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setSelected(sysUsers);
                    setEditAdminShow(true);
                  }}
                  className="Solera-Patients"
                >
                  Update Admin
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setSelected(sysUsers);
                    setEditAdminPwdShow(true);
                  }}
                  className="Solera-Patients"
                >
                  Change Password
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={handleRemoveAdmin}
                  className="Solera-Patients"
                >
                  Remove Admin
                </Dropdown.Item>
              </Dropdown.Menu>
            ) : null}
          </Dropdown>
        </div>
      </div>
      <div>
        <EditAdmin
          selected={selected}
          show={editAdminShow}
          onClose={handleEditAdmin}
          onOpen={() => setEditAdminShow(true)}
        />
        <EditAdminPwd
          selected={selected}
          show={editAdminPwdShow}
          onClose={handleEditAdminPwd}
          onOpen={() => setEditAdminPwdShow(true)}
        />
      </div>
    </div>
  );
}

export default Admin;
