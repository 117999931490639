import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";

function ViewDsModal({ show, onClose }) {
  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);

  const handleSubComment = () => {
    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const handleSub = () => {
    onClose();
    setViewPreOpNoteDetailsShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };
  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera-Modal-Container">
                <br />
                <div className="Report-Display-Heading">Discharge Summary</div>
                <div className="Report-Tittle">Discharge Summary Display</div>
                <div className="Solera-Modal-Container">
                  <div className="Note-Modal-Cont">
                    <div>
                      <div className="Doctors-Name">
                        Doctors's name : &nbsp;<span>Doctors's name</span>
                      </div>
                      <div className="Report-Date">
                        Report Date: &nbsp;<span>Report Date</span>
                      </div>
           
                    </div>

                    <br />
                    <div className="Report-Display"> Discharge Diagnosis</div>
                    <div className="Report-Display-Note">
                      Discharge Diagnosis Display
                    </div>

                    <br />
                    <div className="Report-Display">
                      Procedures and Therapies
                    </div>
                    <div className="Report-Display-Note">
                      Procedures and Therapies Display
                    </div>
                    <br />
                    <div className="Report-Display">Complications</div>
                    <div className="Report-Display-Note">
                      Complications Display
                    </div>
                    <br />

                    <div className="Report-Display">Consultations</div>
                    <div className="Report-Display-Note">
                      Consultations Display
                    </div>
                    <br />

                    <div className="Solera-Settings-Header44">
                      Pertinent History
                    </div>

                    <div className="Report-Display-Note">
                    Pertinent History Display
                    </div>
                    <br />
                    <div className="Report-Display">Lab</div>
                    <div className="Report-Display-Note">
                    Lab Display
                    </div>

                    <br />
                    <div className="Report-Display">Conduct on Discharge</div>
                    <div className="Report-Display-Note">
                    Conduct on Discharge Display
                    </div>
                    <br />
                    <div className="Report-Display">Disposition</div>
                    <div className="Report-Display-Note">
                    Disposition Display
                    </div>
                    <br />
                    <div className="Report-Display">Discharge to</div>
                    <div className="Report-Display-Note">
                    Discharge to Display
                    </div>
                    <br />
                    <div className="Report-Display">Diet</div>
                    <div className="Report-Display-Note">
                    Diet Display
                    </div>
                    <br />
                    <div className="Report-Display">Activity</div>
                    <div className="Report-Display-Note">
                    Activity Display
                    </div>
                    <br />
                    <div className="Report-Display">DME</div>
                    <div className="Report-Display-Note">
                    DME Display
                    </div>
                    <br />
                    <div className="Report-Display">Home Health Service</div>
                    <div className="Report-Display-Note">
                    Home Health Service Display
                    </div>
                    <br />
                    <div className="Report-Display">Lab2</div>
                    <div className="Report-Display-Note">
                    Lab2 Display
                    </div>
                    <br />
                    <div className="Report-Display">F/U Apts</div>
                    <div className="Report-Display-Note">
                    F/U Apts Display
                    </div>
                    <br />
                    <div className="Report-Display">Meds</div>
                    <div className="Report-Display-Note">
                    Meds Display
                    </div>

                    <br />
                    <div className="Report-Display">Stop</div>
                    <div className="Report-Display-Note">
                    Stop Display
                    </div>
                    <br />
                    <div className="Report-Display">Modify</div>
                    <div className="Report-Display-Note">
                    Modify Display
                    </div>
                    <br />
                    <div className="Report-Display">New Rx</div>
                    <div className="Report-Display-Note">
                    New Rx Display
                    </div>
                    
                  </div>
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ViewDsModal;
