import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { drugsCount } from '../../services/DrugService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchExpiredDrugCount = createAsyncThunk('fetchExpiredDrugCount', (initData) => {
    return drugsCount(initData)
    .then((response) =>  response.data.data)
})


const drugExpiredCountSlice = createSlice({
    name: 'drugExpiredCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchExpiredDrugCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchExpiredDrugCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default drugExpiredCountSlice.reducer 