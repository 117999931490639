import axios from '../axiosinstance';

      
export function listPatientTreatmentPlans(initData){
        return axios.get('/patient-treatment-plans', { params: { name: initData?.search, patient: initData?.patientId } })
}

export function addPatientTreatmentPlans(data){
        return axios.post('/patient-treatment-plans', data)
}

export function getPatientTreatmentPlans(initData){
        return axios.get('/patient-treatment-plans/'+initData?.id)
}

export function updatePatientTreatmentPlans(initData){
        return axios.patch('/patient-treatment-plans/'+initData.id, initData)
}

export function patientTreatmentPlanCounts(search){
        return axios.get('/patient-treatment-plans/stat/count', { params: search })
}

