import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientSocialMedication } from '../../../services/PatientSocialMedicationService'

const initialState = {
    loading: false,
    socials: [],
    isSuccessful: false
}

export const fetchSocialMedications = createAsyncThunk('fetchSocialMedications', (initData) => {
    return listPatientSocialMedication(initData)
    .then((response) => response.data.data)
})


const socialMedicationSlice = createSlice({
    name: 'socialMedications',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchSocialMedications.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchSocialMedications.fulfilled, (state, action ) => {
        state.loading = false
        state.socials = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchSocialMedications.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default socialMedicationSlice.reducer 