import { React, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import POstOpVitalsModals from './POstOpVitalsModals';

function AddPostOpVitals() {
  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);
  return (
    <div>
      <button
        className="Add-Patient-Report"
        onClick={() => setAddReportShow(true)}
      >
        Add Post Op Vital's Note
      </button>
      <div>
        <POstOpVitalsModals
          show={addReportShow}
          onClose={handleAddReport}
          onOpen={() => setAddReportShow(true)}
        />
      </div>
    </div>
  )
}

export default AddPostOpVitals