import React from "react";
import "../SoleraCss/Solera.css";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";

function Paginations({handleFetchTransSearch, search, page, setPage, startDate, endDate, pageCount, pageLimit, setPageLimit }) {
  const buttonPreviuos = () => {
    if(page > 1){
      setPage(page - 1)
      handleFetchTransSearch(search, pageLimit, page - 1, startDate, endDate )
    }
  }
  
  const buttonNext = () => {
    if(page !== pageCount){
      setPage(page + 1)
      handleFetchTransSearch(search, pageLimit, page + 1, startDate, endDate )
    }
  }

  return (
    <div>
      <div className="Search__paginate">
        <div onClick={buttonPreviuos} className="Search__paginate-Pre">
        {page === 1 ? (
          <>
            End of Previous
          </>
        ) : (
          <>
            <FiChevronsLeft className="PagIcon" />
            Previous
          </>
        ) }
          
        </div>
        <div>
          Result&nbsp;<span>{page}</span>&nbsp;out of&nbsp;<span>{pageCount}</span>
          &nbsp;page(s)
        </div>
        <div onClick={buttonNext} className="Search__paginate-Pre">
          
          {page === pageCount ? (
            <>
              End of Next
            </>
        ) : (
          <>
            Next
            <FiChevronsRight className="PagIcon" />
          </>
        ) } 
        </div>
      </div>
    </div>
  );
}

export default Paginations;
