import { React, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import { MdAddCircle, MdClose, MdRemoveCircle } from "react-icons/md";

import Accordion from "react-bootstrap/Accordion";
import { updateFluidCharts } from "../../../../services/FluidChartService";
import { fetchfluidChartsCount } from "../../../../features/patients/fluids/fluidChartsCountSlice";
import { fetchfluidCharts } from "../../../../features/patients/fluids/fluidChartsSlice";
import { useParams } from "react-router-dom";
import { getUser } from "../../../../services/AuthService";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import { addFluidChartItem } from "../../../../services/FluidChartItemService";

function UpdateFluidChart({ show, onClose, selected }) {
  let { id } = useParams();
  const user = getUser();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [updateFluidChartShow, setUpdateFluidChartShow] = useState(false);
  const handleUpdateFluidChart = () => setUpdateFluidChartShow(false);

  const [totalTube, setTotalTube] = useState("");
  const [totalUrine, setTotalUrine] = useState("");
  const [totalOral, setTotalOral] = useState("");
  const [totalVomit, setTotalVomit] = useState("");
  const [totalIv, setTotalIv] = useState("");
  const [totalFaeces, setTotalFaeces] = useState("");
  const [totalIn, setTotalIn] = useState("");
  const [totalOut, setTotalOut] = useState("");
  const [totalOutput, setTotalOutput] = useState("");

  const [mornTime, setMornTime] = useState("");
  const [mornInput, setMornInput] = useState([]);
  const [mornOutput, setMornOutput] = useState([]);

  const [afternoonTime, setAfternoonTime] = useState("");
  const [afternoonInput, setAfternoonInput] = useState([]);
  const [afternoonOutput, setAfternoonOutput] = useState([]);

  const [eveningTime, setEveningTime] = useState("");
  const [eveningInput, setEveningInput] = useState([]);
  const [eveningOutput, setEveningOutput] = useState([]);

  const morningInput = (item) => {
    const items = [...mornInput];

    if (items?.length) {
      const foundIndex = items.findIndex((x) => x.type == item.type);

      if (foundIndex !== -1) {
        items[foundIndex] = item;
      } else {
        items.push(item);
      }
      setMornInput(items);
    } else {
      //items = [...mornInput, item]
      items.push(item);
      setMornInput(items);
    }
  };

  const morningOutput = (item) => {
    const items = [...mornOutput];

    if (items?.length) {
      const foundIndex = items.findIndex((x) => x.type == item.type);

      if (foundIndex !== -1) {
        items[foundIndex] = item;
      } else {
        items.push(item);
      }
      setMornOutput(items);
    } else {
      //items = [...mornInput, item]
      items.push(item);
      setMornOutput(items);
    }
  };

  const afterInput = (item) => {
    const items = [...afternoonInput];

    if (items?.length) {
      const foundIndex = items.findIndex((x) => x.type == item.type);

      if (foundIndex !== -1) {
        items[foundIndex] = item;
      } else {
        items.push(item);
      }
      setAfternoonInput(items);
    } else {
      //items = [...mornInput, item]
      items.push(item);
      setAfternoonInput(items);
    }
  };

  const afterOutput = (item) => {
    const items = [...afternoonOutput];

    if (items?.length) {
      const foundIndex = items.findIndex((x) => x.type == item.type);

      if (foundIndex !== -1) {
        items[foundIndex] = item;
      } else {
        items.push(item);
      }
      setAfternoonOutput(items);
    } else {
      //items = [...mornInput, item]
      items.push(item);
      setAfternoonOutput(items);
    }
  };

  const evenInput = (item) => {
    const items = [...eveningInput];

    if (items?.length) {
      const foundIndex = items.findIndex((x) => x.type == item.type);

      if (foundIndex !== -1) {
        items[foundIndex] = item;
      } else {
        items.push(item);
      }
      setEveningInput(items);
    } else {
      //items = [...mornInput, item]
      items.push(item);
      setEveningInput(items);
    }
  };

  const evenOutput = (item) => {
    const items = [...eveningOutput];

    if (items?.length) {
      const foundIndex = items.findIndex((x) => x.type == item.type);

      if (foundIndex !== -1) {
        items[foundIndex] = item;
      } else {
        items.push(item);
      }
      setEveningOutput(items);
    } else {
      //items = [...mornInput, item]
      items.push(item);
      setEveningOutput(items);
    }
  };

  useEffect(() => {
    if (selected?.code) {
      setTotalTube(selected?.total_tube);
      setTotalUrine(selected?.total_urine);
      setTotalOral(selected?.total_oral);
      setTotalVomit(selected?.total_vomit);
      setTotalIv(selected?.total_iv);
      setTotalFaeces(selected?.total_faeces);
      setTotalIn(selected?.total_in);
      setTotalOut(selected?.total_out);
      setTotalOutput(selected?.total_output);
    }
  }, [show]);
  const handleUpdateChart = () => {
    onClose();
    setUpdateFluidChartShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const handleUpdate = () => {
    setLoading(true);
    const data = {
      id: selected?._id,
      user: user?._id,
      patient: id,
      total_tube: totalTube,
      total_urine: totalUrine,
      total_oral: totalOral,
      total_vomit: totalVomit,
      total_iv: totalIv,
      total_faeces: totalFaeces,
      total_in: totalIn,
      total_out: totalOut,
      total_output: totalOutput,
    };
    console.log(data);
    updateFluidCharts(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(fetchfluidCharts({ patientId: id }));
          dispatch(fetchfluidChartsCount({ patientId: id }));
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleMorningAddItem = () => {
    setLoading(true);
    const data = {
      fluid: selected?._id,
      user: user?._id,
      patient: id,
      category: "morning",
      time: mornTime,
      input: mornInput,
      output: mornOutput,
    };
    console.log(data);
    addFluidChartItem(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(fetchfluidCharts({ patientId: id }));
          dispatch(fetchfluidChartsCount({ patientId: id }));
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleAfternoonAddItem = () => {
    setLoading(true);
    const data = {
      fluid: selected?._id,
      user: user?._id,
      patient: id,
      category: "afternoon",
      time: afternoonTime,
      input: afternoonInput,
      output: afternoonOutput,
    };
    console.log(data);
    addFluidChartItem(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(fetchfluidCharts({ patientId: id }));
          dispatch(fetchfluidChartsCount({ patientId: id }));
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleEveningAddItem = () => {
    setLoading(true);
    const data = {
      fluid: selected?._id,
      user: user?._id,
      patient: id,
      category: "evening",
      time: eveningTime,
      input: eveningInput,
      output: eveningOutput,
    };
    console.log(data);
    addFluidChartItem(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(fetchfluidCharts({ patientId: id }));
          dispatch(fetchfluidChartsCount({ patientId: id }));
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>
              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                      Fluid Balance Chart
                    </div>
                    <div className="FormSect-Note">
                      Please fill all the required field
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="FormSect">
                        Morning (AM){" "}
                        <span className="FormSect-Note">
                          Select the time accordily and update (From 9AM - 11AM)
                        </span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="container-fluid p-0 FormInput">
                        <div className="form-floating">
                          <select
                            className="form-select"
                            id="floatingSelect"
                            aria-label="Floating label select example"
                            value={mornTime}
                            onChange={(e) => setMornTime(e.target.value)}
                          >
                            <option selected>
                              Choose time from the provided options
                            </option>
                            <option value="8AM">8AM</option>
                            <option value="9AM">9AM</option>
                            <option value="10AM">10AM</option>
                            <option value="11AM">11AM</option>
                          </select>
                          <label for="floatingSelect">
                            Select time and proceed with your report
                          </label>
                        </div>
                        <br /> <br />
                        <div className="row gx-4">
                          <div className="col-lg">
                            <div className="FormSect">Input(ml)</div>
                            <div className="FormSect-Note">
                              Please fill all the required field
                            </div>
                            <br />
                          </div>
                          <div className="col-lg">
                            <div className="FormSect">Output(ml)</div>
                            <div className="FormSect-Note">
                              Please fill all the required field
                            </div>
                            <br />
                          </div>
                        </div>
                        <div className="FormSect-Contianer">
                          <div className="container-fluid p-0 FormInput">
                            <div className="row gx-4">
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      morningInput({
                                        type: "type",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Type</label>
                                </div>
                              </div>
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      morningOutput({
                                        type: "urine",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Urine</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row gx-4">
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      morningInput({
                                        type: "tube",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Tube</label>
                                </div>
                              </div>
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      morningOutput({
                                        type: "vomit",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Tube/Vomit</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row gx-4">
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      morningInput({
                                        type: "oral",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Oral</label>
                                </div>
                              </div>
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      morningOutput({
                                        type: "faeces",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Faeces</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row gx-4">
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      morningInput({
                                        type: "iv",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">IV</label>
                                </div>
                              </div>
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      morningOutput({
                                        type: "type",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Type</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row gx-4">
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      morningInput({
                                        type: "total",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Total</label>
                                </div>
                              </div>
                              <div className="col-lg">
                                {" "}
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      morningOutput({
                                        type: "total",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Total</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        {loading ? (
                          <Bars
                            heigth="50"
                            width="50"
                            color="gray"
                            ariaLabel="loading-indicator"
                          />
                        ) : (
                          <button
                            className="Add-Button"
                            onClick={handleMorningAddItem}
                          >
                            Submit
                          </button>
                        )}
                        {/* <button
                          className="Add-Button"
                          onClick={handleAddItem}
                        >
                          Submit
                        </button> */}
                        <br /> <br />
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <div className="FormSect">
                        Afternoon (PM) - &nbsp;&nbsp;
                        <span className="FormSect-Note">
                          Select the time accordily and update (From 1PM - 11PM)
                        </span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="container-fluid p-0 FormInput">
                        <div className="form-floating">
                          <select
                            className="form-select"
                            id="floatingSelect"
                            aria-label="Floating label select example"
                            value={afternoonTime}
                            onChange={(e) => setAfternoonTime(e.target.value)}
                          >
                            <option selected>
                              Choose time from the provided options
                            </option>
                            <option value="1PM">1PM</option>
                            <option value="2PM">2PM</option>
                            <option value="3PM">3PM</option>
                            <option value="4PM">4PM</option>
                            <option value="5PM">5PM</option>
                            <option value="6PM">6PM</option>
                            <option value="7PM">7PM</option>
                            <option value="8PM">8PM</option>
                            <option value="9PM">9PM</option>
                            <option value="10PM">10PM</option>
                            <option value="11PM">11PM</option>
                          </select>
                          <label for="floatingSelect">
                            Select time and proceed with your report
                          </label>
                        </div>
                        <br /> <br />
                        <div className="row gx-4">
                          <div className="col-lg">
                            <div className="FormSect">Input(ml)</div>
                            <div className="FormSect-Note">
                              Please fill all the required field
                            </div>
                            <br />
                          </div>
                          <div className="col-lg">
                            <div className="FormSect">Output(ml)</div>
                            <div className="FormSect-Note">
                              Please fill all the required field
                            </div>
                            <br />
                          </div>
                        </div>
                        <div className="FormSect-Contianer">
                          <div className="container-fluid p-0 FormInput">
                            <div className="row gx-4">
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      afterInput({
                                        type: "type",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Type</label>
                                </div>
                              </div>
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      afterOutput({
                                        type: "urine",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Urine</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row gx-4">
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      afterInput({
                                        type: "tube",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Tube</label>
                                </div>
                              </div>
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      afterOutput({
                                        type: "vomit",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Tube/Vomit</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row gx-4">
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      afterInput({
                                        type: "oral",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Oral</label>
                                </div>
                              </div>
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      afterOutput({
                                        type: "faeces",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Faeces</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row gx-4">
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      afterInput({
                                        type: "iv",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">IV</label>
                                </div>
                              </div>
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      afterOutput({
                                        type: "type",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Type</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row gx-4">
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      afterInput({
                                        type: "total",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Total</label>
                                </div>
                              </div>
                              <div className="col-lg">
                                {" "}
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      afterOutput({
                                        type: "total",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Total</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        {loading ? (
                          <Bars
                            heigth="50"
                            width="50"
                            color="gray"
                            ariaLabel="loading-indicator"
                          />
                        ) : (
                          <button
                            className="Add-Button"
                            onClick={handleAfternoonAddItem}
                          >
                            Submit
                          </button>
                        )}
                        {/* <button
                          className="Add-Button"
                          onClick={handleUpdateChart}
                        >
                          Submit
                        </button> */}
                        <br /> <br />
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <div className="FormSect">
                        Evening (PM) -&nbsp;&nbsp;
                        <span className="FormSect-Note">
                          Select the time accordily and update (From 1AM - 7AM)
                        </span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="container-fluid p-0 FormInput">
                        <div className="form-floating">
                          <select
                            className="form-select"
                            id="floatingSelect"
                            aria-label="Floating label select example"
                            value={eveningTime}
                            onChange={(e) => setEveningTime(e.target.value)}
                          >
                            <option selected>
                              Choose time from the provided options
                            </option>
                            <option value="1AM">1AM</option>
                            <option value="2AM">2AM</option>
                            <option value="3AM">3AM</option>
                            <option value="4AM">4AM</option>
                            <option value="5AM">5AM</option>
                            <option value="6AM">6AM</option>
                            <option value="7AM">7AM</option>
                          </select>
                          <label for="floatingSelect">
                            Select time and proceed with your report
                          </label>
                        </div>
                        <br /> <br />
                        <div className="row gx-4">
                          <div className="col-lg">
                            <div className="FormSect">Input(ml)</div>
                            <div className="FormSect-Note">
                              Please fill all the required field
                            </div>
                            <br />
                          </div>
                          <div className="col-lg">
                            <div className="FormSect">Output(ml)</div>
                            <div className="FormSect-Note">
                              Please fill all the required field
                            </div>
                            <br />
                          </div>
                        </div>
                        <div className="FormSect-Contianer">
                          <div className="container-fluid p-0 FormInput">
                            <div className="row gx-4">
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      evenInput({
                                        type: "type",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Type</label>
                                </div>
                              </div>
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      evenOutput({
                                        type: "urine",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Urine</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row gx-4">
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      evenInput({
                                        type: "tube",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Tube</label>
                                </div>
                              </div>
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      evenOutput({
                                        type: "vomit",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Tube/Vomit</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row gx-4">
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      evenInput({
                                        type: "oral",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Oral</label>
                                </div>
                              </div>
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      evenOutput({
                                        type: "faeces",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Faeces</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row gx-4">
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      evenInput({
                                        type: "iv",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">IV</label>
                                </div>
                              </div>
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      evenOutput({
                                        type: "type",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Type</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row gx-4">
                              <div className="col-lg">
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      evenInput({
                                        type: "total",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Total</label>
                                </div>
                              </div>
                              <div className="col-lg">
                                {" "}
                                <div className="form-floating mb-3">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    onChange={(e) =>
                                      evenOutput({
                                        type: "total",
                                        measure: e.target.value,
                                      })
                                    }
                                  />
                                  <label for="floatingInput">Total</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        {loading ? (
                          <Bars
                            heigth="50"
                            width="50"
                            color="gray"
                            ariaLabel="loading-indicator"
                          />
                        ) : (
                          <button
                            className="Add-Button"
                            onClick={handleEveningAddItem}
                          >
                            Submit
                          </button>
                        )}
                        {/* <button
                          className="Add-Button"
                          onClick={handleUpdateChart}
                        >
                          Submit
                        </button> */}
                        <br /> <br />
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <br />
                <div className="FormSect-Contianer">
                  <div className="FormSect">Total</div>
                  <div className="FormSect-Note">
                    Please fill all the required field
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            value={totalTube}
                            onChange={(e) => setTotalTube(e.target.value)}
                          />
                          <label for="floatingInput">Total Tube</label>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            value={totalUrine}
                            onChange={(e) => setTotalUrine(e.target.value)}
                          />
                          <label for="floatingInput">Total Urine</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            value={totalOral}
                            onChange={(e) => setTotalOral(e.target.value)}
                          />
                          <label for="floatingInput">Total Oral</label>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            value={totalVomit}
                            onChange={(e) => setTotalVomit(e.target.value)}
                          />
                          <label for="floatingInput">Total Tube/Vomit</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            value={totalIv}
                            onChange={(e) => setTotalIv(e.target.value)}
                          />
                          <label for="floatingInput">Total IV</label>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            value={totalFaeces}
                            onChange={(e) => setTotalFaeces(e.target.value)}
                          />
                          <label for="floatingInput">Total Faeces</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            value={totalIn}
                            onChange={(e) => setTotalIn(e.target.value)}
                          />
                          <label for="floatingInput">Total In</label>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            value={totalOut}
                            onChange={(e) => setTotalOut(e.target.value)}
                          />
                          <label for="floatingInput">Total Out</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            value={totalOutput}
                            onChange={(e) => setTotalOutput(e.target.value)}
                          />
                          <label for="floatingInput">Total Out </label>
                        </div>
                      </div>
                      <div className="col-lg"></div>
                    </div>
                  </div>

                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                          />
                          <label for="floatingInput">Balance</label>
                        </div>
                      </div>
                      <div className="col-lg"></div>
                    </div>
                  </div>
                </div>
                <br />
                {loading ? (
                  <Bars
                    heigth="50"
                    width="50"
                    color="gray"
                    ariaLabel="loading-indicator"
                  />
                ) : (
                  <button
                    disabled={!totalTube}
                    className="Add-Button"
                    onClick={handleUpdate}
                  >
                    Submit Total
                  </button>
                )}
                {/* <button className="Add-Button" onClick={handleUpdateChart}>
                  Submit Total
                </button> */}
                <br /> <br />
                <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default UpdateFluidChart;
