import axios from '../axiosinstance';

      
export function listPatientScanDocs(initData){
        return axios.get('/patient-docs', { params: initData })
}

export function addPatientScanDoc(data){
        return axios.post('/patient-docs', data)
}

export function getPatientScanDoc(initData){
        return axios.get('/patient-docs/'+initData?.id)
}

export function updatePatientScanDoc(id, initData){
        return axios.patch('/patient-docs/'+id, initData)
}

export function scanCount(search){
        return axios.get('/patient-docs/stat/count', { params: search })
}

