import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientClinicalNote } from '../../services/PatientClinicalNoteService'

const initialState = {
    loading: false,
    postOpVitals: [],
    isSuccessful: false
}

export const fetchPostOpVitals = createAsyncThunk('fetchPostOpVitals', (initData) => {
    return listPatientClinicalNote(initData)
    .then((response) => response.data.data)
})


const postOpVitalsSlice = createSlice({
    name: 'postOpVitalsList',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPostOpVitals.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchPostOpVitals.fulfilled, (state, action ) => {
        state.loading = false
        state.postOpVitals = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchPostOpVitals.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default postOpVitalsSlice.reducer 