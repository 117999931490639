import axios from '../axiosinstance';

      
export function listPatientAllergies(initData){
        return axios.get('/patient-allergies', { params: { name: initData?.search, patient: initData?.patientId } })
}

export function addPatientAllergy(data){
        return axios.post('/patient-allergies', data)
}

export function getPatientAllergy(initData){
        return axios.get('/patient-allergies/'+initData?.id)
}

export function updatePatientAllergy(initData){
        return axios.patch('/patient-allergies/'+initData.id, initData)
}

export function patientAllergyCount(search){
        return axios.get('/patient-allergies/stat/count', { params: search })
}

