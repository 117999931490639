import React from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";

function LabScience({num, loading}) {
  return (
    <div className="Count-Result">{loading ? "loading..." : num }</div>
  )
}

export default LabScience