import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { drugsCount } from '../../services/DrugService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchDrugCount = createAsyncThunk('fetchDrugCount', (initData) => {
    return drugsCount(initData)
    .then((response) =>  response.data.data)
})


const drugCountSlice = createSlice({
    name: 'drugCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchDrugCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchDrugCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default drugCountSlice.reducer 