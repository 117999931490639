import { React, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import AddAllegiesModal from "../../../../Component/Modals/AddAllegiesModal";
import { getSys } from "../../../../services/AuthService";

function AddAllegies() {
  let sys = getSys();


  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);
  return (
    <div>
      <div>

      {sys?.role === "sys-doctor" || sys?.role === "sys-nurse" || sys?.role === "sys-admin" ? (
           <button
           className="Add-Patient-Report"
           onClick={() => setAddReportShow(true)}
         >
           Add Allergies
         </button>
        ) : null}


       
        <AddAllegiesModal
          show={addReportShow}
          onClose={handleAddReport}
          onOpen={() => setAddReportShow(true)}
        />
      </div>
    </div>
  );
}

export default AddAllegies;
