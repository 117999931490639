import React, { useEffect } from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import { FiUsers } from "react-icons/fi";
import NumberOfDispensedDrugs from "./NumberOfDispensedDrugs";
import NumberOfAvailableDrugs from "./NumberOfAvailableDrugs";
import NumberOfOutOfStock from "./NumberOfOutOfStock";
import NumberOfExpiredDrugs from "./NumberOfExpiredDrugs";
import { useDispatch, useSelector } from "react-redux";
import { fetchDrugCount } from "../../features/pharmacy/drugCountSlice";
import { fetchExpiredDrugCount } from "../../features/pharmacy/drugExpiredCountSlice";
import { fetchOutDrugCount } from "../../features/pharmacy/drugOutCountSlice";
import { Link } from "react-router-dom";
import { fetchPatientDrugsSum } from "../../features/patients/stats/patientDrugsSumSlice";

function PharmacyActivities() {
  const dispatch = useDispatch()
  const drugCount = useSelector((state) => state.drugCount)
  const drugExpiredCount = useSelector((state) => state.drugExpiredCount)
  const drugOutCount = useSelector((state) => state.drugOutCount)
  const patientDrugsSum = useSelector((state) => state.patientDrugsSum)

  useEffect((  ) => {
    dispatch(fetchDrugCount({avaliable:true}))
    dispatch(fetchExpiredDrugCount({expired: true}))
    dispatch(fetchOutDrugCount({outoff: true}))
    dispatch(fetchPatientDrugsSum({status: 'active'}))

  }, [])
  return (
    <div>
      {" "}
      <div className="container-fluid p-0">
        <div className="row g-2 ">
            <Link to='/pharmacy-dispensed' className="col-lg  Admin-Heading">
              <div className="Admin-Heading-Container">
                <div className="DashBoard-Cont">
                  <div>
                    <div className="Admin-Heading">Dispensed Drugs</div>
                  </div>
                  <div className="Logo-Admin-Heading">
                    <FiUsers className="Data-Icon" />
                  </div>
                </div>

                <NumberOfDispensedDrugs num={patientDrugsSum?.count} loading={patientDrugsSum?.loading} />
              </div>
            </Link>
          <Link to="/pharmacy" className="col-xl Admin-Heading">
            <div className="Admin-Heading-Container">
              <div className="DashBoard-Cont">
                <div className="Admin-Heading">Available </div>

                {/* <div className="Logo-Admin-Heading">
                  <FiUsers className="Data-Icon" />
                </div> */}
              </div>

              <NumberOfAvailableDrugs num={drugCount?.count} loading={drugCount?.loading} />
            </div>{" "}
          </Link>
      
          <Link to="/pharmacy-expireds" className="col-xl Admin-Heading">
            <div className="Admin-Heading-Container">
              <div className="DashBoard-Cont">
                <div className="Admin-Heading">Expired </div>

                {/* <div className="Logo-Admin-Heading">
                  <FiUsers className="Data-Icon" />
                </div> */}
              </div>

              <NumberOfExpiredDrugs num={drugExpiredCount?.count} loading={drugExpiredCount?.loading} />
            </div>{" "}
          </Link>
          <Link to="/pharmacy-outofstocks" className="col-xl Admin-Heading">
            <div className="Admin-Heading-Container">
              <div className="DashBoard-Cont">
                <div className="Admin-Heading">Out of Stock</div>

                {/* <div className="Logo-Admin-Heading">
                  <FiUsers className="Data-Icon" />
                </div> */}
              </div>

              <NumberOfOutOfStock num={drugOutCount?.count} loading={drugOutCount?.loading} />
            </div>{" "}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PharmacyActivities;
