import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listNurseReports } from '../../services/NurseReportService'

const initialState = {
    loading: false,
    nurseReports: [],
    paginator: {},
    isSuccessful: false
}

export const fetchNurseReports = createAsyncThunk('fetchNurseReports', (initData) => {
    return listNurseReports(initData)
    .then((response) => response.data.data)
})


const nursesReportsSlice = createSlice({
    name: 'nurseReports',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchNurseReports.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchNurseReports.fulfilled, (state, action ) => {
        state.loading = false
        state.nurseReports = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchNurseReports.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default nursesReportsSlice.reducer 