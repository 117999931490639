import React, { useEffect } from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import {  FiUserPlus } from "react-icons/fi";
import Phamacist from "./Phamacist";
import LabScience from "./LabScience";
import NumberOfDoctors from "../DoctorsPage/NumberOfDoctors";
import NumberOfNurses from "../NursesPage/NumberOfNurses";
import { fetchSysDoctorCount } from "../../features/admins/counts/sysDoctorCountSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchSysNurseCount } from "../../features/admins/counts/sysNurseCountSlice";
import { fetchSysPharmacistCount } from "../../features/admins/counts/sysPharmacistCountSlice";
import { fetchSysLapCount } from "../../features/admins/counts/sysLapCountSlice";

function AdminActivities() {
  const dispatch = useDispatch()
  const sysDoctorCount = useSelector((state) => state.sysDoctorCount)
  const sysNurseCount = useSelector((state) => state.sysNurseCount)
  const sysPharmacistCount = useSelector((state) => state.sysPharmacistCount)
  const sysLapCount = useSelector((state) => state.sysLapCount)
  
  useEffect((  ) => {
    dispatch(fetchSysDoctorCount({role: 'sys-doctor'}))
    dispatch(fetchSysNurseCount({role: 'sys-nurse'}))
    dispatch(fetchSysPharmacistCount({role: 'sys-pharmacy'}))
    dispatch(fetchSysLapCount({role: 'sys-lab'}))
  }, [])
  return (
    <div>
      {" "}
      <div className="container-fluid ">
        <div className="row g-2 ">
          <div className="container-fluid ">
            <div className="row g-2 ">
              <div className="col-lg-3">
                <div className="Admin-Heading-Container">
                  <div className="DashBoard-Cont">
                    <div>
                      <div className="Admin-Heading">Doctors</div>
                    </div>
                    <div className="Logo-Admin-Heading">
                      <FiUserPlus className="Data-Icon" />
                    </div>
                  </div>

                  <NumberOfDoctors dnum={sysDoctorCount?.count} dloading={sysDoctorCount?.loading} />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="Admin-Heading-Container">
                  <div className="DashBoard-Cont">
                    <div>
                      <div className="Admin-Heading">Nurses</div>
                    </div>
                    <div className="Logo-Admin-Heading">
                      <FiUserPlus className="Data-Icon" />
                    </div>
                  </div>

                  <NumberOfNurses nurseNum={sysNurseCount?.count} nurseLoading={sysNurseCount?.loading} />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="Admin-Heading-Container">
                  <div className="DashBoard-Cont">
                    <div>
                      <div className="Admin-Heading">Pharmacist</div>
                    </div>
                    <div className="Logo-Admin-Heading">
                      <FiUserPlus className="Data-Icon" />
                    </div>
                  </div>

                  <Phamacist num={sysPharmacistCount?.count} loading={sysPharmacistCount?.loading} />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="Admin-Heading-Container">
                  <div className="DashBoard-Cont">
                    <div>
                      <div className="Admin-Heading">Lab Scientist</div>
                    </div>
                    <div className="Logo-Admin-Heading">
                      <FiUserPlus className="Data-Icon" />
                    </div>
                  </div>

                  <LabScience num={sysLapCount?.count} loading={sysLapCount?.loading} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminActivities;
