import React from 'react'
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";

function PatientName({name}) {
  return (
    <div>{name}</div>
  )
}

export default PatientName