import axios from '../axiosinstance';

      
export function listPatientSurgicalSafeties(initData){
        return axios.get('/patient-surgical-safeties', { params: initData })
}

export function addPatientSurgicalSafety(data){
        return axios.post('/patient-surgical-safeties', data)
}

export function getPatientSurgicalSafety(initData){
        return axios.get('/patient-surgical-safeties/'+initData?.id)
}

export function updatePatientSurgicalSafety(id, initData){
        return axios.patch('/patient-surgical-safeties/'+id, initData)
}

