import React, { useEffect, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import AddAllegies from "./AddAllegies";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { fetchPatientAllergies } from "../../../../features/patients/patientAllergiesSlice";
import { Bars } from "react-loader-spinner";
import { getSys } from "../../../../services/AuthService";

function Allergies() {
  const sys = getSys();

  let { id } = useParams();
  const dispatch = useDispatch();
  const { allergies, loading, isSuccessful } = useSelector(
    (state) => state.patientAllergies
  );

  const [selected, setSelected] = useState({});

  useEffect(() => {
    if (id) {
      dispatch(fetchPatientAllergies({ patientId: id }));
    }
  }, [id]);

  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };

  return (
    <div>
      <div className="ReportHeader">
        <div className="Report-Heading">Allergy</div>
        <div className="Report-Count">
          <span>0</span>&nbsp;Allergy
        </div>

        {/* {sys?.role === "sys-admin" ||
        sys?.role === "sys-nurse" ||
        sys?.role === "sys-matron" ? (
          <AddAllegies />
        ) : null} */}
          <AddAllegies />
      </div>

      {/* <div className="Report-Notes">
        <div>
          <div className="Doctors-Name">
            There is no Allergy for this patient.
          </div>
        </div>
      </div> */}
      {loading ? (
        <div className="d-flex justify-content-center">
          <Bars
            heigth="30"
            width="30"
            color="gray"
            ariaLabel="loading-indicator"
          />
        </div>
      ) : null}
      {allergies?.map((item, index) => (
        <div key={index} className="Report-Notes">
          <div>
            <div className="Doctors-Name">
              Doctors's name: &nbsp;&nbsp;<span>{item?.user?.full_name}</span>
            </div>
            <div className="Report-Date">
              Date : &nbsp;&nbsp;<span>{showDate(item?.created_at)}</span>
            </div>
            <div className="Report-Date">
              Number : &nbsp;&nbsp;<span>{item?.code}</span>
            </div>
          </div>
          <br />
          <br />
          {/* <div className="Report-Display">{item?.note}</div> */}
          <div className="Report-Date4">
            <div dangerouslySetInnerHTML={{ __html: item?.note }}></div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Allergies;
