import { React, useEffect, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import Swal from "sweetalert2";
import Dropdown from "react-bootstrap/Dropdown";
import AddDischargeSummary from "./AddDischargeSummary";
import EditPreOpNoteDetailsModal from "../ClinicalNote/EditPreOpNoteDetailsModal";
import ViewPreOpNoteDetailsModal from "../ClinicalNote/ViewPreOpNoteDetailsModal";
import ViewDischargeSummary from "./ViewDischargeSummary";
import AddColaboDoc from "./AddColaboDoc";
import ViewCollaborativeDocModal from "./ViewCollaborativeDocModal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCollaboratives } from "../../../../features/patients/socials/collaborativeSlice";
import moment from "moment";
import { Bars } from "react-loader-spinner";

function ColaboDoc() {
  let { id } = useParams();
  const dispatch = useDispatch()
  const {collabs, loading, isSuccessful} = useSelector((state) => state.collaboratives)

  const [selected, setSelected] = useState({});

  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);

  useEffect(() => {
    if (id) {
      dispatch(fetchCollaboratives({patientId: id}))
    }
  }, [id]);
  
  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }

  return (
    <div>
      <div className="Report-Notes">
        <div className="ReportHeader">
          <div className="Report-Heading">Colaborative Document</div>
          <div className="Report-Count">
            <span>0</span>&nbsp;Colaborative Document
          </div>
          <AddColaboDoc />
        </div>
      </div>
      <br />
      {loading ? (
            <div className="d-flex justify-content-center">
              <Bars
                heigth="30"
                width="30"
                color="gray"
                ariaLabel="loading-indicator"
              />
            </div>
        ) : null
      }
      {
        collabs?.map((item, index) => (
          <div key={index} className="Patient-Record-Container">
            <div className="Patient-Record-Wrapper">
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Note Number</div>
                <div className="Wallet-Ref-Result">{item?.code}</div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Social Welfare's Name</div>
                <div className="Wallet-Ref-Result">{item?.user?.full_name}</div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Date</div>
                <div className="Wallet-Ref-Result">{showDate(item?.created_at)}</div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Status</div>
                <div className="Wallet-Ref-Result">{item?.status}</div>
              </div>
              <div className="Action-Drop-Down">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
                    Manage Note
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {setSelected(item); setViewPreOpNoteDetailsShow(true)}}
                      className="Solera-Patients"
                    >
                      View Collaborative Document
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        ))
      }
      

      <div>
        <ViewCollaborativeDocModal
          selected={selected}
          show={viewPreOpNoteDetailsShow}
          onClose={handleViewPreOpNoteDetails}
          onOpen={() => setViewPreOpNoteDetailsShow(true)}
        />
      </div>
    </div>
  );
}

export default ColaboDoc;
