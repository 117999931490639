import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listDoctor } from '../../services/DoctorService'

const initialState = {
    loading: false,
    doctors: [],
    paginator: {},
    isSuccessful: false
}

export const fetchDoctors = createAsyncThunk('fetchDoctors', (initData) => {
    return listDoctor(initData)
    .then((response) => response.data.data)
})


const doctorsSlice = createSlice({
    name: 'doctors',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchDoctors.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchDoctors.fulfilled, (state, action ) => {
        state.loading = false
        state.doctors = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchDoctors.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default doctorsSlice.reducer 