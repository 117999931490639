import axios from '../axiosinstance';

      
export function listPatientLapComments(initData){
        return axios.get('/patient-lap-comments', { params: initData })
}

export function addPatientLapComments(data){
        return axios.post('/patient-lap-comments', data)
}

export function getPatientLapComments(initData){
        return axios.get('/patient-lap-comments/'+initData?.id)
}

export function updatePatientLapComments(initData){
        return axios.patch('/patient-lap-comments/'+initData.id, initData)
}


