import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientDischargeSummaries } from '../../../services/PatientDischargeSummaryService'

const initialState = {
    loading: false,
    summaries: [],
    isSuccessful: false
}

export const fetchDischargeSummaries = createAsyncThunk('fetchDischargeSummaries', (initData) => {
    return listPatientDischargeSummaries(initData)
    .then((response) => response.data.data)
})


const dischargeSummariesSlice = createSlice({
    name: 'dischargeSummaries',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchDischargeSummaries.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchDischargeSummaries.fulfilled, (state, action ) => {
        state.loading = false
        state.summaries = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchDischargeSummaries.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default dischargeSummariesSlice.reducer 