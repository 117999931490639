import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { patientLapsCount } from '../../../services/PatientLapsService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchWaitingCount = createAsyncThunk('fetchWaitingCount', (initData) => {
    return patientLapsCount(initData)
    .then((response) =>  response.data.data)
})


const waitingCountSlice = createSlice({
    name: 'waitingCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchWaitingCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchWaitingCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default waitingCountSlice.reducer 