import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listFluidChartItems } from '../../../services/FluidChartItemService'

const initialState = {
    loading: false,
    fluidItems: [],
    isSuccessful: false
}

export const fetchFluidChartsItems = createAsyncThunk('fetchFluidChartsItems', (initData) => {
    return listFluidChartItems(initData)
    .then((response) => response.data.data)
})


const fluidChartsItemSlice = createSlice({
    name: 'fluidChartsItems',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchFluidChartsItems.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchFluidChartsItems.fulfilled, (state, action ) => {
        state.loading = false
        state.fluidItems = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchFluidChartsItems.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default fluidChartsItemSlice.reducer 