import React, { useEffect, useState } from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import Lab from "./Lab";
import Pagination from "../../Component/Pagination";
import LabouratoryTable2 from "../../Component/LabouratoryTable2";
import { fetchPatientListLaps } from "../../features/patients/patientListLapsSlice";
import { useDispatch, useSelector } from "react-redux";

function LabTable2() {
  const dispatch = useDispatch()
  const {dataLabs, paginator, loading} = useSelector((state) => state.patientAllLaps)

  const [search2, setSearch2] = useState('');
  const [page, setPage] = useState(paginator?.currentPage || 1);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(paginator?.pageCount || 0);

  useEffect((  ) => {
    handleFetchLaps()
  }, [])


  const handleFetchLaps = (search = "", limit = 10, page = 1) => {
    dispatch(fetchPatientListLaps({ name: search, page_size: limit, page:page }))
  }

  const handleFetchUpdate = (search = search2, limit = pageLimit, page = 1) => {
    dispatch(fetchPatientListLaps({ name: search, page_size: limit, page:page }));
  }
  return (
    <div className="Patient-Table">
      <div className="Patient-Search">
        <input
          type="Search"
          className="form-control SearchesInput22"
          placeholder="Lab Test Search"
          aria-label="OTP"
          aria-describedby="basic-addon1"
        />
        <div className="Searchesbtn">Search</div>
      </div>
      <div>
        <div> <Pagination /></div>
        <br />
        <div>
          <LabouratoryTable2 />
        </div>
        <br />
        <div><Pagination /></div>
      </div>
    </div>
  );
}

export default LabTable2;
