import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listDispensedDrugs } from '../../services/PatientDrugsService'

const initialState = {
    loading: false,
    dispenseDrugs: [],
    paginator: {},
    isSuccessful: false
}

export const fetchDispenseDrugs = createAsyncThunk('fetchDispenseDrugs', (initData) => {
    return listDispensedDrugs(initData)
    .then((response) => response.data.data)
})


const dispenseDrugsSlice = createSlice({
    name: 'dispenseDrugs',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchDispenseDrugs.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchDispenseDrugs.fulfilled, (state, action ) => {
        state.loading = false
        state.dispenseDrugs = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchDispenseDrugs.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default dispenseDrugsSlice.reducer 