import { React, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import AddPhysiotherapyModal from "./AddPhysiotherapyModal";
import { getSys } from "../../../../services/AuthService";

function AddPhysiotherapy() {
  let sys = getSys();

  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);
  return (
    <div>
      {sys?.role === "sys-admin" || sys?.role === "sys-physiotherapist" ? (
        <button
          className="Add-Patient-Report"
          onClick={() => setAddReportShow(true)}
        >
          Add Physiotherapy
        </button>
      ) : null}

      <div>
        <AddPhysiotherapyModal
          show={addReportShow}
          onClose={handleAddReport}
          onOpen={() => setAddReportShow(true)}
        />
      </div>
    </div>
  );
}

export default AddPhysiotherapy;
