import { React, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import ViewReport from "./ViewReport";
import moment from "moment";
import { findRole } from "../../services/extras";
import { getSys } from "../../services/AuthService";
import UpdateNurseReport from "./UpdateNurseReport";

function NursesReports({report}) {
  const sys = getSys();

  const [editStaffShow, setEditStaffShow] = useState(false);
  const handleEditStaff = () => setEditStaffShow(false);

  const [viewStaffShow, setViewStaffShow] = useState(false);
  const handleViewStaff = () => setViewStaffShow(false);
  const [selected, setSelected] = useState({});

  // const handleRemoveStaff = () => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You want to remove this Nurse?",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#000000",
  //     cancelButtonColor: "#0078d4",
  //     confirmButtonText: "Yes",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       Swal.fire({
  //         title: "Successful!",
  //         icon: "success",
  //         confirmButtonColor: "#000000",
  //         timer: 2000,
  //       });
  //     }
  //   });
  // };

  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };

  return (
    <div>
      <div className="Patient-Displays-Details2">
      <div className="NurseReportNumber">
          <div className="Wallet-Ref-Heading"></div>
        
        </div>
        <div className="PatientNumber-C">
          <div className="Wallet-Ref-Heading">Nurse's Name</div>
          <div className="Wallet-Ref-Result">{report?.user?.full_name}</div>
        </div>
      
        <div className="Date-Cont-P">
          <div className="Wallet-Ref-Heading">Entry Date</div>
          <div className="Wallet-Ref-Result">{showDate(report?.created_at)}</div>
        </div>
        
        {/* <div className="Role-Cont-P">
          <div className="Wallet-Ref-Heading">Role</div>
          <div className="Wallet-Ref-Result">{findRole(sys?.name)}</div>
        </div> */}
        <div className="Dept-Cont-P">
          <div className="Wallet-Ref-Heading">Note Subject</div>
          <div className="Wallet-Ref-Result">{report?.subject}</div>
        </div>

        <div className="Action-Drop-Down">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
              Manage Reports
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {setSelected(report); setViewStaffShow(true)}}
                className="Solera-Patients"
              >
                View Report
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {setSelected(report); setEditStaffShow(true)}}
                className="Solera-Patients"
              >
                Update Nurse
              </Dropdown.Item>

              {/* <Dropdown.Item
                onClick={handleRemoveStaff}
                className="Solera-Patients"
              >
                Delete Report
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div>
        <UpdateNurseReport
          selected={selected}
          show={editStaffShow}
          onClose={handleEditStaff}
          onOpen={() => setEditStaffShow(true)}
        />

        <ViewReport
          selected={selected}
          show={viewStaffShow}
          onClose={handleViewStaff}
          onOpen={() => setViewStaffShow(true)}
        />
      </div>
    </div>
  );
}

export default NursesReports;
