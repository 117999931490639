import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { nurseCount } from '../../services/NurseService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchNurseCount = createAsyncThunk('fetchNurseCount', (initData) => {
    return nurseCount(initData)
    .then((response) =>  response.data.data)
})


const nurseCountSlice = createSlice({
    name: 'nurseCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchNurseCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchNurseCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default nurseCountSlice.reducer 