import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientVitals } from '../../services/PatientVitalsService'
import { listPatientAllergies } from '../../services/PatientAllergyService'

const initialState = {
    loading: false,
    allergies: [],
    isSuccessful: false
}

export const fetchPatientAllergies = createAsyncThunk('fetchPatientAllergies', (initData) => {
    return listPatientAllergies(initData)
    .then((response) => response.data.data)
})


const patientAllergiesSlice = createSlice({
    name: 'patientAllergies',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientAllergies.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchPatientAllergies.fulfilled, (state, action ) => {
        state.loading = false
        state.allergies = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientAllergies.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientAllergiesSlice.reducer 