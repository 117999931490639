import { React, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import AddPhysiotherapyModal from "./AddPhysiotherapyModal";
import AddDoctorNoteModal from "./AddDoctorNoteModal";

function AddDoctorNote() {
  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);
  return (
    <div>
      <button
        className="Add-Patient-Report"
        onClick={() => setAddReportShow(true)}
      >
        Add Doctor's Note
      </button>
      <div>
        <AddDoctorNoteModal
          show={addReportShow}
          onClose={handleAddReport}
          onOpen={() => setAddReportShow(true)}
        />
      </div>
    </div>
  );
}

export default AddDoctorNote;
