import axios from '../axiosinstance';

      

//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

export function listReleaseConsumables(init){
        return axios.get('/release-consumables', { params: { name: init?.name, type: init?.type } })
}

export function searchReleaseConsumables(search){
        return axios.get('/release-consumables/find/by-search', { params: { search: search } })
}

export function AddReleaseConsumables(data){
        return axios.post('/release-consumables', data)
}

export function getReleaseConsumables(initData){
        return axios.get('/release-consumables/'+initData?.id)
}

export function updateReleaseConsumables(initData){
        return axios.patch('/release-consumables/'+initData.id, initData)
}

export function releaseConsumablesCount(search){
        return axios.get('/release-consumables/stat/count', { params: search })
}
