import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientTreatmentLaps } from '../../services/PatientLapsService'

const initialState = {
    loading: false,
    patientLaps: [],
    paginator: {},
    isSuccessful: false
}

export const fetchPatientTreatmentLaps = createAsyncThunk('fetchPatientTreatmentLaps', (initData) => {
    return listPatientTreatmentLaps(initData)
    .then((response) => response.data.data)
})


const patientTreatmentLapsSlice = createSlice({
    name: 'patientTreatmentLaps',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientTreatmentLaps.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatientTreatmentLaps.fulfilled, (state, action ) => {
        state.loading = false
        state.patientLaps = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientTreatmentLaps.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientTreatmentLapsSlice.reducer 