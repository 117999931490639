import { React, useState } from "react";
import "../SoleraCss/Responsive.css";
import "../SoleraCss/Solera.css";
import Swal from "sweetalert2";
import Dropdown from "react-bootstrap/Dropdown";
import ViewPostOpModal from "../Pages/PatientPage/PatientRecords/ClinicalNote/ViewPostOpModal";
import EditPostOpModal from "../Pages/PatientPage/PatientRecords/ClinicalNote/EditPostOpModal";
import AddLabResult from "../Pages/LabPage/AddLabResult";
import AddLabResultModal from "./Modals/AddLabResultModal";
import LabResultDisplay from "../Pages/LabPage/LabResultDisplay";
import ViewLabResult from "../Pages/LabPage/ViewLabResult";
import AddCommentModal from "../Pages/LabPage/AddCommentModal";
import TreatementNumber from "./TreatementNumber";
import SampleNumber from "./SampleNumber";
import PatientName from "../Pages/PatientPage/PatientName";
import PrescribedBy from "./PrescribedBy";
import ViewPrescriptionModal from "../Pages/PatientPage/PatientRecords/Prescription/ViewPrescriptionModal";
import DoctorsPrescriptionModal from "../Pages/PatientPage/PatientRecords/Medication/DoctorsPrescriptionModal";
import LabTestModal from "../Pages/LabPage/LabTestModal";
import { useSelector } from "react-redux";
import { Bars } from "react-loader-spinner";
import moment from "moment";
import UploadLapDoc from "../Pages/PatientPage/PatientRecords/Lab/UploadLapDoc";
import ViewUploadModal from "../Pages/PatientPage/PatientRecords/Lab/ViewUploadModal";
import { conCatName } from "../services/extras";
import { clearAuth, getSys } from "../services/AuthService";

function LabouratoryTable2() {
  const { dataLabs, loading } = useSelector((state) => state.patientAllLaps);
  const [selected, setSelected] = useState({});

  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);

  const [editPreOpNoteDetailsShow, setEditPreOpNoteDetailsShow] =
    useState(false);
  const handleEditPreOpNoteDetails = () => setEditPreOpNoteDetailsShow(false);

  const [labResultShow, setLabResultShow] = useState(false);
  const handleLabResult = () => setLabResultShow(false);

  const [viewLabResultShow, setViewLabResultShow] = useState(false);
  const handleViewLabResult = () => setViewLabResultShow(false);

  const [addcomentShow, setAddcomentShow] = useState(false);
  const handleAddcoment = () => setAddcomentShow(false);

  const [viewPrescriptionsShow, setViewPrescriptionsShow] = useState(false);
  const handleViewPrescriptions = () => setViewPrescriptionsShow(false);

  const [doctorsPrescriptionShow, setDoctorsPrescriptionShow] = useState(false);
  const handleDoctorsPrescription = () => setDoctorsPrescriptionShow(false);

  const [uploadNotesShow, setUploadNotesShow] = useState(false);
  const handleUploadNoteDetails = () => setUploadNotesShow(false);

  const [viewUploadShow, setViewUploadShow] = useState(false);
  const handleViewUpload = () => setViewUploadShow(false);

  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };

  let sys = getSys();
  return (
    <div>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Bars
            heigth="30"
            width="30"
            color="gray"
            ariaLabel="loading-indicator"
          />
        </div>
      ) : null}
      {dataLabs?.map((lap, index) => (
        <div key={index} className="Patient-Record-Container">
          <div className="Patient-Record-Wrapper">
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Patient Name</div>
              <div className="Wallet-Ref-Result">
                <PatientName
                  name={
                    lap?.patient_infos[0]?.first_name +
                    " " +
                    lap?.patient_infos[0]?.last_name
                  }
                />
              </div>
            </div>
            {/* <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Treatment Number</div>
              <div className="Wallet-Ref-Result">
                <TreatementNumber treamentNum={lap?.treatment_infos[0]?.code} />
              </div>
            </div> */}
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Number of Test</div>
              <div className="Wallet-Ref-Result">
                <SampleNumber code={lap?.count} />
              </div>
            </div>

            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Doctor's Name</div>
              <div className="Wallet-Ref-Result">
                <PrescribedBy
                  name={conCatName(
                    lap?.user_infos[0]?.first_name,
                    lap?.user_infos[0]?.last_name
                  )}
                />
              </div>
            </div>
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Date</div>
              <div className="Wallet-Ref-Result">
                {showDate(lap?.created_at)}
              </div>
            </div>
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Status</div>
              <div className="Wallet-Ref-Result">{lap?.status}</div>
            </div>
            <div className="Action-Drop-Down">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
                  Manage Laboratory
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setSelected(lap);
                      setViewPreOpNoteDetailsShow(true);
                    }}
                    className="Solera-Patients"
                  >
                    View lab test to conduct
                  </Dropdown.Item>

                  {sys?.role === "sys-admin" ||  sys?.role === "sys-lap" ? (
              <>
               {lap?.status !== "waiting" || lap?.status === "waiting" ? (
                    <Dropdown.Item
                      onClick={() => {
                        setSelected(lap);
                        setLabResultShow(true);
                      }}
                      className="Solera-Patients"
                    >
                      Add test result
                    </Dropdown.Item>
                  ) : null}



              </>
            ) : null}


                  {/* {lap?.status !== "conducted" ? (
                    <Dropdown.Item
                      onClick={() => {
                        setSelected(lap);
                        setLabResultShow(true);
                      }}
                      className="Solera-Patients"
                    >
                      Add test result
                    </Dropdown.Item>
                  ) : null}
 */}



                  {lap?.status === "conducted" || lap?.status === "waiting"? (
                    <Dropdown.Item
                      onClick={() => {
                        setSelected(lap);
                        setViewLabResultShow(true);
                      }}
                      className="Solera-Patients"
                    >
                      View test result
                    </Dropdown.Item>
                  ) : null}

                  {lap?.status !== "waiting" ? (
                    <Dropdown.Item
                      onClick={() => {
                        setSelected(lap);
                        setViewLabResultShow(true);
                      }}
                      className="Solera-Patients"
                    >
                      View comments
                    </Dropdown.Item>
                  ) : null}

                  {sys?.role === "sys-admin" || sys?.role === "sys-lap" ? (
                    <>
                      {lap?.status !== "conducted" ? (
                        <Dropdown.Item
                          onClick={() => {
                            setSelected(lap);
                            setAddcomentShow(true);
                          }}
                          className="Solera-Patients"
                        >
                          Make a comment on this test
                        </Dropdown.Item>
                      ) : null}
                    </>
                  ) : null}

                  {/* {sys?.role === "sys-admin" || sys?.role === "sys-doctor" ? (
                    <>
                      {lap?.status !== "waiting" ? (
                        <Dropdown.Item
                          onClick={() => {
                            setSelected(lap);
                            setDoctorsPrescriptionShow(true);
                          }}
                          className="Solera-Patients"
                        >
                          Prescribe drugs for this Patient
                        </Dropdown.Item>
                      ) : null}
                    </>
                  ) : null} */}

                  {/* {sys?.role === "sys-admin" || sys?.role === "sys-doctor" ? (
                    <>
                      {lap?.status !== "waiting" ? (
                        <Dropdown.Item
                          onClick={() => {
                            setSelected(lap);
                            setViewPrescriptionsShow(true);
                          }}
                          className="Solera-Patients"
                        >
                          View doctor's prescription
                        </Dropdown.Item>
                      ) : null}
                    </>
                  ) : null} */}

                  {/* {lap?.status !== "waiting" ? (
                    <Dropdown.Item
                      onClick={() => {
                        setSelected(lap);
                        setViewLabResultShow(true);
                      }}
                      className="Solera-Patients"
                    >
                      Update prescribed drugs
                    </Dropdown.Item>
                  ) : null} */}

                  <Dropdown.Item
                    onClick={() => {
                      setSelected(lap);
                      setUploadNotesShow(true);
                    }}
                    className="Solera-Patients"
                  >
                    Upload test result
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={() => {
                      setSelected(lap);
                      setViewUploadShow(true);
                    }}
                    className="Solera-Patients"
                  >
                    View uploaded test result
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      ))}
      <div>
        <LabTestModal
          selected={selected}
          show={viewPreOpNoteDetailsShow}
          onClose={handleViewPreOpNoteDetails}
          onOpen={() => setViewPreOpNoteDetailsShow(true)}
        />

        <AddLabResultModal
          selected={selected}
          show={labResultShow}
          onClose={handleLabResult}
          onOpen={() => setLabResultShow(true)}
        />

        <ViewLabResult
          selected={selected}
          show={viewLabResultShow}
          onClose={handleViewLabResult}
          onOpen={() => setViewLabResultShow(true)}
        />
        <AddCommentModal
          selected={selected}
          show={addcomentShow}
          onClose={handleAddcoment}
          onOpen={() => setAddcomentShow(true)}
        />

        <ViewPrescriptionModal
          selected={selected}
          show={viewPrescriptionsShow}
          onClose={handleViewPrescriptions}
          onOpen={() => setViewPrescriptionsShow(true)}
        />

        <UploadLapDoc
          named={"Lap"}
          selected={selected}
          show={uploadNotesShow}
          onClose={handleUploadNoteDetails}
          onOpen={() => setUploadNotesShow(true)}
        />

        <ViewUploadModal
          selected={selected}
          show={viewUploadShow}
          onClose={handleViewUpload}
          onOpen={() => setViewUploadShow(true)}
        />

        <DoctorsPrescriptionModal
          selected={selected}
          show={doctorsPrescriptionShow}
          onClose={handleDoctorsPrescription}
          onOpen={() => setDoctorsPrescriptionShow(true)}
        />
      </div>{" "}
    </div>
  );
}

export default LabouratoryTable2;
