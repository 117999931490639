import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { doctorCount } from '../../services/DoctorService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchDoctorCount = createAsyncThunk('fetchDoctorCount', (initData) => {
    return doctorCount(initData)
    .then((response) =>  response.data.data)
})


const doctorCountSlice = createSlice({
    name: 'doctorCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchDoctorCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchDoctorCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default doctorCountSlice.reducer 