import axios from '../axiosinstance';

      
export function listFluidCharts(initData){
        return axios.get('/fluid-charts', { params: initData })
}

export function addFluidChart(data){
        return axios.post('/fluid-charts', data)
}

export function getFluidCharts(initData){
        return axios.get('/fluid-charts/'+initData?.id)
}

export function updateFluidCharts(initData){
        return axios.patch('/fluid-charts/'+initData.id, initData)
}

export function FluidChartsCount(search){
        return axios.get('/fluid-charts/stat/count', { params: search })
}

