import React from "react";
import "../SoleraCss/Responsive.css";
import "../SoleraCss/Solera.css";
import { FiBell } from "react-icons/fi";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { getUser } from "../services/AuthService";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 420,
    Width: 420,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

function StaffGreetings() {
  const user = getUser();
  return (
    <div className="Notify-Container">
      <div className="Notify-Wrapper">
        <FiBell className="Notify-Icon" />
        {/* <div>
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography>
                  <div className="Notify-Box">
                    <div className="Notify-From">
                      From Dr&nbsp;<span>Name</span>
                    </div>
                    <div className="Notify-For">
                      For&nbsp;<span>ID Number</span>
                    </div>
                    <div className="Notify-For">
                      Date&nbsp;<span>Date</span>
                    </div>

                    <div className="Notify-Note">
                      Note Note Note Note Note Note Note Note Note Note
                    </div>
                  </div>
                  <div className="Notify-Box">
                    <div className="Notify-From">
                      From Dr&nbsp;<span>Name</span>
                    </div>
                    <div className="Notify-For">
                      For&nbsp;<span>ID Number</span>
                    </div>
                    <div className="Notify-For">
                      Date&nbsp;<span>Date</span>
                    </div>

                    <div className="Notify-Note">Note Note Note Note Note</div>
                  </div>
                </Typography>
              </React.Fragment>
            }
          >
            
              <badge className="Notify-Number">0</badge>
           
          </HtmlTooltip>
        </div> */}
        {/* <badge className="Notify-Number">0</badge> */}
      </div>

      <div className="Solera-Greetings">
        <div>Hello&nbsp;</div>
        <div>
          <span>{user?.first_name || ""}</span>
        </div>
      </div>
    </div>
  );
}

export default StaffGreetings;
