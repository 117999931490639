import { React, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";

function ViewUploadedResult({ show, onClose, selected }) {
  return (
    <div>
    <Modal
      size="xl"
      show={show}
      onHide={onClose}
      backdrop="static"
      aria-labelledby="example-modal-sizes-title-lg"
      keyboard={false}
      animation={true}
    >
      <Modal.Body>
        <div className="Solera__ModalHeadings">
          <MdClose className="Solera__Close" onClick={onClose} />
        </div>

        <div className="Solera__ModalHeadings"></div>
        <div className="Solera-Modal-Container">
          <div className="Solera__Headings">
            <div className="Log__Cont">
              <img
                src="/assets/lg.png"
                alt=""
                className="Log__Img__Modal"
              />
            </div>
            <div className="Solera__Headings__Note">
              <div className="Solera-Settings-Header">
                {selected?.url &&  <img width={900} height={1000} src={selected?.url} alt="result" /> }
              </div>
              <br />
              <br />
            </div>
          </div>
          <br />
          <br />
        
        </div>
      </Modal.Body>
    </Modal></div>
  )
}

export default ViewUploadedResult