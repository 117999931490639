import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import "../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import MenuItem from "@mui/material/MenuItem";

function EditAdminPwd({ show, onClose, onOpen, selected }) {
  const [editAdminShow, setEditAdminShow] = useState(false);
  const handleEditAdmin = () => setEditAdminShow(false);

  const handleSaveEditAdmin = () => {
    onClose();
    setEditAdminShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };
  const occupation = [
    {
      value: "Doctor",
      label: "Doctor",
    },
    {
      value: "Nurse",
      label: "Nurse",
    },
    {
      value: "Phamacist",
      label: "Phamacist",
    },
    {
      value: "Radiographer",
      label: "Radiographer",
    },
    {
      value: "Lab Scientist",
      label: "Lab Scientist",
    },
  ];

  return (
    <div>
      {" "}
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Solera__ModalHeadings">
              <MdClose className="Solera__Close" onClick={onClose} />
            </div>

            <div className="Solera__ModalHeadings"></div>
            <div className="Solera-Modal-Container">
              <div className="Solera__Headings">
                <div className="Log__Cont">
                  <img
                    src="/assets/lg.png"
                    alt=""
                    className="Log__Img__Modal"
                  />
                </div>
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">
                    Edit Admin Account Password
                  </div>
                  <div className="Solera-Settings-Note">
                    Edit your admin account and assign roles.
                  </div>
                </div>
              </div>
              <br />
              <br />
              {/* <div className="container-fluid p-0 FormInput">
                <div className="row gx-4">
                  <div className="col-lg">
                    <TextField
                      fullWidth
                      label="First Name"
                      id="fullWidth"
                      type="text"
                      className="Solera-Login-Input mb-3"
                    />
                  </div>
                  <div className="col-lg">
                    <TextField
                      fullWidth
                      label="Last name"
                      id="fullWidth"
                      type="password"
                      className="Solera-Login-Input mb-3"
                    />
                  </div>
                </div>
              </div> */}
              {/* <div className="container-fluid p-0 FormInput">
                <div className="row gx-4">
                  <div className="col-lg">
                    <div>
                      <TextField
                        fullWidth
                        id="outlined-select-currency"
                        select
                        label="Occupation"
                        defaultValue="Occupation"
                      >
                        {occupation.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="container-fluid p-0 FormInput">
                <div className="row gx-4">
                  <div className="col-lg">
                    <TextField
                      fullWidth
                      label="Phone number"
                      id="fullWidth"
                      type="number"
                      className="Solera-Login-Input mb-3"
                    />
                  </div>
                  <div className="col-lg">
                    <TextField
                      fullWidth
                      label="Password"
                      id="fullWidth"
                      type="password"
                      className="Solera-Login-Input mb-3"
                    />
                  </div>
                </div>
              </div>
              <button className="Add-Button" onClick={handleSaveEditAdmin}>
                Save Admin
              </button>
              <br /> <br /> <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default EditAdminPwd;
