import { React, useEffect, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import AddPreOpNote from "../ClinicalNote/AddPreOpNote";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import Dropdown from "react-bootstrap/Dropdown";
import ViewPreOpNoteDetails from "./ViewPreOpNoteDetailsModal";
import ViewPreOpNoteDetailsModal from "./ViewPreOpNoteDetailsModal";
import EditPreOpNoteDetailsModal from "./EditPreOpNoteDetailsModal";
import ViewPostOpModal from "./ViewPostOpModal";
import EditPostOpModal from "./EditPostOpModal";
import AddPostOpNote from "./AddPostOpNote";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPostOps } from "../../../../features/patients/postOpSlice";
import moment from "moment";
import { Bars } from "react-loader-spinner";
import UploadClinicalNote from "./UploadClinicalNote";
import ViewPostOpNoteUploadModal from "./ViewPostOpNoteUploadModal";

function PostOpNote() {
  let { id } = useParams();
  const dispatch = useDispatch()
  const {postOps, loading, isSuccessful} = useSelector((state) => state.postOpsList)
  const [selected, setSelected] = useState({});

  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);

  const [uploadNotesShow, setUploadNotesShow] =
    useState(false);
  const handleUploadNoteDetails = () => setUploadNotesShow(false);

  const [viewPostOpNoteUploadShow, setViewPostOpNoteUploadShow] =
    useState(false);
  const handleViewPostOpNoteUpload = () => setViewPostOpNoteUploadShow(false);

  const [editPreOpNoteDetailsShow, setEditPreOpNoteDetailsShow] =
    useState(false);
  const handleEditPreOpNoteDetails = () => setEditPreOpNoteDetailsShow(false);


  useEffect(() => {
    if (id) {
      dispatch(fetchPostOps({patientId: id, type: 'post Op'}))
    }
  }, [id]);

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }

  return (
    <div>
      <div className="Report-Notes">
        <div className="ReportHeader">
          <div className="Report-Heading">preparation pre op</div>
          <div className="Report-Count">
            <span>0</span>&nbsp;preparation pre op
          </div>
          <AddPostOpNote />
        </div>
      </div>
      <br />
      {loading ? (
            <div className="d-flex justify-content-center">
              <Bars
                heigth="30"
                width="30"
                color="gray"
                ariaLabel="loading-indicator"
              />
            </div>
        ) : null
      }
      {
        postOps?.map((item, index) => (
          <div key={index} className="Patient-Record-Container">
            <div className="Patient-Record-Wrapper">
            <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Note Number</div>
                <div className="Wallet-Ref-Result">{item?.code}</div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Doctor's Name</div>
                <div className="Wallet-Ref-Result">{item?.user?.full_name}</div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Date</div>
                <div className="Wallet-Ref-Result">{showDate(item?.created_at)}</div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Status</div>
                <div className="Wallet-Ref-Result">{item?.status}</div>
              </div>
              <div className="Action-Drop-Down">
                <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
                    Manage Note
                  </Dropdown.Toggle>


                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {setSelected(item); setViewPreOpNoteDetailsShow(true)}}
                      className="Solera-Patients"
                    >
                      View preparation pre op
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {setSelected(item); setEditPreOpNoteDetailsShow(true)}}
                      className="Solera-Patients"
                    >
                      Update  preparation pre op
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {setSelected(item); setUploadNotesShow(true)}}
                      className="Solera-Patients"
                    >
                      Upload preparation pre op
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {setSelected(item); setViewPostOpNoteUploadShow(true)}}
                      className="Solera-Patients"
                    >
                      View uploaded preparation pre op
                    </Dropdown.Item>
                  
                    {/* <Dropdown.Item
                      // onClick={() => setPatientDetailsShow(true)}
                      className="Solera-Patients"
                    >
                      Delete Post Op Note
                    </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          ))
        }

      <div>
        <ViewPostOpModal
          selected={selected}
          show={viewPreOpNoteDetailsShow}
          onClose={handleViewPreOpNoteDetails}
          onOpen={() => setViewPreOpNoteDetailsShow(true)}
        />

        <UploadClinicalNote 
          named={'post Op'}
          selected={selected}
          show={uploadNotesShow}
          onClose={handleUploadNoteDetails}
          onOpen={() => setUploadNotesShow(true)}
        />

        <ViewPostOpNoteUploadModal 
          selected={selected}
          show={viewPostOpNoteUploadShow}
          onClose={handleViewPostOpNoteUpload}
          onOpen={() => setViewPostOpNoteUploadShow(true)}
        />

        <EditPostOpModal
          show={editPreOpNoteDetailsShow}
          onClose={handleEditPreOpNoteDetails}
          onOpen={() => setEditPreOpNoteDetailsShow(true)}
        />
      </div>
    </div>
  );
}

export default PostOpNote;
