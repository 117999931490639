import { React, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import AddNoteModal from "../Notes/AddNoteModal";
import ConductTextModal from "../../../../Component/Modals/ConductTextModal";
import AssessmentModal from "./AssessmentModal";

function AddAssessment() {
  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);
  return (
    <div>
      <button
        className="Add-Patient-Report"
        onClick={() => setAddReportShow(true)}
      >
       Add Assessment
      </button>
      <div>
        <AssessmentModal
          show={addReportShow}
          onClose={handleAddReport}
          onOpen={() => setAddReportShow(true)}
        />
      </div>
    </div>
  );
}

export default AddAssessment;
