import { React, useEffect, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import AddPreOpNote from "../ClinicalNote/AddPreOpNote";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import Dropdown from "react-bootstrap/Dropdown";
import AddMedicalRecord from "./AddMedicalRecord";
import ViewMedicalRecordsModal from "./ViewMedicalRecordsModal";
import PatientNumber from "../../PatientNumber";
import PatientDetails from "../../PatientDetails";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { fetchPatientRecords } from "../../../../features/patients/patientsRecordsSlice";
import { Bars } from "react-loader-spinner";
import Paginations from "../../../../Component/Pagination";
import { matronRole } from "../../../../services/extras";
import { getSys } from "../../../../services/AuthService";
import PrescribedBy from "../../../../Component/PrescribedBy";


function MedicalRecords() {
  let { id } = useParams();
  const sys = getSys();
  const dispatch = useDispatch();

  const { records, paginator, loading } = useSelector(
    (state) => state.patientRecords
  );
  const [selected, setSelected] = useState({});

  const [viewvitaldetailsShow, setViewvitaldetailsShow] = useState(false);
  const handleViewvitaldetails = () => setViewvitaldetailsShow(false);

  const [patientDetailsShow, setPatientDetailsShow] = useState(false);
  const handlePatientDetails = () => setPatientDetailsShow(false);

  const [search2, setSearch2] = useState("");
  const [page, setPage] = useState(paginator?.currentPage || 1);
  const [pageLimit, setPageLimit] = useState(5);
  const [pageCount, setPageCount] = useState(paginator?.pageCount || 0);

  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };

  useEffect(() => {
    if (id) {
      handleFetchPatientRecords();
    }
  }, [id]);

  const handleFetchPatientRecords = (search = "", limit = 5, page = 1) => {
    dispatch(
      fetchPatientRecords({
        patient: id,
        name: search,
        page_size: limit,
        page: page,
      })
    );
  };

  const handleFetchUpdate = (search = search2, limit = pageLimit, page = 1) => {
    dispatch(
      fetchPatientRecords({
        patient: id,
        name: search,
        page_size: limit,
        page: page,
      })
    );
  };
  return (
    <div>
      <div className="Report-Notes">
        <div className="ReportHeader">
          <div className="Report-Heading">Patient Medical Records </div>
          <div className="Report-Count">
            <span>{records?.length || 0}</span>&nbsp;Records
          </div>

          {sys?.role === "sys-admin" || sys?.role === "sys-nurse" ? (
            <div className="SideBarSuperHeadingsContainer">
              <AddMedicalRecord />
            </div>
          ) : null}
        </div>
      </div>
      <Paginations
        handleFetchTransSearch={handleFetchUpdate}
        page={page}
        setPage={setPage}
        pageCount={paginator?.pageCount || 0}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
      />
      <br />
      {loading ? (
        <div className="d-flex justify-content-center">
          <Bars
            heigth="30"
            width="30"
            color="gray"
            ariaLabel="loading-indicator"
          />
        </div>
      ) : null}
      {records?.map((record, index) => (
        <div key={index} className="Patient-Record-Container">
          <div className="Patient-Record-Wrapper">
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Record Number</div>
              <div className="Wallet-Ref-Result">
                <PatientNumber pId={record?.code} />
              </div>
            </div>
            {/*<div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Patient's Name</div>
                <div className="Wallet-Ref-Result">{record?.patient?.full_name}</div>
              </div>*/}

            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Entered by</div>
              <div className="Wallet-Ref-Result">
                
             
                {record?.user?.full_name}



              </div>
              
            </div>
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Date</div>
              <div className="Wallet-Ref-Result">
                {showDate(record?.created_at)}
              </div>
            </div>
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Status</div>
              <div className="Wallet-Ref-Result">Submitted</div>
            </div>
            <div className="Action-Drop-Down">
              {sys?.role === "sys-admin" ||
              sys?.role === "sys-doctor" ||
              sys?.role === "sys-nurse" ? (
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
                    Manage Record
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        setSelected(record);
                        setPatientDetailsShow(true);
                      }}
                      className="Solera-Patients"
                    >
                      View Patient details
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={() => {
                        setSelected(record);
                        setViewvitaldetailsShow(true);
                      }}
                      className="Solera-Patients"
                    >
                      View Patient Medical Record
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setSelected(record);
                        setViewvitaldetailsShow(true);
                      }}
                      className="Solera-Patients"
                    >
                      Update Record
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : null}
            </div>
          </div>
        </div>
      ))}
      <Paginations
        handleFetchTransSearch={handleFetchUpdate}
        page={page}
        setPage={setPage}
        pageCount={paginator?.pageCount || 0}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
      />

      <div>
        <ViewMedicalRecordsModal
          selected={selected}
          show={viewvitaldetailsShow}
          onClose={handleViewvitaldetails}
          onOpen={() => setViewvitaldetailsShow(true)}
        />
        <PatientDetails
          selected={selected?.patient}
          show={patientDetailsShow}
          onClose={handlePatientDetails}
          onOpen={() => setPatientDetailsShow(true)}
        />
      </div>
    </div>
  );
}

export default MedicalRecords;
