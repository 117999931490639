import React from "react";
import { Routes, Route } from "react-router-dom";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import SideBar from "../../Component/SideBar";
import StaffGreetings from "../../Component/StaffGreetings";
import DashboardContainer from "../DashboardPage/DashboardContainer";
import DashboardPage from "../DashboardPage/DashboardPage";
import PatientPage from "../PatientPage/PatientPage";
import StaffPage from "../StaffPage/StaffPage";
import DoctorsPage from "../DoctorsPage/DoctorsPage";
import NursePage from "../NursesPage/NursePage";
import Pharmacy from "../Pharmacy/Pharmacy";
import LabScientist from "../LabPage/LabScientist";
import PatientRecord from "../PatientPage/PatientRecord";
import AdminPage from "../AdminPage/AdminPage";
import Medicals from "../MedicalConsumables/Medicals"
import ReleasedItems from "../MedicalConsumables/ReleasedItems"
import NonMedicals from "../NonMedicalConsumables/NonMedicals";
import NonMedReleasedItems from "../NonMedicalConsumables/NonMedReleasedItems";
import OutOfStockItems from "../MedicalConsumables/OutOfStockItems";
import ExpiredItems from "../MedicalConsumables/ExpiredItems";
import NonMedExpiredItems from "../NonMedicalConsumables/NonMedExpiredItems";
import NonMedOutOfStockes from "../NonMedicalConsumables/NonMedOutOfStockes";
import PharmacyExpiredItems from "../Pharmacy/ExpiredItems";
import PharmacyOutOfStockItems from "../Pharmacy/OutOfStockItems";
import DispensedItems from "../Pharmacy/DispensedItems";
import NursesReportPage from "../NursesReportPage/NursesReportPage";

function MainContainer() {
  return (
    <div className="Solera-FullBody">
      <article className="container-fluid Solera-Main">
        <div className="Solera-SideBar-Container sticky-top">
          <SideBar />
        </div>

          <Routes>
                  
            <Route index element={<DashboardPage />}></Route>
            <Route path="/dashboard" element={<DashboardPage />}></Route>
            <Route path="/patients" element={<PatientPage />}></Route>
            <Route path="/staff" element={<StaffPage />}></Route>
            <Route path="/doctors" element={<DoctorsPage />}></Route>
            <Route path="/nurses" element={<NursePage />}></Route>
            <Route path="/nursesreport" element={<NursesReportPage />}></Route>
            <Route path="/pharmacy" element={<Pharmacy />}></Route>
            <Route path="/pharmacy-expireds" element={<PharmacyExpiredItems />}></Route>
            <Route path="/pharmacy-outofstocks" element={<PharmacyOutOfStockItems />}></Route>
            <Route path="/pharmacy-dispensed" element={<DispensedItems />}></Route>

            
            <Route path="/lab-scientist" element={<LabScientist />}></Route>
      
            <Route path="/patients/:id" element={<PatientRecord />}></Route>
            <Route path="/consumables" element={<Medicals />}></Route>
            <Route path="/release-consumables" element={<ReleasedItems />}></Route>
            <Route path="/release-non-consumables" element={<NonMedReleasedItems />}></Route>
            <Route path="/non-consumables" element={<NonMedicals />}></Route>
            <Route path="/expired-consumables" element={<ExpiredItems />}></Route>
            <Route path="/outofstock-consumables" element={<OutOfStockItems />}></Route>
            <Route path="/expired-non-consumables" element={<NonMedExpiredItems />}></Route>
            <Route path="/outofstock-non-consumables" element={<NonMedOutOfStockes />}></Route>

            
            <Route path="/admin" element={<AdminPage />}></Route>
          </Routes>
      </article>
    </div>
  );
}

export default MainContainer;
