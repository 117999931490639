import { React, useState, useEffect } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import AddLabResult from "../../../LabPage/AddLabResult";
import AddXray from "./AddXray";
import AddScanResult from "./AddScanResult";
import Dropdown from "react-bootstrap/Dropdown";
import Swal from "sweetalert2";
import ViewAnaModal from "../Notes/ViewAnaModal";
import PrescribedBy from "../../../../Component/PrescribedBy";
import ViewXrayReportModal from "./ViewXrayReportModal";
import XrayReport from "./XrayReport";
import TreatementNumber from "../../../../Component/TreatementNumber";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientXrays } from "../../../../features/patients/docs/patientXraysSlice";
import moment from "moment";
import { Bars } from "react-loader-spinner";
import { fetchXrayCount } from "../../../../features/patients/docs/xRayCountSlice";
import Paginations from "../../../../Component/Pagination";

function Xray() {
  let { id } = useParams();
  const dispatch = useDispatch()
  const {listXrays, loading, paginator} = useSelector((state) => state.patientXrays)
  const xrayCount = useSelector((state) => state.xrayCount)

  const [selected, setSelected] = useState({});
  
  const [viewXrayShow, setviewXrayShow] =
    useState(false);
  const handleviewXray = () => setviewXrayShow(false);

  const [viewXrayReportShow, setViewXrayReportShow] =
  useState(false);
const handleViewXrayReport = () => setViewXrayReportShow(false);

const [search2, setSearch2] = useState('');
const [page, setPage] = useState(paginator?.currentPage || 1);
const [pageLimit, setPageLimit] = useState(10);
const [pageCount, setPageCount] = useState(paginator?.pageCount || 0);

useEffect((  ) => {
  if (id) {
    handleFetchPatientXrays()
    dispatch(fetchXrayCount({patient: id, type: 'x-ray'}))
  }
}, [id])


const handleFetchPatientXrays = (search = "", limit = 10, page = 1) => {
  dispatch(fetchPatientXrays({patient: id, type: 'x-ray', name: search, page_size: limit, page:page }))
}

const handleFetchUpdate = (search = search2, limit = pageLimit, page = 1) => {
  dispatch(fetchPatientXrays({patient: id, type: 'x-ray', name: search, page_size: limit, page:page }));
}


  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }


  return (
    <div>
      <div className="Report-Notes">
        <div className="ReportHeader">
          <div className="Report-Heading"> X-ray</div>
          <div className="Report-Count">
            <span>{xrayCount?.loading ? "Loading..." : xrayCount?.count}</span>&nbsp;X-ray{ xrayCount?.count > 1 ? "s" : null}
          </div>
          <AddXray />
        </div>
      </div>
      <br />
      <Paginations 
        handleFetchTransSearch={handleFetchUpdate} 
        page={page} 
        setPage={setPage} 
        pageCount={paginator?.pageCount || 0} 
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
      />
      {loading ? (
              <div className="d-flex justify-content-center">
                <Bars
                  heigth="30"
                  width="30"
                  color="gray"
                  ariaLabel="loading-indicator"
                />
              </div>
          ) : null
      }
      {
        listXrays?.map((item, index) => (
          <div key={index} className="Patient-Record-Container">
            <div className="Patient-Record-Wrapper">
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Treatment ID</div>
              <div className="Wallet-Ref-Result"><TreatementNumber treamentNum={item?.code} /></div>
            </div>
              {/* <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Instruction</div>
                <div className="Wallet-Ref-Result">
                  <PrescribedBy name={item?.name?.length < 21 ? item?.name?.substr(0, 20) : item?.name?.substr(0, 20)+'...' } />
                </div>
              </div> */}
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Doctor's Name</div>
                <div className="Wallet-Ref-Result">
                  <PrescribedBy name={item?.user?.full_name} />
                </div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Date</div>
                <div className="Wallet-Ref-Result">{showDate(item?.created_at)}</div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Status</div>
                <div className="Wallet-Ref-Result">{item?.status}</div>
              </div>
              <div className="Action-Drop-Down">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
                    Manage X-Ray
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {setSelected(item); setviewXrayShow(true)}}
                      className="Solera-Patients"
                    >
                      View X-Ray to conduct 
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {setSelected(item); setViewXrayReportShow(true)}}
                      className="Solera-Patients"
                    >
                      Upload X-Ray Result
                    </Dropdown.Item>
                   
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        ))
      }
      <Paginations 
        handleFetchTransSearch={handleFetchUpdate} 
        page={page} 
        setPage={setPage} 
        pageCount={paginator?.pageCount || 0} 
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
      />
      <div>
        <XrayReport
          selected={selected}
          show={viewXrayShow}
          onClose={handleviewXray}
          onOpen={() => setviewXrayShow(true)}
        />

        <ViewXrayReportModal
          named={"x-ray"}
          selected={selected}
          show={viewXrayReportShow}
          onClose={handleViewXrayReport}
          onOpen={() => setViewXrayReportShow(true)}
        />
      </div>
    </div>
  );
}

export default Xray;
