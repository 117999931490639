import React, { useEffect } from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import { FiUserPlus } from "react-icons/fi";
import NumberOfTestConducted from "./NumberOfTestConducted";
import NumberOfWaitingTest from "./NumberOfWaitingTest";
import NumberOfTest from "./NumberOfTest";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTestCount } from "../../features/lap/count/allTestCountSlice";
import { fetchConductedCount } from "../../features/lap/count/conductedCountSlice";
import { fetchWaitingCount } from "../../features/lap/count/waitingCountSlice";

function LabActivities() {
  const dispatch = useDispatch()
  const allTestCount = useSelector((state) => state.allTestCount)
  const conductedCount = useSelector((state) => state.conductedCount)
  const waitingCount = useSelector((state) => state.waitingCount)

  useEffect((  ) => {
    dispatch(fetchAllTestCount({}))
    dispatch(fetchConductedCount({status: 'conducted'}))
    dispatch(fetchWaitingCount({status: 'waiting'}))

  }, [])
  return (
    <div>
      <div className="container-fluid ">
        <div className="row g-2">
          <div className="container-fluid ">
            <div className="row g-2 ">
              <div className="col-lg">
                <div className="Admin-Heading-Container">
                  <div className="DashBoard-Cont">
                    <div>
                      <div className="Admin-Heading">Number of Test</div>
                    </div>
                    <div className="Logo-Admin-Heading">
                      <FiUserPlus className="Data-Icon" />
                    </div>
                  </div>

                  <NumberOfTest num={allTestCount?.count} loading={allTestCount?.loading} />
                </div>
              </div>
              <div className="col-lg">
                <div className="Admin-Heading-Container">
                  <div className="DashBoard-Cont">
                    <div>
                      <div className="Admin-Heading">Conducted Test</div>
                    </div>
                    <div className="Logo-Admin-Heading">
                      <FiUserPlus className="Data-Icon" />
                    </div>
                  </div>

                  <NumberOfTestConducted num={conductedCount?.count} loading={conductedCount?.loading} />
                </div>
              </div>
              <div className="col-lg">
                <div className="Admin-Heading-Container">
                  <div className="DashBoard-Cont">
                    <div>
                      <div className="Admin-Heading">Waiting Test</div>
                    </div>
                    <div className="Logo-Admin-Heading">
                      <FiUserPlus className="Data-Icon" />
                    </div>
                  </div>

                  <NumberOfWaitingTest num={waitingCount?.count} loading={waitingCount?.loading} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LabActivities;
