import axios from '../axiosinstance';

      
export function listPatientPrescriptions(initData){
        return axios.get('/patient-prescriptions', { params: { name: initData?.search, patient: initData?.patientId } })
}

export function listPatientPrescriptionsByTreatment(initData){
        return axios.get('/patient-prescriptions/by/treatment', { params: { name: initData?.search, treatment: initData?.treatmentId } })
}

export function addPatientPrescription(data){
        return axios.post('/patient-prescriptions', data)
}

export function getPatientPrescription(initData){
        return axios.get('/patient-prescriptions/'+initData?.id)
}

export function updatePatientPrescription(id, initData){
        return axios.patch('/patient-prescriptions/'+id, initData)
}

