import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { sysUserCount } from '../../../services/UserService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchSysNurseCount = createAsyncThunk('fetchSysNurseCount', (initData) => {
    return sysUserCount(initData)
    .then((response) =>  response.data.data)
})


const sysNurseCountSlice = createSlice({
    name: 'sysNurseCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchSysNurseCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchSysNurseCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default sysNurseCountSlice.reducer 