import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { sysUserCount } from '../../../services/UserService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchSysPharmacistCount = createAsyncThunk('fetchSysPharmacistCount', (initData) => {
    return sysUserCount(initData)
    .then((response) =>  response.data.data)
})


const sysPharmacistCountSlice = createSlice({
    name: 'sysPharmacistCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchSysPharmacistCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchSysPharmacistCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default sysPharmacistCountSlice.reducer 