import axios from '../axiosinstance';

      
export function listPatientProgressNotes(initData){
        return axios.get('/patient-progress-notes', { params: { name: initData?.search, patient: initData?.patientId } })
}

export function addPatientProgressNotes(data){
        return axios.post('/patient-progress-notes', data)
}

export function getPatientProgressNotes(initData){
        return axios.get('/patient-progress-notes/'+initData?.id)
}

export function updatePatientProgressNotes(initData){
        return axios.patch('/patient-progress-notes/'+initData.id, initData)
}

export function patientProgressNotesCount(search){
        return axios.get('/patient-progress-notes/stat/count', { params: search })
}

