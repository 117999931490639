import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import PrescribedBy from "../../../../Component/PrescribedBy";
import moment from "moment";
import {  Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

function XrayReport({ show, onClose, selected }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const [viewXrayShow, setviewXrayShow] =
    useState(false);
  const handleviewXray = () => setviewXrayShow(false);

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }
  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
                      <div className="Solera__ModalHeadings">
                        <MdClose className="Solera__Close" onClick={onClose} />
                      </div>
        
                      <div className="Solera__ModalHeadings"></div>
                      <div className="Solera-Modal-Container">
                        <div className="Solera__Headings">
                          <div className="Log__Cont">
                            <img
                              src="/assets/lg.png"
                              alt=""
                              className="Log__Img__Modal"
                            />
                          </div>
                          <div className="Solera__Headings__Note">
                            <div className="Solera-Settings-Header">
                              Xray Instructions
                            </div>
                            <div className="Solera-Settings-Note">
                              {/* View Result here. */}
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />

                        <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Doctor's Name</div>
                <div className="Wallet-Ref-Result">
                  <PrescribedBy name={selected?.user?.full_name} />
                </div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Date</div>
                <div className="Wallet-Ref-Result">{showDate(selected?.created_at)}</div>
              </div> <br />
                        <br />

                        <div className="FormSect-Contianer">
                        <div className="Report-Display">Doctor's Instruction</div>
                          {/* <div className="Report-Display-Note">
                            {selected?.name}
                          </div> */}
                        
                          <div className="Report-Date4">
                          <div dangerouslySetInnerHTML={{__html:selected?.name}}></div>
                      </div>
                          {
                            selected?.url?.length ? (
                              selected?.fileType === "application/pdf" ? (
                                <div className="pdf-div">
                                  <Viewer
                                      fileUrl={selected?.url}
                                      plugins={[defaultLayoutPluginInstance]}
                                  />
                                </div>
                                
                              ) : (
                                <div>
                                  <img width={900} height={1000} src={selected?.url} />
                                </div>
                              )
                              
                            ): null
                          }
                        </div>
                        <br /> <br /> <br />
                      </div>
                    </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default XrayReport