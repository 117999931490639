import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/./Modal.css";
import TextField from "@mui/material/TextField";
import { MdAddCircle, MdClose, MdRemoveCircle } from "react-icons/md";
import { useParams } from "react-router-dom";
import { getUser } from "../../../../services/AuthService";
import { useDispatch } from "react-redux";
import { Bars } from "react-loader-spinner";
import { toast } from "react-toastify";
import { fetchPatientDrugs } from "../../../../features/patients/patientDrugsSlice";
import { addPatientMedications } from "../../../../services/PatientMedicationService";

import RichTextEditor from "react-rte"; // Copy Editor

function AdministerMedicationModal({ show, onClose, selected }) {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue()); // Copy Editor

  let { id } = useParams();
  const user = getUser();
  const dispatch = useDispatch();

  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);

  const [serviceList, setServiceList] = useState([{ service: "" }]);
  const [day, setDay] = useState("");
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;
    setServiceList(list);
  };

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };

  const handleServiceAdd = () => {
    setServiceList([...serviceList, { service: "" }]);
  };

  const handleSub = () => {
    onClose();
    setAddReportShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: " Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const handleAdd = () => {
    setLoading(true);
    const data = {
      user: user?._id,
      patient: id,
      treatment: selected?.treatment_infos[0]?._id,
      day: day,
      medications: serviceList,
      note: note,
    };
    console.log(data);
    addPatientMedications(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          setAddReportShow(false);
          dispatch(fetchPatientDrugs({ patient: id }));
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  {
    /* Copy Editor */
  }
  const onChange = (value) => {
    setValue(value);
    value.toString("html");
    setNote(value.toString("html"));
  };

  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                      Administer Medication
                    </div>
                    <div className="Solera-Settings-Note">
                      Include the doctor-prescribed medications in the patient's
                      record. Click on the Add Icon to add more medication
                      accordingly.
                    </div>
                  </div>
                </div>
                <br />
                <br />
                {serviceList &&
                  serviceList.map((singleService, index) => (
                    <ul key={index}>
                      {singleService.service && (
                        <li className="ListIt">{singleService.service}</li>
                      )}
                    </ul>
                  ))}
                <div className="FormSect-Contianer">
                  <div>
                    <div className="Doctors-Name"> Day</div>
                    <div className="Report-Date4">
                      Enter Day (example 1 or 2)
                    </div>
                  </div>
                  <br />
                  <TextField
                    fullWidth
                    label="Day "
                    id="fullWidth"
                    type="number"
                    className="Solera-Login-Input mb-3"
                    value={day}
                    onChange={(e) => setDay(e.target.value)}
                  />

                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {serviceList.map((singleService, index) => (
                        <div key={index} className="services">
                          <div className="first-division">
                            <TextField
                              fullWidth
                              label="Enter Drug name - Dose - Route"
                              name="service"
                              type="text"
                              id="fullWidth"
                              value={singleService.service}
                              onChange={(e) => handleServiceChange(e, index)}
                              required
                              placeholder="Add Medications"
                              className="Solera-Login-Input mb-3"
                            />
                          </div>
                          <div className="RemoveAndAddBtn">
                            <div className="second-division">
                              {serviceList.length !== 1 && (
                                <MdRemoveCircle
                                  className="CounterIcon"
                                  type="button"
                                  onClick={() => handleServiceRemove(index)}
                                />
                              )}
                            </div>
                            <div>
                              {serviceList.length - 1 === index &&
                                serviceList.length < 100 && (
                                  <MdAddCircle
                                    className="CounterIcon"
                                    type="button"
                                    onClick={handleServiceAdd}
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="FormSect-Contianer">
                    <div className="container-fluid p-0 FormInput">
                      <div className="col">
                        {/* <TextField
                          fullWidth
                          id="outlined-multiline-static "
                          label="Remark"
                          multiline
                          rows={5}
                          value={note}
                          onChange={(e) => setNote(e.target.value)}
                          // defaultValue="Default Value"
                        /> */}

                        {/* Copy Editor */}
                        <RichTextEditor
                          className="editor"
                          value={value}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="FormUpload">
                  {loading ? (
                    <Bars
                      heigth="30"
                      width="30"
                      color="gray"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    <button className="Add-Button" onClick={handleAdd}>
                      Add Medication
                    </button>
                  )}
                  {/* <button className="Add-Button" onClick={handleSub}>
                    Add Medication
                  </button> */}
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default AdministerMedicationModal;
