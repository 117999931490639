import axios from '../axiosinstance';

      
export function listPatientLaps(initData){
        return axios.get('/patient-laps', { params: { name: initData?.search, patient: initData?.patientId } })
}

export function listPatientTreatmentLaps(initData){
        return axios.get('/patient-laps/list/treatment', { params: { name: initData?.search, patient: initData?.patientId, treatment: initData?.treatmentId } })
}

export function listLaps(initData){
        return axios.get('/patient-laps/list/laps', { params: { name: initData?.search } })
}

export function addPatientLaps(data){
        return axios.post('/patient-laps', data)
}

export function getPatientLaps(initData){
        return axios.get('/patient-laps/'+initData?.id)
}

export function updatePatientLaps(id, initData){
        return axios.patch('/patient-laps/'+id, initData)
}

export function updateManyPatientLaps(initData){
        return axios.patch('/patient-laps/many/result', initData)
}

export function patientLapsCount(search){
        return axios.get('/patient-laps/stat/count', { params: search })
}




