import { React, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import DocScanRequestModal from "./DocScanRequestModal";

function AddCTScan() {
    
  const [cTScanShow, setCTScanShow] = useState(false);
  const handleCTScan = () => setCTScanShow(false);
  return (
    <div>
      <button
        className="Add-Patient-Report"
        onClick={() => setCTScanShow(true)}
      >
        CT Scan to conduct
      </button>
      <div>
        <DocScanRequestModal
          show={cTScanShow}
          onClose={handleCTScan}
          selected={'CTScan'}
          onOpen={() => setCTScanShow(true)}
        />
      </div>
    </div>
  );
}

export default AddCTScan;
