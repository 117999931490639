import React, { useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";

import {
  CiLineHeight,
  CiMail,
  CiMemoPad,
  CiMicrochip,
  CiPickerHalf,
  CiPill,
  CiSun,
  CiUser,
  CiViewList,
  CiViewTable,
  CiVirus,
  CiWheat,
} from "react-icons/ci";

import ClinicalNote from "../ClinicalNote/ClinicalNote";
import CTScan from "./CTScan";
import Xray from "./Xray";
import MRI from "./MRI";
import UntraSoundScan from "./UntraSoundScan";
import AddTest from "../Lab/AddTest";
import AddLabResult from "../../../LabPage/AddLabResult";

function Documents() {
  const [active, setActive] = useState("Xray");
  return (
    <div>
      <div className="ReportHeader">
        <div className="Report-Heading">Documents</div>

        <div className="DocumemtBtn">
        <div
            className={active === "Xray" ? "ActiveIconX" : "InactiveIcon"}
            onClick={() => setActive("Xray")}
          >
            <CiMemoPad className="Solera-Icons" />
            <div> Xray </div>
          </div>
          <div
            className={active === "CTScan" ? "ActiveIconX" : "InactiveIcon"}
            onClick={() => setActive("CTScan")}
          >
            <CiMemoPad className="Solera-Icons" />
            <div> CT Scan </div>
          </div>
          <div
            className={active === "MRI" ? "ActiveIconX" : "InactiveIcon"}
            onClick={() => setActive("MRI")}
          >
            <CiMemoPad className="Solera-Icons" />
            <div> MRI </div>
          </div>
          <div
            className={
              active === "UntraSoundScan" ? "ActiveIconX" : "InactiveIcon"
            }
            onClick={() => setActive("UntraSoundScan")}
          >
            <CiMemoPad className="Solera-Icons" />
            <div> Ultrasound scan </div>
          </div>
        
        </div>
      </div>

      <div>
        <div>
        {active === "Xray" && <Xray />}
          {active === "CTScan" && <CTScan />}
          {active === "MRI" && <MRI />}
          {active === "UntraSoundScan" && <UntraSoundScan />}
        </div>
      </div>
    </div>
  );
}

export default Documents;
