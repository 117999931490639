import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { patientTheatreCount } from '../../../../services/PatientTheatreService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchPatientDentistCount = createAsyncThunk('fetchPatientDentistCount', (initData) => {
    return patientTheatreCount(initData)
    .then((response) =>  response.data.data)
})


const patientDentistCountSlice = createSlice({
    name: 'patientDentistCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientDentistCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatientDentistCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default patientDentistCountSlice.reducer 