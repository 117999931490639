import React from "react";
import FaceLogo from "../assets/FV.png"
import SideFaceLogo from "../assets/SV.jpeg"

function Facials() {
  return (
    <div>
      <div className="container-fluid p-0 FormInput">
        <div className="row g-2">
          <div className="col-6">
            <div className="">
              <img src={FaceLogo} alt="" width="350" height="400" />
            </div>
          </div>
          <div className="col-6">
            <div className="">
              <img src={SideFaceLogo} alt="" width="450" height="400" />
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

export default Facials;
