import React, { useEffect } from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import SideBar from "../../Component/SideBar";
import StaffGreetings from "../../Component/StaffGreetings";
import { FiUsers } from "react-icons/fi";
import AddStaff from "./AddStaff";
import NumberOfStaff from "./NumberOfStaff";
import StaffTable from "./StaffTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchStaffCount } from "../../features/staff/staffCountSlice";

function StaffPage() {
  const dispatch = useDispatch()
  const staffCount = useSelector((state) => state.staffCount)

  useEffect((  ) => {
    dispatch(fetchStaffCount({}))
  }, [])
  return (
    <div className="Solera-Main-Container">
          <div className="NavBarContainer">
            <nav className="navbar navbar-expand-lg Solera-Nav">
              <div className="container-fluid">
                <div className="Solera-Admin-Heading">STAFF</div>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarText"
                  aria-controls="navbarText"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarText">
                  <div className="navbar-nav me-auto mb-2 mb-lg-0"></div>
                  <span className="navbar-text">
                    <StaffGreetings />
                  </span>
                </div>
              </div>
            </nav>
            <div className="Solera-Main-Dash">
              <div>
                <div>
                  <div className="container-fluid overflow-hidden Solera-Dash-section1">
                    <div className="container-fluid Patients-Bar">
                      <div className="Solera-Patient-Activities">
                      Staff Activities
                      </div>
                      <div className="Solera-Welcome-Note">
                        <AddStaff />
                      </div>
                    </div>

                    <div className="container-fluid ">
                      <div className="row g-5 ">
                        <div className="col">
                          <div className="Admin-Heading-Container">
                            <div className="DashBoard-Cont">
                              <div className="Admin-Heading">Staff</div>

                              <div className="Logo-Admin-Heading">
                                <FiUsers className="Data-Icon" />
                              </div>
                            </div>

                            <NumberOfStaff num={staffCount?.count} loading={staffCount?.loading} />
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="container-fluid overflow-visible Solera-Dash-section2">
                    <div className="row g-5 ">
                      <div className="col-12">
                        <div className="Solera-Activity-Table">
                          <div className="Patient-Notification">
                            <StaffTable />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  );
}

export default StaffPage;
