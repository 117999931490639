import React from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";

function NumberOfNurses({nurseNum, nurseLoading}) {
  return (
          <div className="Count-Result">{nurseLoading ? "loading..." : nurseNum }</div>
  )
}

export default NumberOfNurses