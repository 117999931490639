import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import { findFace, findsideFace } from "../../../../services/extras";
import Facials from "../../../../Component/Facials";

function ViewMedicalRecordsModal({ show, onClose, selected }) {
  const [viewvitaldetailsShow, setViewvitaldetailsShow] = useState(false);
  const handleViewvitaldetails = () => setViewvitaldetailsShow(false);
  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera-Modal-Container">
                <br />
                <div className="Report-Display-Heading">
                  Patient's Medical Records
                </div>
                <div className="Report-Tittle">
                  Patient's Medical Record Details
                </div>
                <div className="Solera-Modal-Container">
                  <div className="Note-Modal-Cont">
                    <div>
                      <div className="Doctors-Name">
                        {" "}
                        History of the disease and family history
                      </div>

                      <div className="Report-Date4">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: selected?.disease_history,
                          }}
                        ></div>
                      </div>
                    </div>
                    <br />

                    <div>
                      <div className="Doctors-Name"> Main Pathology</div>
                      <div className="Report-Date4">
                        Main Pathology:&nbsp;
                        <span>
                          {selected?.main_pathology ? "Noma" : " Not noma"}
                        </span>
                      </div>
                      <div className="Report-Date4">
                        Stage:&nbsp;<span>{selected?.if_noma}</span>
                      </div>
                    </div>
                    <br />

                    <div>
                      <div className="Doctors-Name">HIV Status.</div>
                      <div className="Report-Date4">
                        {selected?.hiv ? "Positive" : "Negative"}
                      </div>
                    </div>
                    <br />

                    <div>
                      <div className="Doctors-Name">Hepatitis</div>
                      <div className="Report-Date4">
                        {selected?.hepatitis ? "Positive" : "Negative"}
                      </div>
                    </div>
                    <br />

                    <div>
                      <Facials />
                    </div>
                    <br />

                    <br />
                    <div className="container p-0">
                      <div className="row g-2">
                        <div className="col-lg-6">
                          <div>
                            <div className="Doctors-Name">
                              Front Face Measurements(CM)
                            </div>
                            {selected?.front_face?.map((front, index) => (
                              <div key={index} className="Report-Date4">
                                {index + 1 + ". "}
                                {findFace(front?.type) + ": "}
                                {front?.type}:&nbsp;
                                <span>{front?.measure}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          <div>
                            <div className="Doctors-Name">
                              Side Face Measurements(CM)
                            </div>
                            <div className="Report-Date4">
                              {" "}
                              {selected?.side_face?.map((side, index) => (
                                <div key={index} className="Report-Date4">
                                  {findsideFace(side?.type) + ": "}
                                  {side?.type}:&nbsp;
                                  <span>{side?.measure}</span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <br />

                    <br />

                    <div>
                      <div className="Doctors-Name">Thyromental Distance (cm)</div>
                      <div className="Report-Date4">
                        {selected?.thyromental_distance}
                      </div>
                    </div>
                    <br />
                    <div>
                      <div className="Doctors-Name">
                        Mid Upper Arm Circumfrence (cm)
                      </div>
                      <div className="Report-Date4">
                        {selected?.mid_upper_arm_circumfrence}
                      </div>
                    </div>
                    <br />
                    <br />
                    <div>
                      <div className="Doctors-Name">Others</div> <br />
                      <div className="Report-Date4">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: selected?.other_pathology,
                          }}
                        ></div>
                      </div>
                    </div>
                    <br />
                    {/* <div>
                      <div className="Doctors-Name">Consent form</div>
                      <div className="Report-Date4">Consent Display</div>
                    </div> */}

                    <br />
                  </div>
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ViewMedicalRecordsModal;
