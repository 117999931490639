import { React, useEffect, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import AddTest from "./AddTest";
import LabouratoryTable from "../../../../Component/LabouratoryTable";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientLaps } from "../../../../features/patients/patientLapsSlice";
import moment from "moment";
import { getSys } from "../../../../services/AuthService";

function LabResult() {

  const sys = getSys();


  let { id } = useParams();
  const dispatch = useDispatch()

  const {patientLaps, loading} = useSelector((state) => state.patientLaps)

  const [viewvitaldetailsShow, setViewvitaldetailsShow] = useState(false);
  const handleViewvitaldetails = () => setViewvitaldetailsShow(false);

  const [patientDetailsShow, setPatientDetailsShow] = useState(false);
  const handlePatientDetails = () => setPatientDetailsShow(false);

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }


  useEffect(() => {
    if (id) {
      dispatch(fetchPatientLaps({patientId: id}))
    }
  }, [id]);
  return (
    <div>
      <div className="Report-Notes">
        <div className="ReportHeader">
          <div className="Report-Heading">Laboratory</div>
          <div className="Report-Count">
            <span>{patientLaps?.length || 0}</span>&nbsp;Laboratory
          </div>

          {sys?.role === "sys-admin" || sys?.role === "sys-doctor" ? (
                <AddTest />
              ) : null}
        
        </div>
      </div>
      <br />
      <LabouratoryTable />
    </div>
  );
}

export default LabResult;
