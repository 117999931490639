import axios from '../axiosinstance';

      

//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

export function listPatient(initData){
        return axios.get('/patients', { params: initData })
}

export function addPatient(data){
        return axios.post('/patients', data)
}

export function getPatient(initData){
        return axios.get('/patients/'+initData?.id)
}

export function updatePatient(initData){
        return axios.patch('/patients/'+initData.id, initData)
}

export function updatePatientPic(id, initData){
        return axios.patch('/patients/'+id, initData)
}

export function patientCount(search){
        return axios.get('/patients/stat/count', { params: search })
}
