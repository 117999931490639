import { React, useEffect, useState } from "react";
// import "../../../SoleraCss/Responsive.css";
// import "../../../SoleraCss/Solera.css";
// import AddPreOpNote from "../ClinicalNote/AddPreOpNote";
// import TextField from "@mui/material/TextField";
// import Swal from "sweetalert2";
import Dropdown from "react-bootstrap/Dropdown";
// import ViewPostOpModal from "./ViewPostOpModal";
// import AddPostOpNote from "./AddPostOpNote";
// import { useParams } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchPostOps } from "../../../../features/patients/postOpSlice";
// import moment from "moment";
// import { Bars } from "react-loader-spinner";
// import UploadClinicalNote from "./UploadClinicalNote";
// import ViewPostOpNoteUploadModal from "./ViewPostOpNoteUploadModal";
import CreateSheet from "./CreateSheet";
import ViewSheet from "./ViewSheet";
import UpdteSheet from "./UpdteSheet";
import UploadSheet from "./UploadSheet";
import ViewUploadedSheet from "./ViewUploadedSheet";

function IntraoperativeSheet() {

  
  const [viewSheetDetails, setSheetDetailsShow] =
    useState(false);
  const handleSheetDetails = () => setSheetDetailsShow(false);


  const [updateSheetShow, setUpdateSheetShow] =
    useState(false);
  const handleUpdateSheet = () => setUpdateSheetShow(false);

  const [uploadSheetShow, setUploadSheetShow] =
  useState(false);
const handleUploadSheet= () => setUploadSheetShow(false);

const [viewUploadedSheetShow, setViewUploadedSheetShow] =
useState(false);
const handleViewUploadedSheet= () => setViewUploadedSheetShow(false);




  
 

  

  // const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
  //   useState(false);
  // const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);

  // const [uploadNotesShow, setUploadNotesShow] = useState(false);
  // const handleUploadNoteDetails = () => setUploadNotesShow(false);

  // const [viewPostOpNoteUploadShow, setViewPostOpNoteUploadShow] =
  //   useState(false);
  // const handleViewPostOpNoteUpload = () => setViewPostOpNoteUploadShow(false);

  // const [editPreOpNoteDetailsShow, setEditPreOpNoteDetailsShow] =
  //   useState(false);
  // const handleEditPreOpNoteDetails = () => setEditPreOpNoteDetailsShow(false);

  return (
    <div>
      <div className="Report-Notes">
        <div className="ReportHeader">
          <div className="Report-Heading">Intraoperative Sheet</div>
          <div className="Report-Count">
            <span>0</span>&nbsp;Intraoperative Sheet
          </div>
          <CreateSheet />
        </div>
      </div>
      <br />
      {/* {loading ? (
        <div className="d-flex justify-content-center">
          <Bars
            heigth="30"
            width="30"
            color="gray"
            ariaLabel="loading-indicator"
          />
        </div>
      ) : null} */}
   
        <div  className="Patient-Record-Container">
          <div className="Patient-Record-Wrapper">
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Note Number</div>
              <div className="Wallet-Ref-Result">
                {/* {item?.code} */}
                </div>
            </div>
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Doctor's Name</div>
              <div className="Wallet-Ref-Result">
              {/* {item?.user?.full_name} */}
              </div>
            </div>
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Date</div>
              <div className="Wallet-Ref-Result">
                {/* {showDate(item?.created_at)} */}
              </div>
            </div>
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Status</div>
              <div className="Wallet-Ref-Result">
                {/* {item?.status} */}
              </div>
            </div>
            <div className="Action-Drop-Down">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
                  Manage sheet
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      // setSelected(item);
                      setSheetDetailsShow(true);
                    }}
                    className="Solera-Patients"
                  >
                    View sheet
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      // setSelected(item);
                      setUpdateSheetShow(true);
                    }}
                    className="Solera-Patients"
                  >
                    Update sheet
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      // setSelected(item);
                      setUploadSheetShow(true);
                    }}
                    className="Solera-Patients"
                  >
                    Upload sheet
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      // setSelected(item);
                      setViewUploadedSheetShow(true);
                    }}
                    className="Solera-Patients"
                  >
                    View uploaded sheet
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
    

      <div>
        <ViewSheet
        
          show={viewSheetDetails}
          onClose={handleSheetDetails}
          onOpen={() => setSheetDetailsShow(true)}
        />

        <ViewUploadedSheet
          // named={"post Op"}
          // selected={selected}
          show={viewUploadedSheetShow}
          onClose={handleViewUploadedSheet}
          onOpen={() => setViewUploadedSheetShow(true)}
        />

        <UploadSheet
          // selected={selected}
          show={uploadSheetShow}
          onClose={handleUploadSheet}
          onOpen={() => setUploadSheetShow(true)}
        />

        <UpdteSheet
          show={updateSheetShow}
          onClose={handleUpdateSheet}
          onOpen={() => setUpdateSheetShow(true)}
        />
      </div>
    </div>
  );
}

export default IntraoperativeSheet;
