import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientClinicalNote } from '../../services/PatientClinicalNoteService'

const initialState = {
    loading: false,
    physiotherapies: [],
    isSuccessful: false
}

export const fetchPhysiotherapy = createAsyncThunk('fetchPhysiotherapy', (initData) => {
    return listPatientClinicalNote(initData)
    .then((response) => response.data.data)
})


const physiotherapySlice = createSlice({
    name: 'physiotherapiesList',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPhysiotherapy.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchPhysiotherapy.fulfilled, (state, action ) => {
        state.loading = false
        state.physiotherapies = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchPhysiotherapy.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default physiotherapySlice.reducer 