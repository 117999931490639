import { React, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import "./Modal.css";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientTreatmentLaps } from "../../features/patients/patientTreatmentLapsSlice";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import { updateManyPatientLaps, updatePatientLaps } from "../../services/PatientLapsService";
import { fetchPatientLaps } from "../../features/patients/patientLapsSlice";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { fetchPatientListLaps } from "../../features/patients/patientListLapsSlice";

function AddLabResultModal({ show, onClose, selected }) {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  let { id } = useParams();
  const dispatch = useDispatch();
  const { patientLaps, loading, isSuccessful } = useSelector(
    (state) => state.patientTreatmentLaps
  );
  const [serviceList, setServiceList] = useState([]);
  const [startLoading, setStartLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState("");

  useEffect(() => {
    if (show) {
      callPatientLap()
    }
  }, [show]);

  const callPatientLap = () => {
    dispatch(
      fetchPatientTreatmentLaps({
        patientId: selected?.patient_infos[0]?._id,
        treatmentId: selected?.treatment_infos[0]?._id,
      })
    );
  }

  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };

  const handleServiceChange = (e, id) => {
    let found = {};
    found = patientLaps.find((obj) => {
      return obj._id === id;
    });

    console.log("INSIDE FOUND", found)
    let data = serviceList;
    let index = patientLaps.findIndex((item) => item._id === id);
    console.log("INSIDE FOUND index", index)

    if (found) {
      if (index === -1) {
        data.push({ _id: found?._id, result: e.target.value });
      } else {
        data[index] = { _id: found?._id, result: e.target.value };
      }
      console.log("INSIDE FOUND data", data)

      setServiceList(data);
    }
  };

  const handleFindName= (id) => {
    let found = {};
    found = serviceList.find((obj) => {
      return obj?._id === id;
    });
    return found?.result;
  };


  // const handleUpdate = () => {
  //   setStartLoading(true);

  //   updateManyPatientLaps({ results: serviceList })
  //     .then((response) => {
  //       setStartLoading(false);
  //       if (response.data.success) {
  //         //closeRef.current.click()
  //         toast.success(
  //           response.data.message
  //             ? response.data.message
  //             : "Successfully Created"
  //         );
  //         onClose();
  //         setAddReportShow(false);
  //         dispatch(fetchPatientLaps({ patientId: id }));
  //         Swal.fire({
  //           customClass: {
  //             title: "title-class",
  //             icon: "icon-class",
  //           },

  //           icon: "success",
  //           title: "Successful",
  //           showConfirmButton: false,
  //           timer: 2000,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       setStartLoading(false);
  //       console.log(err);
  //     });
  // };


  const handleUpdateOne = (data) => {
    setStartLoading(true);
    let formData = new FormData(); 

    formData.append('result', handleFindName(data._id));
    formData.append('photo', selectedFile);
    formData.append('status', 'conducted');
    formData.append('treatment', selected?.treatment_infos[0]?._id);

    // console.log("FORM DATA", data)
    // console.log("FORM DATA result", handleFindName(data._id))
    // console.log("FORM DATA serviceList", serviceList)

    
    updatePatientLaps(data._id, formData)
      .then((response) => {
        setStartLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Uploaded"
          );
          setAddReportShow(false);
          dispatch(fetchPatientListLaps({}));
          callPatientLap()
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setStartLoading(false);
        console.log(err);
      });
  };

  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                      Enter Patient's Lab Result
                    </div>
                    <div className="Solera-Settings-Note">
                      Kindy enter the patient's result here
                    </div>
                  </div>
                </div>
                <br />
                <br />
                {loading ? <div>Loading...</div> : null}
                {patientLaps?.map((item, index) => (
                  <div className="FormSect-Contianer">
                    <div className="Text-Result-Display">
                      Test Number {index + 1} - {item?.name} {item?.status === "conducted" ? '- conducted' : null}
                    </div>
                    {
                      item?.status === "waiting" ? (
                        <div className="container-fluid p-0 FormInput">
                      <div className="col">
                        <TextField
                          fullWidth
                          id="outlined-multiline-static "
                          label="Enter Test Result"
                          multiline
                          rows={1}
                          name="result"
                          onChange={(e) => {
                            handleServiceChange(e, item?._id);
                          }}
                          // defaultValue="Default Value"
                        />
                      </div>
                      <div className="Solera-Settings-Note">
                        <br />
                        <Button
                            className="Add-Patient-Report"
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            id={"finput"}
                            onChange={onSelectFile}
                          >
                              Upload file
                              <VisuallyHiddenInput type="file" />
                          </Button>
                      </div>
                      <br />
                      {patientLaps?.length ? (
                        startLoading ? (
                          <Bars
                            heigth="30"
                            width="30"
                            color="gray"
                            ariaLabel="loading-indicator"
                          />
                        ) : (
                          <button className="FileSubmit"  onClick={() => handleUpdateOne(item)} >Submit result</button>
                        )
                      ) : null}
                      
                    </div>
                      ) : (
                        <div className="FormSect-Contianer">
                          <div className="container-fluid p-0 FormInput">
                            {item?.url &&  <img width={100} height={100} src={item?.url} alt="result" /> }
                          </div>
                        </div>
                      )
                    }
                  </div>
                ))}
                <div className="FormUpload">
                  {/* <div>
                    <input type="file" />
                  </div> */}

                  {/* {patientLaps?.length ? (
                    startLoading ? (
                      <Bars
                        heigth="30"
                        width="30"
                        color="gray"
                        ariaLabel="loading-indicator"
                      />
                    ) : (
                      <button className="Add-Button" onClick={handleUpdate}>
                        Submit
                      </button>
                    )
                  ) : null} */}
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default AddLabResultModal;
