import axios from '../axiosinstance';

      

//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

export function listDrugs(search){
        return axios.get('/pharmacy', { params: search })
}

export function searchDrugs(search){
        return axios.get('/pharmacy/find/by-search', { params: { search: search } })
}

export function AddDrug(data){
        return axios.post('/pharmacy', data)
}

export function getDrugs(initData){
        return axios.get('/pharmacy/'+initData?.id)
}

export function updateDrugs(initData){
        return axios.patch('/pharmacy/'+initData.id, initData)
}

export function drugsCount(search){
        return axios.get('/pharmacy/stat/count', { params: search })
}
