import { React, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {  MdClose } from "react-icons/md";
import { searchDrugs } from "../../../../services/DrugService";
import moment from "moment";
import { Bars } from "react-loader-spinner";
import { addPatientDrugs } from "../../../../services/PatientDrugsService";
import { fetchPatientDrugs } from "../../../../features/patients/patientDrugsSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../../services/AuthService";
import { toast } from "react-toastify";
import { fetchPatientPrescriptionsTreatment } from "../../../../features/patients/patientPrescriptionsTreatmentSlice";

function AdminsterPrescriptionModal({ show, onClose, onOpen, selected }) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const user = getUser();
  const { listPrescriptionsTreatment, loading } = useSelector(
    (state) => state.patientPrescriptionsTreatment
  );

  const [name, setName] = useState("");
  const [result, setResult] = useState([]);
  const [prescriptions, setPrescriptions] = useState([]);
  const [checkLoading, setCheckLoading] = useState(false);
  const [pushLoading, setPushLoading] = useState(false);
  const [sloading, setLoading] = useState(false);
  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);
  const [receiverName, setReceiverName] = useState("");

  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };

  useEffect(() => {
    if (selected?._id) {
      dispatch(
        fetchPatientPrescriptionsTreatment({ treatmentId: selected?.treatment })
      );
    }
  }, [show]);

  const handleCheckDrug = (searchName) => {
    setCheckLoading(true);
    searchDrugs(searchName)
      .then((response) => {
        if (response.data.success) {
          setCheckLoading(false);
          setResult(response.data.data.data);
        }
      })
      .catch((err) => {
        setCheckLoading(false);
        console.log(err);
      });
  };

  const handlePushDrug = async (check) => {
    if(1 > check?.qty ){
      Swal.fire({
        customClass: {
          title: "title-class",
          icon: "icon-class",
        },

        icon: "warning",
        title: "Out of stock",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
  } 
    const likes = prescriptions;
    if (likes.includes(check)) {
      const room = likes.find((s) => s?._id === check?._id);
      room.release = room?.release + 1;
      setPrescriptions((prev) =>
        prev.find((r) => r?._id === room?._id)
          ? prev.map((r) => (r?._id === room?._id ? room : r))
          : prev.concat(room)
      );
      return;
    } else {
      check.release = 1;
      setPrescriptions([...prescriptions, check]);
    }
  };

  const handleRemove = (check) => {
    const tempo = [...prescriptions];

    tempo.splice(check, 1);

    setPrescriptions(tempo);
  };


   const updateObjectProperty = (index, propName, propValue, mainItem) => {
    if(propValue > mainItem?.qty ){
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "warning",
            title: "Above stock",
            showConfirmButton: false,
            timer: 2000,
          });
          return;
    }else{
      setPrescriptions(prescriptions.map((item, idx) =>
        idx === index ? { ...item, [propName]: parseInt(propValue, 10) } : item
      ));
    }
    
   }; 

  const handleAdd = () => {
    setLoading(true);
    const data = {
      user: user?._id,
      patient: id,
      treatment: selected?.treatment_infos[0]?._id,
      drugs: prescriptions,
      receiver_name: receiverName,
    };
    console.log(data);
    addPatientDrugs(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          setAddReportShow(false);
          dispatch(fetchPatientDrugs({ patient: id }));
          setResult({});
          setPrescriptions([]);
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div>
      {" "}
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Solera__ModalHeadings">
              <MdClose className="Solera__Close" onClick={onClose} />
            </div>
            <div className="Solera__ModalHeadings"></div>
            <div className="Solera-Modal-Container30">
              <div className="Solera__Headings">
                <div className="Log__Cont">
                  <img
                    src="/assets/lg.png"
                    alt=""
                    className="Log__Img__Modal"
                  />
                </div>
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">
                    Release doctor's prescription
                  </div>
                  <div className="Solera-Settings-Note">
                    Kindy release doctor's prescription to the nurse.
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="container-fluid">
                <div className="row g-2">
                  <div className="col-lg-5">
                    <div className="">
                      <div className="Selected-Drugs-Box-Heading">
                        List of drugs prescribed by the doctor
                      </div>
                      {loading ? (
                        <Bars
                          heigth="30"
                          width="30"
                          color="gray"
                          ariaLabel="loading-indicator"
                        />
                      ) : null}
                      {listPrescriptionsTreatment?.length
                        ? listPrescriptionsTreatment?.map((item, index) => (
                            <div className="Vital-Box-Result mt-2">
                              <div className="Vital-Box-Heading">
                                <div className="FormSect">
                                  Prescription {index + 1}
                                </div>
                              </div>
                              <div>
                                <div className="FormSect-Note">
                                  Drug Name - Dose - Route - Quantity
                                </div>
                                <div className="FormSect-Note5">
                                  <span>{item?.name}</span>
                                </div>
                                <br/>

                                <div className="FormSect-Note">
                                  Doctor : &nbsp;
                                  <span>{item?.user?.full_name}</span>
                                </div>
                              </div>
                              <br />
                            </div>
                          ))
                        : null}
                      <div className="FormSect-Note">Prescription Note</div>
                      <div className="Report-Date4">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: listPrescriptionsTreatment[0]?.note,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div>
                      <div className="Selected-Drugs-Box-Heading">
                        Receiver's Name
                      </div>
                      <div className="Solera-Settings-Note">
                        Enter the name of the Nurse that will poick up this
                        drugs
                      </div>
                      <br />
                      <TextField
                        fullWidth
                        label="Receiver's Name"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={receiverName}
                        onChange={(e) => setReceiverName(e.target.value)}
                      />
                    </div>
                    <br />
                    <div className="">
                      <div>
                        <div className="Patient-Search">
                          <input
                            type="Search"
                            className="form-control SearchesInput22"
                            placeholder="Search for drugs"
                            aria-label="OTP"
                            aria-describedby="basic-addon1"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                          <div
                            onClick={() => {
                              if (name) {
                                handleCheckDrug(name);
                              }
                            }}
                            className="Searchesbtn"
                          >
                            Search
                          </div>
                        </div>
                        <div className="Selected-Drugs-Box-Container">
                          <div className="Selected-Drugs-Box-Heading">
                            Searched drugs
                          </div>
                          <div className="Selected-Drugs-Box-Note">
                            Choose the desired number of drugs and click "Add"
                            to include them in the list below. You can add the
                            same drugs multiple times if needed.
                          </div>
                        </div>
                        {checkLoading ? (
                          <Bars
                            heigth="30"
                            width="30"
                            color="gray"
                            ariaLabel="loading-indicator"
                          />
                        ) : null}
                        {result?.length > 0 ? (
                          result?.map((item, index) => (
                            <div
                              key={index}
                              className="Drug-search-Display mt-3"
                            >
                              <div className="Drug-search-Display2">
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox defaultChecked />}
                                  />
                                </FormGroup>
                                <div className="Drug-search-Display1">
                                  <div className="Drugnamedisplay">
                                    <div className="Drug-search-Heading">
                                      Drugs Name{" "}
                                    </div>
                                    <div className="Drug-search-Result">
                                      {item?.name}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="Drug-search-Heading">
                                      Expiry Date
                                    </div>
                                    <div className="Drug-search-Result">
                                      {showDate(item?.expiry_date)}
                                    </div>
                                  </div>

                                  {/* <div>
                                    <div className="Drug-search-Heading">
                                      Stock
                                    </div>
                                    <div className="Drug-search-Result">
                                      <span>{item?.qty}</span>
                                    </div>
                                  </div> */}
                                  

                                  <div>
                                    <div className="Drug-search-Heading">
                                      Tablets
                                    </div>
                                    <div className="Drug-search-Result">
                                      <span>{item?.qty}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <button
                                  onClick={() => handlePushDrug(item)}
                                  className="Drug-BTN"
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="Selected-Drugs-Box-Heading">
                            No drug selected
                          </div>
                        )}

                        {/* {
                        result?.code ? (
                          <div className="Drug-search-Display">
                            <div className="Drug-search-Display2">
                              <FormGroup>
                                <FormControlLabel control={<Checkbox defaultChecked />} />
                              </FormGroup>
                              <div className="Drug-search-Display1">
                                <div>
                                  <div className="Drug-search-Heading">Drugs Name </div>
                                  <div className="Drug-search-Result">{result?.name}</div>
                                </div>
                                <div>
                                  <div className="Drug-search-Heading">Expiry Date</div>
                                  <div className="Drug-search-Result">
                                    {showDate(result?.expiry_date)}
                                  </div>
                                </div>

                                <div>
                                  <div className="Drug-search-Heading">Stock</div>
                                  <div className="Drug-search-Result">
                                    <span>{result?.qty}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <button onClick={() => handlePushDrug(result)} className="Drug-BTN">Add</button>
                            </div>
                          </div>
                        ) : <div>No result</div>
                      } */}


                      </div>
                    </div>
                    <div className="Selected-Drugs-Box-Container">
                      <div className="Selected-Drugs-Box-Heading">
                        Selected Drugs to be dispensed is displayed below
                      </div>
                      <div className="Selected-Drugs-Box-Note">
                        Selected Drugs to be dispensed
                      </div>
                      {pushLoading ? (
                        <Bars
                          heigth="30"
                          width="30"
                          color="gray"
                          ariaLabel="loading-indicator"
                        />
                      ) : null}
                      {prescriptions?.length
                        ? prescriptions?.map((item, index) => (
                            <div key={index} className="Selected-Drugs-Box70">
                              <div className="Drugnamedisplay60">
                                <div className="Drug-search-Heading">
                                  Drugs Name{" "}
                                </div>
                                <div className="Drug-search-Result">
                                  {item?.name}{" "}
                                </div>
                              </div>
                              <div>
                                <div className="Drug-search-Heading">
                                  Expiry Date
                                </div>
                                <div className="Drug-search-Result">
                                  {showDate(item?.expiry_date)}
                                </div>
                              </div>
                              {/* <div>
                                <div className="Drug-search-Heading">
                                  Qty{" "}
                                </div>
                                <div className="Drug-search-Result">
                                  {item?.release}{" "}
                                </div>
                              </div> */}
                              <div>
                                <div className="Drug-search-Heading">
                                  Tablets
                                </div>
                                <div className="Drug-search-Result">
                                  <input
                                    type="number"
                                    id="fname"
                                    name="fname"
                                    className="InputDrugsPack"
                                    value={item?.release}
                                    onChange={(e) =>{
                                      updateObjectProperty(index, 'release', e.target.value, item)
                                      console.log(e.target.value)}}
                                  />
                                </div>
                              </div>

                              <button
                                onClick={() => handleRemove(item)}
                                className="Drug-BTN"
                              >
                                Remove
                              </button>
                            </div>
                          ))
                        : null}
                    </div>
                    <br /> <br />
                    <div className="FormUpload">
                      {sloading ? (
                        <Bars
                          heigth="30"
                          width="30"
                          color="gray"
                          ariaLabel="loading-indicator"
                        />
                      ) : (
                        <button className="Add-Button" onClick={handleAdd}>
                          Dispense Drugs
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* <PrescriptionDisplay /> */}
              <br />
              <br />
            </div>
            <br /> <br /> <br />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default AdminsterPrescriptionModal;
