import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { getUser } from "../../../../services/AuthService";
import { useDispatch } from "react-redux";
import AddTreatmentPlan from "./AddTreatmentPlan";
import { toast } from "react-toastify";
import { fetchTreatmentPlan } from "../../../../features/patients/socials/treatmentPlanSlice";
import { Bars } from "react-loader-spinner";
import { addPatientTreatmentPlans } from "../../../../services/PatientTreatmentPlanService";
import RichTextEditor from 'react-rte';  // Copy Editor 

function TreatmentPlanModal({ show, onClose }) {


  const [value, setValue] = useState(RichTextEditor.createEmptyValue());// Copy Editor 
  const [value2, setValue2] = useState(RichTextEditor.createEmptyValue());// Copy Editor 
  let { id } = useParams();
  const user = getUser();
  const dispatch = useDispatch()
  const [treatmentGoals, setTreatmentGoals] = useState('');
  const [interventions, setInterventions] = useState('');

  const [loading, setLoading] = useState(false);

  const [addDoctorShow, setAddDoctorShow] = useState(false);
  const handleAddDoctor = () => setAddDoctorShow(false);

  const handleSaveDoctor = () => {
    onClose();
    setAddDoctorShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const handleAdd = () => {
    setLoading(true);
    const data = {
      user: user?._id,
      patient: id,
      treatment_goals: treatmentGoals,
      interventions: interventions,
    };
    console.log(data);
    addPatientTreatmentPlans(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(fetchTreatmentPlan({patientId: id}))
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  {/* Copy Editor */}
  const onChange = (value) => {
    setValue(value);
    value.toString('html')
    setTreatmentGoals(value.toString('html'))
  };


   {/* Copy Editor */}
   const onChange2 = (value) => {
    setValue2(value);
    value.toString('html')
    setInterventions(value.toString('html'))
  };

  return (
    <div>
      {" "}
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Solera__ModalHeadings">
              <MdClose className="Solera__Close" onClick={onClose} />
            </div>

            <div className="Solera__ModalHeadings"></div>
            <div className="Solera-Modal-Container">
              <div className="Solera__Headings">
                <div className="Log__Cont">
                  <img
                    src="/assets/lg.png"
                    alt=""
                    className="Log__Img__Modal"
                  />
                </div>
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">
                    Patient Treatment Goals
                  </div>
                  {/* <div className="Solera-Settings-Note">
                                    Kindly add a doctor for your records
                                  </div> */}
                </div>
              </div>
              <br />
              <br />
              <div className="FormSect-Contianer">
                <div className="FormSect">Treatment Goals</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>

                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Treatment Goals"
                        multiline
                        rows={5}
                        value={treatmentGoals}
                        onChange={(e) => setTreatmentGoals(e.target.value)}
                        // defaultValue="Default Value"
                      /> */}
                             {/* Copy Editor */}
                <RichTextEditor 
                  className="editor"
                  value={value} onChange={onChange} 
                />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Interventions </div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Interventions"
                        multiline
                        rows={5}
                        value={interventions}
                        onChange={(e) => setInterventions(e.target.value)}
                        // defaultValue="Default Value"
                      /> */}
                          <RichTextEditor 
                  className="editor"
                  value={value2} onChange={onChange2} 
                />
                    </div>
                  </div>
                </div>
              </div>
              {
                loading ? (
                  <Bars
                    heigth="30"
                    width="30"
                    color="gray"
                    ariaLabel="loading-indicator"
                  />
                ) : (
                  <button className="Add-Button" onClick={handleAdd}>
                    Add Patient Treatment Goals
                  </button>
                ) 
              }
              <br /> <br /> <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default TreatmentPlanModal;
