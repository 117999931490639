import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { FluidChartsCount } from '../../../services/FluidChartService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchfluidChartsCount = createAsyncThunk('fetchfluidChartsCount', (initData) => {
    return FluidChartsCount(initData)
    .then((response) =>  response.data.data)
})


const fluidChartsCountSlice = createSlice({
    name: 'fluidChartsCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchfluidChartsCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchfluidChartsCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default fluidChartsCountSlice.reducer 