import { React, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import MedicationModals from "./MedicationModals";
import DoctorsPrescriptionModal from "./DoctorsPrescriptionModal";
import AllPatientMedictionModal from "./AllPatientMedictionModal";

function ViewAllMedications() {
  const [doctorsPrescriptionShow, setDoctorsPrescriptionShow] = useState(false);
  const handleDoctorsPrescription = () => setDoctorsPrescriptionShow(false);
  return (
    <div>
      <button
        className="Add-Patient-Report"
        onClick={() => setDoctorsPrescriptionShow(true)}
      >
        View All Medications
      </button>
      <div>
      <AllPatientMedictionModal
            all={true}
            show={doctorsPrescriptionShow}
            onClose={handleDoctorsPrescription}
            onOpen={() => setDoctorsPrescriptionShow(true)}
          />


      </div>
    </div>
  );
}

export default ViewAllMedications;
