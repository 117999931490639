import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import { getUser } from "../../../../services/AuthService";
import { useDispatch } from "react-redux";
import { addPatientClinicalNote } from "../../../../services/PatientClinicalNoteService";
import { toast } from "react-toastify";
import { fetchPostOps } from "../../../../features/patients/postOpSlice";
import { Bars } from "react-loader-spinner";
import RichTextEditor from "react-rte"; // Copy Editor
import Form from "react-bootstrap/Form";

function PostOpNoteModal({ show, onClose }) {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue()); // Copy Editor

  let { id } = useParams();
  const user = getUser();
  const dispatch = useDispatch();
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);

  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);

  const handleSub = () => {
    onClose();
    setAddReportShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const handleAdd = () => {
    setLoading(true);
    const data = {
      user: user?._id,
      patient: id,
      note: note,
      type: "post Op",
    };
    console.log(data);
    addPatientClinicalNote(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(fetchPostOps({ patientId: id, type: "post Op" }));
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  {
    /* Copy Editor */
  }
  const onChange = (value) => {
    setValue(value);
    value.toString("html");
    setNote(value.toString("html"));
  };

  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                   PRE OPERATIVE PREPERATION
                    </div>
                    <div className="Solera-Settings-Note">
                     
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-8 FormSect">TO DO LIST</div>
                    <div className="col-lg-2 FormSect">TIME</div>
                    <div className="col-lg-2 FormSect">INITIAL</div>
                  </div>
                </div>{" "}
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-8 CheckSheet">
                      <div className="CheckSheet">
                        <Form.Check label="" />
                      </div>
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Time"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input "
                      />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      {" "}
                      <TextField
                        fullWidth
                        label="Initial"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-8 CheckSheet">
                      {" "}
                      <Form.Check label="Body wash" />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Time"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input "
                      />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      {" "}
                      <TextField
                        fullWidth
                        label="Initial"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-8 CheckSheet">
                      {" "}
                      <Form.Check label="Urine" />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Time"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input "
                      />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      {" "}
                      <TextField
                        fullWidth
                        label="Initial"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-8 CheckSheet">
                      {" "}
                      <Form.Check label="Oral Disinfection " />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Time"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input "
                      />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      {" "}
                      <TextField
                        fullWidth
                        label="Initial"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-8 CheckSheet">
                      {" "}
                      <Form.Check label="VITAL SIGN BP" />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Time"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input "
                      />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      {" "}
                      <TextField
                        fullWidth
                        label="Initial"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-8 CheckSheet">
                      {" "}
                      <Form.Check label="PULSE" />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Time"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input "
                      />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      {" "}
                      <TextField
                        fullWidth
                        label="Initial"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-8 CheckSheet">
                      {" "}
                      <Form.Check label="TEMPERATURE" />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Time"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input "
                      />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      {" "}
                      <TextField
                        fullWidth
                        label="Initial"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-8 CheckSheet">
                      {" "}
                      <Form.Check label="RESP RATE" />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Time"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input "
                      />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      {" "}
                      <TextField
                        fullWidth
                        label="Initial"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-8 CheckSheet">
                      {" "}
                      <Form.Check label="IVF ACCESS " />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Time"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input "
                      />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      {" "}
                      <TextField
                        fullWidth
                        label="Initial"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-8 CheckSheet">
                      {" "}
                      <Form.Check label="Pre-medication" />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Time"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input "
                      />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      {" "}
                      <TextField
                        fullWidth
                        label="Initial"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      <div className="FormSect">Remarks</div>
                      <br />
                      <RichTextEditor
                        className="editor"
                        value={value}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="FormUpload">
                  {loading ? (
                    <Bars
                      heigth="30"
                      width="30"
                      color="gray"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    <button className="Add-Button" onClick={handleAdd}>
                      Submit{" "}
                    </button>
                  )}
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default PostOpNoteModal;
