import { React, useState } from "react"
import { FiPlus } from "react-icons/fi";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import AddNurseModal from "./AddNurseModal";

function AddNurse() {
  const [addNurseShow, setAddNurseShow] = useState(false);
  const handleAddNurse = () => setAddNurseShow(false);
  return (
    <div>
      <button className="Add-Button" onClick={() => setAddNurseShow(true)}>
        <FiPlus className="Add-Icon"/>
        <span> Add a Nurse</span>
      </button>
      <AddNurseModal
        show={addNurseShow}
        onClose={handleAddNurse}
        onOpen={() => setAddNurseShow(true)}
      />
    </div>
  );
}

export default AddNurse;
