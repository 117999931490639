import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import { getUser } from "../../../../services/AuthService";
import { useDispatch } from "react-redux";
import { addPatientClinicalNote } from "../../../../services/PatientClinicalNoteService";
import { toast } from "react-toastify";
import { fetchPhysiotherapy } from "../../../../features/patients/physiotherapySlice";
import { Bars } from "react-loader-spinner";
import RichTextEditor from 'react-rte';  // Copy Editor 

function AddPhysiotherapyModal({ show, onClose }) {
  let { id } = useParams();

  const [value, setValue] = useState(RichTextEditor.createEmptyValue());// Copy Editor 


  const user = getUser();
  const dispatch = useDispatch()
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);

  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);

  const handleSub = () => {
    onClose();
    setAddReportShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const handleAdd = () => {
    setLoading(true);
    const data = {
      user: user?._id,
      patient: id,
      note: note,
      type: 'physiotherapy',
    };
    console.log(data);
    addPatientClinicalNote(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(fetchPhysiotherapy({patientId: id, type: 'physiotherapy'}))
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }


   {/* Copy Editor */}
   const onChange = (value) => {
    setValue(value);
    value.toString('html')
    setNote(value.toString('html'))
  };

  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                      Physiotherapy Note
                    </div>
                    <div className="Solera-Settings-Note">Add a Note</div>
                  </div>
                </div>
                <br />
                <br />
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Make a test note for this patient"
                        multiline
                        rows={15}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        // defaultValue="Default Value"
                      /> */}

                                                   {/* Copy Editor */}
                 <RichTextEditor 
                  className="editor"
                  value={value} onChange={onChange} 
                />
                    </div>
                  </div>
                </div>
                <div className="FormUpload">
                {
                  loading ? (
                    <Bars
                      heigth="50"
                      width="50"
                      color="gray"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    <button className="Add-Button" onClick={handleAdd}>
                      Add Note
                    </button>
                  ) 
                }
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default AddPhysiotherapyModal;
