import { FiPlus } from "react-icons/fi";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import { React, useState } from "react";
import AddPatientModal from "./AddPatientModal";
import { clearAuth, getSys } from "../../services/AuthService";

function AddPatient() {
  const [addPatientShow, setAddPatientShow] = useState(false);
  const handleAddPatient = () => setAddPatientShow(false);

  let sys = getSys();
  return (
    <div>


{sys?.role === "sys-admin" ||  sys?.role === "sys-nurse" ||
            sys?.role === "sys-matron"  ? (
                <button className="Add-Button" onClick={() => setAddPatientShow(true)}>
                <FiPlus className="Add-Icon" />
                <span> Register Patient</span>
              </button>
        
            ) : null}


     
      

      <AddPatientModal
        show={addPatientShow}
        onClose={handleAddPatient}
        onOpen={() => setAddPatientShow(true)}
      />
    </div>
  );
}

export default AddPatient;
