import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listNurses } from '../../services/NurseService'

const initialState = {
    loading: false,
    nurses: [],
    paginator: {},
    isSuccessful: false
}

export const fetchNurses = createAsyncThunk('fetchNurses', (initData) => {
    return listNurses(initData)
    .then((response) => response.data.data)
})


const nursesSlice = createSlice({
    name: 'nurses',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchNurses.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchNurses.fulfilled, (state, action ) => {
        state.loading = false
        state.nurses = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchNurses.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default nursesSlice.reducer 