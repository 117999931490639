import { React, useState } from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import AddMedicalModal from "./AddMedicalModal";

function MedInventory() {
          const [addReportShow, setAddReportShow] = useState(false);
          const handleAddReport = () => setAddReportShow(false);
  return (
          <div>
          <button
            className="Add-Patient-Report"
            onClick={() => setAddReportShow(true)}
          >
            Add Item
          </button>
          <AddMedicalModal
            show={addReportShow}
            onClose={handleAddReport}
            onOpen={() => setAddReportShow(true)}
          />
        </div>
  )
}

export default MedInventory