import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { patientLapsCount } from '../../../services/PatientLapsService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchConductedCount = createAsyncThunk('fetchConductedCount', (initData) => {
    return patientLapsCount(initData)
    .then((response) =>  response.data.data)
})


const conductedCountSlice = createSlice({
    name: 'conductedCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchConductedCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchConductedCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default conductedCountSlice.reducer 