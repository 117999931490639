import React, { useEffect, useState } from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import Pagination from "../../Component/Pagination";
import ReleasedList from "./ReleasedList";
import { useDispatch, useSelector } from "react-redux";
import { fetchReleaseConsumables } from "../../features/consumables/releaseConsumablesSlice";
import { Bars } from "react-loader-spinner";

function ReleaseItemsTable() {
  const dispatch = useDispatch()
  const {releaseConsumes, paginator, loading} = useSelector((state) => state.releaseConsumables)

  const [search2, setSearch2] = useState('');
  const [page, setPage] = useState(paginator?.currentPage || 1);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(paginator?.pageCount || 0);

  useEffect((  ) => {
    handleFetchReleaseConsumables()
  }, [])


  const handleFetchReleaseConsumables = (search = "", limit = 10, page = 1) => {
    dispatch(fetchReleaseConsumables({ name: search, type: 'consume', page_size: limit, page:page }))
  }

  const handleFetchUpdate = (search = search2, limit = pageLimit, page = 1) => {
    dispatch(fetchReleaseConsumables({ name: search, type: 'consume', page_size: limit, page:page }));
  }
  return (
    <div className="Patient-Table">
      <div className="Patient-Search">
        <input
          type="Search"
          className="form-control SearchesInput22"
          placeholder="Search for Items"
          aria-label="OTP"
          aria-describedby="basic-addon1"
        />
        <div className="Searchesbtn">Search</div>
      </div>
      <div>
        <div>
          {" "}
          <Pagination 
            handleFetchTransSearch={handleFetchUpdate} 
            page={page} 
            setPage={setPage} 
            pageCount={paginator?.pageCount || 0} 
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
          />
        </div>
        <br />
        <div>
        {loading ? (
            <div className="d-flex justify-content-center">
              <Bars
                heigth="30"
                width="30"
                color="gray"
                ariaLabel="loading-indicator"
              />
            </div>
        ) : null}
        {
          releaseConsumes?.map((item, index) => (
            <ReleasedList release={item} key={index} />
          ))
        }
        </div>
        <br />
        <div>
          <Pagination 
            handleFetchTransSearch={handleFetchUpdate} 
            page={page} 
            setPage={setPage} 
            pageCount={paginator?.pageCount || 0} 
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
          />
        </div>
      </div>
    </div>
  );
}

export default ReleaseItemsTable;
