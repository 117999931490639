import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listConsumables } from '../../services/ConsumableService'

const initialState = {
    loading: false,
    outNonConsumes: [],
    paginator: {},
    isSuccessful: false
}

export const fetchOutOfStockNonConsumables = createAsyncThunk('fetchOutOfStockNonConsumables', (initData) => {
    return listConsumables(initData)
    .then((response) => response.data.data)
})


const outOfStockNonConsumablesSlice = createSlice({
    name: 'outOfStockNonConsumables',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchOutOfStockNonConsumables.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchOutOfStockNonConsumables.fulfilled, (state, action ) => {
        state.loading = false
        state.outNonConsumes = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchOutOfStockNonConsumables.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default outOfStockNonConsumablesSlice.reducer 