import { React } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import "../../Component/Modals/Modal.css";

function ViewStaff({ show, onClose, selected }) {
  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">Staff Details</div>
                    <div>
                      <div className="FormSect-Note">
                        Staff Number : {selected?.cus_code?.toUpperCase()}
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div>
                  <div className="FormSect">Staff Details</div>

                  <div>
                    <div className="FormSect-Note">
                      Surname : {selected?.last_name}
                    </div>
                    <div className="FormSect-Note">
                      First Name: {selected?.first_name}
                    </div>
                    <div className="FormSect-Note">
                      Gender : {selected?.gender}
                    </div>
                    <div className="FormSect-Note">
                      Phone Number : {selected?.phone}
                    </div>
                    <div className="FormSect-Note">Role : {selected?.role}</div>
                    {/* <div className="FormSect-Note">Language : </div> */}
                  </div>
                </div>
                <br />
                {/* <br />
                <div>
                  <div className="FormSect">Contact Person</div>
                  <div className="FormSect-Note">Surname :</div>
                  <div className="FormSect-Note">Last Name:</div>
                  <div className="FormSect-Note">Occupation :</div>
                  <div className="FormSect-Note">Marital Status:</div>
                </div>
                <br />
                <br />
                <div>
                  <div className="FormSect">Note</div>
                  <div className="FormSect-Note">Note Display:</div>
                </div>{" "}
                <br />
                <br /> */}
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ViewStaff;
