import { React } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import "../../Component/Modals/Modal.css";
import moment from "moment";
import {  Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

function PatientImageDetails({ show, named, onClose, selected }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }
  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                      Patient Medical Details
                    </div>
                    <div>
                      <div className="FormSect-Note">Patient Number : {selected?.cus_code?.toUpperCase()}</div>
                      <div className="FormSect-Note">
                        Patient Admission Status : &nbsp;
                        <span>{selected?.status}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
            
                <div className="responsive">
                  {
                    named === 'ref-form' && selected?.referral_form?.length ? (
                        selected?.referral_form?.length ? (
                          selected?.referral_form_fileType === "application/pdf" ? (
                            <div>
                              <Viewer
                                  fileUrl={selected?.referral_form}
                                  plugins={[defaultLayoutPluginInstance]}
                              />
                            </div>
                            
                          ) : (
                            <div>
                              <img width={900} height={1000} src={selected?.referral_form} />
                            </div>
                          )
                          
                        ): null
                      
                    ): null
                  }
                  {
                    named === 'ref-letter' && selected?.referral_letter?.length ? (
                      selected?.referral_letter?.length ? (
                        selected?.referral_letter_fileType === "application/pdf" ? (
                          <div>
                            <Viewer
                                fileUrl={selected?.referral_letter}
                                plugins={[defaultLayoutPluginInstance]}
                            />
                          </div>
                          
                        ) : (
                          <div>
                            <img width={900} height={1000} src={selected?.referral_letter} /> 
                          </div>
                        )
                        
                      ): null
                    ): null
                  }
                  {
                    named === 'concent' && selected?.concent_form?.length ? (
                        selected?.concent_form?.length ? (
                          selected?.concent_form_fileType === "application/pdf" ? (
                            <div>
                              <Viewer
                                  fileUrl={selected?.concent_form}
                                  plugins={[defaultLayoutPluginInstance]}
                              />
                            </div>
                            
                          ) : (
                            <div>
                              <img width={900} height={1000} src={selected?.concent_form} />
                            </div>
                          )
                          
                        ): null
                    ): null
                  }
                  {
                    named === 'release-form' && selected?.release_form?.length ? (
                      selected?.release_form?.length ? (
                        selected?.release_form_fileType === "application/pdf" ? (
                          <div>
                            <Viewer
                                fileUrl={selected?.release_form}
                                plugins={[defaultLayoutPluginInstance]}
                            />
                          </div>
                          
                        ) : (
                          <div>
                            <img width={900} height={1000} src={selected?.release_form} />
                          </div>
                        )
                        
                      ): null
                    ): null
                  }
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default PatientImageDetails;
