import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { patientClinicalNoteCount } from '../../services/PatientClinicalNoteService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchPreOpCount = createAsyncThunk('fetchPreOpCount', (initData) => {
    return patientClinicalNoteCount(initData)
    .then((response) =>  response.data.data)
})


const preOpCountSlice = createSlice({
    name: 'preOpCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPreOpCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPreOpCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default preOpCountSlice.reducer 