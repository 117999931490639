import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { getUser } from "../../../../services/AuthService";
import { updatePatientClinicalNote } from "../../../../services/PatientClinicalNoteService";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import { fetchPreOps } from "../../../../features/patients/preOpSlice";
import RichTextEditor from "react-rte"; // Copy Editor
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

function ViewUploadMedModal({ show, onClose, selected }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [value, setValue] = useState(RichTextEditor.createEmptyValue()); // Copy Editor

  let { id } = useParams();
  const dispatch = useDispatch();
  const user = getUser();
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);

  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);

  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };

  {
    /* Copy Editor */
  }
  const onChange = (value) => {
    setValue(value);
    value.toString("html");
    setComment(value.toString("html"));
  };
  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera-Modal-Container">
                <br />
                <div className="Report-Display-Heading">Medication</div>
                <div className="Report-Tittle">Medication Upload Display</div>
                <div className="Solera-Modal-Container">
                  <div className="Note-Modal-Cont">
                    <div>
                      <div className="Doctors-Name">
                        Doctors's name : &nbsp;
                        <span>{selected?.user?.full_name}</span>
                      </div>
                      <div className="Report-Date">
                        Report Date: &nbsp;
                        <span>{showDate(selected?.created_at)}</span>
                      </div>
                      {/* <div className="Report-Date">
                      Report Number: &nbsp;<span>{selected?.code}</span>
                    </div> */}
                    </div>
                    <br />
                  </div>
                </div>
                <br /> <br /> <br />
                {selected?.url?.length ? (
                  selected?.fileType === "application/pdf" ? (
                    <div className="pdf-div">
                      <Viewer
                        fileUrl={selected?.url}
                        plugins={[defaultLayoutPluginInstance]}
                      />
                    </div>
                  ) : (
                    <div>
                      <img width={900} height={1000} src={selected?.url} />
                    </div>
                  )
                ) : null}
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ViewUploadMedModal;
