import React, { useState, useEffect } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";

import { CiMemoPad } from "react-icons/ci";
import Anasethetic from "./Anasethetic";
import SurgeonNote from "./SurgeonNote";
import Physiotherapy from "../ClinicalNote/Physiotherapy";
import PostOpNote from "../ClinicalNote/PostOpNote";
import PreOpNote from "../ClinicalNote/PreOpNote";
import PostOpVitals from "../ClinicalNote/PostOpVitals";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSys } from "../../../../services/AuthService";
import moment from "moment";
import { Bars } from "react-loader-spinner";
import { fetchPatientSurgeon } from "../../../../features/patients/patientSurgeonSlice";
import DieticianNote from "./DieticianNote";
import DentistNote from "./DentistNote";

function Notes() {
  const sys = getSys();
  let { id } = useParams();
  const dispatch = useDispatch();

  const [active, setActive] = useState("SurgeonNote");

  return (
    <div>
      <div className="ReportHeader">
        <div className="Report-Heading">Theatre/Note</div>

        <div className="DocumemtBtn">
          {sys?.role === "sys-admin" || sys?.role === "sys-doctor" ? (
            <div
              className={
                active === "SurgeonNote" ? "ActiveIconX" : "InactiveIcon"
              }
              onClick={() => setActive("SurgeonNote")}
            >
              <CiMemoPad className="Solera-Icons" />
              <div> Surgeon Note</div>
            </div>
          ) : null}
          {sys?.role === "sys-admin" || sys?.role === "sys-anaesthetist" || sys?.role === "sys-doctor" ? (
            <div
              className={
                active === "Anasethetic" ? "ActiveIconX" : "InactiveIcon"
              }
              onClick={() => setActive("Anasethetic")}
            >
              <CiMemoPad className="Solera-Icons" />
              <div> Pre-Anaesthetic consultation</div>
            </div>
          ) : null}
          {sys?.role === "sys-admin" ||
          // sys?.role === "sys-doctor" ||
          sys?.role === "sys-dietician" ? (
            <div
              className={
                active === "DieticianNote" ? "ActiveIconX" : "InactiveIcon"
              }
              onClick={() => setActive("DieticianNote")}
            >
              <CiMemoPad className="Solera-Icons" />
              <div>Dietician Note </div>
            </div>
          ) : null}
          {/* {sys?.role === "sys-admin" ||
          sys?.role === "sys-doctor" ||
          sys?.role === "sys-dentist" ? (
            <div
              className={
                active === "DentistNote" ? "ActiveIconX" : "InactiveIcon"
              }
              onClick={() => setActive("DentistNote")}
            >
              <CiMemoPad className="Solera-Icons" />
              <div>Dentist Note </div>
            </div>
          ) : null} */}
        </div>
      </div>

      <div>
        <div>
          <div>{active === "Anasethetic" && <Anasethetic />}</div>
          {active === "SurgeonNote" && <SurgeonNote />}
          {active === "DieticianNote" && <DieticianNote />}
          {active === "DentistNote" && <DentistNote />}
        </div>
      </div>
    </div>
  );
}

export default Notes;
