import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listDrugs } from '../../services/DrugService'

const initialState = {
    loading: false,
    expiredDrugs: [],
    paginator: {},
    isSuccessful: false
}

export const fetchExpiredDrugs = createAsyncThunk('fetchExpiredDrugs', (initData) => {
    return listDrugs(initData)
    .then((response) => response.data.data)
})


const expiredDrugsSlice = createSlice({
    name: 'expiredDrugs',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchExpiredDrugs.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchExpiredDrugs.fulfilled, (state, action ) => {
        state.loading = false
        state.expiredDrugs = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchExpiredDrugs.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default expiredDrugsSlice.reducer 