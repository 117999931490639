import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import { addPatientSocialMedication } from "../../../../services/PatientSocialMedicationService";
import { fetchSocialMedications } from "../../../../features/patients/socials/socialMedicationSlice";
import { useParams } from "react-router-dom";
import { getUser } from "../../../../services/AuthService";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";

import RichTextEditor from "react-rte"; // Copy Editor

function MedicationModal({ show, onClose }) {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue()); // Copy Editor
  const [value2, setValue2] = useState(RichTextEditor.createEmptyValue()); // Copy Editor

  let { id } = useParams();
  const user = getUser();
  const dispatch = useDispatch();
  const [sideEffect, setSideEffect] = useState("");
  const [concerns, setConcerns] = useState("");

  const [loading, setLoading] = useState(false);

  const [addDoctorShow, setAddDoctorShow] = useState(false);
  const handleAddDoctor = () => setAddDoctorShow(false);

  const handleSaveDoctor = () => {
    onClose();
    setAddDoctorShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const handleAdd = () => {
    setLoading(true);
    const data = {
      user: user?._id,
      patient: id,
      side_effect: sideEffect,
      concerns: concerns,
    };
    console.log(data);
    addPatientSocialMedication(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(fetchSocialMedications({ patientId: id }));
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  {
    /* Copy Editor */
  }
  const onChange = (value) => {
    setValue(value);
    value.toString("html");
    setSideEffect(value.toString("html"));
  };

  {
    /* Copy Editor */
  }
  const onChange2 = (value) => {
    setValue2(value);
    value.toString("html");
    setConcerns(value.toString("html"));
  };

  return (
    <div>
      {" "}
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Solera__ModalHeadings">
              <MdClose className="Solera__Close" onClick={onClose} />
            </div>

            <div className="Solera__ModalHeadings"></div>
            <div className="Solera-Modal-Container">
              <div className="Solera__Headings">
                <div className="Log__Cont">
                  <img
                    src="/assets/lg.png"
                    alt=""
                    className="Log__Img__Modal"
                  />
                </div>
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">
                    Patient Medications
                  </div>
                  {/* <div className="Solera-Settings-Note">
                                            Kindly add a doctor for your records
                                          </div> */}
                </div>
              </div>
              <br />
              <br />
              <div className="FormSect-Contianer">
                <div className="FormSect">Side Effect</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>

                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                                fullWidth
                                id="outlined-multiline-static "
                                label="Side Effect"
                                multiline
                                rows={5}
                                value={sideEffect}
                                onChange={(e) => setSideEffect(e.target.value)}
                                // defaultValue="Default Value"
                              /> */}
                      <RichTextEditor
                        className="editor"
                        value={value}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Concerns </div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                                fullWidth
                                id="outlined-multiline-static "
                                label="Concerns"
                                multiline
                                rows={5}
                                value={concerns}
                                onChange={(e) => setConcerns(e.target.value)}
                                // defaultValue="Default Value"
                              /> */}
                      <RichTextEditor
                        className="editor"
                        value={value2}
                        onChange={onChange2}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {loading ? (
                <Bars
                  heigth="50"
                  width="50"
                  color="gray"
                  ariaLabel="loading-indicator"
                />
              ) : (
                <button className="Add-Button" onClick={handleAdd}>
                  Add Medications
                </button>
              )}
              <br /> <br /> <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default MedicationModal;
