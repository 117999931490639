import React from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";


function NumberOfAdmitted({num, loading}) {
  return (
          <div className="Count-Result">{loading ? "loading..." : num }</div>
  )
}

export default NumberOfAdmitted