import { React, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import PostOpNoteModal from "./PostOpNoteModal";
import DSNoteModal from "./DSNoteModal";

function AddDs() {
  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);
  return (
    <div>
      <button
        className="Add-Patient-Report"
        onClick={() => setAddReportShow(true)}
      >
        Add Discharge Summary
      </button>
      <div>
        <DSNoteModal
          show={addReportShow}
          onClose={handleAddReport}
          onOpen={() => setAddReportShow(true)}
        />
      </div>
    </div>
  );
}

export default AddDs;

