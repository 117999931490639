import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientRecord } from '../../services/PatientRecordService'

const initialState = {
    loading: false,
    records: [],
    paginator: {},
    isSuccessful: false
}

export const fetchPatientRecords = createAsyncThunk('fetchPatientRecords', (initData) => {
    return listPatientRecord(initData)
    .then((response) => response.data.data)
})


const patientsRecordsSlice = createSlice({
    name: 'patientRecords',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientRecords.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatientRecords.fulfilled, (state, action ) => {
        state.loading = false
        state.records = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientRecords.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientsRecordsSlice.reducer 