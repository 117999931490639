import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getPatient } from '../../services/PatientService'

const initialState = {
    loading: false,
    record: {},
    isSuccessful: false
}

export const fetchPatientRecord = createAsyncThunk('fetchPatientRecord', (initData) => {
    return getPatient(initData)
    .then((response) => response.data)
})


const patientRecordSlice = createSlice({
    name: 'patientRecord',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientRecord.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchPatientRecord.fulfilled, (state, action ) => {
        state.loading = false
        state.record = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientRecord.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientRecordSlice.reducer 