import axios from '../axiosinstance';

      
export function listPatientSocialMedication(initData){
        return axios.get('/patient-social-medications', { params: { name: initData?.search, patient: initData?.patientId } })
}

export function addPatientSocialMedication(data){
        return axios.post('/patient-social-medications', data)
}

export function getPatientSocialMedication(initData){
        return axios.get('/patient-social-medications/'+initData?.id)
}

export function updatePatientSocialMedication(initData){
        return axios.patch('/patient-social-medications/'+initData.id, initData)
}

export function patientSocialMedicationCount(search){
        return axios.get('/patient-social-medications/stat/count', { params: search })
}

