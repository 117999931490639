import React from 'react'
import "../SoleraCss/Responsive.css";
import "../SoleraCss/Solera.css";

function SampleNumber({code}) {
  return (
    <div>{code}</div>
  )
}

export default SampleNumber