import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientDrugs } from '../../services/PatientDrugsService'

const initialState = {
    loading: false,
    listDrugs: [],
    paginator: {},
    isSuccessful: false
}

export const fetchPatientDrugs = createAsyncThunk('fetchPatientDrugs', (initData) => {
    return listPatientDrugs(initData)
    .then((response) => response.data)
})


const patientDrugsSlice = createSlice({
    name: 'patientDrugs',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientDrugs.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatientDrugs.fulfilled, (state, action ) => {
        state.loading = false
        state.listDrugs = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientDrugs.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientDrugsSlice.reducer 