import axios from '../axiosinstance';

      
export function listPatientDischargeSummaries(initData){
        return axios.get('/patient-discharge-summaries', { params: { name: initData?.search, patient: initData?.patientId } })
}

export function addPatientDischargeSummaries(data){
        return axios.post('/patient-discharge-summaries', data)
}

export function getPatientDischargeSummaries(initData){
        return axios.get('/patient-discharge-summaries/'+initData?.id)
}

export function updatePatientDischargeSummariest(initData){
        return axios.patch('/patient-discharge-summaries/'+initData.id, initData)
}

export function patientDischargeSummariesCount(search){
        return axios.get('/patient-discharge-summaries/stat/count', { params: search })
}

