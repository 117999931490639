import { React, useState } from "react";
// import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
// import "../../../../SoleraCss/Responsive.css";
// import "../../../../SoleraCss/Solera.css";
// import "../../../../Component/Modals/Modal.css";
// import TextField from "@mui/material/TextField";
// import { useParams } from "react-router-dom";
// import { getUser } from "../../../../services/AuthService";
// import { useDispatch } from "react-redux";
// import { addPatientClinicalNote } from "../../../../services/PatientClinicalNoteService";
// import { toast } from "react-toastify";
// import { fetchPostOps } from "../../../../features/patients/postOpSlice";
import { Bars } from "react-loader-spinner";
import Form from "react-bootstrap/Form";
import RichTextEditor from "react-rte"; // Copy Editor
import Swal from "sweetalert2";

function ViewUploadedSheet({ show, onClose }) {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue()); // Copy Editor

  //   let { id } = useParams();
  //   const user = getUser();
  //   const dispatch = useDispatch();
  //   const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);

  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);

  const sex = [
    {
      value: "Gender",
      label: "Gender",
    },
    {
      value: "Male",
      label: "Male",
    },
    {
      value: "Female",
      label: "Female",
    },
  ];
  const status = [
    {
      value: "Single",
      label: "Single",
    },
    {
      value: "Married",
      label: "Married",
    },
  ];

  const language = [
    {
      value: "PG",
      label: "PG",
    },
    {
      value: "HA",
      label: "HA",
    },
    {
      value: "IG",
      label: "IG",
    },
    {
      value: "YO",
      label: "YO",
    },
    {
      value: "FUL",
      label: "FUL",
    },
    {
      value: "OTHERS",
      label: "OTHERS",
    },
  ];

  const handleSub = () => {
    onClose();
    setAddReportShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  //   const handleSub = () => {
  //     onClose();
  //     setAddReportShow(false);

  //     Swal.fire({
  //       customClass: {
  //         title: "title-class",
  //         icon: "icon-class",
  //       },

  //       icon: "success",
  //       title: "Successful",
  //       showConfirmButton: false,
  //       timer: 2000,
  //     });
  //   };

  //   const handleAdd = () => {
  //     setLoading(true);
  //     const data = {
  //       user: user?._id,
  //       patient: id,
  //       note: note,
  //       type: 'post Op',
  //     };
  //     console.log(data);
  //     addPatientClinicalNote(data)
  //       .then((response) => {
  //         setLoading(false);
  //         if (response.data.success) {
  //           //closeRef.current.click()
  //           toast.success(
  //             response.data.message
  //               ? response.data.message
  //               : "Successfully Created"
  //           );
  //           onClose();
  //           dispatch(fetchPostOps({patientId: id, type: 'post Op'}))
  //           Swal.fire({
  //             customClass: {
  //               title: "title-class",
  //               icon: "icon-class",
  //             },

  //             icon: "success",
  //             title: "Successful",
  //             showConfirmButton: false,
  //             timer: 2000,
  //           });

  //         }
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //         console.log(err);
  //       });
  //   }

  {
    /* Copy Editor */
  }
  const onChange = (value) => {
    setValue(value);
    value.toString("html");
    // setPresentingConcern(value.toString("html"));
  };

  return (
    <div>
      <div>
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container30">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                      Uploaded Intraoperative Sheet
                    </div>
                    <div className="Solera-Settings-Note">
                      Intraoperative Sheet
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div>
                  <div className="FormSect">Details</div>
                  <div className="Solera-Settings-Note">
                     Uploaded by : Name Display
                    </div>
                    <div className="Solera-Settings-Note">
                     Date : Date Display 
                    </div>
                </div>
                <br />
                <br />
                <div>
                    Display Upload
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ViewUploadedSheet;
