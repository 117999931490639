import { React, useEffect, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import Swal from "sweetalert2";
import Dropdown from "react-bootstrap/Dropdown";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPhysiotherapy } from "../../../../features/patients/physiotherapySlice";
import moment from "moment";
import { Bars } from "react-loader-spinner";
import ViewSurgicalChecklist from "./ViewSurgicalChecklist";
import AddSurgicalCheckList from "./AddSurgicalCheckList";
import { fetchPatientsSurgicalSafety } from "../../../../features/patients/patientsSurgicalSafetySlice";
import UploadDoc from "./UploadDoc";
import ViewUploadModal from "./ViewUploadModal";
import UpdateCheckList from "./UpdateCheckList";




function SurgicalSafety() {
  let { id } = useParams();
  const dispatch = useDispatch()
  const {safeties, loading, paginator} = useSelector((state) => state.patientsSurgicalSafety)

  const [selected, setSelected] = useState({});

  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);

  const [uploadSurgicalShow, setUploadSurgicalShow] =
    useState(false);
  const handleUploadSurgicalDetails = () => setUploadSurgicalShow(false);

  const [viewUploadShow, setViewUploadShow] =
    useState(false);
  const handleViewUpload = () => setViewUploadShow(false);

  const [editPreOpNoteDetailsShow, setEditPreOpNoteDetailsShow] =
    useState(false);
  const handleEditPreOpNoteDetails = () => setEditPreOpNoteDetailsShow(false);

  console.log("paginator", paginator)
  useEffect(() => {
    if (id) {
      dispatch(fetchPatientsSurgicalSafety({patient: id}))
    }
  }, [id]);

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }

  return (
    <div>
      <div className="Report-Notes">
        <div className="ReportHeader">
          <div className="Report-Heading">Surgical Safety Checklist</div>
          <div className="Report-Count">
            <span>{loading ? "Loading..." : paginator?.itemCount || 0}</span>&nbsp;Surgical Safety Checklist
          </div>
          <AddSurgicalCheckList />
        </div>
      </div>
      <br />
      {loading ? (
            <div className="d-flex justify-content-center">
              <Bars
                heigth="30"
                width="30"
                color="gray"
                ariaLabel="loading-indicator"
              />
            </div>
        ) : null
      }
      {
        safeties?.map((item, index) => (
          <div key={index} className="Patient-Record-Container">
            <div className="Patient-Record-Wrapper">
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Note Number</div>
                <div className="Wallet-Ref-Result">{item?.code}</div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Doctor's Name</div>
                <div className="Wallet-Ref-Result">{item?.user?.full_name}</div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Date</div>
                <div className="Wallet-Ref-Result">{showDate(item?.created_at)}</div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Status</div>
                <div className="Wallet-Ref-Result">{item?.status}</div>
              </div>
              <div className="Action-Drop-Down">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
                    Manage surgical checklist
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {setSelected(item); setViewPreOpNoteDetailsShow(true)}}
                      className="Solera-Patients"
                    >
                      View surgical checklist note
                    </Dropdown.Item>
                   

                    <Dropdown.Item
                      onClick={() => {setSelected(item); setEditPreOpNoteDetailsShow(true)}}
                      className="Solera-Patients"
                    >
                     Update Check List
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {setSelected(item); setUploadSurgicalShow(true)}}
                      className="Solera-Patients"
                    >
                      Upload surgical checklist
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {setSelected(item); setViewUploadShow(true)}}
                      className="Solera-Patients"
                    >
                      View uploaded surgical checklist
                    </Dropdown.Item>
                   
                    {/* <Dropdown.Item
                      // onClick={() => setPatientDetailsShow(true)}
                      className="Solera-Patients"
                    >
                      Delete Physiotherapy Note
                    </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        ))
      
      }
      

      <div>
        <ViewSurgicalChecklist
          selected={selected}
          show={viewPreOpNoteDetailsShow}
          onClose={handleViewPreOpNoteDetails}
          onOpen={() => setViewPreOpNoteDetailsShow(true)}
        />

        <UploadDoc 
          named={'Surgical Safety'}
          selected={selected}
          show={uploadSurgicalShow}
          onClose={handleUploadSurgicalDetails}
          onOpen={() => setUploadSurgicalShow(true)}
        />

        <ViewUploadModal 
          selected={selected}
          show={viewUploadShow}
          onClose={handleViewUpload}
          onOpen={() => setViewUploadShow(true)}
        />  

        <UpdateCheckList
          show={editPreOpNoteDetailsShow}
          onClose={handleEditPreOpNoteDetails}
          onOpen={() => setEditPreOpNoteDetailsShow(true)}
        />
      </div>
    </div>
  );
}

export default SurgicalSafety;

