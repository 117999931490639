import { React, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import "../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";


import RichTextEditor from "react-rte"; // Copy Editor
import { useDispatch } from "react-redux";
import { getUser } from "../../services/AuthService";
import { Bars } from "react-loader-spinner";
import { updateNurseReport } from "../../services/NurseReportService";
import { toast } from "react-toastify";
import { fetchNurseReports } from "../../features/nurses/nursesReportsSlice";
import { fetchNurseReportsCount } from "../../features/nurses/nurseReportsCountSlice";

function UpdateNurseReport({ show, onClose, onOpen, selected }) {
  const dispatch = useDispatch()
  const user = getUser();
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState('');
  const [errSubject, setErrSubject] = useState("");
  const [note, setNote] = useState("");

 const [value, setValue] = useState(RichTextEditor.createEmptyValue()); // Copy Editor

  const [addNurseShow, setAddNurseShow] = useState(false);
  const handleAddNurse = () => setAddNurseShow(false);

  const handleSaveNurse = () => {
    onClose();
    setAddNurseShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  useEffect(() => {
    if(selected?._id){
      setSubject(selected?.subject);
      //setValue(selected?.note);
    }
  }, [show])


  const onChange = (value) => {
    setValue(value);
    value.toString("html");
//     setNote(value.toString("html"));
  };

  const handleUpdate = () => {
    if (!subject) {
      setErrSubject("Subbject is required");
      return;
    }
   

    setLoading(true);
    const data = {
      id: selected?._id,
      user: user?._id,
      subject: subject,
      note: note || selected?.note,
    };
    console.log(data);
    updateNurseReport(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Updated"
          );
          onClose();
          dispatch(fetchNurseReports({}))
          dispatch(fetchNurseReportsCount({}))
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }
  return (
    <div>
      {" "}
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Solera__ModalHeadings">
              <MdClose className="Solera__Close" onClick={onClose} />
            </div>

            <div className="Solera__ModalHeadings"></div>
            <div className="Solera-Modal-Container">
              <div className="Solera__Headings">
                <div className="Log__Cont">
                  <img
                    src="/assets/lg.png"
                    alt=""
                    className="Log__Img__Modal"
                  />
                </div>
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">Update Nurse's Report</div>
                  <div className="Solera-Settings-Note">
                    Kindly update your report here
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="FormSect-Contianer">
                <div className="FormSect">Subject</div>
                <div className="FormSect-Note">
                  Enter what your report is all about in not less than 50 letters
                </div>

                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Subject"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={subject}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setErrSubject("")
                          }else{
                            setErrSubject("Subject is required")
                          }
                          setSubject(event.target.value)
                        }}
                      />
                      {(errSubject) && <div className='input_error'>{errSubject}</div>}  
                    </div>
                  
                  </div>
                </div>

               
              </div>
             
              <div className="FormSect-Contianer">
                <div className="FormSect">Report</div>
                <div className="FormSect-Note">
                  Enter your report in details
                </div>

                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <div>
                      <RichTextEditor
                        className="editor"
                        value={value}
                        onChange={onChange}
                      />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                loading ? (
                  <Bars
                    heigth="30"
                    width="30"
                    color="gray"
                    ariaLabel="loading-indicator"
                  />
                ) : (
                  <button className="Add-Button" onClick={handleUpdate}>
                    Submit
                  </button>
                ) 
              }
              <br /> <br /> <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}





export default UpdateNurseReport