import { React, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { styled } from '@mui/material/styles';
import { MdAddCircle, MdClose, MdRemoveCircle, CloudUploadIcon } from "react-icons/md";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import "../../Component/Modals/Modal.css";
import Button from '@mui/material/Button';
import { Bars } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { getUser } from "../../services/AuthService";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { updatePatientPic } from "../../services/PatientService";
import { fetchPatients } from "../../features/patients/patientsSlice";
import { fetchPatientCount } from "../../features/patients/patientCountSlice";


function UpdatePatientConcentFormModal({ show, onClose, onOpen, selected, named }) {
  let { id } = useParams();
  const user = getUser();
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState('')
  const [preview, setPreview] = useState('')
  const [viewXrayReportShow, setViewXrayReportShow] = useState(false);
  const handleViewXrayReport = () => setViewXrayReportShow(false);

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  useEffect(() => {
    if (!selectedFile) {
        setPreview(undefined)
        return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
}, [selectedFile])

const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
        setSelectedFile(undefined)
        return
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0])
}

const handleAdd = () => {
  setLoading(true);
  let formData = new FormData(); 

  formData.append('photo', selectedFile);
  formData.append('concentForm', true);

  updatePatientPic(selected?._id, formData)
    .then((response) => {
      setLoading(false);
      if (response.data.success) {
        //closeRef.current.click()
        toast.success(
          response.data.message
            ? response.data.message
            : "Successfully Uploaded"
        );
        onClose();
        dispatch(fetchPatients({}))
        dispatch(fetchPatientCount({}))
        Swal.fire({
          customClass: {
            title: "title-class",
            icon: "icon-class",
          },

          icon: "success",
          title: "Successful",
          showConfirmButton: false,
          timer: 2000,
        });
      
      }
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
    });
}


  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                      {/* {named}  */}
                       Consent form 
                    </div>
                    <div className="Solera-Settings-Note">
                      <Button
                        className="Add-Patient-Report"
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        id={"finput"}
                        onChange={onSelectFile}
                      >
                        Upload file
                        <VisuallyHiddenInput type="file" />
                      </Button>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    {selectedFile &&  <img width={250} height={200} src={preview} /> }
                  </div>
                </div>
                <br /> <br /> 
                {
                  loading ? (
                    <Bars
                      heigth="50"
                      width="50"
                      color="gray"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    <button className="Add-Button" onClick={handleAdd}>
                      Update Consent form
                    </button>
                  ) 
                }
                <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default UpdatePatientConcentFormModal;
