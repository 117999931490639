import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientTheatre } from '../../../services/PatientTheatreService'

const initialState = {
    loading: false,
    dentists: [],
    isSuccessful: false
}

export const fetchPatientDentist = createAsyncThunk('fetchPatientDentist', (initData) => {
    return listPatientTheatre(initData)
    .then((response) => response.data.data)
})


const patientDentistSlice = createSlice({
    name: 'patientDentist',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientDentist.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchPatientDentist.fulfilled, (state, action ) => {
        state.loading = false
        state.dentists = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientDentist.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientDentistSlice.reducer 