import { React, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import "../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { getUser } from "../../services/AuthService";
import { updateConsumables } from "../../services/ConsumableService";
import { toast } from "react-toastify";
import { fetchConsumables } from "../../features/consumables/consumablesSlice";
import { Bars } from "react-loader-spinner";

function EditMed({ show, onClose, onOpen, selected }) {
  const dispatch = useDispatch()
  const user = getUser();
  const [loading, setLoading] = useState(false);


  const [name, setName] = useState('');
  const [errName, setErrName] = useState("");
  const [supplierName, setSupplierName] = useState('');
  const [errSupplierName, setErrSupplierName] = useState("");
  const [supplierPhone, setSupplierPhone] = useState('');
  const [errSupplierPhone, setErrSupplierPhone] = useState("");
  const [qty, setQty] = useState('');
  const [errQty, setErrQty] = useState("");
  const [expiryDate, setExpiryDate] = useState("");

  const [editDrugsShow, setEditDrugsShow] = useState(false);
  const handleEditDrugs = () => setEditDrugsShow(false);

  const handleEdit = () => {
    onClose();
    setEditDrugsShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  useEffect(() => {
    if(show){
      setName(selected?.name)
      setQty(selected?.qty)
      setExpiryDate(selected?.expiry_date)
      setSupplierName(selected?.supplier_name)
      setSupplierPhone(selected?.supplier_phone)
    }
  }, [show])

  const handleUpdate = () => {
    if (!name) {
      setName("Name is required");
      return;
    }

    setLoading(true);
    const data = {
      id: selected?._id,
      user: user?._id,
      name: name,
      supplier_name: supplierName,
      supplier_phone: supplierPhone,
      expiry_date: expiryDate,
      qty: qty,
    };
    console.log(data);
    updateConsumables(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(fetchConsumables({type: 'consume'}))
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  return (
    <div>
      {" "}
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Solera__ModalHeadings">
              <MdClose className="Solera__Close" onClick={onClose} />
            </div>

            <div className="Solera__ModalHeadings"></div>
            <div className="Solera-Modal-Container">
              <div className="Solera__Headings">
                <div className="Log__Cont">
                  <img
                    src="/assets/lg.png"
                    alt=""
                    className="Log__Img__Modal"
                  />
                </div>
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">
                    Medical Consumables
                  </div>
                  <div className="Solera-Settings-Note">
                    Kindly Medical Consumables to your inventory
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="FormSect-Contianer">
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Item Name"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={name}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setErrName("")
                          }else{
                            setErrName("Name is required")
                          }
                          setName(event.target.value)
                        }}
                      />
                      {(errName) && <div className='input_error'>{errName}</div>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid p-0 FormInput">
                <div className="row gx-4">
                  <div className="col-lg">
                    <TextField
                      fullWidth
                      label="Quantity"
                      id="fullWidth"
                      type="number"
                      className="Solera-Login-Input mb-3"
                      value={qty}
                      onChange={(event) => {
                        if(event.target.value.length){
                          setErrQty("")
                        }else{
                          setErrQty("Name is required")
                        }
                        setQty(event.target.value)
                      }}
                    />
                    {(errQty) && <div className='input_error'>{errQty}</div>}
                  </div>
                </div>
              </div>
              <div className="container-fluid p-0 FormInput">
                <div className="row gx-4">
                  <div className="col-lg">
                    <TextField
                      fullWidth
                      label="Supplier's Name"
                      id="fullWidth"
                      type="text"
                      className="Solera-Login-Input mb-3"
                      value={supplierName}
                      onChange={(event) => {
                        setSupplierName(event.target.value)
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="container-fluid p-0 FormInput">
                <div className="row gx-4">
                  <div className="col-lg">
                    <TextField
                      fullWidth
                      label="Supplier's Phone Number"
                      id="fullWidth"
                      type="number"
                      className="Solera-Login-Input mb-3"
                      value={supplierPhone}
                      onChange={(event) => {
                        setSupplierPhone(event.target.value)
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Expiry Date</div>

                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label=""
                        id="fullWidth"
                        type="date"
                        className="Solera-Login-Input mb-3"
                        selected={expiryDate} 
                        onChange={async(date) => {
                          await setExpiryDate(date.target.value)
                        }} 
                      />
                    </div>
                  </div>
                </div>
              </div>
              {
                loading ? (
                  <Bars
                    heigth="30"
                    width="30"
                    color="gray"
                    ariaLabel="loading-indicator"
                  />
                ) : (
                  <button className="Add-Button" onClick={handleUpdate}>
                    Update
                  </button>
                ) 
              }
              {/* <button className="Add-Button" onClick={handleEdit}>
                Update
              </button> */}
              <br /> <br /> <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default EditMed;
