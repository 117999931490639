import axios from '../axiosinstance';

      

//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

export function listUsers(initData){
        return axios.get('/users', { params: initData })
}

export function addUser(data){
        return axios.post('/users', data)
}

export function getUser(initData){
        return axios.get('/users/'+initData?.id)
}

export function updateUser(initData){
        return axios.patch('/users/'+initData.id, initData)
}

export function updateSysUser(initData){
        return axios.patch('/users/'+initData.id+'/sys-user', initData)
}

export function userCount(search){
        return axios.get('/users/stat/count', { params: { name: search } })
}

export function sysUserCount(search){
        return axios.get('/users/sys/stat/count', { params: search })
}

