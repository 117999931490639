import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import { MdAddCircle, MdClose, MdRemoveCircle } from "react-icons/md";
import { useParams } from "react-router-dom";
import { getUser } from "../../../../services/AuthService";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import { fetchPatientUltrasounds } from "../../../../features/patients/docs/patientUltrasoundSlice";
import { fetchPatientXrays } from "../../../../features/patients/docs/patientXraysSlice";
import { fetchPatientCTScans } from "../../../../features/patients/docs/patientCTScansSlice";
import { fetchPatientMRIs } from "../../../../features/patients/docs/patientMRIsSlice";
import { addPatientScanDoc } from "../../../../services/PatientScanDocService";
import { fetchMRICount } from "../../../../features/patients/docs/mRICountSlice";
import { fetchCTScanCount } from "../../../../features/patients/docs/cTScanCountSlice";
import { fetchXrayCount } from "../../../../features/patients/docs/xRayCountSlice";
import { fetchUltransoundCount } from "../../../../features/patients/docs/ultransoundCountSlice";
import RichTextEditor from 'react-rte';  // Copy Editor 


function DocScanRequestModal({ show, onClose, selected }) {

  const [value, setValue] = useState(RichTextEditor.createEmptyValue());// Copy Editor 


  let { id } = useParams();
  const user = getUser();
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');

  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);

  const handleAdd = () => {
    setLoading(true);

    const body = {
      user: user?._id,
      patient: id,
      type: selected,
      name: name,
    }
    
    addPatientScanDoc(body)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          setName('');
          if(selected === 'MRI'){
            dispatch(fetchPatientMRIs({patient: id, type: 'MRI'}))
            dispatch(fetchMRICount({patient: id, type: 'MRI'}))
          }
          if(selected === 'CTScan'){
            dispatch(fetchPatientCTScans({patient: id, type: "CTScan"}))
            dispatch(fetchCTScanCount({patient: id, type: 'CTScan'}))            
          }
          if(selected === 'x-ray'){
            dispatch(fetchPatientXrays({patient: id, type: 'x-ray'}))
            dispatch(fetchXrayCount({patient: id, type: 'x-ray'}))
          }
          if(selected === 'ultrasound'){
            dispatch(fetchPatientUltrasounds({patient: id, type: 'ultrasound'}))
            dispatch(fetchUltransoundCount({patient: id, type: 'ultrasound'}))
          }
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },
  
            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }
 {/* Copy Editor */}
 const onChange = (value) => {
  setValue(value);
  value.toString('html')
  setName(value.toString('html'))
};
  return (
    <div>
      {" "}
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Solera__ModalHeadings">
              <MdClose className="Solera__Close" onClick={onClose} />
            </div>

            <div className="Solera__ModalHeadings"></div>
            <div className="Solera-Modal-Container">
              <div className="Solera__Headings">
                <div className="Log__Cont">
                  <img
                    src="/assets/lg.png"
                    alt=""
                    className="Log__Img__Modal"
                  />
                </div>
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">
                    Doctor's {selected} request
                  </div>
                  {/* <div className="Solera-Settings-Note">
                    Kindly add a doctor for your records
                  </div> */}
                </div>
              </div>
              <br />
              <br />
              <div className="FormSect">Doctor's {selected} request</div>
              <div className="FormSect-Note">
                Please make your scan request below
              </div>
              <br />
              {/* <TextField
                fullWidth
                id="outlined-multiline-static "
                label="Request Note"
                multiline
                rows={5}
                value={name}
                onChange={(e) => setName(e.target.value)}
                // defaultValue="Default Value"
              /> */}

                <RichTextEditor 
                  className="editor"
                  value={value} onChange={onChange} 
                />
              <br />
              <br />
              {
                  loading ? (
                    <Bars
                      heigth="50"
                      width="50"
                      color="gray"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    <button disabled={!name} className="Add-Button" onClick={handleAdd}>
                      Submit
                    </button>
                  ) 
                }
              {/* <button className="Add-Button" onClick={handleSaveDoctor}>
                Submit Instructions to display the box below
              </button> */}
              <br /> <br />
              <br /> <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default DocScanRequestModal;
