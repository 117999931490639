import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addPatientVitals } from "../../../../services/PatientVitalsService";
import { toast } from "react-toastify";
import { fetchPatientVitals } from "../../../../features/patients/patientVitalsSlice";
import { getUser } from "../../../../services/AuthService";
import { Bars } from "react-loader-spinner";
import moment from "moment";

function VitalsModal({ show, onClose }) {
  let { id } = useParams();
  const user = getUser();
  const dispatch = useDispatch()
  const [addDoctorShow, setAddDoctorShow] = useState(false);
  const handleAddDoctor = () => setAddDoctorShow(false);
  const [loading, setLoading] = useState(false);

  const [subject, setSubject] = useState('');
  const [bodyTemp, setBodyTemp] = useState('');
  const [bloodPress, setBloodPress] = useState('');
  const [height, setHeight] = useState('');
  const [bodyWeight, setBodyWeight] = useState('');
  const [sp02, setSp02] = useState('');
  const [pulseRate, setPulseRate] = useState('');
  const [respiration, setRespiration] = useState('');
  const [painScore, setPainScore] = useState('');

  const handleSaveDoctor = () => {
    onClose();
    setAddDoctorShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const handleAdd = () => {
    setLoading(true);
    const data = {
      user: user?._id,
      patient: id,
      subject: subject,
      body_temperature: bodyTemp,
      blood_pressure: bloodPress,
      height: height,
      body_weight: bodyWeight,
      sp02: sp02,
      pulse_rate: pulseRate,
      respiration: respiration,
      pain_score: painScore,
    };
    console.log(data);
    addPatientVitals(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(fetchPatientVitals({patientId: id}))
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  return (
    <div>
      {" "}
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Solera__ModalHeadings">
              <MdClose className="Solera__Close" onClick={onClose} />
            </div>

            <div className="Solera__ModalHeadings"></div>
            <div className="Solera-Modal-Container">
              <div className="Solera__Headings">
                <div className="Log__Cont">
                  <img
                    src="/assets/lg.png"
                    alt=""
                    className="Log__Img__Modal"
                  />
                </div>
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">Patient Vitals</div>
                  {/* <div className="Solera-Settings-Note">
                    Kindly add a doctor for your records
                  </div> */}
                </div>
              </div>
              <br />
              <br />
              <div className="FormSect-Contianer">
               
                <div className="FormSect">Vital Subject</div>
                <div className="FormSect-Note">
                  Indicate the type of note you want to input, Such as Post-Ops,
                  Pre Ops
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Vital Type- Pre-Ops Vitals - Post-Ops Vitals - Etc"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="FormSect">Body Temprature</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <br />
            

                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Celcius"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={bodyTemp}
                        onChange={(e) => setBodyTemp(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Blood Pressure</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="mm Hg"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={bloodPress}
                        onChange={(e) => setBloodPress(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Height </div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Meters"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={height}
                        onChange={(e) => setHeight(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Body Weight</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Kg"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={bodyWeight}
                        onChange={(e) => setBodyWeight(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">SpO2</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="%"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={sp02}
                        onChange={(e) => setSp02(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Pulse Rate</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="bpm"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={pulseRate}
                        onChange={(e) => setPulseRate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Respiration</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Respiration"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={respiration}
                        onChange={(e) => setRespiration(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Pain Score</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="NRS"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={painScore}
                        onChange={(e) => setPainScore(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
             
              {
                loading ? (
                  <Bars
                    heigth="30"
                    width="30"
                    color="gray"
                    ariaLabel="loading-indicator"
                  />
                ) : (
                  <button className="Add-Button" onClick={handleAdd}>
                    Add Patient's Vital
                  </button>
                ) 
              }
              {/* <button className="Add-Button" onClick={handleSaveDoctor}>
                Add Patient's Vital
              </button> */}
              <br /> <br /> <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default VitalsModal;
