import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientVitals } from '../../services/PatientVitalsService'

const initialState = {
    loading: false,
    vitals: [],
    isSuccessful: false
}

export const fetchPatientVitals = createAsyncThunk('fetchPatientVitals', (initData) => {
    return listPatientVitals(initData)
    .then((response) => response.data.data)
})


const patientVitalsSlice = createSlice({
    name: 'patientVitals',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientVitals.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchPatientVitals.fulfilled, (state, action ) => {
        state.loading = false
        state.vitals = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientVitals.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientVitalsSlice.reducer 