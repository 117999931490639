import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listConsumables } from '../../services/ConsumableService'

const initialState = {
    loading: false,
    expiredConsumes: [],
    paginator: {},
    isSuccessful: false
}

export const fetchExpiryConsumables = createAsyncThunk('fetchExpiryConsumables', (initData) => {
    return listConsumables(initData)
    .then((response) => response.data.data)
})


const expiryConsumablesSlice = createSlice({
    name: 'expiryConsumables',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchExpiryConsumables.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchExpiryConsumables.fulfilled, (state, action ) => {
        state.loading = false
        state.expiredConsumes = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchExpiryConsumables.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default expiryConsumablesSlice.reducer 