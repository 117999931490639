import axios from '../axiosinstance';

      
export function listPatientRecord(initData){
        return axios.get('/patient-records', { params: initData })
}

export function addPatientRecord(data){
        return axios.post('/patient-records', data)
}

export function getPatientRecord(initData){
        return axios.get('/patient-records/'+initData?.id)
}

export function updatePatientRecord(initData){
        return axios.patch('/patient-records/'+initData.id, initData)
}

