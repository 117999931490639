import { React, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import DocScanRequestModal from "./DocScanRequestModal";

function AddXray() {
  const [xrayShow, setXrayShow] = useState(false);
  const handleXray = () => setXrayShow(false);
  return (
    <div>
      <button
        className="Add-Patient-Report"
        onClick={() => setXrayShow(true)}
      >
        X-ray to conduct
      </button>
      <div>
        <DocScanRequestModal
          show={xrayShow}
          onClose={handleXray}
          selected={'x-ray'}
          onOpen={() => setXrayShow(true)}
        />
      </div>
    </div>
  );
}

export default AddXray;
