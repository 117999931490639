import axios from '../axiosinstance';

      
export function listPatientDrugs(initData){
        return axios.get('/patient-drugs', { params: initData })
}

export function listDispensedDrugs(initData){
        return axios.get('/patient-drugs/all/dispensed', { params: initData })
}

export function addPatientDrugs(data){
        return axios.post('/patient-drugs', data)
}

export function getPatientDrugs(initData){
        return axios.get('/patient-drugs/'+initData?.id)
}

export function updatePatientDrugs(initData){
        return axios.patch('/patient-drugs/'+initData.id, initData)
}

export function patientDrugsCount(search){
        return axios.get('/patient-drugs/stat/count', { params: search })
}

export function patientDrugsSum(search){
        return axios.get('/patient-drugs/stat/sum', { params: search })
}

