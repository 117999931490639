import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useDispatch } from "react-redux";
import { getUser } from "../../../../services/AuthService";
import { addPatientRecord } from "../../../../services/PatientRecordService";
import { fetchPatientRecords } from "../../../../features/patients/patientsRecordsSlice";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import { FiTrendingUp } from "react-icons/fi";
import FaceLogo from "../../../../assets/FV.png"
import SideFaceLogo from "../../../../assets/SV.jpeg"

import RichTextEditor from 'react-rte';  // Copy Editor 
import Facials from "../../../../Component/Facials";
const myData = [
  {
    type: "EU-EU",
    measure: "",
  },
  {
    type: "EE-EE",
    measure: "",
  },
  {
    type: "FT-FT",
    measure: "",
  },
  {
    type: "MF-MF",
    measure: "",
  },
  {
    type: "ZY-ZY",
    measure: "",
  },
  {
    type: "AI-AI",
    measure: "",
  },
  {
    type: "CH-CH",
    measure: "",
  },
  {
    type: "GO-GO",
    measure: "",
  },            
]

const myData2 = [
  {
    type: "(V-GN)",
    measure: "",
  },
  {
    type: "(N-GN)",
    measure: "",
  },
  {
    type: "(SN-GN)",
    measure: "",
  },
  {
    type: "(V-N)",
    measure: "",
  },
  {
    type: "(STO-GN)",
    measure: "",
  },
  {
    type: "(N-SN)",
    measure: "",
  },   
  {
    type: "(STO-SL) ",
    measure: "",
  },  
  {
    type: "(TR-G)",
    measure: "",
  },  
  {
    type: "(SN-STO)",
    measure: "",
  },  
  {
    type: "(SL-GN)",
    measure: "",
  }, 
  {
    type: "(V-TR)",
    measure: "",
  }, 
  {
    type: "(V-T)",
    measure: "",
  },                   
        
]

                                
function MedicalRecordModal({ show, onClose }) {
  let { id } = useParams();
  const dispatch = useDispatch()
  const user = getUser();
  const [loading, setLoading] = useState(false);

  const [addDoctorShow, setAddDoctorShow] = useState(false);
  const handleAddDoctor = () => setAddDoctorShow(false);

  const [diseaseHistory, setDiseaseHistory] = useState('');
  const [mainPathology, setMainPathology] = useState('');
  const [ifNoma, setIfNoma] = useState(0);
  const [hiv, setHiv] = useState(false);
  const [hepatitis, setHepatitis] = useState(false);
  const [frontFace, setFrontFace] = useState(myData);              
  const [sideFace, setSideFace] = useState(myData2);    
  const [thyromentalDistance, setThyromentalDistance] = useState('');
  const [midUpperArmCircumfrence, setMidUpperArmCircumfrence] = useState('');
  const [patientConcentForm, setPatientConcentForm] = useState('');
  const [otherPathology, setOtherPathology] = useState('');
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());// Copy Editor 
  const [value2, setValue2] = useState(RichTextEditor.createEmptyValue());// Copy Editor 


  const handleAdd = () => {

    setLoading(true);
    const data = {
      user: user?._id,
      patient: id,
      disease_history: diseaseHistory,
      last_name: mainPathology,
      if_noma: ifNoma,
      hiv: hiv,
      hepatitis: hepatitis,
      front_face: frontFace,
      side_face: sideFace,
      thyromental_distance: thyromentalDistance,
      mid_upper_arm_circumfrence: midUpperArmCircumfrence,
      patient_concent_form: patientConcentForm,
      other_pathology: otherPathology,
    };
    // console.log(data);
    addPatientRecord(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          setAddDoctorShow(false);
          clearData();
          dispatch(fetchPatientRecords({patient: id}))
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  const changeIfNorm = (value) => {
    console.log("ON CHANGE", value)
  }


   {/* Copy Editor */}
   const onChange = (value) => {
    setValue(value);
    value.toString('html')
    setDiseaseHistory(value.toString('html'))
  };

   {/* Copy Editor */}
   const onChange2 = (value) => {
    setValue2(value);
    value.toString('html')
    setOtherPathology(value.toString('html'))
  };
  
  const clearData = () => {
    setDiseaseHistory("")
    setMainPathology("")
    setIfNoma(0)
    setHiv(false)
    setHepatitis(false)
    setFrontFace(myData)
    setSideFace(myData2)
    setThyromentalDistance("")
    setMidUpperArmCircumfrence("")
    setPatientConcentForm("")
    setOtherPathology("")
    setValue(RichTextEditor.createEmptyValue())
    setValue2(RichTextEditor.createEmptyValue())
  }
  

  

  return (
    <div>
      {" "}
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Solera__ModalHeadings">
              <MdClose className="Solera__Close" onClick={onClose} />
            </div>

            <div className="Solera__ModalHeadings"></div>
            <div className="Solera-Modal-Container">
              <div className="Solera__Headings">
                <div className="Log__Cont">
                  <img
                    src="/assets/lg.png"
                    alt=""
                    className="Log__Img__Modal"
                  />
                </div>
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">
                    Patient Medical Records
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="FormSect-Contianer">
                <div className="FormSect">History of the disease and family history</div>
                <br/>
                {/* <div className="FormSect-Note">
                  <TextField
                    fullWidth
                    id="outlined-multiline-static "
                    label="History of the disease and family history"
                    multiline
                    rows={10}
                    // defaultValue="Default Value"
                    value={diseaseHistory}
                    onChange={(event) => {
                      setDiseaseHistory(event.target.value)
                    }}
                  />
                </div> */}

                {/* <TextField
                  fullWidth
                  id="outlined-multiline-static "
                  label="Other Pathologies"
                  multiline
                  rows={10}
                  value={note}
                  onChange={(event) => {
                    setNote(event.target.value)
                  }}
                  // defaultValue="Default Value"
                /> */}

                {/* <br />
                <div className="FormSect">Other Pathologies</div>
                <br /> */}
                <div >
                {/* Copy Editor */}
                <RichTextEditor 
                  className="editor"
                  value={value} onChange={onChange} 
                />
                </div>
                <br /> <br />
                <div className="FormSect">Main Pathology</div>
                <div className="container-fluid p-0 FormInput">
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(event) => {
                        setMainPathology(event.target.value)
                      }}
                    >
                      <FormControlLabel
                        value="noma"
                        control={<Radio />}
                        label="Noma"
                      />
                      <FormControlLabel
                        value="others"
                        control={<Radio />}
                        label="Others"
                      />{" "}
                    </RadioGroup>
                  </FormControl>
                </div>{" "}
                <div className="FormSect">If Noma </div>
                <div className="FormSect-Note">Select the statge</div>
                <div className="container-fluid p-0 FormInput">
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(event) => {
                        setIfNoma(event.target.value)
                      }}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="1"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="2"
                      />{" "}
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label="3"
                      />{" "}
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        label="4"
                      />{" "}
                      <FormControlLabel
                        value="5"
                        control={<Radio />}
                        label="5"
                      />{" "}
                      <FormControlLabel
                        value="Unknown"
                        control={<Radio />}
                        label="Unknown"
                      />{" "}
                    </RadioGroup>
                  </FormControl>
                </div>{" "}
                <div className="FormSect">HIV </div>
                <div className="FormSect-Note">Select status</div>
                <div className="container-fluid p-0 FormInput">
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(event) => {
                        setHiv(event.target.value)
                      }}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Negative"
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Positive"
                      />{" "}
                    </RadioGroup>
                  </FormControl>
                </div>{" "}
                <div className="FormSect">Hepatitis</div>
                <div className="FormSect-Note">Select status</div>
                <div className="container-fluid p-0 FormInput">
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(event) => {
                        setHepatitis(event.target.value)
                      }}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Negative"
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Positive"
                      />{" "}
                    </RadioGroup>
                  </FormControl>
                </div>{" "}
                <div className="FormSect">Face Measurement</div>
                <div className="FormSect-Note">Select status</div>
                <br />
                <Facials/>
                {/* <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-6">
                      <div className="">
                        <img
                          src={FaceLogo}
                          alt=""
                          width="350"
                          height="400"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="">
                        <img
                          src={SideFaceLogo}
                          alt=""
                          width="450"
                          height="400"
                        />
                      </div>
                    </div>
                  </div>
                </div>{" "} */}
                <div className="FormSect">Front Face Measurement (cm)</div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    
                      {
                        frontFace?.map((data, index) => (
                          <div key={index} className="col-lg">
                            <TextField
                              fullWidth
                              label={data?.type}
                              id="fullWidth"
                              type="number"
                              className="Solera-Login-Input mb-3"
                              value={data.measure}
                              onChange={(e) => {
                                data.measure = e.target.value;
                                setFrontFace([...frontFace]);
                              }}
                              
                            />
                          </div>
                        ))
                      }
                  </div>
                </div>
                <div className="FormSect">Side Face Measurement (cm)</div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    {
                        sideFace?.map((data, index) => (
                          index < 6 ? (
                               <div key={index} className="col-lg">
                                  <TextField
                                    fullWidth
                                    label={data?.type}
                                    id="fullWidth"
                                    type="number"
                                    className="Solera-Login-Input mb-3"
                                    value={data.measure}
                                    onChange={(e) => {
                                      data.measure = e.target.value;
                                      setSideFace([...sideFace]);
                                    }}
                                    
                                  />
                                </div>
                          ) : null 
                        ))
                      }
                     
                     
                    {/* 
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="7-(STO-SL)"
                        id="fullWidth"
                        type="number"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="8-(TR-G)"
                        id="fullWidth"
                        type="number"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="9-(SN-STO)"
                        id="fullWidth"
                        type="number"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="10-(SL-GN)"
                        id="fullWidth"
                        type="number"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="11-(V-TR)"
                        id="fullWidth"
                        type="number"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="12-(V-T)"
                        id="fullWidth"
                        type="number"
                        className="Solera-Login-Input mb-3"
                      />
                    </div> */}
                  </div>
                  <div className="row gx-4">
                        {
                          sideFace?.map((data, index) => (
                            index > 5 ? (
                                <div key={index} className="col-lg">
                                    <TextField
                                      fullWidth
                                      label={data?.type}
                                      id="fullWidth"
                                      type="number"
                                      className="Solera-Login-Input mb-3"
                                      value={data.measure}
                                      onChange={(e) => {
                                        data.measure = e.target.value;
                                        setSideFace([...sideFace]);
                                      }}
                                      
                                    />
                                  </div>
                            ) : null 
                          ))
                        }
                      </div>
                </div>{" "}
                <br />
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <div className="FormSect">Thyromental Distance (cm)</div>{" "}
                      <br />
                      <TextField
                        fullWidth
                        label="Thyromental Distance"
                        id="fullWidth"
                        type="number"
                        className="Solera-Login-Input mb-3"
                        value={thyromentalDistance}
                        onChange={(event) => {
                          setThyromentalDistance(event.target.value)
                        }}
                      />
                    </div>
                    <div className="col-lg">
                      <div className="FormSect">Mid Upper Arm Circumfrence (cm)</div>{" "}
                      <br />
                      <TextField
                        fullWidth
                        label="Mid Upper Arm Circumfrence"
                        id="fullWidth"
                        type="number"
                        className="Solera-Login-Input mb-3"
                        value={midUpperArmCircumfrence}
                        onChange={(event) => {
                          setMidUpperArmCircumfrence(event.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <br />
                {/* <div className="FormSect">Patient Concent form</div>
                <div className="FormSect-Note">
                  Attache patient conscent form here
                </div>
                <div className="container-fluid p-0 FormInput">
                  <input alt="" type="file" />
                </div>{" "} */}
                {/* <TextField
                  fullWidth
                  id="outlined-multiline-static "
                  label="Note"
                  multiline
                  rows={10}
                  // defaultValue="Default Value"
                  value={otherPathology}
                  onChange={(event) => {
                    setOtherPathology(event.target.value)
                  }}
                /> */}
                {/* Copy Editor */}
                <RichTextEditor 
                  className="editor"
                  value={value2} onChange={onChange2} 
                />
              </div>
              {
                loading ? (
                  <Bars
                    heigth="30"
                    width="30"
                    color="gray"
                    ariaLabel="loading-indicator"
                  />
                ) : (
                  <button className="Add-Button" onClick={handleAdd}>
                    Add Medical Record
                  </button>
                ) 
              }
             
              <br /> <br /> <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default MedicalRecordModal;
