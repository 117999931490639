import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { patientCount } from '../../services/PatientService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchPatientCount = createAsyncThunk('fetchPatientCount', (initData) => {
    return patientCount(initData)
    .then((response) =>  response.data.data)
})


const patientCountSlice = createSlice({
    name: 'patientCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatientCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default patientCountSlice.reducer 