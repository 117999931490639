import React from "react";
import Container from "react-bootstrap/Container";

function Footer() {
  return (
    <div>
      <Container className="Solera-Footer-Container">
        {/* <img src="/assets/TLogo.png" alt="" className="Footer__Img" /> */}
        <div className="Footer__Note">
          &copy;{new Date().getFullYear()} Solera EMR. All Rights Reserved
        </div>
      </Container>
    </div>
  );
}

export default Footer;
