import axios from '../axiosinstance';

      

//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

export function listNurses(initData){
        return axios.get('/nurses', { params: initData })
}

export function addNurse(data){
        return axios.post('/nurses', data)
}

export function getNurse(initData){
        return axios.get('/nurses/'+initData?.id)
}

export function updateNurse(initData){
        return axios.patch('/nurses/'+initData.id, initData)
}

export function nurseCount(search){
        return axios.get('/nurses/stat/count', { params: { name: search } })
}

