import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listUsers } from '../../services/UserService'

const initialState = {
    loading: false,
    usersList: [],
    paginator: {},
    isSuccessful: false
}

export const fetchUsers = createAsyncThunk('fetchUsers', (initData) => {
    return listUsers(initData)
    .then((response) => response.data.data)
})


const usersSlice = createSlice({
    name: 'users',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchUsers.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchUsers.fulfilled, (state, action ) => {
        state.loading = false
        state.usersList = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchUsers.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default usersSlice.reducer 