import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientScanDocs } from '../../../services/PatientScanDocService'

const initialState = {
    loading: false,
    listCTScans: [],
    paginator: {},
    isSuccessful: false
}

export const fetchPatientCTScans = createAsyncThunk('fetchPatientCTScans', (initData) => {
    return listPatientScanDocs(initData)
    .then((response) => response.data.data)
})


const patientCTScansSlice = createSlice({
    name: 'patientCTScans',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientCTScans.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatientCTScans.fulfilled, (state, action ) => {
        state.loading = false
        state.listCTScans = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientCTScans.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientCTScansSlice.reducer 