import React, { useEffect, useState } from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import TextField from "@mui/material/TextField";
import Footer from "../../Component/Footer";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../auth";
import { getToken, login, saveSysInLocalStorage, saveTokenInLocalStorage, saveUserInLocalStorage } from "../../services/AuthService";
import { loginUser } from "../../features/auth/loginSlice";
import { Bars } from "react-loader-spinner";
import { toast } from "react-toastify";

const signInValidate = yup.object().shape({
  email: yup.string().min(10).required('Username field is required!'),
  password: yup.string().min(6).required(),
});

function HomePage() {

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const auth = useAuth();
  const token = getToken();
  
  const [loading, setLoading] = useState(false);

  const handleSignin = (values) => {
    
    setLoading(true)
   
     
    login(values)
    .then(async (response) => {
      console.log(response)
      if(response.data.success){
        toast.success(
          response.data.message
            ? response.data.message
            : "Successfully Signed-In"
        );
        await saveUserInLocalStorage(response.data.user)
        await saveTokenInLocalStorage(response.data.token)
        await saveSysInLocalStorage(response.data.sysuser)
        setLoading(false)
        auth?.signup(response.data.user)
        navigate("/dashboard")
      }
      
     
    }).catch((err) => {
      setLoading(false)
      console.log(err)
    })

  }

  return (
    <div className="Solera-FullBody">
      {/* <header>
        <NavBar />
      </header> */}
      <div className="Solera-FullBody-Center">
        <div className="centered-div">
          <div className="Solera-Welcome">
            <img
              src="Asset/nani.png"
              alt=""
              width="150"
              height="50"
              className="d-inline-block align-text-top"
            />
            <div className="Emr-Brand">By SOLERA EMR</div>
          </div>
          <Formik
                  initialValues={{ email: '', password: '' }}
                  validationSchema={signInValidate}
                  onSubmit={(values, { setSubmitting }) => {
                    handleSignin(values)
                  }}
                >
              {({
                isValid,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                  setFieldValue,
                /* and other goodies */
              }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Username"
                  id="fullWidth"
                  type="text"
                  className="Solera-Login-Input mb-3"
                  value={values.email}
                  name='email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {(errors.email && touched.email) && <span className='input_error'>{errors.email}</span>}


                <TextField
                  fullWidth
                  label="Password"
                  id="fullWidth"
                  type="password"
                  className="Solera-Login-Input mb-3"
                  value={values.password}
                  name='password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />{" "}
                {(errors.password && touched.password) && <span className='input_error'>{errors.password}</span>}
                
                {loading ? (
                    <Bars
                      heigth="30"
                      width="30"
                      color="gray"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    <div className="Solera-SideBar-Link Solera-Logo">
                      <button disabled={!isValid} onClick={handleSubmit} className="Solera-Login-Btn">Login</button>
                    </div>
                  )}
                
              </form>
          )}
          </Formik>
        </div>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
}
export default HomePage;
