import { React, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import NonMedListView from "./NonMedListView";
import EditNonMed from "./EditNonMed";
import ReleaseItemModal from "./ReleaseItemModal";
import moment from "moment";

function NonMedList({nonConsume}) {
  const [drugsDetailsShow, setDrugsDetailsShow] = useState(false);
  const handleDrugsDetails = () => setDrugsDetailsShow(false);

  const [selected, setSelected] = useState({});


  const [editDrugsShow, setEditDrugsShow] = useState(false);
  const handleEditDrugs = () => setEditDrugsShow(false);

  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }
  return (
    <div>
      <div className="Patient-Displays-Details2">
        <div className="PatientNumber-C">
          <div className="Wallet-Ref-Heading">Item Name</div>
          <div className="Wallet-Ref-Result">{nonConsume?.name}</div>
        </div>
        <div className="StaffNumber">
          <div className="Wallet-Ref-Heading">Item Number</div>
          <div className="Wallet-Ref-Result">{nonConsume?.code}</div>
        </div>

        <div className="Position-Cont-P">
          <div className="Wallet-Ref-Heading">Expiry Date</div>
        
          <div className="Wallet-Ref-Result">

          {showDate(nonConsume?.expiry_date)}
            
            {/* {showDate(nonConsume?.created_at)} */}



          </div>
        </div>
        <div className="Role-Cont-P">
          <div className="Wallet-Ref-Heading">Quantity</div>
          <div className="Wallet-Ref-Result">{nonConsume?.qty}</div>
        </div>
        <div className="Dept-Cont-P">
          <div className="Wallet-Ref-Heading">Status</div>
          <div className="Wallet-Ref-Result">{nonConsume?.status}</div>
        </div>

        <div className="Action-Drop-Down">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
              Manage Item
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                className="Solera-Patients"
                onClick={() => {setSelected(nonConsume); setDrugsDetailsShow(true)}}
              >
                View Item's Details
              </Dropdown.Item>

              <Dropdown.Item
                className="Solera-Patients"
                onClick={() => {setSelected(nonConsume); setAddReportShow(true)}}
              >
                Release Items
              </Dropdown.Item>

              <Dropdown.Item
                className="Solera-Patients"
                onClick={() => {setSelected(nonConsume); setEditDrugsShow(true)}}
              >
                Update Item
              </Dropdown.Item>
              <Dropdown.Item className="Solera-Patients">
                Remove Item
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div>
        <NonMedListView
          selected={selected}
          show={drugsDetailsShow}
          onClose={handleDrugsDetails}
          onOpen={() => setDrugsDetailsShow(true)}
        />
        <EditNonMed
          selected={selected}
          show={editDrugsShow}
          onClose={handleEditDrugs}
          onOpen={() => setEditDrugsShow(true)}
        />

        {/* <ReleaseItem
          show={addReportShow}
          onClose={handleAddReport}
          onOpen={() => setAddReportShow(true)}
        /> */}

          <ReleaseItemModal
          selected={selected}
          show={addReportShow}
          onClose={handleAddReport}
          onOpen={() => setAddReportShow(true)}
        />
      </div>
    </div>
  );
}

export default NonMedList;
