import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientPrescriptionsByTreatment } from '../../services/PatientPrescriptionService'

const initialState = {
    loading: false,
    listPrescriptionsTreatment: [],
    paginator: {},
    isSuccessful: false
}

export const fetchPatientPrescriptionsTreatment = createAsyncThunk('fetchPatientPrescriptionsTreatment', (initData) => {
    return listPatientPrescriptionsByTreatment(initData)
    .then((response) => response.data.data)
})


const patientPrescriptionsTreatmentSlice = createSlice({
    name: 'patientPrescriptionsTreatment',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientPrescriptionsTreatment.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatientPrescriptionsTreatment.fulfilled, (state, action ) => {
        state.loading = false
        state.listPrescriptionsTreatment = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientPrescriptionsTreatment.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientPrescriptionsTreatmentSlice.reducer 