import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { sysUserCount } from '../../../services/UserService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchSysDoctorCount = createAsyncThunk('fetchSysDoctorCount', (initData) => {
    return sysUserCount(initData)
    .then((response) =>  response.data.data)
})


const sysDoctorCountSlice = createSlice({
    name: 'sysDoctorCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchSysDoctorCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchSysDoctorCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default sysDoctorCountSlice.reducer 