import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatient } from '../../services/PatientService'

const initialState = {
    loading: false,
    patients: [],
    paginator: {},
    isSuccessful: false
}

export const fetchPatients = createAsyncThunk('fetchPatients', (initData) => {
    return listPatient(initData)
    .then((response) => response.data.data)
})


const patientsSlice = createSlice({
    name: 'patients',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatients.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatients.fulfilled, (state, action ) => {
        state.loading = false
        state.patients = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchPatients.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientsSlice.reducer 