import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientAssessment } from '../../services/PatientAssessmentService'

const initialState = {
    loading: false,
    assessments: [],
    isSuccessful: false
}

export const fetchPatientAssessment = createAsyncThunk('fetchPatientAssessment', (initData) => {
    return listPatientAssessment(initData)
    .then((response) => response.data.data)
})


const patientAssessmentSlice = createSlice({
    name: 'patientAssessment',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientAssessment.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchPatientAssessment.fulfilled, (state, action ) => {
        state.loading = false
        state.assessments = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientAssessment.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientAssessmentSlice.reducer 