import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useParams } from "react-router-dom";
import { getUser } from "../../../../services/AuthService";
import { useDispatch } from "react-redux";
import { addPatientClinicalNote } from "../../../../services/PatientClinicalNoteService";
import { toast } from "react-toastify";
import { fetchPhysiotherapy } from "../../../../features/patients/physiotherapySlice";
import { Bars } from "react-loader-spinner";
import RichTextEditor from "react-rte"; // Copy Editor
import { fetchPatientDocNotes } from "../../../../features/patients/clinical/patientDocNotesSlice";

function AddDoctorNoteModal({ show, onClose }) {
  let { id } = useParams();

  const [value, setValue] = useState(RichTextEditor.createEmptyValue()); // Copy Editor

  const user = getUser();
  const dispatch = useDispatch();
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState("");
  const [actionNote, setActionNote] = useState("");

  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);


  



  const language = [
    {
      value: "Admit to children’s home ",
      label: "Admit to children’s home ",
    },
    {
      value: "  Admit to quarantine",
      label: "  Admit to quarantine",
    },
    {
      value: "  Admit to ward",
      label: "  Admit to ward",
    },
    {
      value: "  Admit to ICU",
      label: " Admit to ICU",
    },
    {
      value: "Discharge from ICU",
      label: "Discharge from ICU",
    },
    {
      value: "Discharge from quarantine",
      label: "Discharge from quarantine",
    },
    {
      value: "Discharge from ward",
      label: "Discharge from ward",
    },
    {
      value: "Discharge from facility",
      label: "Discharge from facility",
    },
    {
      value: "Discharge from recovery room",
      label: "Discharge from recovery room",
    },
  ];










  const handleSub = () => {
    onClose();
    setAddReportShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const handleAdd = () => {
    setLoading(true);
    const data = {
      user: user?._id,
      patient: id,
      subject: subject,
      action_note: actionNote,
      note: note,
      type: "doctor",
    };
    console.log(" EEEEE ",data);
    addPatientClinicalNote(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(fetchPatientDocNotes({ patientId: id, type: "doctor" }));
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  {
    /* Copy Editor */
  }
  const onChange = (value) => {
    setValue(value);
    value.toString("html");
    setNote(value.toString("html"));
  };

  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">Doctor's Note</div>
                    <div className="Solera-Settings-Note">Add a Note</div>
                  </div>
                </div>
                <br />
                <br />
                <div className="FormSect">Subject</div>
                <div className="FormSect-Note">
                  Indicate the type of note you want to input, Such as Post-Ops,
                  Pre Ops
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Note Type- Pre-Ops- Post-Ops - Etc"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="FormSect">Note</div>
                <div className="FormSect-Note">Make a note accordingly</div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Make a test note for this patient"
                        multiline
                        rows={15}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        // defaultValue="Default Value"
                      /> */}

                      {/* Copy Editor */}
                      <RichTextEditor
                        className="editor"
                        value={value}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="FormSect">Action Note</div>
                <div className="FormSect-Note">
                  Take action on this patiient. What action do you want to take on ths patient
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                    <TextField
                        fullWidth
                        id="outlined-select-currency"
                        select
                        label="Take Action"
                        defaultValue="Language"
                        value={actionNote}
                        onChange={(e) => setActionNote(e.target.value)}
                      >
                        {language.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                </div>
                <div className="FormUpload">
                  {loading ? (
                    <Bars
                      heigth="50"
                      width="50"
                      color="gray"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    <button className="Add-Button" onClick={handleAdd}>
                      Add Note
                    </button>
                  )}
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default AddDoctorNoteModal;
