import React from 'react'
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";

function PatientNumber({pId}) {
  return (
    <div>{pId?.toUpperCase()}</div>
  )
}

export default PatientNumber