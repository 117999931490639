import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import "../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { MdAddCircle, MdClose, MdRemoveCircle } from "react-icons/md";
import { useDispatch } from "react-redux";
import { getUser } from "../../services/AuthService";
import { AddReleaseConsumables } from "../../services/ReleaseConsumableService";
import { toast } from "react-toastify";
import { fetchReleaseConsumables } from "../../features/consumables/releaseConsumablesSlice";
import moment from "moment";
import { Bars } from "react-loader-spinner";
import { fetchNonConsumableCount } from "../../features/nonConsumables/nonConsumableCountSlice";
import { fetchNonConsumables } from "../../features/nonConsumables/nonConsumablesSlice";
import { fetchReleasedNonConsumableCount } from "../../features/nonConsumables/releasedNonConsumableCountSlice";
// import DrugsRequest from "./DrugsRequest";

function ReleaseItemModal({ show, onClose, onOpen, selected }) {
  const dispatch = useDispatch();
  const user = getUser();
  const [loading, setLoading] = useState(false);

  const [collectorName, setCollectorName] = useState("");
  const [qty, setQty] = useState("");
  const [errQty, setErrQty] = useState("");
  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);

  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };

  const handleSub = () => {
    onClose();
    setAddReportShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const handleAdd = () => {
    if (!qty) {
      setQty("Quantity is required");
      return;
    }
    if (qty > selected?.qty) {
      setQty("Quantity is greater than in stock");
      return;
    }

    setLoading(true);
    const data = {
      user: user?._id,
      collector_name: collectorName,
      consume: selected?._id,
      qty: qty,
      type: "non-consume",
    };
    console.log(data);
    AddReleaseConsumables(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(
            fetchNonConsumableCount({
              type: "non-consume",
              status: "available",
            })
          );
          dispatch(fetchNonConsumables({ type: "non-consume" }));
          dispatch(
            fetchReleasedNonConsumableCount({
              type: "non-consume",
              status: "released",
            })
          );
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div>
      {" "}
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Solera__ModalHeadings">
              <MdClose className="Solera__Close" onClick={onClose} />
            </div>
            <div className="Solera__ModalHeadings"></div>
            <div className="Solera-Modal-Container30">
              <div className="Solera__Headings">
                <div className="Log__Cont">
                  <img
                    src="/assets/lg.png"
                    alt=""
                    className="Log__Img__Modal"
                  />
                </div>
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">Release items</div>
                  <div className="Solera-Settings-Note">
                    Kindy release items.
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="container-fluid">
                <div className="row g-2">
                  <div className="col-lg-12">
                    <div className="">
                      <div>
                        <div className="Drug-search-Display">
                          <div className="Drug-search-Display2">
                            <div className="Drug-search-Display1">
                              <div>
                                <div className="Drug-search-Heading">
                                  Item's Name{" "}
                                </div>
                                <div className="Drug-search-Result">
                                  {selected?.name}
                                </div>
                              </div>
                              <div>
                                <div className="Drug-search-Heading">
                                  Expiry Date
                                </div>
                                <div className="Drug-search-Result">
                                  {showDate(selected?.expiry_date)}
                                </div>
                              </div>

                              <div>
                                <div className="Drug-search-Heading">Stock</div>
                                <div className="Drug-search-Result">
                                  <span>{selected?.qty}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div>
                            <button className="Drug-BTN">Add</button>
                          </div> */}
                        </div>
                      </div>
                      <div className="Selected-Drugs-Box-Container">
                        <div className="Selected-Drugs-Box-Heading">
                          Enter Quantity
                        </div>
                        <div className="Selected-Drugs-Box-Note">
                          You are advised to enter the Enter quantityof items
                        </div>
                      </div>
                      <div className="FormSect-Contianer">
                        <div className="container-fluid p-0 FormInput">
                          <div className="row gx-4">
                            <div className="col-lg">
                              <TextField
                                fullWidth
                                label="How many items"
                                id="fullWidth"
                                type="number"
                                className="Solera-Login-Input mb-3"
                                value={qty}
                                onChange={(event) => {
                                  if (event.target.value.length) {
                                    setErrQty("");
                                  } else {
                                    setErrQty("Quantity is required");
                                  }
                                  if (
                                    parseInt(event.target.value) > selected?.qty
                                  ) {
                                    setErrQty(
                                      "Quantity is greater than in stock"
                                    );
                                  }
                                  setQty(event.target.value);
                                }}
                              />
                              {errQty && (
                                <div className="input_error">{errQty}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="Selected-Drugs-Box-Container">
                        <div className="Selected-Drugs-Box-Heading">
                          Enter receiver's name
                        </div>
                        <div className="Selected-Drugs-Box-Note">
                          You are advised to enter the name and staff number of
                          the person to pick up items
                        </div>
                      </div>
                      <div className="FormSect-Contianer">
                        <div className="container-fluid p-0 FormInput">
                          <div className="row gx-4">
                            <div className="col-lg">
                              <TextField
                                fullWidth
                                label="Name of receiver/Staff Number"
                                id="fullWidth"
                                type="text"
                                className="Solera-Login-Input mb-3"
                                value={collectorName}
                                onChange={(event) => {
                                  setCollectorName(event.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="FormUpload">
                        {loading ? (
                          <Bars
                            heigth="50"
                            width="50"
                            color="gray"
                            ariaLabel="loading-indicator"
                          />
                        ) : (
                          <button className="Add-Button" onClick={handleAdd}>
                            Release Items
                          </button>
                        )}
                        {/* <button className="Add-Button" onClick={handleSub}>
                          Release Items
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <br />
            <br />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default ReleaseItemModal;
