import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { consumablesCount } from '../../services/ConsumableService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchNonConsumableCount = createAsyncThunk('fetchNonConsumableCount', (initData) => {
    return consumablesCount(initData)
    .then((response) =>  response.data.data)
})


const nonConsumableCountSlice = createSlice({
    name: 'nonConsumableCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchNonConsumableCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchNonConsumableCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default nonConsumableCountSlice.reducer 