import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { releaseConsumablesCount } from '../../services/ReleaseConsumableService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchReleasedNonConsumableCount = createAsyncThunk('fetchReleasedNonConsumableCount', (initData) => {
    return releaseConsumablesCount(initData)
    .then((response) =>  response.data.data)
})


const releasedNonConsumableCountSlice = createSlice({
    name: 'releasedNonConsumableCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchReleasedNonConsumableCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchReleasedNonConsumableCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default releasedNonConsumableCountSlice.reducer 