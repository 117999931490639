import { React, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import VitalsModal from "./VitalsModal";

function AddVitals() {
  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);
  return (
    <div>
      <div>
        <button
          className="Add-Patient-Report"
          onClick={() => setAddReportShow(true)}
        >
          Add Vitals
        </button>
        <VitalsModal
          show={addReportShow}
          onClose={handleAddReport}
          onOpen={() => setAddReportShow(true)}
        />
      </div>
    </div>
  );
}

export default AddVitals;
