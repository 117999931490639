import React, { useEffect, useState } from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import Patient from "./Patient";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatients } from "../../features/patients/patientsSlice";
import { Bars } from "react-loader-spinner";
import Pagination from "../../Component/Pagination";
// import TextField from "@mui/material/TextField";
// import Pagination from "./Pagination";

function PatientTable() {
  const dispatch = useDispatch()
  const {patients, paginator, loading} = useSelector((state) => state.patients)

  const [search2, setSearch2] = useState('');
  const [page, setPage] = useState(paginator?.currentPage || 1);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(paginator?.pageCount || 0);

  useEffect((  ) => {
    handleFetchPatients()
  }, [])


  const handleFetchPatients = (search = "", limit = 10, page = 1) => {
    dispatch(fetchPatients({ name: search, page_size: limit, page:page }))
  }

  const handleFetchUpdate = (search = search2, limit = pageLimit, page = 1) => {
    dispatch(fetchPatients({ name: search, page_size: limit, page:page }));
  }
  return (
    <div className="Patient-Table">
      
      <div className="Patient-Search">
        <input
          type="Search"
          className="form-control SearchesInput22"
          placeholder="Patient Number"
          aria-label="OTP"
          aria-describedby="basic-addon1"
          onKeyUp={(e) => {
            if(e.target.value.length > 1){
              setSearch2(e.target.value)
              handleFetchUpdate(e.target.value)
            }else{
              setSearch2('')
              handleFetchPatients()
            }
          }}
        />
        <div className="Searchesbtn">Search</div>
      </div>
      <div>
        <Pagination 
          handleFetchTransSearch={handleFetchUpdate} 
          page={page} 
          setPage={setPage} 
          pageCount={paginator?.pageCount || 0} 
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
        <div>
        {loading ? (
              <div className="d-flex justify-content-center">
                <Bars
                  heigth="30"
                  width="30"
                  color="gray"
                  ariaLabel="loading-indicator"
                />
              </div>
          ) : null}
          {patients?.map((patient, index) => (
            <Patient key={index} patient={patient} />
          ))}
          
        </div>
        <Pagination 
          handleFetchTransSearch={handleFetchUpdate} 
          page={page} 
          setPage={setPage} 
          pageCount={paginator?.pageCount || 0} 
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      </div>
    </div>
  );
}

export default PatientTable;
