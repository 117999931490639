import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientScanDocs } from '../../../services/PatientScanDocService'

const initialState = {
    loading: false,
    listXrays: [],
    paginator: {},
    isSuccessful: false
}

export const fetchPatientXrays = createAsyncThunk('fetchPatientXrays', (initData) => {
    return listPatientScanDocs(initData)
    .then((response) => response.data.data)
})


const patientXraysSlice = createSlice({
    name: 'patientXrays',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientXrays.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatientXrays.fulfilled, (state, action ) => {
        state.loading = false
        state.listXrays = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientXrays.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientXraysSlice.reducer 