import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientLaps } from '../../services/PatientLapsService'
import { listPatientPrescriptions } from '../../services/PatientPrescriptionService'

const initialState = {
    loading: false,
    listPrescriptions: [],
    paginator: {},
    isSuccessful: false
}

export const fetchPatientPrescriptions = createAsyncThunk('fetchPatientPrescriptions', (initData) => {
    return listPatientPrescriptions(initData)
    .then((response) => response.data)
})


const patientPrescriptionsSlice = createSlice({
    name: 'patientPrescriptions',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientPrescriptions.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatientPrescriptions.fulfilled, (state, action ) => {
        state.loading = false
        state.listPrescriptions = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientPrescriptions.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientPrescriptionsSlice.reducer 