import { FiPlus } from "react-icons/fi";
import { React, useState } from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";

import AdminAccountModal from "./AdminAccountModal";

function AddAdmin() {
          const [addAccountShow, setAddAccountShow] = useState(false);
          const handleAddAccount = () => setAddAccountShow(false);
          return (
            <div>
              <button className="Add-Button" onClick={() => setAddAccountShow(true)}>
                <FiPlus className="Add-Icon"/>
                <span> create Admin account </span>
              </button>
              <AdminAccountModal
                show={addAccountShow}
                onClose={handleAddAccount}
                onOpen={() => setAddAccountShow(true)}
              />
            </div>
  )
}

export default AddAdmin