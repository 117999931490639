import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listLaps } from '../../services/PatientLapsService'

const initialState = {
    loading: false,
    dataLabs: [],
    paginator: {},
    isSuccessful: false
}

export const fetchPatientListLaps = createAsyncThunk('fetchPatientListLaps', (initData) => {
    return listLaps(initData)
    .then((response) => response.data)
})


const patientListLapsSlice = createSlice({
    name: 'patientAllLaps',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientListLaps.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatientListLaps.fulfilled, (state, action ) => {
        state.loading = false
        state.dataLabs = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientListLaps.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientListLapsSlice.reducer 