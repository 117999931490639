import { Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage/HomePage";
import MainContainer from "./Pages/MainPage/MainContainer";
import { RequiredAuth } from "./RequiredAuth";
import { AuthProvider } from "./auth";
import { RedirectAuth } from "./RedirectAuth";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const App = () => {
  return (
    <div>
      <AuthProvider>
        <Routes>
          <Route index element={<RequiredAuth><MainContainer /></RequiredAuth>}></Route>
          <Route path="/login" element={<RedirectAuth><HomePage /></RedirectAuth>}></Route>
          <Route path="/*" element={<RequiredAuth><MainContainer /></RequiredAuth>}></Route>
        </Routes>
        <ToastContainer />
      </AuthProvider>
    </div>
   
    );
}

export default App;
