import { FiPlus } from "react-icons/fi";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import { React, useState } from "react";
import AddDoctorModal from "./AddDoctorModal";

function AddDoctor() {
  const [addDoctorShow, setAddDoctorShow] = useState(false);
  const handleAddDoctor = () => setAddDoctorShow(false);
  return (
    <div>
      <button className="Add-Button" onClick={() => setAddDoctorShow(true)}>
        <FiPlus className="Add-Icon" />
        <span> Add Doctor</span>
      </button>
      <AddDoctorModal
        show={addDoctorShow}
        onClose={handleAddDoctor}
        onOpen={() => setAddDoctorShow(true)}
      />
    </div>
  );
}

export default AddDoctor;
