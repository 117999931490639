import React, { useEffect, useState } from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import Pagination from "../../Component/Pagination";
import ExpiredList from "./ExpiredList";
import { useDispatch, useSelector } from "react-redux";
import { fetchExpiryConsumables } from "../../features/consumables/expiryConsumablesSlice";
import { Bars } from "react-loader-spinner";
import { fetchExpiredDrugs } from "../../features/pharmacy/expiredDrugsSlice";

function ExpiredTable() {
  const dispatch = useDispatch()
  const {expiredDrugs, paginator, loading} = useSelector((state) => state.expiredDrugs)

  const [search2, setSearch2] = useState('');
  const [page, setPage] = useState(paginator?.currentPage || 1);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(paginator?.pageCount || 0);

  useEffect((  ) => {
    handleFetchExpiredDrugs()
  }, [])


  const handleFetchExpiredDrugs = (search = "", limit = 10, page = 1) => {
    dispatch(fetchExpiredDrugs({ name: search, expired: true, page_size: limit, page:page }))
  }

  const handleFetchUpdate = (search = search2, limit = pageLimit, page = 1) => {
    dispatch(fetchExpiredDrugs({ name: search, expired: true, page_size: limit, page:page }));
  }
  return (
    <div className="Patient-Table">
      <div className="Patient-Search">
        <input
          type="Search"
          className="form-control SearchesInput22"
          placeholder="Search for drugs"
          aria-label="OTP"
          aria-describedby="basic-addon1"
          onKeyUp={(e) => {
            if(e.target.value.length > 1){
              setSearch2(e.target.value)
              handleFetchUpdate(e.target.value)
            }else{
              setSearch2('')
              handleFetchExpiredDrugs()
            }
          }}
        />
        <div className="Searchesbtn">Search</div>
      </div>
      <div>
        <div>
          {" "}
          <Pagination 
            handleFetchTransSearch={handleFetchUpdate} 
            page={page} 
            setPage={setPage} 
            pageCount={paginator?.pageCount || 0} 
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
          />
        </div>
        <br />
        <div>
        {loading ? (
            <div className="d-flex justify-content-center">
              <Bars
                heigth="30"
                width="30"
                color="gray"
                ariaLabel="loading-indicator"
              />
            </div>
        ) : null}
        {
          expiredDrugs?.map((item, index) => (
            <ExpiredList expireds={item} key={index} />
          ))
        }
        </div>
        <br />
        <div>
          <Pagination 
            handleFetchTransSearch={handleFetchUpdate} 
            page={page} 
            setPage={setPage} 
            pageCount={paginator?.pageCount || 0} 
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
          />
        </div>
      </div>
    </div>
  );
}

export default ExpiredTable;



