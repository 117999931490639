import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { getUser } from "../../../../services/AuthService";
import { useDispatch } from "react-redux";
import { addPatientAssessment } from "../../../../services/PatientAssessmentService";
import { toast } from "react-toastify";
import { fetchPatientAssessment } from "../../../../features/patients/patientAssessmentSlice";
import { Bars } from "react-loader-spinner";
import RichTextEditor from "react-rte"; // Copy Editor
import MenuItem from "@mui/material/MenuItem";
import Form from "react-bootstrap/Form";

function UpdateAssessment({ show, onClose }) {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue()); // Copy Editor
  const [value2, setValue2] = useState(RichTextEditor.createEmptyValue()); // Copy Editor
  const [value3, setValue3] = useState(RichTextEditor.createEmptyValue()); // Copy Editor

  let { id } = useParams();
  const user = getUser();
  const dispatch = useDispatch();
  const [presentingConcern, setPresentingConcern] = useState("");
  const [relevantMedicalHistory, setRelevantMedicalHistory] = useState("");
  const [psychosocialFactors, setPsychosocialFactors] = useState("");

  const [loading, setLoading] = useState(false);

  const [addDoctorShow, setAddDoctorShow] = useState(false);
  const handleAddDoctor = () => setAddDoctorShow(false);

  const handleSaveDoctor = () => {
    onClose();
    setAddDoctorShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const handleAdd = () => {
    setLoading(true);
    const data = {
      user: user?._id,
      patient: id,
      presenting_concern: presentingConcern,
      relevant_medical_history: relevantMedicalHistory,
      psychosocial_factors: psychosocialFactors,
    };
    console.log(data);
    addPatientAssessment(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(fetchPatientAssessment({ patientId: id }));
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  {
    /* Copy Editor */
  }
  const onChange = (value) => {
    setValue(value);
    value.toString("html");
    setPresentingConcern(value.toString("html"));
  };

  {
    /* Copy Editor */
  }
  const onChange2 = (value) => {
    setValue2(value);
    value.toString("html");
    setRelevantMedicalHistory(value.toString("html"));
  };
  {
    /* Copy Editor */
  }
  const onChange3 = (value) => {
    setValue3(value);
    value.toString("html");
    setPsychosocialFactors(value.toString("html"));
  };

  const sex = [
    {
      value: "Gender",
      label: "Gender",
    },
    {
      value: "Male",
      label: "Male",
    },
    {
      value: "Female",
      label: "Female",
    },
  ];
  const status = [
    {
      value: "Single",
      label: "Single",
    },
    {
      value: "Married",
      label: "Married",
    },
  ];

  const language = [
    {
      value: "PG",
      label: "PG",
    },
    {
      value: "HA",
      label: "HA",
    },
    {
      value: "IG",
      label: "IG",
    },
    {
      value: "YO",
      label: "YO",
    },
    {
      value: "FUL",
      label: "FUL",
    },
    {
      value: "OTHERS",
      label: "OTHERS",
    },
  ];

  return (
    <div>
      {" "}
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Solera__ModalHeadings">
              <MdClose className="Solera__Close" onClick={onClose} />
            </div>

            <div className="Solera__ModalHeadings"></div>
            <div className="Solera-Modal-Container">
              <div className="Solera__Headings">
                <div className="Log__Cont">
                  <img
                    src="/assets/lg.png"
                    alt=""
                    className="Log__Img__Modal"
                  />
                </div>
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">
                    Update Patient's Assessment
                  </div>
                  {/* <div className="Solera-Settings-Note">
                            Kindly add a doctor for your records
                          </div> */}
                </div>
              </div>
              <br />
              <br />
              <div className="FormSect">Assessment 1</div>
              <div className="FormSect-Note">
                Please fill all the required field
              </div>
              <br />
              <br />
              <div className="FormSect">
                If care was sought before arriving here today, where was it
                sought? Select all that apply
              </div>
              <div className="FormSect-Contianer">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Nowhere, this was our first visit to a health care provider" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Traditional healer" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Health centre
"
                        />
                      </div>

                      <div className="FormSect-Contianer00">
                        <Form>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row g-2">
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check label="Hospital " />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check label="Other NGO" />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check
                                  label="Other: {other_previous_care}
"
                                />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check
                                  label="don't know
"
                                />
                              </div>
                            </div>
                          </div>{" "}
                        </Form>
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                What treatment was offered? Select all that apply
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Traditional treatment- herbs on skin/ ointment herbs to drink/" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Traditional treatment- piercing of the cheek" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Antibiotics
"
                        />
                      </div>

                      <div className="FormSect-Contianer00">
                        <Form>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row g-2">
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check label="Nutritional support " />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check label="Mouth wash" />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check
                                  label="Cleaning of wound
"
                                />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check
                                  label="Pain management (medication)
"
                                />
                              </div>
                            </div>
                          </div>{" "}
                        </Form>
                      </div>
                      <div className="FormSect-Contianer00">
                        <Form>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row g-2">
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check label="Treatment for comorbidities " />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check label="Surgery" />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check
                                  label="Other: {other_tx}
"
                                />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check
                                  label="I don't know
"
                                />
                              </div>
                            </div>
                          </div>{" "}
                        </Form>
                      </div>
                      <div className="container-fluid p-0 FormInput">
                        <div className="row gx-4">
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Other traditional treatment, please describe"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                How did you hear about our services?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="From traditional healer" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="From Imam/ community leader" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="From friends/ family
"
                        />
                      </div>

                      <div className="FormSect-Contianer00">
                        <Form>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row g-2">
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check label="Radio advert " />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check label="Poster" />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check
                                  label="TV advert
"
                                />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check
                                  label="Outreach team activity
"
                                />
                              </div>
                            </div>
                          </div>{" "}
                        </Form>
                      </div>
                      <div className="FormSect-Contianer00">
                        <Form>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row g-2">
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check
                                  label="Other: {other_tx}
"
                                />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check
                                  label="I don't know
"
                                />
                              </div>
                            </div>
                          </div>{" "}
                        </Form>
                      </div>
                      <div className="container-fluid p-0 FormInput">
                        <div className="row gx-4">
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Other traditional treatment, please describe"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Did you face any difficulties getting here today?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Unsure
"
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                If yes, what were the difficulties *Select all that apply
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Transport was expensive" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Transport was difficult to find" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="It was difficult to find people to look after my other children who are at home
"
                        />
                      </div>

                      <div className="FormSect-Contianer00">
                        <Form>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row g-2">
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check label="It took a long time to get here" />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check label="It was difficult to leave my work" />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check
                                  label="I was worried about losing my income while we travelled here
"
                                />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check
                                  label="Bad weather/ heavy rain causing blocked roads
"
                                />
                              </div>
                            </div>
                          </div>{" "}
                        </Form>
                      </div>
                      <div className="FormSect-Contianer00">
                        <Form>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row g-2">
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check label="Long waiting time " />
                              </div>

                              <div className="col-lg-3 CheckSheet">
                                <Form.Check
                                  label="Other: {other_tx}
"
                                />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check
                                  label="I don't know
"
                                />
                              </div>
                            </div>
                          </div>{" "}
                        </Form>
                      </div>
                      <div className="container-fluid p-0 FormInput">
                        <div className="row gx-4">
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Other traditional treatment, please describe"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                <b>Financial impacts</b>- Has noma impacted you/ your family's
                ability to work and earn a living?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">If so, how? *Select all that apply</div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="I/ we lost a job due to stigma" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="I/ we had to stop working to look after my child (the noma patient)" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="I/ we have not been offered jobs due to stigma
"
                        />
                      </div>

                      <div className="FormSect-Contianer00">
                        <Form>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row g-2">
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check label="I was unable to go to school because I had noma and so now cannot find a job" />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check label="I/ we had to stop working in the fields/ doing my farming duties" />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check
                                  label="I was worried about losing my income while we travelled here
"
                                />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check
                                  label="Other: {other_tx}
"
                                />
                              </div>
                            </div>
                          </div>{" "}
                        </Form>
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Did you have to sell anything to get to or afford care for noma?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                <b>Financial impacts</b>- Has noma impacted you/ your family's
                ability to work and earn a living?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">If yes, what did you have to sell?</div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Animals" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Land" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Possessions (such as cell phone or clothes)
"
                        />
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Other: {other_tx}
"
                          />
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Do you know any other people who have noma?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="If yes, can you give us their names or contact details *(such as address or phone number)?"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="FormSect">Quality of life</div>
              <div className="FormSect-Note">
                Please fill all the required field
              </div>
              <br />
              <div className="FormSect">
                Who will be answering the quality of life questions?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Patient" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Caretaker" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <br />
              <br />
              <div className="FormSect">Quality of life (Patient)</div>
              <div className="FormSect-Note">
                Please fill all the required field
              </div>
              <br />
              <div className="FormSect">
                Is it painful when you eat or drink?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Sometimes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Does your restricted mouth opening make eating or drinking
                difficult?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Sometimes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Do your family/ household members include you in communal
                mealtimes?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Sometimes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Can you smile and speak without pain?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Sometimes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">Do you like the way you look?</div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Sometimes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Physical health- In the past month, has your physical health
                been good?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Is it painful when you eat or drink?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Sometimes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Physical health- How many days in the past month has your physical health not been good?"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect">
                Physical health- How many days in the past month has your
                physical pain stopped you from doing your usual activities?
              </div>
              <div className="FormSect-Contianer00">
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Enter here"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect">
                Physical health- How much pain are you in today? *1 is none and
                7 is unbearable pain
              </div>
              <div className="FormSect-Contianer00">
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Enter here"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect">
                Independence- Do you feel noma has limited your ability to take
                care of yourself?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Independence- What would help you to reduce the feeling of not
                being able to take care of yourself?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Learning how to feed, dress, care for myself" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Assistance with finding a house where I can bemore independent" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Assistance finding a job which would allow me to earn a living to support myself" />
                      </div>

                      <div className="FormSect-Contianer00">
                        <Form>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row g-2">
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check label="Surgery to fix the face defect so I can integrate more easily into society" />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check label="Other: {other_independence}" />
                              </div>
                            </div>
                          </div>{" "}
                        </Form>
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Social inclusion- Do people exclude you from social activities
                due to noma?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Social inclusion- Have your family been left out of communal
                activities because of your noma diagnosis?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Social inclusion- Do people treat you the same as they treat
                other community members?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Social inclusion- Do you feel your prospects for love
                relationships/ marriage etc are negatively impacted by No noma?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Social inclusion- Are you included in religious/ spiritual
                gatherings?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Mental health- How do you feel about your life now?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Hopeful" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Excited" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Unsure" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Sad" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Other: {other_mh}" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Mental health- In the past two weeks, have you felt sad,
                anxious, worried?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                How many days in the past two weeks, have you felt sad, anxious,
                worried?
              </div>
              <div className="FormSect-Contianer00">
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Enter here"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect">
                How happy are you with your life (1 you are very happy and 7 you
                are very unhappy)?
              </div>
              <div className="FormSect-Contianer00">
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Enter here"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="FormSect">
                Quality of life (Caretaker cpompleting , "your child" is the
                noma Patient)
              </div>
              <div className="FormSect-Note">
                Please fill all the required field
              </div>
              <br />
              <div className="FormSect">
                Is it painful for your child to eat or drink?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Sometimes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Don't know" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Does your child's restricted mouth opening make eating or
                drinking difficult?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Sometimes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Don't know" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Do you include your child in communal mealtimes?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Sometimes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Don't know" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Do people make fun of the way your child eats, drinks or speaks?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Sometimes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Don't know" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Can your child smile and speak without pain?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Sometimes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Don't know" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Does your child like the way they look?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Sometimes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Don't know" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Physical health- In the past month, has your child's physical
                health been good?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Sometimes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Don't know" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Physical health- How many days in the past month has your
                child's physical health not been good?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Physical health- In the past month, has physical pain stopped
                your child from doing their usual activities such as playing,
                going to school etc.?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Physical health- How many days in the past month has your
                child's physical pain stopped them doing their usual activities?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Independence- Do you feel noma has limited your child's ability
                to take care of themselves?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Social inclusion- Do people exclude your child from social
                activities due to noma?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Social inclusion- Have your family been left out of communal
                activities because of your child's noma?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Social inclusion- Do people treat your child the same as they
                treat other community members?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Social inclusion- Are you and your child included in religious/
                spiritual gatherings?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Mental health- In the past two weeks, has your child felt sad,
                anxious or worried?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                How many days in the past two weeks has your child felt sad,
                anxious or worried?
              </div>
              <div className="FormSect-Contianer00">
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Enter here"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect">Psychosocial needs</div>
              <div className="FormSect">Psychosocial needs of the patient</div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Psychological support" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Nutritional support" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Medical treatment
"
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Short term housing for patient and caretaker
"
                        />
                      </div>

                      <div className="FormSect-Contianer00">
                        <Form>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row g-2">
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check label="Reintegration with family" />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check label="Vocational training" />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check
                                  label="Schooling/ university
"
                                />
                              </div>
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check
                                  label="Acquiring land for farming
"
                                />
                              </div>
                            </div>
                          </div>{" "}
                        </Form>
                      </div>
                      <div className="FormSect-Contianer00">
                        <Form>
                          <div className="container-fluid p-0 FormInput">
                            <div className="row g-2">
                              <div className="col-lg-3 CheckSheet">
                                <Form.Check label="Settled in grant (e.g. for purchase of sewing machine after tailor training)" />
                              </div>

                              <div className="col-lg-3 CheckSheet">
                                <Form.Check
                                  label="Other: {other_tx}
"
                                />
                              </div>
                            </div>
                          </div>{" "}
                        </Form>
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Presenting Concerns</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>

                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Presenting Concerns"
                        multiline
                        rows={5}
                        value={presentingConcern}
                        onChange={(e) => setPresentingConcern(e.target.value)}
                        // defaultValue="Default Value"
                      /> */}
                      {/* Copy Editor */}
                      <RichTextEditor
                        className="editor"
                        value={value}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Relevant Medical History</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Relevant Medical History"
                        multiline
                        rows={5}
                        value={relevantMedicalHistory}
                        onChange={(e) => setRelevantMedicalHistory(e.target.value)}
                        // defaultValue="Default Value"
                      /> */}
                      <RichTextEditor
                        className="editor"
                        value={value2}
                        onChange={onChange2}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Psychosocial factors </div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Psychosocial factors"
                        multiline
                        rows={5}
                        value={psychosocialFactors}
                        onChange={(e) => setPsychosocialFactors(e.target.value)}
                        // defaultValue="Default Value"
                      /> */}
                      <RichTextEditor
                        className="editor"
                        value={value3}
                        onChange={onChange3}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {loading ? (
                <Bars
                  heigth="50"
                  width="50"
                  color="gray"
                  ariaLabel="loading-indicator"
                />
              ) : (
                <button className="Add-Button" onClick={handleAdd}>
                  Add Patient Assessment
                </button>
              )}
              <br /> <br /> <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}



export default UpdateAssessment