import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { login } from '../../services/AuthService'

const initialState = {
    loading: false,
    user: {} ,
    user: '' ,
    isSuccess2Fa: false,
}

export const loginUser = createAsyncThunk('loginUser', (initData) => {
    return login(initData)
    .then((response) => response.data)
})


const loginSlice = createSlice({
    name: 'login',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(loginUser.pending, (state) => {
        state.loading = true
       })
       builder.addCase(loginUser .fulfilled, (state, action ) => {
        state.loading = false
        state.isSuccessFul = true
        state.token = action.payload.token
        state.user = action.payload.user
       })
       builder.addCase(loginUser .rejected, (state, action ) => {
        state.isSuccessFul = false
        state.loading = false
       })
    }
})

export default loginSlice.reducer 