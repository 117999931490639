import axios from '../axiosinstance';

      
export function listPatientCollaborative(initData){
        return axios.get('/patient-collaboratives', { params: { name: initData?.search, patient: initData?.patientId } })
}

export function addPatientCollaborative(data){
        return axios.post('/patient-collaboratives', data)
}

export function getPatientCollaborative(initData){
        return axios.get('/patient-collaboratives/'+initData?.id)
}

export function updatePatientCollaborative(initData){
        return axios.patch('/patient-collaboratives/'+initData.id, initData)
}

export function patientCollaborativeCount(search){
        return axios.get('/patient-collaboratives/stat/count', { params: search })
}

