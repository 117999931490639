import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import RichTextEditor from "react-rte"; // Copy Editor

function DSNoteModal({ show, onClose }) {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const [value2, setValue2] = useState(RichTextEditor.createEmptyValue());
  const [value3, setValue3] = useState(RichTextEditor.createEmptyValue());
  // const [value4, setValue4] = useState(RichTextEditor.createEmptyValue());

  const [note, setNote] = useState("");

  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);

  const handleSub = () => {
    onClose();
    setAddReportShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const onChange = (value) => {
    setValue(value);
    value.toString("html");
    setNote(value.toString("html"));
  };

  {
    /* Copy Editor */
  }
  const onChange2 = (value) => {
    setValue2(value);
    value.toString("html");
    setNote(value.toString("html"));
  };

  /* Copy Editor */

  const onChange3 = (value) => {
    setValue3(value);
    value.toString("html");
    setNote(value.toString("html"));
  };

  //         const onChange4 = (value) => {
  //           setValue4(value);
  //           value.toString("html");
  //           setOperation(value.toString("html"));
  //         };

  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                      Discharge Summary
                    </div>
                    <div className="Solera-Settings-Note">Add a Note</div>
                  </div>
                </div>
                <br />
                <br />
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="Solera-Settings-Header44">
                      Discharge Diagnosis
                    </div>
                    <div className="Solera-Settings-Note">
                    Add the discharge diagnosis number for each line.
                    </div>
                    <div className="col">
                      {/* Copy Editor */} <br />
                      <RichTextEditor
                        className="editor"
                        value={value}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="Solera-Settings-Header44">
                    Discharge Diagnosis
                  </div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Procedures and Therapies"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Complications"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Consultations"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>{" "}
                <br />
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="Solera-Settings-Header44">
                      Pertinent History
                    </div>
                    <br />
                    {/* <div className="Solera-Settings-Note">
                      Add by numbers tha
                    </div> */}
                    <div className="col">
                      <RichTextEditor
                        className="editor"
                        value={value2}
                        onChange={onChange2}
                      />
                    </div>
                  </div>
                </div>   <br/>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="Solera-Settings-Header44">Lab</div>
                    <br />
                    {/* <div className="Solera-Settings-Note">
                      Add by numbers tha
                    </div> */}
                    <div className="col">
                      <RichTextEditor
                        className="editor"
                        value={value3}
                        onChange={onChange3}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Conduct on Discharge"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Disposition"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Discharge to"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Diet"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Activity"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="DME"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Home Health Service"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Lab2"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="F/U Apts"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Meds"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Stop"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Modify"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="New Rx"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                   
                  </div>
                </div>
                <div className="FormUpload">
                  <button className="Add-Button" onClick={handleSub}>
                    Submit Discharge Summary
                  </button>
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default DSNoteModal;
