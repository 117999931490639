import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientTreatmentPlans } from '../../../services/PatientTreatmentPlanService'

const initialState = {
    loading: false,
    plans: [],
    isSuccessful: false
}

export const fetchTreatmentPlan = createAsyncThunk('fetchTreatmentPlan', (initData) => {
    return listPatientTreatmentPlans(initData)
    .then((response) => response.data.data)
})


const treatmentPlanSlice = createSlice({
    name: 'treatmentPlans',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchTreatmentPlan.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchTreatmentPlan.fulfilled, (state, action ) => {
        state.loading = false
        state.plans = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchTreatmentPlan.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default treatmentPlanSlice.reducer 