import React, { useEffect } from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import { FiUserCheck } from "react-icons/fi";
import NumberOfExpiredIytems from "./NumberOfExpiredIytems";
import NumberOfItems from "./NumberOfItems";
import NumberOfOutOfStock from "./NumberOfOutOfStock";
import { Link } from "react-router-dom";
import NumberOfReleasedItems from "./NumberOfReleasedItems";
import { useDispatch, useSelector } from "react-redux";
import { fetchNonConsumableCount } from "../../features/nonConsumables/nonConsumableCountSlice";
import { fetchNonConsumableOutOfStockCount } from "../../features/nonConsumables/nonConsumableOutOfStockCountSlice";
import { fetchNonConsumableExpiredCount } from "../../features/nonConsumables/nonConsumableExpiredCountSlice";
import { fetchReleasedNonConsumableCount } from "../../features/nonConsumables/releasedNonConsumableCountSlice";

function NoMedicalConsumablesActivities() {
  const dispatch = useDispatch()
  const nonConsumableCount = useSelector((state) => state.nonConsumableCount)
  const nonConsumableOutOfStockCount = useSelector((state) => state.nonConsumableOutOfStockCount)
  const nonConsumableExpiredCount = useSelector((state) => state.nonConsumableExpiredCount)
  const releasedNonConsumableCount = useSelector((state) => state.releasedNonConsumableCount)

  useEffect(() => {
    dispatch(fetchNonConsumableCount({type: 'non-consume', status: "available"}))
    dispatch(fetchNonConsumableOutOfStockCount({type: 'non-consume', outoff: true}))
    dispatch(fetchNonConsumableExpiredCount({type: 'non-consume', expired: true}))
    dispatch(fetchReleasedNonConsumableCount({type: 'non-consume', status: "released"}))

  }, [])

  return (
    <div>
      <div className="container-fluid ">
        <div className="row g-2 ">
          <div className="col-lg">
            {" "}
            <Link className="ConsumablesBTN Admin-Heading" to="/non-consumables">
              <div className="Admin-Heading-Container">
                <div className="DashBoard-Cont">
                  <div>
                    <div className="Admin-Heading">Available Items</div>
                  </div>
                  <div className="Logo-Admin-Heading">
                    <FiUserCheck className="Data-Icon" />
                  </div>
                </div>

                <NumberOfItems num={nonConsumableCount?.count} loading={nonConsumableCount?.loading} />
              </div>
            </Link>
          </div>
          <div className="col-lg">
            <Link className="ConsumablesBTN Admin-Heading" to="/release-non-consumables">
              <div className="Admin-Heading-Container">
                <div className="DashBoard-Cont">
                  <div>
                    <div className="Admin-Heading">Released Items</div>
                  </div>
                  <div className="Logo-Admin-Heading">
                    <FiUserCheck className="Data-Icon" />
                  </div>
                </div>

                <NumberOfReleasedItems num={releasedNonConsumableCount?.count} loading={releasedNonConsumableCount?.loading} />
              </div>{" "}
            </Link>
          </div>

          <div className="col-lg">
            <Link className="ConsumablesBTN  Admin-Heading" to="/expired-non-consumables">
              <div className="Admin-Heading-Container">
                <div className="DashBoard-Cont">
                  <div>
                    <div className="Admin-Heading">Expired Items</div>
                  </div>
                  <div className="Logo-Admin-Heading">
                    <FiUserCheck className="Data-Icon" />
                  </div>
                </div>

                <NumberOfExpiredIytems num={nonConsumableExpiredCount?.count} loading={nonConsumableExpiredCount?.loading} />
              </div>{" "}
            </Link>
          </div>
          <div className="col-lg">
            {" "}
            <Link className="ConsumablesBTN Admin-Heading" to="/outofstock-non-consumables">
              <div className="Admin-Heading-Container">
                <div className="DashBoard-Cont">
                  <div>
                    <div className="Admin-Heading">Out Of Stock</div>
                  </div>
                  <div className="Logo-Admin-Heading">
                    <FiUserCheck className="Data-Icon" />
                  </div>
                </div>

                <NumberOfOutOfStock num={nonConsumableOutOfStockCount?.count} loading={nonConsumableOutOfStockCount?.loading} />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoMedicalConsumablesActivities;
