import { React, useEffect, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import AddPreOpNote from "../ClinicalNote/AddPreOpNote";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import Dropdown from "react-bootstrap/Dropdown";
import EnterPatientPrescription from "../Medication/EnterPatientPrescription";
import ViewPrescriptionModal from "./ViewPrescriptionModal";
import EditPrescription from "./EditPrescription";
import AdminsterPrescriptionModal from "./AdminsterPrescriptionModal";
import TreatementNumber from "../../../../Component/TreatementNumber";
import PrescribedBy from "../../../../Component/PrescribedBy";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientPrescriptions } from "../../../../features/patients/patientPrescriptionsSlice";
import { Bars } from "react-loader-spinner";
import moment from "moment";
import UploadDoc from "./UploadDoc";
import ViewUploadModal from "./ViewUploadModal";
import { conCatName } from "../../../../services/extras";
import { getSys } from "../../../../services/AuthService";

function Prescription() {
  const sys = getSys();

  let { id } = useParams();
  const dispatch = useDispatch()

  const {listPrescriptions, loading} = useSelector((state) => state.patientPrescriptions)
  const [selected, setSelected] = useState({});

  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);

  const [viewPrescriptionsShow, setViewPrescriptionsShow] = useState(false);
  const handleViewPrescriptions = () => setViewPrescriptionsShow(false);

  const [adminsiterPrescriptionShow, setAdminsiterPrescriptionShow] =
    useState(false);
  const handleAdminsiterPrescription = () =>
    setAdminsiterPrescriptionShow(false);

  const [editPreOpNoteDetailsShow, setEditPreOpNoteDetailsShow] =
    useState(false);
  const handleEditPreOpNoteDetails = () => setEditPreOpNoteDetailsShow(false);

  const [uploadPrescriptionShow, setUploadPrescriptionShow] =
    useState(false);
  const handleUploadPrescriptionDetails = () => setUploadPrescriptionShow(false);

  const [viewUploadShow, setViewUploadShow] =
    useState(false);
  const handleViewUpload = () => setViewUploadShow(false);

  useEffect(() => {
    if (id) {
      dispatch(fetchPatientPrescriptions({patientId: id}))
    }
  }, [id]);

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }

  return (
    <div>
      
      <div className="Report-Notes">
        <div className="ReportHeader">
          <div className="Report-Heading">Prescription</div>
          <div className="Report-Count">
            <span>{listPrescriptions?.length}</span>&nbsp;Prescription
          </div>

          {sys?.role === "sys-admin" ||
                      sys?.role === "sys-doctor" ? (
                        <EnterPatientPrescription /> 
                      ) : null}
  
        </div>
      </div>
      <br />
      {loading ? (
              <div className="d-flex justify-content-center">
                <Bars
                  heigth="30"
                  width="30"
                  color="gray"
                  ariaLabel="loading-indicator"
                />
              </div>
          ) : null}
      {
        listPrescriptions?.map((prescrip, index) => (
          <div key={index} className="Patient-Record-Container">
            <div className="Patient-Record-Wrapper">
              
              {/* <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Treatment Number</div>
                <div className="Wallet-Ref-Result">
                  <TreatementNumber treamentNum={prescrip?.treatment_infos[0]?.code} />
                </div>
              </div> */}
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Prescription(s)</div>
                <div className="Wallet-Ref-Result">{prescrip?.count}</div>
              </div>

              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Prescribed by</div>
                <div className="Wallet-Ref-Result"><PrescribedBy  name={conCatName(
                    prescrip?.user_infos[0]?.first_name,
                    prescrip?.user_infos[0]?.last_name
                  )} /></div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Date</div>
                <div className="Wallet-Ref-Result">{showDate(prescrip?.created_at)}</div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Status</div>
                <div className="Wallet-Ref-Result">{prescrip?.status}</div>
              </div>
              <div className="Action-Drop-Down">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
                    Manage prescription
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {setSelected(prescrip); setAdminsiterPrescriptionShow(true)}}
                      className="Solera-Patients"
                    >
                      Dispense prescription
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {setSelected(prescrip); setViewPrescriptionsShow(true)}}
                      className="Solera-Patients"
                    >
                      View prescription
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {setSelected(prescrip); setUploadPrescriptionShow(true)}}
                      className="Solera-Patients"
                    >
                      Upload prescription
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {setSelected(prescrip); setViewUploadShow(true)}}
                      className="Solera-Patients"
                    >
                      View uploaded prescription
                    </Dropdown.Item>
                    
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        ))
      }

      <div>
        <AdminsterPrescriptionModal
          selected={selected}
          show={adminsiterPrescriptionShow}
          onClose={handleAdminsiterPrescription}
          onOpen={() => setAdminsiterPrescriptionShow(true)}
        />
        <ViewPrescriptionModal
          selected={selected}
          show={viewPrescriptionsShow}
          onClose={handleViewPrescriptions}
          onOpen={() => setViewPrescriptionsShow(true)}
        />

        <UploadDoc 
          named={'Prescription'}
          selected={selected}
          show={uploadPrescriptionShow}
          onClose={handleUploadPrescriptionDetails}
          onOpen={() => setUploadPrescriptionShow(true)}
        />

          <ViewUploadModal 
            selected={selected}
            show={viewUploadShow}
            onClose={handleViewUpload}
            onOpen={() => setViewUploadShow(true)}
          />  

        {/* <EditPrescription
          selected={selected}
          show={editPreOpNoteDetailsShow}
          onClose={handleEditPreOpNoteDetails}
          onOpen={() => setEditPreOpNoteDetailsShow(true)}
        /> */}
      </div>
    </div>
  );
}

export default Prescription;
