import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import "../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Swal from "sweetalert2";
import { getUser } from "../../services/AuthService";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import { fetchNurses } from "../../features/nurses/nursesSlice";
import { useDispatch } from "react-redux";
import { addNurse } from "../../services/NurseService";

function AddNurseModal({ show, onClose, onOpen }) {
  const dispatch = useDispatch()
  const user = getUser();

  const [loading, setLoading] = useState(false);

  const [otherName, setOtherName] = useState('');
  const [errOtherName, setErrOtherName] = useState("");
  const [lastName, setLastName] = useState('');
  const [errlastName, setErrLastName] = useState("");
  const [gender, setGender] = useState('');
  const [errGender, setErrGender] = useState("");
  const [age, setAge] = useState('');
  const [errAge, setErrAge] = useState("");
  const [email, setEmail] = useState('');
  const [errEmail, setErrEmail] = useState("");
  const [phone, setPhone] = useState('');
  const [errPhone, setErrPhone] = useState("");
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [stateN, setSateN] = useState('');
  const [department, setDepartment] = useState('');

  const [addNurseShow, setAddNurseShow] = useState(false);
  const handleAddNurse = () => setAddNurseShow(false);


  const handleAdd = () => {
    if (!otherName) {
      setErrOtherName("First name is required");
      return;
    }
    if (!lastName) {
      setErrLastName("Last name is required");
      return;
    }
    // if (!age) {
    //   setErrAge("Age is required");
    //   return;
    // }
    if (!gender) {
      setErrGender("Gender is required");
      return;
    }

    if (!email) {
      setErrEmail("Email is required");
      return;
    }

    if (!phone) {
      setErrPhone("Phone is required");
      return;
    }

    setLoading(true);
    const data = {
      user: user?._id,
      first_name: otherName,
      last_name: lastName,
      // age: age,
      gender: gender,
      email: email,
      phone: phone,
      // address: address,
      // city: city,
      // state: stateN,
      role: department,
    };
    console.log(data);
    addNurse(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          setAddNurseShow(false);
          dispatch(fetchNurses({}))
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  const sex = [
    {
      value: "",
      label: "Gender",
    },
    {
      value: "male",
      label: "Male",
    },
    {
      value: "female",
      label: "Female",
    },
  ];

  const roles = [
    {
      value: "matron",
      label: "Matron",
    },
    {
      value: "regular",
      label: "Regular",
    },
    {
      value: "social worker",
      label: "Social Worker",
    },
  ];

  return (
    <div>
      {" "}
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Solera__ModalHeadings">
              <MdClose className="Solera__Close" onClick={onClose} />
            </div>

            <div className="Solera__ModalHeadings"></div>
            <div className="Solera-Modal-Container">
              <div className="Solera__Headings">
                <div className="Log__Cont">
                  <img
                    src="/assets/lg.png"
                    alt=""
                    className="Log__Img__Modal"
                  />
                </div>
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">Add a Nurse</div>
                  <div className="Solera-Settings-Note">
                    Kindly add a Nurse for your records
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="FormSect-Contianer">
                <div className="FormSect">Nurse's Personal Details</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>

                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Other Names"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={otherName}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setOtherName("")
                          }else{
                            setOtherName("Name is required")
                          }
                          setOtherName(event.target.value)
                        }}
                      />
                      {(errOtherName) && <div className='input_error'>{errOtherName}</div>}  
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Last Name"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={lastName}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setErrLastName("")
                          }else{
                            setErrLastName("Last name is required")
                          }
                          setLastName(event.target.value)
                        }}
                      />
                      {(errlastName) && <div className='input_error'>{errlastName}</div>}
                    </div>
                  </div>
                </div>

                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <div>
                        <TextField
                          fullWidth
                          id="outlined-select-currency"
                          select
                          label="Select Gender"
                          defaultValue="Gender"
                          value={gender}
                          onChange={(event) => {
                            if(event.target.value.length){
                              setErrGender("")
                            }else{
                              setErrGender("Gender is required")
                            }
                            setGender(event.target.value)
                          }}
                        >
                          {sex.map((option, index) => (
                            <MenuItem key={index} value={option?.value || ""}>
                              {option.label}
                            </MenuItem>
                          ))}
                          {(errGender) && <div className='input_error'>{errGender}</div>}

                        </TextField>
                      </div>
                    </div>
                    {/* <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Age"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={age}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setErrAge("")
                          }else{
                            setErrAge("Age is required")
                          }
                          setAge(event.target.value)
                        }}
                      />
                      {(errAge) && <div className='input_error'>{errAge}</div>}
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Nurse's Contact Details</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Phone Number"
                        id="fullWidth"
                        type="number"
                        className="Solera-Login-Input mb-3"
                        value={phone}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setErrPhone("")
                          }else{
                            setErrPhone("Phone is required")
                          }
                          setPhone(event.target.value)
                        }}
                      />
                      {(errPhone) && <div className='input_error'>{errPhone}</div>}
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Email"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={email}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setErrEmail("")
                          }else{
                            setErrEmail("Email is required")
                          }
                          setEmail(event.target.value)
                        }}
                      />
                      {(errEmail) && <div className='input_error'>{errEmail}</div>}
                    </div>
                  </div>
                </div>
                {/* <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Address"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={address}
                        onChange={(event) => {
                          setAddress(event.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div> */}
                {/* <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="City"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={city}
                        onChange={(event) => {
                          setCity(event.target.value)
                        }}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="State"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={stateN}
                        onChange={(event) => {
                          setSateN(event.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Role</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>

                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <div>
                        <TextField
                          fullWidth
                          id="outlined-select-currency"
                          select
                          label="Select Role"
                          defaultValue="Role"
                          value={department}
                          onChange={(event) => {
                            setDepartment(event.target.value)
                          }}
                        >
                          {roles.map((option, index) => (
                            <MenuItem key={index} value={option?.value || ""}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                loading ? (
                  <Bars
                    heigth="30"
                    width="30"
                    color="gray"
                    ariaLabel="loading-indicator"
                  />
                ) : (
                  <button className="Add-Button" onClick={handleAdd}>
                    Add a Nurse
                  </button>
                ) 
              }
              
              <br /> <br /> <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default AddNurseModal;
