import { React, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import PostOpNoteModal from "./PostOpNoteModal";

function AddPostOpNote() {
  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);
  return (
    <div>
      <button
        className="Add-Patient-Report"
        onClick={() => setAddReportShow(true)}
      >
        Add preparation pre op
      </button>
      <div>
        <PostOpNoteModal
          show={addReportShow}
          onClose={handleAddReport}
          onOpen={() => setAddReportShow(true)}
        />
      </div>
    </div>
  );
}

export default AddPostOpNote;
