import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientTheatre } from '../../../services/PatientTheatreService'

const initialState = {
    loading: false,
    dietcians: [],
    isSuccessful: false
}

export const fetchPatientDietcian = createAsyncThunk('fetchPatientDietcian', (initData) => {
    return listPatientTheatre(initData)
    .then((response) => response.data.data)
})


const patientDietcianSlice = createSlice({
    name: 'patientDietcian',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientDietcian.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchPatientDietcian.fulfilled, (state, action ) => {
        state.loading = false
        state.dietcians = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientDietcian.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientDietcianSlice.reducer 