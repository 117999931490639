import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { patientCount } from '../../services/PatientService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchPatientAdmissionCount = createAsyncThunk('fetchPatientAdmissionCount', (initData) => {
    return patientCount(initData)
    .then((response) =>  response.data.data)
})


const patientAdmissionCountSlice = createSlice({
    name: 'patientAdmissionCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientAdmissionCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatientAdmissionCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default patientAdmissionCountSlice.reducer 