import { React, useState } from "react";
import PatientRegistrationDate from "../PatientPage/PatientRegistrationDate";
import Dropdown from "react-bootstrap/Dropdown";
import MedListView from "./MedListView";
import EditMed from "./EditMed";
import ReleaseItem from "./ReleaseItemModal";
import ReleaseItemModal from "./ReleaseItemModal";
import moment from "moment";

function MedList({consume}) {
  const [drugsDetailsShow, setDrugsDetailsShow] = useState(false);
  const handleDrugsDetails = () => setDrugsDetailsShow(false);

  const [selected, setSelected] = useState({});

  const [editDrugsShow, setEditDrugsShow] = useState(false);
  const handleEditDrugs = () => setEditDrugsShow(false);

  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }
  return (
    <div>
      <div className="Patient-Displays-Details2">
          <div className="PatientNumber-C">
            <div className="Wallet-Ref-Heading">Item Name</div>
            <div className="Wallet-Ref-Result">{consume?.name}</div>
          </div>

          <div className="Position-Cont-P">
            <div className="Wallet-Ref-Heading">Expiry Date</div>
            <div className="Wallet-Ref-Result">{showDate(consume?.created_at)}</div>
          </div>
          <div className="Role-Cont-P">
            <div className="Wallet-Ref-Heading">Quantity</div>
            <div className="Wallet-Ref-Result">{consume?.qty}</div>
          </div>
          <div className="Dept-Cont-P">
            <div className="Wallet-Ref-Heading">Status</div>
            <div className="Wallet-Ref-Result">{consume?.status}</div>
          </div>

          <div className="Action-Drop-Down">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
                Manage Item
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  className="Solera-Patients"
                  onClick={() => {setSelected(consume); setDrugsDetailsShow(true)}}
                >
                  View Item's Details
                </Dropdown.Item>

                <Dropdown.Item
                  className="Solera-Patients"
                  onClick={() => {setSelected(consume); setAddReportShow(true)}}
                >
                  Release Items
                </Dropdown.Item>

                <Dropdown.Item
                  className="Solera-Patients"
                  onClick={() => {setSelected(consume); setEditDrugsShow(true)}}
                >
                  Update Item
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      <div>
        <MedListView
          selected={selected}
          show={drugsDetailsShow}
          onClose={handleDrugsDetails}
          onOpen={() => setDrugsDetailsShow(true)}
        />
        <EditMed
          selected={selected}
          show={editDrugsShow}
          onClose={handleEditDrugs}
          onOpen={() => setEditDrugsShow(true)}
        />
        <ReleaseItemModal
          selected={selected}
          show={addReportShow}
          onClose={handleAddReport}
          onOpen={() => setAddReportShow(true)}
        />
      </div>
    </div>
  );
}

export default MedList;
