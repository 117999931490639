import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listConsumables } from '../../services/ConsumableService'

const initialState = {
    loading: false,
    outConsumes: [],
    paginator: {},
    isSuccessful: false
}

export const fetchOutOfStockConsumables = createAsyncThunk('fetchOutOfStockConsumables', (initData) => {
    return listConsumables(initData)
    .then((response) => response.data.data)
})


const outOfStockConsumablesSlice = createSlice({
    name: 'outOfStockConsumables',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchOutOfStockConsumables.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchOutOfStockConsumables.fulfilled, (state, action ) => {
        state.loading = false
        state.outConsumes = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchOutOfStockConsumables.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default outOfStockConsumablesSlice.reducer 