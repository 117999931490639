import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listDrugs } from '../../services/DrugService'

const initialState = {
    loading: false,
    drugs: [],
    paginator: {},
    isSuccessful: false
}

export const fetchDrugs = createAsyncThunk('fetchDrugs', (initData) => {
    return listDrugs(initData)
    .then((response) => response.data.data)
})


const drugsSlice = createSlice({
    name: 'drugs',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchDrugs.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchDrugs.fulfilled, (state, action ) => {
        state.loading = false
        state.drugs = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchDrugs.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default drugsSlice.reducer 