import axios from '../axiosinstance';

      
export function listPatientAssessment(initData){
        return axios.get('/patient-assessments', { params: { name: initData?.search, patient: initData?.patientId } })
}

export function addPatientAssessment(data){
        return axios.post('/patient-assessments', data)
}

export function getPatientAssessment(initData){
        return axios.get('/patient-assessments/'+initData?.id)
}

export function updatePatientAssessment(initData){
        return axios.patch('/patient-assessments/'+initData.id, initData)
}

export function patientAssessmentCount(search){
        return axios.get('/patient-assessments/stat/count', { params: search })
}

