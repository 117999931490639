import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { consumablesCount } from '../../services/ConsumableService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchNonConsumableOutOfStockCount = createAsyncThunk('fetchNonConsumableOutOfStockCount', (initData) => {
    return consumablesCount(initData)
    .then((response) =>  response.data.data)
})


const nonConsumableOutOfStockCountSlice = createSlice({
    name: 'nonConsumableOutOfStockCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchNonConsumableOutOfStockCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchNonConsumableOutOfStockCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default nonConsumableOutOfStockCountSlice.reducer 