import { React, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment";
import { fetchFluidChartsItems } from "../../../../features/patients/fluids/fluidChartsItemSlice";
import { useDispatch, useSelector } from "react-redux";
import { Bars } from "react-loader-spinner";

function ViewFluidDetails({ show, onClose, selected }) {
  const dispatch = useDispatch()
  const {fluidItems, loading, isSuccessful} = useSelector((state) => state.fluidChartsItems)

  const [viewvitaldetailsShow, setViewvitaldetailsShow] = useState(false);
  const handleViewvitaldetails = () => setViewvitaldetailsShow(false);

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }

  useEffect(() => {
    if (show) {
      dispatch(fetchFluidChartsItems({patient: selected?.patient?._id, fluid: selected?._id}))
    }
  }, [show]);

  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera-Modal-Container">
                <div className="Solera-Modal-Container">
                  <div className="Solera__ModalHeadings">
                    <MdClose className="Solera__Close" onClick={onClose} />
                  </div>

                  <div className="Solera__ModalHeadings"></div>
                  <div className="Solera-Modal-Container">
                    <div className="Solera__Headings">
                      <div className="Log__Cont">
                        <img
                          src="/assets/lg.png"
                          alt=""
                          className="Log__Img__Modal"
                        />
                      </div>
                      <div className="Solera__Headings__Note">
                        <div className="Solera-Settings-Header">
                          Fluid Balance Chart
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div>
                      <div className="FormSect">Details</div>
                      <div className="Doctors-Name">
                        Date : {showDate(selected?.created_at)}
                      </div>
                      <br />
                      <div>
                        <div className="Doctors-Name">
                          Intravenous Instructions.
                        </div>
                        {/* <div className="Report-Date">
                          {selected?.intravenous_instructions}
                        </div> */}

                        <div className="Vital-Box-Result">
                        <div dangerouslySetInnerHTML={{__html: selected?.intravenous_instructions}}></div>
                        </div>



                      </div>

                      <br />
                      <div>
                        <div className="Doctors-Name">Oral.</div>
                        {/* <div className="Report-Date">
                          {selected?.oral}
                        </div> */}

                        <div className="Vital-Box-Result">
                        <div dangerouslySetInnerHTML={{__html: selected?.oral}}></div>
                        </div>

                      </div>

                      <br />

                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <div className="FormSect">
                              Morning (AM){" "}
                              <span className="FormSect-Note">
                                Select the time accordily and update (From 8AM -
                                11AM)
                              </span>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div className="Doctors-Name">
                                Morning (AM) Select the time accordily and
                                update (From 9AM - 11AM).
                              </div>
                              {loading ? (
                                <div className="d-flex justify-content-center">
                                  <Bars
                                    heigth="30"
                                    width="30"
                                    color="gray"
                                    ariaLabel="loading-indicator"
                                  />
                                </div>
                            ) : null}
                            {
                              fluidItems?.map((item, index) => (
                                item?.category === 'morning' ? (
                                  <>
                                    <div key={index}>
                                    <br />
                                    <div className="Doctors-Name">{item?.time}</div>

                                    <div className="Report-Date">
                                      Nurses Name &nbsp;<span>{item?.user?.full_name}</span>
                                    </div>
                                    <div className="Report-Date">
                                      Date &nbsp;<span>{showDate(item?.created_at)}</span>
                                    </div>
                                    <br />

                                    <div className="container p-0">
                                      <div className="row g-5">
                                        <div className="col-lg-6">
                                          <div className="">
                                            <div>
                                              <div className="DisplayItem-Note">
                                                Input(ml)
                                              </div>
                                              <div className="DisplayItem-Note">
                                                Type:{" "}
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.input[0].measure}
                                                </span>
                                              </div>

                                              <div className="DisplayItem-Note">
                                                Tube
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.input[1].measure}
                                                </span>
                                              </div>
                                              <div className="DisplayItem-Note">
                                                Oral
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.input[2].measure}
                                                </span>
                                              </div>
                                              <div className="DisplayItem-Note">
                                                IV:
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.input[3].measure}
                                                </span>
                                              </div>
                                              <div className="DisplayItem-Note">
                                                Total
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.input[4].measure}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-lg-6">
                                          <div className="">
                                            <div className="DisplayItem-Note">
                                              Output(ml)
                                            </div>
                                            <div>
                                              <div className="DisplayItem-Note">
                                                Urine :
                                                <span className="FormSect-Note ">
                                                {item?.output[0].measure}
                                                </span>
                                              </div>

                                              <div className="DisplayItem-Note">
                                                Tube/Vomit
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.output[1].measure}
                                                </span>
                                              </div>
                                              <div className="DisplayItem-Note">
                                                Faeces :
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.output[2].measure}
                                                </span>
                                              </div>
                                              <div className="DisplayItem-Note">
                                                Type :
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.output[3].measure}
                                                </span>
                                              </div>
                                              <div className="DisplayItem-Note">
                                                Total :
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.output[4].measure}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                  </>
                                ) : null
                              ))
                            }
                            </div>
                            <br />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            <div className="FormSect">
                              Afternoon (PM) - &nbsp;&nbsp;
                              <span className="FormSect-Note">
                                Select the time accordily and update (From NOON
                                PM - 11PM)
                              </span>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div className="Doctors-Name">
                                Afternoon (PM) - Select the time accordily and
                                update (From NOON - 11PM)
                              </div>

                              {loading ? (
                                <div className="d-flex justify-content-center">
                                  <Bars
                                    heigth="30"
                                    width="30"
                                    color="gray"
                                    ariaLabel="loading-indicator"
                                  />
                                </div>
                            ) : null}
                            {
                              fluidItems?.map((item, index) => (
                                item?.category === 'afternoon' ? (
                                  <>
                                    <div key={index}>
                                    <br />
                                    <div className="Doctors-Name">{item?.time}</div>

                                    <div className="Report-Date">
                                      Nurses Name &nbsp;<span>{item?.user?.full_name}</span>
                                    </div>
                                    <div className="Report-Date">
                                      Date &nbsp;<span>{showDate(item?.created_at)}</span>
                                    </div>
                                    <br />

                                    <div className="container p-0">
                                      <div className="row g-5">
                                        <div className="col-lg-6">
                                          <div className="">
                                            <div>
                                              <div className="DisplayItem-Note">
                                                Input(ml)
                                              </div>
                                              <div className="DisplayItem-Note">
                                                Type:{" "}
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.input[0].measure}
                                                </span>
                                              </div>

                                              <div className="DisplayItem-Note">
                                                Tube
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.input[1].measure}
                                                </span>
                                              </div>
                                              <div className="DisplayItem-Note">
                                                Oral
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.input[2].measure}
                                                </span>
                                              </div>
                                              <div className="DisplayItem-Note">
                                                IV:
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.input[3].measure}
                                                </span>
                                              </div>
                                              <div className="DisplayItem-Note">
                                                Total
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.input[4].measure}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-lg-6">
                                          <div className="">
                                            <div className="DisplayItem-Note">
                                              Output(ml)
                                            </div>
                                            <div>
                                              <div className="DisplayItem-Note">
                                                Urine :
                                                <span className="FormSect-Note ">
                                                {item?.output[0].measure}
                                                </span>
                                              </div>

                                              <div className="DisplayItem-Note">
                                                Tube/Vomit
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.output[1].measure}
                                                </span>
                                              </div>
                                              <div className="DisplayItem-Note">
                                                Faeces :
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.output[2].measure}
                                                </span>
                                              </div>
                                              <div className="DisplayItem-Note">
                                                Type :
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.output[3].measure}
                                                </span>
                                              </div>
                                              <div className="DisplayItem-Note">
                                                Total :
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.output[4].measure}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                  </>
                                ) : null
                              ))
                            }
                            </div>
                            <hr />
                            <br />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            <div className="FormSect">
                              Evening (PM) -&nbsp;&nbsp;
                              <span className="FormSect-Note">
                                Select the time accordily and update (From 12MIDNIGHT -
                                7AM)
                              </span>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div className="Doctors-Name">
                                Evening (PM) - Select the time accordily and
                                update (From 12 MIDNIGHT- 7AM)
                              </div>
                              {loading ? (
                                <div className="d-flex justify-content-center">
                                  <Bars
                                    heigth="30"
                                    width="30"
                                    color="gray"
                                    ariaLabel="loading-indicator"
                                  />
                                </div>
                            ) : null}
                            {
                              fluidItems?.map((item, index) => (
                                item?.category === 'evening' ? (
                                  <>
                                    <div key={index}>
                                    <br />
                                    <div className="Doctors-Name">{item?.time}</div>

                                    <div className="Report-Date">
                                      Nurses Name &nbsp;<span>{item?.user?.full_name}</span>
                                    </div>
                                    <div className="Report-Date">
                                      Date &nbsp;<span>{showDate(item?.created_at)}</span>
                                    </div>
                                    <br />

                                    <div className="container p-0">
                                      <div className="row g-5">
                                        <div className="col-lg-6">
                                          <div className="">
                                            <div>
                                              <div className="DisplayItem-Note">
                                                Input(ml)
                                              </div>
                                              <div className="DisplayItem-Note">
                                                Type:{" "}
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.input[0].measure}
                                                </span>
                                              </div>

                                              <div className="DisplayItem-Note">
                                                Tube
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.input[1].measure}
                                                </span>
                                              </div>
                                              <div className="DisplayItem-Note">
                                                Oral
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.input[2].measure}
                                                </span>
                                              </div>
                                              <div className="DisplayItem-Note">
                                                IV:
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.input[3].measure}
                                                </span>
                                              </div>
                                              <div className="DisplayItem-Note">
                                                Total
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.input[4].measure}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-lg-6">
                                          <div className="">
                                            <div className="DisplayItem-Note">
                                              Output(ml)
                                            </div>
                                            <div>
                                              <div className="DisplayItem-Note">
                                                Urine :
                                                <span className="FormSect-Note ">
                                                {item?.output[0].measure}
                                                </span>
                                              </div>

                                              <div className="DisplayItem-Note">
                                                Tube/Vomit
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.output[1].measure}
                                                </span>
                                              </div>
                                              <div className="DisplayItem-Note">
                                                Faeces :
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.output[2].measure}
                                                </span>
                                              </div>
                                              <div className="DisplayItem-Note">
                                                Type :
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.output[3].measure}
                                                </span>
                                              </div>
                                              <div className="DisplayItem-Note">
                                                Total :
                                                <span className="FormSect-Note ">
                                                  {" "}
                                                  {item?.output[4].measure}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                  </>
                                ) : null
                              ))
                            }
                            </div>

                            <br />
                            <br />
                            <hr />
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <br />
                      <br />

                      <div>
                        <div className="Doctors-Name">TOTAL</div>
                        <div className="Report-Date">
                          Nurses Name  :&nbsp;<span>{selected?.user?.full_name}</span>
                        </div>
                        <div className="Report-Date">
                          Date &nbsp;<span>{showDate(selected?.created_at)}</span>
                        </div>
                        <br />
                        <div className="container p-0">
                          <div className="row g-5">
                            <div className="col-lg-6">
                              <div className="">
                                <div>
                                  <div className="DisplayItem-Note">
                                    Total Tube :{" "}
                                    <span className="FormSect-Note ">
                                      {" "}
                                      {selected?.total_tube}
                                    </span>
                                  </div>

                                  <div className="DisplayItem-Note">
                                    Total Oral :
                                    <span className="FormSect-Note ">
                                      {" "}
                                      {selected?.total_oral}
                                    </span>
                                  </div>
                                  <div className="DisplayItem-Note">
                                    Total IV:
                                    <span className="FormSect-Note ">
                                      {" "}
                                      {selected?.total_iv}
                                    </span>
                                  </div>
                                  <div className="DisplayItem-Note">
                                    Total Input:
                                    <span className="FormSect-Note ">
                                      {" "}
                                      {selected?.total_in}
                                    </span>
                                  </div>
                                  <div className="DisplayItem-Note">
                                    Total Output:
                                    <span className="FormSect-Note ">
                                      {" "}
                                      {selected?.total_output}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="">
                                <div>
                                  <div className="DisplayItem-Note">
                                    Total Urine :
                                    <span className="FormSect-Note ">
                                    {selected?.total_urine}
                                    </span>
                                  </div>

                                  <div className="DisplayItem-Note">
                                    Total Tube/Vomit :
                                    <span className="FormSect-Note ">
                                      {" "}
                                      {selected?.total_vomit}
                                    </span>
                                  </div>
                                  <div className="DisplayItem-Note">
                                    Total Faeces :
                                    <span className="FormSect-Note ">
                                      {" "}
                                      {selected?.total_faeces}
                                    </span>
                                  </div>
                                  <div className="DisplayItem-Note">
                                    Total Output :
                                    <span className="FormSect-Note ">
                                      {" "}
                                      {selected?.total_out}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
              <br />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ViewFluidDetails;
