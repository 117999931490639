import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { patientTheatreCount } from '../../../../services/PatientTheatreService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchPatientDietcianCount = createAsyncThunk('fetchPatientDietcianCount', (initData) => {
    return patientTheatreCount(initData)
    .then((response) =>  response.data.data)
})


const patientDietcianCountSlice = createSlice({
    name: 'patientDietcianCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientDietcianCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatientDietcianCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default patientDietcianCountSlice.reducer 