import { React, useState } from "react"
import { FiPlus } from "react-icons/fi";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import ReportModal from "./ReportModal";


function AddNurseReport() {
  const [addNurseShow, setAddNurseShow] = useState(false);
  const handleAddNurse = () => setAddNurseShow(false);
  return (
    <div>
      <button className="Add-Button" onClick={() => setAddNurseShow(true)}>
        <FiPlus className="Add-Icon"/>
        <span> Add Report</span>
      </button>
      < ReportModal
        show={addNurseShow}
        onClose={handleAddNurse}
        onOpen={() => setAddNurseShow(true)}
      />
    </div>
  );
}


export default AddNurseReport












// export default AddNurseReport