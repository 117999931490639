import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listReleaseConsumables } from '../../services/ReleaseConsumableService'

const initialState = {
    loading: false,
    releaseNonConsumes: [],
    paginator: {},
    isSuccessful: false
}

export const fetchReleaseNonConsumables = createAsyncThunk('fetchReleaseNonConsumables', (initData) => {
    return listReleaseConsumables(initData)
    .then((response) => response.data.data)
})


const releaseNonConsumablesSlice = createSlice({
    name: 'releaseNonConsumables',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchReleaseNonConsumables.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchReleaseNonConsumables.fulfilled, (state, action ) => {
        state.loading = false
        state.releaseNonConsumes = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchReleaseNonConsumables.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default releaseNonConsumablesSlice.reducer 