import React, { useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import AddClincalReport from "./AddClincalReport";
import {
  CiLineHeight,
  CiMail,
  CiMemoPad,
  CiMicrochip,
  CiPickerHalf,
  CiPill,
  CiSun,
  CiUser,
  CiViewList,
  CiViewTable,
  CiVirus,
  CiWheat,
} from "react-icons/ci";
import Physiotherapy from "./Physiotherapy";

import PreOpNote from "./PreOpNote";
import PostOpNote from "./PostOpNote";
import PostOpVitals from "./PostOpVitals";
import DoctorNote from "./DoctorNote";
import IntraoperativeSheet from "../../../../Component/Forms/IntraoperativeSheet/IntraoperativeSheet";
import { getSys } from "../../../../services/AuthService";
import DS from "./DS";

function ClinicalNote() {
  const [active, setActive] = useState("DoctorNote");

  const sys = getSys();
  return (
    <div>
      <div className="ReportHeader">
        <div className="Report-Heading">Clinical Notes</div>

        <div className="SociaL-Welfare-Cont">
          <div className="DocumemtBtn">
            {" "}
            {sys?.role === "sys-admin" || sys?.role === "sys-doctor" ? (
              <div
                className={
                  active === "DoctorNote" ? "ActiveIconX" : "InactiveIcon"
                }
                onClick={() => setActive("DoctorNote")}
              >
                <CiMemoPad className="Solera-Icons" />
                <div> Doctor's Note </div>
              </div>
            ) : null}
            {sys?.role === "sys-admin" || sys?.role === "sys-doctor" ? (
              <div
                className={
                  active === "IntraoperativeSheet"
                    ? "ActiveIconX"
                    : "InactiveIcon"
                }
                onClick={() => setActive("IntraoperativeSheet")}
              >
                <CiMemoPad className="Solera-Icons" />
                <div> Intra operative Sheet </div>
              </div>
            ) : null}
            {sys?.role === "sys-admin" || sys?.role === "sys-doctor" ? (
              <div
                className={
                  active === "PreOpNote" ? "ActiveIconX" : "InactiveIcon"
                }
                onClick={() => setActive("PreOpNote")}
              >
                <CiMemoPad className="Solera-Icons" />
                <div> Operative Report</div>
              </div>
            ) : null}
            {sys?.role === "sys-admin" || sys?.role === "sys-nurse" || sys?.role === "sys-doctor" ? (
              <div
                className={
                  active === "PostOpNote" ? "ActiveIconX" : "InactiveIcon"
                }
                onClick={() => setActive("PostOpNote")}
              >
                <CiMemoPad className="Solera-Icons" />
                <div>Preparation pre op</div>
              </div>
            ) : null}
          </div>

          <div className="DocumemtBtn">
            {sys?.role === "sys-admin" ||
            sys?.role === "sys-doctor" ||
            sys?.role === "sys-physiotherapist" ? (
              <div
                className={
                  active === "Physiotherapy" ? "ActiveIconX" : "InactiveIcon"
                }
                onClick={() => setActive("Physiotherapy")}
              >
                <CiMemoPad className="Solera-Icons" />
                <div> Physiotherapy </div>
              </div>
            ) : null}
            {sys?.role === "sys-admin" || sys?.role === "sys-doctor" ? (
              <div
                className={
                  active === "PostOpVitals" ? "ActiveIconX" : "InactiveIcon"
                }
                onClick={() => setActive("PostOpVitals")}
              >
                <CiMemoPad className="Solera-Icons" />
                <div> Post Op Vitals </div>
              </div>
            ) : null}
            {sys?.role === "sys-admin" || sys?.role === "sys-doctor" ? (
              <div
                className={active === "DS" ? "ActiveIconX" : "InactiveIcon"}
                onClick={() => setActive("DS")}
              >
                <CiMemoPad className="Solera-Icons" />
                <div> Discharge Summary </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div>
        <div>
          {active === "IntraoperativeSheet" && <IntraoperativeSheet />}
          {active === "Physiotherapy" && <Physiotherapy />}
          {active === "PostOpNote" && <PostOpNote />}
          {active === "PreOpNote" && <PreOpNote />}
          {active === "PostOpVitals" && <PostOpVitals />}
          {active === "DoctorNote" && <DoctorNote />}
          {active === "DS" && <DS />}
        </div>
      </div>
    </div>
  );
}

export default ClinicalNote;
