import { React, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import Swal from "sweetalert2";
import EditStaff from "./EditStaff";
import ViewStaff from "./ViewStaff";
import StaffNumber from "./StaffNumber";
import moment from "moment";
import { updateStaff } from "../../services/StaffService";
import { toast } from "react-toastify";
import { fetchStaffs } from "../../features/staff/staffsSlice";
import { useDispatch } from "react-redux";
import { fetchStaffCount } from "../../features/staff/staffCountSlice";

function Staff({staff}) {
  const dispatch = useDispatch()
  const [editStaffShow, setEditStaffShow] = useState(false);
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);

  const handleEditStaff = () => setEditStaffShow(false);

  const [viewStaffShow, setViewStaffShow] = useState(false);
  const handleViewStaff = () => setViewStaffShow(false);

  const handleRemoveStaff = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to remove this Staff?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#000000",
      cancelButtonColor: "#0078d4",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleRemove();
      }
    });
  };

  const handleRemove = () => {
    setLoading(true);
    const data = {
      id: staff?._id,
      status: "deleted",
    };
    updateStaff(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Removed"
          );
          dispatch(fetchStaffs({}))
          dispatch(fetchStaffCount({}))
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }
  return (
    <div>
      <div className="Patient-Displays-Details2">
        <div className="AdminType-C">
          <div className="Wallet-Ref-Heading">Staff Number</div>
          <div className="Wallet-Ref-Result">
            <StaffNumber num={staff?.cus_code} />
          </div>
        </div>
        <div className="StaffNumber">
          <div className="Wallet-Ref-Heading">Name</div>
          <div className="Wallet-Ref-Result">{staff?.full_name}</div>
        </div>
        
        <div className="StaffNumber">
          <div className="Wallet-Ref-Heading">Phone</div>
          <div className="Wallet-Ref-Result">{staff?.phone}</div>
        </div>
        <div className="StaffNumber">
          <div className="Wallet-Ref-Heading">Role</div>
          <div className="Wallet-Ref-Result">{staff?.role || "none"}</div>
        </div>
        <div className="Position-Cont-P">
          <div className="Wallet-Ref-Heading">Date</div>
          <div className="Wallet-Ref-Result">{showDate(staff?.created_at)}</div>
        </div>
        <div className="Action-Drop-Down">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
              Manage Staff
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => {setSelected(staff); setViewStaffShow(true)}} className="Solera-Patients">
                View Staff
              </Dropdown.Item>
              <Dropdown.Item onClick={() => {setSelected(staff); setEditStaffShow(true)}} className="Solera-Patients">
              Update Staff
              </Dropdown.Item>

              <Dropdown.Item onClick={handleRemoveStaff} className="Solera-Patients">
                Remove Staff
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div>
        <EditStaff
          selected={selected}
          show={editStaffShow}
          onClose={handleEditStaff}
          onOpen={() => setEditStaffShow(true)}
        />

        <ViewStaff
          selected={selected}
          show={viewStaffShow}
          onClose={handleViewStaff}
          onOpen={() => setViewStaffShow(true)}
        />
      </div>
    </div>
  );
}

export default Staff;
