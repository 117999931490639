import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientMedications } from '../../services/PatientMedicationService'

const initialState = {
    loading: false,
    listMedication: [],
    paginator: {},
    isSuccessful: false
}

export const fetchPatientMedication = createAsyncThunk('fetchPatientMedication', (initData) => {
    return listPatientMedications(initData)
    .then((response) => response.data.data)
})


const patientMedicationSlice = createSlice({
    name: 'patientMedication',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientMedication.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatientMedication.fulfilled, (state, action ) => {
        state.loading = false
        state.listMedication = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientMedication.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientMedicationSlice.reducer 