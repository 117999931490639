import { React, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import DocScanRequestModal from "./DocScanRequestModal";

function AddMRI() {
  const [mriShow, setMriShow] = useState(false);
  const handleMri = () => setMriShow(false);
  return (
    <div>
      <button
        className="Add-Patient-Report"
        onClick={() => setMriShow(true)}
      >
        MRI Scan to conduct
      </button>
      <div>
        <DocScanRequestModal
          show={mriShow}
          onClose={handleMri}
          selected={'MRI'}
          onOpen={() => setMriShow(true)}
        />
      </div>
    </div>
  );
}

export default AddMRI;
