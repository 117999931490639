import { React, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import Swal from "sweetalert2";
import ViewDoctor from "./ViewDoctor";
import EditDoctor from "./EditDoctor";
import StaffNumber from "../StaffPage/StaffNumber";
import { updateDoctor } from "../../services/DoctorService";
import { toast } from "react-toastify";
import { fetchDoctors } from "../../features/doctors/doctorsSlice";
import { useDispatch } from "react-redux";


function Doctors({doctor}) {
  const dispatch = useDispatch()

  const [editStaffShow, setEditStaffShow] = useState(false);
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);

  const handleEditStaff = () => setEditStaffShow(false);

  const [viewStaffShow, setViewStaffShow] = useState(false);
  const handleViewStaff = () => setViewStaffShow(false);

  const handleRemoveStaff = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to remove this Doctor?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#000000",
      cancelButtonColor: "#0078d4",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleUpdate()
      }
    });
  };

  const handleUpdate = () => {
    setLoading(true);
    const data = {
      id: doctor?._id,
      status: 'deleted',
    };
    updateDoctor(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          dispatch(fetchDoctors({}))
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  return (
    <div>
      <div className="Patient-Displays-Details2">
        <div className="PatientNumber-C">
          <div className="Wallet-Ref-Heading">Doctor's Name</div>
          <div className="Wallet-Ref-Result">{doctor?.full_name}</div>
        </div>
        <div className="StaffNumber">
          <div className="Wallet-Ref-Heading">Phone Number</div>
          <div className="Wallet-Ref-Result"><StaffNumber num={doctor?.phone} /></div>
        </div>
        <div className="AdminType-C">
          <div className="Wallet-Ref-Heading">Staff Number</div>
          <div className="Wallet-Ref-Result">{doctor?.cus_code}</div>
        </div>

        {/*  <div className="Position-Cont-P">
          <div className="Wallet-Ref-Heading">Position</div>
          <div className="Wallet-Ref-Result">Doctor</div>
        </div>*/}
       
        <div className="Role-Cont-P">
          <div className="Wallet-Ref-Heading">Role</div>
          <div className="Wallet-Ref-Result">{doctor?.role}</div>
        </div>

         {/*  <div className="Dept-Cont-P">
          <div className="Wallet-Ref-Heading">Department</div>
          <div className="Wallet-Ref-Result">Doctor</div>
        </div>*/}

        
        

        <div className="Action-Drop-Down">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
              Manage Doctor
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {setSelected(doctor); setViewStaffShow(true)}}
                className="Solera-Patients"
              >
                View Doctor
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {setSelected(doctor); setEditStaffShow(true)}}
                className="Solera-Patients"
              >
                Update Doctor
              </Dropdown.Item>

              <Dropdown.Item
                onClick={handleRemoveStaff}
                className="Solera-Patients"
              >
                Remove Doctor
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div>
        <EditDoctor
          selected={selected}
          show={editStaffShow}
          onClose={handleEditStaff}
          onOpen={() => setEditStaffShow(true)}
        />

        <ViewDoctor
          selected={selected}
          show={viewStaffShow}
          onClose={handleViewStaff}
          onOpen={() => setViewStaffShow(true)}
        />
      </div>
    </div>
  );
}

export default Doctors;
