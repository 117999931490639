import axios from '../axiosinstance';

      

//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

export function listNurseReports(initData){
        return axios.get('/nurse-reports', { params: initData })
}

export function addNurseReport(data){
        return axios.post('/nurse-reports', data)
}

export function getNurseReport(initData){
        return axios.get('/nurse-reports/'+initData?.id)
}

export function updateNurseReport(initData){
        return axios.patch('/nurse-reports/'+initData.id, initData)
}

export function nurseCountReport(search){
        return axios.get('/nurse-reports/stat/count', { params: { name: search } })
}

