import { React, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import AddPreOpNote from "../ClinicalNote/AddPreOpNote";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import Dropdown from "react-bootstrap/Dropdown";
import ViewPreOpNoteDetails from "./ViewPreOpNoteDetailsModal";
import ViewPreOpNoteDetailsModal from "./ViewPreOpNoteDetailsModal";
import EditPreOpNoteDetailsModal from "./EditPreOpNoteDetailsModal";
import ViewPostOpModal from "./ViewPostOpModal";
import EditPostOpModal from "./EditPostOpModal";
import AddPostOpNote from "./AddPostOpNote";
import AddDs from "./AddDs";
import ViewDsModal from "./ViewDsModal";
import UpdateDs from "./UpdateDs";

function DS() {
  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);

  const [editPreOpNoteDetailsShow, setEditPreOpNoteDetailsShow] =
    useState(false);
  const handleEditPreOpNoteDetails = () => setEditPreOpNoteDetailsShow(false);

  const handleSubComment = () => {
    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };
  return (
    <div>
      <div className="Report-Notes">
        <div className="ReportHeader">
          <div className="Report-Heading">Discharge Summary</div>
          <div className="Report-Count">
            <span>0</span>&nbsp;Discharge Summary
          </div>
          <AddDs />
        </div>
      </div>
      <br />
      <div className="Patient-Record-Container">
        <div className="Patient-Record-Wrapper">
          <div className="PatientNote-C">
            <div className="Wallet-Ref-Heading">Note Number</div>
            <div className="Wallet-Ref-Result">245364</div>
          </div>
          <div className="PatientNote-C">
            <div className="Wallet-Ref-Heading">Doctor's Name</div>
            <div className="Wallet-Ref-Result">name display</div>
          </div>
          <div className="PatientNote-C">
            <div className="Wallet-Ref-Heading">Date</div>
            <div className="Wallet-Ref-Result">20-20-20</div>
          </div>
          <div className="PatientNote-C">
            <div className="Wallet-Ref-Heading">Status</div>
            <div className="Wallet-Ref-Result">Submitted</div>
          </div>
          <div className="Action-Drop-Down">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
                Manage Note
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => setViewPreOpNoteDetailsShow(true)}
                  className="Solera-Patients"
                >
                  View Discharge Summary Note
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setEditPreOpNoteDetailsShow(true)}
                  className="Solera-Patients"
                >
                  Update Discharge Summary Note
                </Dropdown.Item>
                <Dropdown.Item
                  // onClick={() => setPatientDetailsShow(true)}
                  className="Solera-Patients"
                >
                  Delete Discharge Summary Note
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        
      </div>
      

      <div>
        <ViewDsModal
          show={viewPreOpNoteDetailsShow}
          onClose={handleViewPreOpNoteDetails}
          onOpen={() => setViewPreOpNoteDetailsShow(true)}
        />

        <UpdateDs
          show={editPreOpNoteDetailsShow}
          onClose={handleEditPreOpNoteDetails}
          onOpen={() => setEditPreOpNoteDetailsShow(true)}
        />
      </div>
    </div>
  );
}

export default DS