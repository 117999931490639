import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import "../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Swal from "sweetalert2";
import { getUser } from "../../services/AuthService";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import { fetchStaffs } from "../../features/staff/staffsSlice";
import { useDispatch } from "react-redux";
import { addStaff } from "../../services/StaffService";
import { fetchStaffCount } from "../../features/staff/staffCountSlice";

function AddStaffModal({ show, onClose }) {
  const dispatch = useDispatch()
  const user = getUser();

  const [loading, setLoading] = useState(false);

  const [otherName, setOtherName] = useState('');
  const [errOtherName, setErrOtherName] = useState("");
  const [lastName, setLastName] = useState('');
  const [errlastName, setErrLastName] = useState("");
  const [gender, setGender] = useState('');
  const [errGender, setErrGender] = useState("");
  const [phone, setPhone] = useState('');
  const [errPhone, setErrPhone] = useState("");
  const [role, setRole] = useState('');
  const [staffCode, setStaffCode] = useState('');
  // const [age, setAge] = useState('');
  // const [errAge, setErrAge] = useState("");
  // const [email, setEmail] = useState('');
  // const [errEmail, setErrEmail] = useState("");
  // const [address, setAddress] = useState('');
  // const [city, setCity] = useState('');
  // const [stateN, setSateN] = useState('');
  // const [department, setDepartment] = useState('');
  // const [task, setTask] = useState('');
  const [note, setNote] = useState('');

  const [addStaffShow, setAddStaffShow] = useState(false);
  const handleAddStaff = () => setAddStaffShow(false);


  const handleAdd = () => {
    if (!otherName) {
      setErrOtherName("First name is required");
      return;
    }
    if (!lastName) {
      setErrLastName("Last name is required");
      return;
    }
    // if (!age) {
    //   setErrAge("Age is required");
    //   return;
    // }
    if (!gender) {
      setErrGender("Gender is required");
      return;
    }

    // if (!email) {
    //   setErrEmail("Email is required");
    //   return;
    // }

    if (!phone) {
      setErrPhone("Phone is required");
      return;
    }

    setLoading(true);
    const data = {
      user: user?._id,
      first_name: otherName,
      last_name: lastName,
      gender: gender,
      phone: phone,
      role: role,
      staff_code: staffCode,
      // email: email,
      // address: address,
      // city: city,
      // state: stateN,
      // department: department,
      //task: task;
    };
    console.log(data);
    addStaff(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          setAddStaffShow(false);
          dispatch(fetchStaffs({}))
          dispatch(fetchStaffCount({}))
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  const sex = [
    {
      value: "",
      label: "Gender",
    },
    {
      value: "male",
      label: "Male",
    },
    {
      value: "female",
      label: "Female",
    },
  ];

  const tasks = [
    {
      value: "Doctor",
      label: "Doctor",
    },
    {
      value: "Nurse",
      label: "Nurse",
    },
    {
      value: "Matron",
      label: "Matron",
    },
    {
      value: "Lab Scientist",
      label: "Lab Scientist",
    },
    {
      value: "Pharmacist",
      label: "Pharmacist",
    },

    {
      value: "Radiographer",
      label: "Radiographer",
    },
  ];
  const departments = [
    {
      value: "",
      label: "Department",
    },
    {
      value: "general",
      label: "General",
    },
    {
      value: "laboratory",
      label: "Laboratory",
    },
    {
      value: "emergency",
      label: "Emergency",
    },
    {
      value: "pediatric",
      label: "Pediatric",
    },
  ];

  return (
    <div>
      {" "}
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Solera__ModalHeadings">
              <MdClose className="Solera__Close" onClick={onClose} />
            </div>

            <div className="Solera__ModalHeadings"></div>
            <div className="Solera-Modal-Container">
              <div className="Solera__Headings">
                {/* <div className="Log__Cont">
                  <img
                    src="/assets/lg.png"
                    alt=""
                    className="Log__Img__Modal"
                  />
                </div> */}
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">Add a Staff</div>
                  <div className="Solera-Settings-Note">
                    Kindly add a staff for your records
                  </div>
                </div>
              </div>
              <br />
              <br />
              {/* <div className="FormSect-Contianer">
                <div className="FormSect">Staff Account creation</div>
                <div className="FormSect-Note">
                  The phone number and password is used to login to staff page
                </div>

                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Phonenumber"
                        id="fullWidth"
                        type="nuber"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Password"
                        id="fullWidth"
                        type="password"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="FormSect-Contianer">
                <div className="FormSect">Staff Personal Details</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                
        

                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="First Names"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={otherName}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setOtherName("")
                          }else{
                            setOtherName("Name is required")
                          }
                          setOtherName(event.target.value)
                        }}
                      />
                      {(errOtherName) && <div className='input_error'>{errOtherName}</div>} 
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Last Name"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={lastName}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setErrLastName("")
                          }else{
                            setErrLastName("Last name is required")
                          }
                          setLastName(event.target.value)
                        }}
                      />
                      {(errlastName) && <div className='input_error'>{errlastName}</div>}
                    </div>
                  </div>
                </div>

                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <div>
                        <TextField
                          fullWidth
                          id="outlined-select-currency"
                          select
                          label="Select Gender"
                          defaultValue="Gender"
                          value={gender}
                          onChange={(event) => {
                            if(event.target.value.length){
                              setErrGender("")
                            }else{
                              setErrGender("Gender is required")
                            }
                            setGender(event.target.value)
                          }}
                        >
                          {sex.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                          {(errGender) && <div className='input_error'>{errGender}</div>}
                        </TextField>
                      </div>
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Phone"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={phone}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setErrPhone("")
                          }else{
                            setErrPhone("Phone is required")
                          }
                          setPhone(event.target.value)
                        }}
                      />
                      {(errPhone) && <div className='input_error'>{errPhone}</div>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Staff's Role</div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Role"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={role}
                        onChange={(event) => {
                          setRole(event.target.value)
                        }}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Staff Number"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={staffCode}
                        onChange={(event) => {
                          setStaffCode(event.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div>
                
              </div>
              {/* <div className="FormSect-Contianer">
                <div className="FormSect">Department</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>

                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <div>
                        <TextField
                          fullWidth
                          id="outlined-select-currency"
                          select
                          label="Select Department"
                          defaultValue="Department"
                          value={department}
                          onChange={(event) => {
                            setDepartment(event.target.value)
                          }}
                        >
                          {departments.map((option, index) => (
                            <MenuItem key={index} value={option.value || ""}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="FormSect-Contianer">
                <div className="FormSect">Task</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>

                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <div>
                        <TextField
                          fullWidth
                          id="outlined-select-currency"
                          select
                          label="Select Task"
                          defaultValue="Task"
                          value={task}
                          onChange={(event) => {
                            setTask(event.target.value)
                          }}
                        >
                          {tasks.map((option, index) => (
                            <MenuItem key={index} value={option.value || ""}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="FormSect-Contianer">
                <div className="FormSect">Staff Note</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <div>
                        <TextField
                          fullWidth
                          id="outlined-multiline-static "
                          label="Make a note for this staff"
                          multiline
                          rows={10}
                          value={note}
                          onChange={(event) => {
                            setNote(event.target.value)
                          }}
                          // defaultValue="Default Value"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                loading ? (
                  <Bars
                    heigth="30"
                    width="30"
                    color="gray"
                    ariaLabel="loading-indicator"
                  />
                ) : (
                  <button className="Add-Button" onClick={handleAdd}>
                    Add a Staff
                  </button>
                ) 
              }
              <br /> <br /> <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default AddStaffModal;
