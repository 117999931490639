import { React, useEffect, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import AddPreOpNote from "../ClinicalNote/AddPreOpNote";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import Dropdown from "react-bootstrap/Dropdown";
import ViewPhysioModal from "./ViewPhysioModal";
import EditPhysioModal from "./EditPhysioModal";
import AddPhysiotherapy from "./AddPhysiotherapy";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPhysiotherapy } from "../../../../features/patients/physiotherapySlice";
import moment from "moment";
import { Bars } from "react-loader-spinner";
import UploadClinicalNote from "./UploadClinicalNote";
import ViewPhysiotherapyUploadModal from "./ViewPhysiotherapyUploadModal";


function Physiotherapy() {
  let { id } = useParams();
  const dispatch = useDispatch()
  const {physiotherapies, loading, isSuccessful} = useSelector((state) => state.physiotherapiesList)

  const [selected, setSelected] = useState({});

  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);

  const [uploadNotesShow, setUploadNotesShow] =
    useState(false);
  const handleUploadNoteDetails = () => setUploadNotesShow(false);

  const [viewPhysiotherapyUploadShow, setViewPhysiotherapyUploadShow] =
    useState(false);
  const handleViewPhysiotherapyUpload = () => setViewPhysiotherapyUploadShow(false);

  const [editPreOpNoteDetailsShow, setEditPreOpNoteDetailsShow] =
    useState(false);
  const handleEditPreOpNoteDetails = () => setEditPreOpNoteDetailsShow(false);

  const handleSubComment = () => {
    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchPhysiotherapy({patientId: id, type: 'physiotherapy'}))
    }
  }, [id]);

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }

  return (
    <div>
      <div className="Report-Notes">
        <div className="ReportHeader">
          <div className="Report-Heading">Physiotherapy Note</div>
          <div className="Report-Count">
            <span>0</span>&nbsp;Physiotherapy Note
          </div>
          <AddPhysiotherapy />
        </div>
      </div>
      <br />
      {loading ? (
            <div className="d-flex justify-content-center">
              <Bars
                heigth="30"
                width="30"
                color="gray"
                ariaLabel="loading-indicator"
              />
            </div>
        ) : null
      }
      {
        physiotherapies?.map((item, index) => (
          <div key={index} className="Patient-Record-Container">
            <div className="Patient-Record-Wrapper">
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Note Number</div>
                <div className="Wallet-Ref-Result">{item?.code}</div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Doctor's Name</div>
                <div className="Wallet-Ref-Result">{item?.user?.full_name}</div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Date</div>
                <div className="Wallet-Ref-Result">{showDate(item?.created_at)}</div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Status</div>
                <div className="Wallet-Ref-Result">{item?.status}</div>
              </div>
              <div className="Action-Drop-Down">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
                    Manage Note
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {setSelected(item); setViewPreOpNoteDetailsShow(true)}}
                      className="Solera-Patients"
                    >
                      View Physiotherapy Note
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {setSelected(item); setUploadNotesShow(true)}}
                      className="Solera-Patients"
                    >
                      Upload Physiotherapy Note
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {setSelected(item); setViewPhysiotherapyUploadShow(true)}}
                      className="Solera-Patients"
                    >
                      View uploaded Physiotherapy Note
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      onClick={() => {setSelected(item); setEditPreOpNoteDetailsShow(true)}}
                      className="Solera-Patients"
                    >
                      Edit Physiotherapy Note
                    </Dropdown.Item> */}
                    {/* <Dropdown.Item
                      // onClick={() => setPatientDetailsShow(true)}
                      className="Solera-Patients"
                    >
                      Delete Physiotherapy Note
                    </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        ))
      
      }
      

      <div>
        <ViewPhysioModal
          selected={selected}
          show={viewPreOpNoteDetailsShow}
          onClose={handleViewPreOpNoteDetails}
          onOpen={() => setViewPreOpNoteDetailsShow(true)}
        />

        <UploadClinicalNote 
          named={'physiotherapy'}
          selected={selected}
          show={uploadNotesShow}
          onClose={handleUploadNoteDetails}
          onOpen={() => setUploadNotesShow(true)}
        />   

        <ViewPhysiotherapyUploadModal 
          selected={selected}
          show={viewPhysiotherapyUploadShow}
          onClose={handleViewPhysiotherapyUpload}
          onOpen={() => setViewPhysiotherapyUploadShow(true)}
        />

        {/* <EditPhysioModal
          show={editPreOpNoteDetailsShow}
          onClose={handleEditPreOpNoteDetails}
          onOpen={() => setEditPreOpNoteDetailsShow(true)}
        /> */}
      </div>
    </div>
  );
}

export default Physiotherapy;
