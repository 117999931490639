import { React, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import DocScanRequestModal from "./DocScanRequestModal";

function AddUltraSound() {
  const [soundScanShow, setSoundScanShow] = useState(false);
  const handleSoundScan = () => setSoundScanShow(false);
  return (
    <div>
      <button
        className="Add-Patient-Report"
        onClick={() => setSoundScanShow(true)}
      >
        Ultrasound Scan to conduct
      </button>
      <div>
        <DocScanRequestModal
          show={soundScanShow}
          onClose={handleSoundScan}
          selected={'ultrasound'}
          onOpen={() => setSoundScanShow(true)}
        />
      </div>
    </div>
  );
}

export default AddUltraSound;
