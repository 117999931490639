import { React, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import AddAllegiesModal from "../../../../Component/Modals/AddAllegiesModal";
import AddSurgicalModal from "./AddSurgicalModal";

function AddSurgicalCheckList() {
  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);
  return (
    <div>
      <div>
        <button
          className="Add-Patient-Report"
          onClick={() => setAddReportShow(true)}
        >
          Add Surgical Safety Checklist 
        </button>
        <AddSurgicalModal
          show={addReportShow}
          onClose={handleAddReport}
          onOpen={() => setAddReportShow(true)}
        />
      </div>
    </div>
  );
}

export default AddSurgicalCheckList;
