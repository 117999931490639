import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientLaps } from '../../services/PatientLapsService'

const initialState = {
    loading: false,
    patientLaps: [],
    paginator: {},
    isSuccessful: false
}

export const fetchPatientLaps = createAsyncThunk('fetchPatientLaps', (initData) => {
    return listPatientLaps(initData)
    .then((response) => response.data)
})


const patientLapsSlice = createSlice({
    name: 'patientLaps',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientLaps.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatientLaps.fulfilled, (state, action ) => {
        state.loading = false
        state.patientLaps = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientLaps.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientLapsSlice.reducer 