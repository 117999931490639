import React, { useEffect } from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import { FiUsers, FiUserCheck } from "react-icons/fi";
import NumberOfItems from "./NumberOfItems";
import NumberOfExpiredIytems from "./NumberOfExpiredIytems";
import NumberOfOutOfStock from "./NumberOfOutOfStock";
import NumberOfReleasedItems from "./NumberOfReleasedItems";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchConsumableCount } from "../../features/consumables/consumableCountSlice";
import { fetchConsumableOutOfStockCount } from "../../features/consumables/consumableOutOfStockCountSlice";
import { fetchConsumableExpiredCount } from "../../features/consumables/consumableExpiredCountSlice";
import { fetchReleasedConsumableCount } from "../../features/consumables/releasedConsumableCountSlice";

function MedicalConsumableActivities() {
  const dispatch = useDispatch()
  const consumableCount = useSelector((state) => state.consumableCount)
  const consumableOutOfStockCount = useSelector((state) => state.consumableOutOfStockCount)
  const consumableExpiredCount = useSelector((state) => state.consumableExpiredCount)
  const releasedConsumableCount = useSelector((state) => state.releasedConsumableCount)

  useEffect(() => {
    dispatch(fetchConsumableCount({type: 'consume', status: "available"}))
    dispatch(fetchConsumableOutOfStockCount({type: 'consume', outoff: true}))
    dispatch(fetchConsumableExpiredCount({type: 'consume', expired: true}))
    dispatch(fetchReleasedConsumableCount({type: 'consume', status: "released"}))
  }, [])

  return (
    <div>
      <div className="container-fluid ">
        <div className="row g-2 ">
          <div className="col-lg">
            <Link className="ConsumablesBTN Admin-Heading" to="/consumables">
              <div className="Admin-Heading-Container">
                <div className="DashBoard-Cont">
                  <div>
                    <div className="Admin-Heading">Available Items</div>
                  </div>
                  <div className="Logo-Admin-Heading">
                    <FiUserCheck className="Data-Icon" />
                  </div>
                </div>

                <NumberOfItems num={consumableCount?.count} loading={consumableCount?.loading} />
              </div>{" "}
            </Link>
          </div>
          <div className="col-lg">
            <Link className="ConsumablesBTN Admin-Heading" to="/release-consumables">
              <div className="Admin-Heading-Container">
                <div className="DashBoard-Cont">
                  <div>
                    <div className="Admin-Heading">Released Items</div>
                  </div>
                  <div className="Logo-Admin-Heading">
                    <FiUserCheck className="Data-Icon" />
                  </div>
                </div>

                <NumberOfReleasedItems num={releasedConsumableCount?.count} loading={releasedConsumableCount?.loading}    />
              </div>{" "}
            </Link>
          </div>

          <div className="col-lg">
            <Link className="ConsumablesBTN Admin-Heading" to="/expired-consumables">
              <div className="Admin-Heading-Container">
                <div className="DashBoard-Cont">
                  <div>
                    <div className="Admin-Heading">Expired Items</div>
                  </div>
                  <div className="Logo-Admin-Heading">
                    <FiUserCheck className="Data-Icon" />
                  </div>
                </div>

                <NumberOfExpiredIytems num={consumableExpiredCount?.count} loading={consumableExpiredCount?.loading} />
              </div>
            </Link>
          </div>
          <div className="col-lg">
            <Link className="ConsumablesBTN Admin-Heading" to="/outofstock-consumables">
              <div className="Admin-Heading-Container">
                <div className="DashBoard-Cont">
                  <div>
                    <div className="Admin-Heading">Out Of Stock</div>
                  </div>
                  <div className="Logo-Admin-Heading">
                    <FiUserCheck className="Data-Icon" />
                  </div>
                </div>

                <NumberOfOutOfStock num={consumableOutOfStockCount?.count} loading={consumableOutOfStockCount?.loading} />
              </div>{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MedicalConsumableActivities;
