import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdAddCircle, MdClose, MdRemoveCircle } from "react-icons/md";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import "./Modal.css";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUser } from "../../services/AuthService";
import { addPatientLaps } from "../../services/PatientLapsService";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import { fetchPatientLaps } from "../../features/patients/patientLapsSlice";
import RichTextEditor from 'react-rte';  // Copy Editor 



function ConductTextModal({ show, onClose, onOpen }) {

  const [value, setValue] = useState(RichTextEditor.createEmptyValue());// Copy Editor 
  const label = { inputProps: { "aria-label": "Checkbox demo" } };


  let { id } = useParams();
  const dispatch = useDispatch()
  const user = getUser();

  const [name, setName] = useState("");
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [serviceList, setServiceList] = useState([{ service: "" }]);

  const [addDoctorShow, setAddReportShow] = useState(false);
  const handleAddDoctor = () => setAddReportShow(false);


  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;
    setServiceList(list);
  };

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };

  const handleServiceAdd = () => {
    setServiceList([...serviceList, { service: "" }]);
  };

  const handleAdd = () => {

    setLoading(true);
    const data = {
      user: user?._id,
      patient: id,
      tests: serviceList,
      note: note
    };
    console.log(data);
    addPatientLaps(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          setAddReportShow(false);
          dispatch(fetchPatientLaps({patientId: id}))
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  {/* Copy Editor */}
  const onChange = (value) => {
    setValue(value);
    value.toString('html')
    setNote(value.toString('html'))
  };

  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                      Test to Conduct
                    </div>
                    <div className="Solera-Settings-Note">
                      Please specify the test to be performed on this patient.
                      Use the icon on the right to add or remove tests.
                    </div>
                  </div>
                </div>
                <br />
                <br />
                {serviceList &&
                  serviceList.map((singleService, index) => (
                    <ul key={index}>
                      {singleService.service && (
                        <li className="ListIt">{singleService.service}</li>
                      )}
                    </ul>
                  ))}
                <div className="FormSect-Contianer">
                  {/* <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      <div className="first-division">
                        <TextField
                            fullWidth
                            label="Add Test"
                            name="service"
                            type="text"
                            id="fullWidth"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            placeholder=" Add Test"
                            className="Solera-Login-Input mb-3"
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {serviceList.map((singleService, index) => (
                        <div key={index} className="services">
                          <div className="first-division">
                            <TextField
                              fullWidth
                              label="Add Test"
                              name="service"
                              type="text"
                              id="fullWidth"
                              value={singleService.service}
                              onChange={(e) => handleServiceChange(e, index)}
                              required
                              placeholder=" Add Test"
                              className="Solera-Login-Input mb-3"
                            />
                          </div>
                          <div className="RemoveAndAddBtn">
                            <div className="second-division">
                              {serviceList.length !== 1 && (
                                <MdRemoveCircle
                                  className="CounterIcon"
                                  type="button"
                                  onClick={() => handleServiceRemove(index)}
                                />
                              )}
                            </div>
                            <div>
                              {serviceList.length - 1 === index &&
                                serviceList.length < 100 && (
                                  <MdAddCircle
                                    className="CounterIcon"
                                    type="button"
                                    onClick={handleServiceAdd}
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="FormSect-Contianer">
                    <div className="container-fluid p-0 FormInput">
                      <div className="col">
                        {/* <TextField
                          fullWidth
                          id="outlined-multiline-static "
                          label="Make a test note for this patient"
                          multiline
                          rows={10}
                          value={note}
                            onChange={(e) => setNote(e.target.value)}
                          // defaultValue="Default Value"
                        /> */}

                 {/* Copy Editor */}
                 <RichTextEditor 
                  className="editor"
                  value={value} onChange={onChange} 
                />
                      </div>
                    </div>
                  </div>
                  <div className="FormUpload">
                  {
                    loading ? (
                    <Bars
                        heigth="30"
                        width="30"
                        color="gray"
                        ariaLabel="loading-indicator"
                    />
                    ) : (
                    <button className="Add-Button" onClick={handleAdd}>
                        Submit
                    </button>
                    ) 
                }
                    
                  </div>
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ConductTextModal;
