import { React } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import moment from "moment";

function PatientSummary({ show, onClose,  selected, }) {
  return (
    <div>

      <div>

        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose}/>
              </div>

              <div className="Patient-Summary Details">Patient Sumary</div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default PatientSummary;
