import { React, useState } from "react";
import { FiPlus } from "react-icons/fi";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import AddStaffModal from "./AddStaffModal";

function AddStaff() {
  const [addStaffShow, setAddStaffShow] = useState(false);
  const handleAddStaff = () => setAddStaffShow(false);
  return (
    <div>
      <button className="Add-Button" onClick={() => setAddStaffShow(true)}>
        <FiPlus className="Add-Icon" />
        <span> Add Staff</span>
      </button>
      <AddStaffModal
        show={addStaffShow}
        onClose={handleAddStaff}
        onOpen={() => setAddStaffShow(true)}
      />
    </div>
  );
}

export default AddStaff;
