import { React, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import { MdAddCircle, MdClose, MdRemoveCircle } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientMedication } from "../../../../features/patients/patientMedicationSlice";
import { Bars } from "react-loader-spinner";
import moment from "moment";

function AllMedictionModal({ show, onClose, onOpen, selected }) {
  const dispatch = useDispatch()
  const {listMedication, loading} = useSelector((state) => state.patientMedication)

  useEffect(() => {
    if (show) {
      dispatch(fetchPatientMedication({treatment: selected?.treatment_infos[0]?._id}))
    }
  }, [show]);

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY hh:mm')
  }

  //treatment_infos
  return (
    <div>
      <div>
        <div>
          {" "}
          <div>
            <Modal
              size="xl"
              show={show}
              onHide={onClose}
              backdrop="static"
              aria-labelledby="example-modal-sizes-title-lg"
              keyboard={false}
              animation={true}
            >
              <Modal.Body>
                <div className="Solera__ModalHeadings">
                  <MdClose className="Solera__Close" onClick={onClose} />
                </div>

                <div className="Solera__ModalHeadings"></div>
                <div className="Solera-Modal-Container">
                  <div className="Solera__Headings">
                    <div className="Log__Cont">
                      <img
                        src="/assets/lg.png"
                        alt=""
                        className="Log__Img__Modal"
                      />
                    </div>
                    <div className="Solera__Headings__Note">
                      <div className="Solera-Settings-Header">
                        Parient's Daily Medication
                      </div>
                      <div className="Solera-Settings-Note">
                        Medication display as entered by the nurse is seen here
                      </div>
                    </div>
                  </div>
                  <br />
                  {loading ? (
                      <div className="d-flex justify-content-center">
                        <Bars
                          heigth="30"
                          width="30"
                          color="gray"
                          ariaLabel="loading-indicator"
                        />
                      </div>
                  ) : null}
                  <br />
                 {
                  listMedication?.map((item, index) => (
                    <div key={index} className="Medication-Container">
                      <div>
                        <br />
                        <div className="Doctors-Name">
                          Day &nbsp; &nbsp; <span>{item?.day}</span>
                        </div>
                        <br />
                        <div></div>
                        {/* <div className="Doctors-Name">
                          Medication Number&nbsp; &nbsp;{" "}
                          <span className="Report-Date4">{item?.code}</span>
                        </div> */}
                         <div className="Doctors-Name">
                          Name of Nurse&nbsp; &nbsp;{" "}
                          <span className="Report-Date4">{item?.user?.full_name}</span>
                        </div>
                        <div className="Doctors-Name">
                          Date&nbsp; &nbsp;{" "}
                          <span className="Report-Date4">{showDate(item?.created_at)}</span>
                        </div>
                       
                      </div>{" "}
                      <br />
                      <div>
                        {
                          item?.medications?.map((med, ind) => (
                            <div key={ind} className="Doctors-Name">
                              Medication {ind + 1}&nbsp; &nbsp;{" "}
                              <span className="Report-Date4">{med?.service}</span>
                            </div>
                          ))
                        }
                      </div>
                      <br />
                      <div>
                        <div className="Doctors-Name">Note</div>
                        <div className="Report-Date4">
                      <div dangerouslySetInnerHTML={{__html:item?.note}}></div>
                      </div>
                      </div>

                     
                    </div>
                  ))
                 }
                  <br />
                  <br />
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllMedictionModal;
