import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import { getUser } from "../../../../services/AuthService";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import moment from "moment";
import { fetchPhysiotherapy } from "../../../../features/patients/physiotherapySlice";
import { updatePatientClinicalNote } from "../../../../services/PatientClinicalNoteService";

import RichTextEditor from "react-rte"; // Copy Editor

function ViewDoctorNoteModal({ show, onClose, selected }) {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue()); // Copy Editor

  let { id } = useParams();
  const dispatch = useDispatch();
  const user = getUser();
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);

  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);

  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };

  const handleUpdate = () => {
    setLoading(true);
    const data = {
      id: selected?._id,
      doc_note: comment,
      doctor: user?.full_name,
    };
    console.log(data);
    updatePatientClinicalNote(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(
            fetchPhysiotherapy({ patientId: id, type: "physiotherapy" })
          );
          setComment("");
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  {
    /* Copy Editor */
  }
  const onChange = (value) => {
    setValue(value);
    value.toString("html");
    setComment(value.toString("html"));
  };

  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera-Modal-Container">
                <br />
                <div className="Report-Display-Heading">Doctor's Note</div>
                <div className="Report-Tittle">Doctor's Note Display</div>
                <div className="Solera-Modal-Container">
                  <div className="Note-Modal-Cont">
                    <div>
                      <div className="Doctors-Name">
                        Doctors's name : &nbsp;
                        <span>{selected?.user?.full_name}</span>
                      </div>
                      <div className="Report-Date">
                        Report Date: &nbsp;
                        <span>{showDate(selected?.created_at)}</span>
                      </div>
                      <div className="Report-Date">
                        Note Number: &nbsp;<span>{selected?.code}</span>
                      </div>
                    </div>
                    <br />
                    <div className="Report-Display">Type of note/Subject</div>
                    <div className="Report-Tittle">Type or Subject Display</div>

                    <br />
                    <div className="Report-Display">Doctor's Note</div>

                    <div className="Report-Tittle">
                      <div
                        dangerouslySetInnerHTML={{ __html: selected?.note }}
                      ></div>
                    </div>
                    <br />

                    <div className="Report-Display">Action request</div>
                    <div className="Report-Tittle">Action Display</div>
                  </div>
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ViewDoctorNoteModal;
