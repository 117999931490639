import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientScanDocs } from '../../../services/PatientScanDocService'

const initialState = {
    loading: false,
    listMRIs: [],
    paginator: {},
    isSuccessful: false
}

export const fetchPatientMRIs = createAsyncThunk('fetchPatientMRIs', (initData) => {
    return listPatientScanDocs(initData)
    .then((response) => response.data.data)
})


const patientMRIsSlice = createSlice({
    name: 'patientMRIs',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientMRIs.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatientMRIs.fulfilled, (state, action ) => {
        state.loading = false
        state.listMRIs = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientMRIs.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientMRIsSlice.reducer 