import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientCollaborative } from '../../../services/PatientCollaborativeService'

const initialState = {
    loading: false,
    collabs: [],
    isSuccessful: false
}

export const fetchCollaboratives = createAsyncThunk('fetchCollaboratives', (initData) => {
    return listPatientCollaborative(initData)
    .then((response) => response.data.data)
})


const collaborativeSlice = createSlice({
    name: 'collaboratives',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchCollaboratives.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchCollaboratives.fulfilled, (state, action ) => {
        state.loading = false
        state.collabs = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchCollaboratives.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default collaborativeSlice.reducer 