
export function conCatName(first, last){
    return first+" "+last;
}

export function findFace(data){
    let result = "";
    if(data === 'EU-EU'){
        result = 'Width of Head'
    }
    if(data === 'EE-EE'){
        result = 'Circumfrence of the head'
    }
    if(data === 'FT-FT'){
        result = 'Width of the fore-head'
    }
    if(data === 'MF-MF'){
        result = 'Width of the nasal root'
    }
    if(data === 'ZY-ZY'){
        result = 'Width of the face'
    }
    if(data === 'AI-AI'){
        result = 'Width of the nose'
    }
    if(data === 'CH-CH'){
        result = 'Width of the mouth'
    }

    if(data === 'GO-GO'){
        result = 'Width of the mandible'
    }
    return result
}




export function findsideFace(data){
    let result = "";
    if(data === '(V-GN)'){
        result = '1 - Facial Height'
    }
    if(data === '(N-GN)'){
        result = '2 - Logic height of the face'
    }
    if(data === '(SN-GN)'){
        result = '3 - The lower face'
    }
    if(data === '(V-N)'){
        result = '4 - Height of the nose'
    }
    if(data === '(STO-GN)'){
        result = '5 - Height of the mandible'
    }
    if(data === '(N-SN)'){
        result = '6 - Width of the nose'
    }
    if(data === '(STO-SL) '){
        result = '7 - Lip height'
    }

    if(data === '(TR-G)'){
        result = '8 - Height of the forehead'
    }
    if(data === '(SN-STO)'){
        result = '9 - Height of the upper lip'
    }
    if(data === '(SL-GN)'){
        result = '10 - Height of the chin'
    }
    if(data === '(V-TR)'){
        result = '11 - Height of the calvarium'
    }
    if(data === '(V-T)'){
        result = '12 - Distance between the vertex and Tragus '
    }
   
    return result
}

export function findRole(data){
    let result = "Super Admin";
    if(data === 'sys-admin'){
        result = 'Super Admin'
    }
    if(data === 'sys-doctor'){
        result = 'Doctor'
    }
    if(data === 'sys-matron'){
        result = 'Clinical Matron'
    }
    if(data === 'sys-nurse'){
        result = 'Nurse'
    }
   
    if(data === 'sys-pharmacy'){
        result = 'Pharmacist'
    }
    if(data === 'sys-lap'){
        result = 'Lab Scientist'
    }
    if(data === 'sys-store'){
        result = 'Store'
    }
    if(data === 'sys-dentist'){
        result = 'Dentist'
    }
    if(data === 'sys-physiotherapist'){
        result = 'Physiotherapist'
    }
    if(data === 'sys-dietician'){
        result = 'Dietician'
    }
    if(data === 'sys-clinicalsychologist'){
        result = 'Clinicalsychologist'
    }
    if(data === 'sys-socialworker'){
        result = 'Socialworker'
    }
    if(data === 'sys-anaesthetist'){
        result = 'Anaesthetist'
    }
    return result
}

export function socialWorkerRole(data){
    let result = false;
    if(data === 'sys-admin'){
        result = true
    }
    if(data === 'sys-doctor'){
        result = true
    }
    if(data === 'sys-nurse'){
        result = true
    }
    if(data === 'sys-staff'){
        result = false
    }
    if(data === 'sys-pharmacy'){
        result = false
    }
    if(data === 'sys-lap'){
        result = false
    }
    if(data === 'sys-chef'){
        result = false
    }
    if(data === 'sys-matron'){
        result = false
    }
    if(data === 'sys-social'){
        result = true
    }
    return result
}

export function patientRole(data){
    let result = false;
    if(data === 'sys-admin'){
        result = true
    }
    if(data === 'sys-doctor'){
        result = true
    }
    if(data === 'sys-nurse'){
        result = true
    }
    if(data === 'sys-staff'){
        result = false
    }
    if(data === 'sys-pharmacy'){
        result = false
    }
    if(data === 'sys-lap'){
        result = false
    }
    if(data === 'sys-chef'){
        result = false
    }
    if(data === 'sys-matron'){
        result = false
    }
    if(data === 'sys-social'){
        result = true
    }
    return result
}

export function matronRole(data){
    let result = false;
    if(data === 'sys-admin'){
        result = true
    }
    if(data === 'sys-doctor'){
        result = true
    }
    if(data === 'sys-nurse'){
        result = false
    }
    if(data === 'sys-staff'){
        result = false
    }
    if(data === 'sys-pharmacy'){
        result = false
    }
    if(data === 'sys-lap'){
        result = false
    }
    if(data === 'sys-chef'){
        result = false
    }
    if(data === 'sys-matron'){
        result = true
    }
    if(data === 'sys-social'){
        result = false
    }
    return result
}