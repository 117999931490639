import React from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import SideBar from "../../Component/SideBar";
import StaffGreetings from "../../Component/StaffGreetings";
import DashboardContainer from "./DashboardContainer";

function DashboardPage() {
  return (
    <div className="Solera-Main-Container">
      <div className="NavBarContainer">
        <nav className="navbar navbar-expand-lg Solera-Nav">
          <div className="container-fluid">
            <div className="Solera-Admin-Heading">Dashboard</div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarText">
              <div className="navbar-nav me-auto mb-2 mb-lg-0"></div>
              <span className="navbar-text">
                <StaffGreetings />
              </span>
            </div>
          </div>
        </nav>
        <div className="Solera-Main-Dash">
          <DashboardContainer />
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;
