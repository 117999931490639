import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/./Modal.css";
import TextField from "@mui/material/TextField";
import { MdAddCircle, MdClose, MdRemoveCircle } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUser } from "../../../../services/AuthService";
import { addPatientPrescription } from "../../../../services/PatientPrescriptionService";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import { fetchPatientPrescriptions } from "../../../../features/patients/patientPrescriptionsSlice";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import RichTextEditor from "react-rte"; // Copy Editor
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import { searchDrugs } from "../../../../services/DrugService";

function DoctorsPrescriptionModal({ show, onClose, selected }) {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue()); // Copy Editor

  const [result, setResult] = useState([]);
  const [prescriptions, setPrescriptions] = useState([]);
  const [checkLoading, setCheckLoading] = useState(false);
  const [pushLoading, setPushLoading] = useState(false);

  const [receiverName, setReceiverName] = useState("");

  let { id } = useParams();
  const dispatch = useDispatch();
  const user = getUser();

  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);

  const [name, setName] = useState("");
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [serviceList, setServiceList] = useState([{ service: "" }]);

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;
    setServiceList(list);
  };

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };

  const handleServiceAdd = () => {
    setServiceList([...serviceList, { service: "" }]);
  };

  console.log("selected PRE", selected);

  const handleSub = () => {
    onClose();
    setAddReportShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Prescribed Successfully",
      showConfirmButton: false,
      timer: 2000,
    });
  };
  const handleAdd = () => {
    setLoading(true);
    const data = {
      user: user?._id,
      patient: id,
      treatment: selected?.treatment_infos[0]?._id,
      prescriptions: serviceList,
      note: note,
    };
    console.log(data);
    addPatientPrescription(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          setAddReportShow(false);
          dispatch(fetchPatientPrescriptions({ patientId: id }));
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const handlePushDrug = async (check) => {
    const likes = prescriptions;
    if (likes.includes(check)) {
      const room = likes.find((s) => s?._id === check?._id);
      room.release = room?.release + 1;
      setPrescriptions((prev) =>
        prev.find((r) => r?._id === room?._id)
          ? prev.map((r) => (r?._id === room?._id ? room : r))
          : prev.concat(room)
      );
      return;
    } else {
      check.release = 1;
      setPrescriptions([...prescriptions, check]);
    }
  };

  const handleCheckDrug = (searchName) => {
    setCheckLoading(true);
    searchDrugs(searchName)
      .then((response) => {
        if (response.data.success) {
          setCheckLoading(false);
          setResult(response.data.data.data);
        }
      })
      .catch((err) => {
        setCheckLoading(false);
        console.log(err);
      });
  };

  {
    /* Copy Editor */
  }
  const onChange = (value) => {
    setValue(value);
    value.toString("html");
    setNote(value.toString("html"));
  };

  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };

  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                      Doctor's Prescription
                    </div>
                    <div className="Solera-Settings-Note">
                      Kindy prescribe drugs for this patient. Use the add icon
                      to add more prescription.
                    </div>
                  </div>
                </div>
                <br />
                <div className="">
                  <div>
                  <div className="Selected-Drugs-Box-Container">
                      <div className="Selected-Drugs-Box-Heading">
                        Searched drugs
                      </div>
                      <div className="Selected-Drugs-Box-Note">
                        Search for the available drugs before prescription
                      </div>
                    </div>
                    <div className="Patient-Search">
                      <input
                        type="Search"
                        className="form-control SearchesInput22"
                        placeholder="Search for drugs before prescription"
                        aria-label="OTP"
                        aria-describedby="basic-addon1"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <div
                        onClick={() => {
                          if (name) {
                            handleCheckDrug(name);
                          }
                        }}
                        className="Searchesbtn"
                      >
                        Search
                      </div>
                    </div>
                   
                    {checkLoading ? (
                      <Bars
                        heigth="30"
                        width="30"
                        color="gray"
                        ariaLabel="loading-indicator"
                      />
                    ) : null}
                    {result?.length > 0 ? (
                      result?.map((item, index) => (
                        <div key={index} className="Drug-search-Display44 mt-3">
                          <div className="Drugnamedisplay50">
                            <div className="Drug-search-Heading">
                              Drugs Name{" "}
                            </div>
                            <div className="Drug-search-Result">
                              {item?.name}
                            </div>
                          </div>

                          <div>
                            <div className="Drug-search-Heading">
                              Expiry Date
                            </div>
                            <div className="Drug-search-Result">
                              {showDate(item?.expiry_date)}
                            </div>
                          </div>

                          {/* <div>
                            <div className="Drug-search-Heading">Stock</div>
                            <div className="Drug-search-Result">
                              <span>{item?.qty}</span>
                            </div>
                          </div> */}

                          <div>
                            <div className="Drug-search-Heading">Packs</div>
                            <div className="Drug-search-Result">
                              <span>{item?.qty}</span>
                            </div>
                          </div>

                          <div>
                            <div className="Drug-search-Heading">sachets</div>
                            <div className="Drug-search-Result">
                              <span>{item?.qty}</span>
                            </div>
                          </div>

                          <div>
                            <div className="Drug-search-Heading">Tablets</div>
                            <div className="Drug-search-Result">
                              <span>{item?.qty}</span>
                            </div>
                          </div>

                          {/* <div>
                                <button
                                  onClick={() => handlePushDrug(item)}
                                  className="Drug-BTN"
                                >
                                  Add
                                </button>
                              </div> */}
                        </div>
                      ))
                    ) : (
                      <div className="Selected-Drugs-Box-Heading">
                        No drug to prescribe
                      </div>
                    )}
                  </div>
                </div>
                <br />
                <br />
                {serviceList &&
                  serviceList.map((singleService, index) => (
                    <ul key={index}>
                      {singleService.service && (
                        <li className="ListIt">{singleService.service}</li>
                      )}
                    </ul>
                  ))}
                <div className="Selected-Drugs-Box-Container">
                  <div className="Selected-Drugs-Box-Note">
                    Prescribe drugs using the following sequence (Enter Drug
                    name - Dose - Route - Quantity- Pack - Sachet - Tablet)
                  </div>
                </div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {serviceList.map((singleService, index) => (
                        <div key={index} className="services">
                          <div className="first-division">
                            <TextField
                              fullWidth
                              label="Enter Drug name - Dose - Route - Quantity"
                              name="service"
                              type="text"
                              id="fullWidth"
                              value={singleService.service}
                              onChange={(e) => handleServiceChange(e, index)}
                              required
                              placeholder="Add drug"
                              className="Solera-Login-Input mb-3"
                            />
                          </div>
                          <div className="RemoveAndAddBtn">
                            <div className="second-division">
                              {serviceList.length !== 1 && (
                                <MdRemoveCircle
                                  className="CounterIcon"
                                  type="button"
                                  onClick={() => handleServiceRemove(index)}
                                />
                              )}
                            </div>
                            <div>
                              {serviceList.length - 1 === index &&
                                serviceList.length < 100 && (
                                  <MdAddCircle
                                    className="CounterIcon"
                                    type="button"
                                    onClick={handleServiceAdd}
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="FormSect-Contianer">
                    <div className="container-fluid p-0 FormInput">
                      <div className="col">
                        {/* <TextField
                          fullWidth
                          id="outlined-multiline-static "
                          label="Remark"
                          multiline
                          rows={15}
                          value={note}
                          onChange={(e) => setNote(e.target.value)}
                          // defaultValue="Default Value"
                        /> */}

                        {/* Copy Editor */}
                        <RichTextEditor
                          className="editor"
                          value={value}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="FormUpload">
                  {loading ? (
                    <Bars
                      heigth="30"
                      width="30"
                      color="gray"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    <button className="Add-Button" onClick={handleAdd}>
                      Prescribe
                    </button>
                  )}
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default DoctorsPrescriptionModal;
