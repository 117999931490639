import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { getUser } from "../../../../services/AuthService";
import { useDispatch } from "react-redux";
import { addPatientProgressNotes } from "../../../../services/PatientProgressNoteService";
import { fetchProgressNotes } from "../../../../features/patients/socials/progressNoteSlice";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import RichTextEditor from 'react-rte';  // Copy Editor 

function ProgressiveNoteModal({ show, onClose }) {

  const [value, setValue] = useState(RichTextEditor.createEmptyValue());// Copy Editor 
  const [value2, setValue2] = useState(RichTextEditor.createEmptyValue());// Copy Editor 
  const [value3, setValue3] = useState(RichTextEditor.createEmptyValue());// Copy Editor 
  const [value4, setValue4] = useState(RichTextEditor.createEmptyValue());// Copy Editor 
  const [value5, setValue5] = useState(RichTextEditor.createEmptyValue());// Copy Editor 
  const [value6, setValue6] = useState(RichTextEditor.createEmptyValue());// Copy Editor 

  let { id } = useParams();
  const user = getUser();
  const dispatch = useDispatch()
  const [theraputicModalities, setTheraputicModalities] = useState('');
  const [sessionFrequency, setSessionFrequency] = useState('');
  const [sessionSummary, setSessionSummary] = useState('');
  const [responseToTreatment, setResponseToTreatment] = useState('');
  const [treatmentGoals, setTreatmentGoals] = useState('');
  const [treatmentInterventions, setTreatmentInterventions] = useState('');

  const [loading, setLoading] = useState(false);

  const [addDoctorShow, setAddDoctorShow] = useState(false);
  const handleAddDoctor = () => setAddDoctorShow(false);

  const handleSaveDoctor = () => {
    onClose();
    setAddDoctorShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const handleAdd = () => {
    setLoading(true);
    const data = {
      user: user?._id,
      patient: id,
      theraputic_modalities: theraputicModalities,
      session_frequency: sessionFrequency,
      session_summary: sessionSummary,
      response_to_treatment: responseToTreatment,
      treatment_goals: treatmentGoals,
      treatment_interventions: treatmentInterventions,
    };
    console.log(data);
    addPatientProgressNotes(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(fetchProgressNotes({patientId: id}))
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }
   {/* Copy Editor */}
   const onChange = (value) => {
    setValue(value);
    value.toString('html')
    setTheraputicModalities(value.toString('html'))
  };


   {/* Copy Editor */}
   const onChange2 = (value) => {
    setValue2(value);
    value.toString('html')
    setSessionFrequency(value.toString('html'))
  };
  {/* Copy Editor */}
  const onChange3 = (value) => {
    setValue3(value);
    value.toString('html')
    setSessionSummary(value.toString('html'))
  };
   {/* Copy Editor */}
   const onChange4 = (value) => {
    setValue4(value);
    value.toString('html')
    setResponseToTreatment(value.toString('html'))
  };


   {/* Copy Editor */}
   const onChange5 = (value) => {
    setValue5(value);
    value.toString('html')
    setTreatmentGoals(value.toString('html'))
  };
  {/* Copy Editor */}
  const onChange6 = (value) => {
    setValue6(value);
    value.toString('html')
    setTreatmentInterventions(value.toString('html'))
  };

  

  
        
    return (
    <div>
      {" "}
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Solera__ModalHeadings">
              <MdClose className="Solera__Close" onClick={onClose} />
            </div>

            <div className="Solera__ModalHeadings"></div>
            <div className="Solera-Modal-Container">
              <div className="Solera__Headings">
                <div className="Log__Cont">
                  <img
                    src="/assets/lg.png"
                    alt=""
                    className="Log__Img__Modal"
                  />
                </div>
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">
                    Patient Progress Note
                  </div>
                  {/* <div className="Solera-Settings-Note">
                            Kindly add a doctor for your records
                          </div> */}
                </div>
              </div>
              <br />
              <br />
              <div className="FormSect-Contianer">
                <div className="FormSect">Therapeutic Modalities</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>

                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Theraputic Modalities"
                        multiline
                        rows={5}
                        value={theraputicModalities}
                        onChange={(e) => setTheraputicModalities(e.target.value)}
                        // defaultValue="Default Value"
                      /> */}
                        <RichTextEditor 
                  className="editor"
                  value={value} onChange={onChange} 
                />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Session Frequency</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Session Frequency"
                        multiline
                        rows={5}
                        value={sessionFrequency}
                        onChange={(e) => setSessionFrequency(e.target.value)}
                        // defaultValue="Default Value"
                      /> */}
                        <RichTextEditor 
                  className="editor"
                  value={value2} onChange={onChange2} 
                />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Session Summary </div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Session Summary"
                        multiline
                        rows={5}
                        value={sessionSummary}
                        onChange={(e) => setSessionSummary(e.target.value)}
                        // defaultValue="Default Value"
                      /> */}
                        <RichTextEditor 
                  className="editor"
                  value={value3} onChange={onChange3} 
                />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Patient response to treatment</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Patient response to treatment"
                        multiline
                        rows={5}
                        value={responseToTreatment}
                        onChange={(e) => setResponseToTreatment(e.target.value)}
                        // defaultValue="Default Value"
                      /> */}
                        <RichTextEditor 
                  className="editor"
                  value={value4} onChange={onChange4} 
                />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Changes in treatment goals </div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Changes in treatment goals"
                        multiline
                        rows={5}
                        value={treatmentGoals}
                        onChange={(e) => setTreatmentGoals(e.target.value)}
                        // defaultValue="Default Value"
                      /> */}
                        <RichTextEditor 
                  className="editor"
                  value={value5} onChange={onChange5} 
                />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Changes in treatment interventions</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Changes in treatment interventions"
                        multiline
                        rows={5}
                        value={treatmentInterventions}
                        onChange={(e) => setTreatmentInterventions(e.target.value)}
                        // defaultValue="Default Value"
                      /> */}
  <RichTextEditor 
                  className="editor"
                  value={value6} onChange={onChange6} 
                />

                    </div>
                  </div>
                </div>
              </div>
              {
                loading ? (
                  <Bars
                    heigth="30"
                    width="30"
                    color="gray"
                    ariaLabel="loading-indicator"
                  />
                ) : (
                  <button className="Add-Button" onClick={handleAdd}>
                    Add Patient Progress Note
                  </button>
                ) 
              }
            
              <br /> <br /> <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}

export default ProgressiveNoteModal