import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientTheatre } from '../../services/PatientTheatreService'

const initialState = {
    loading: false,
    surgeons: [],
    isSuccessful: false
}

export const fetchPatientSurgeon = createAsyncThunk('fetchPatientSurgeon', (initData) => {
    return listPatientTheatre(initData)
    .then((response) => response.data.data)
})


const patientSurgeonSlice = createSlice({
    name: 'patientSurgeon',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientSurgeon.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchPatientSurgeon.fulfilled, (state, action ) => {
        state.loading = false
        state.surgeons = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientSurgeon.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientSurgeonSlice.reducer 