import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientTheatre } from '../../services/PatientTheatreService'

const initialState = {
    loading: false,
    anasethetics: [],
    isSuccessful: false
}

export const fetchPatientAnasethetic = createAsyncThunk('fetchPatientAnasethetic', (initData) => {
    return listPatientTheatre(initData)
    .then((response) => response.data.data)
})


const patientAnasetheticSlice = createSlice({
    name: 'patientAnasethetic',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientAnasethetic.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchPatientAnasethetic.fulfilled, (state, action ) => {
        state.loading = false
        state.anasethetics = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientAnasethetic.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientAnasetheticSlice.reducer 