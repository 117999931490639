import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";

function ViewVitalsModals({ show, onClose, selected }) {
  const [viewvitaldetailsShow, setViewvitaldetailsShow] = useState(false);
  const handleViewvitaldetails = () => setViewvitaldetailsShow(false);
  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera-Modal-Container">
                <br />
                <div className="Report-Display-Heading">Patient's Vitals</div>
                <div className="Report-Tittle">Vital Display</div>
                <div className="Solera-Modal-Container">
                  <div className="Note-Modal-Cont">
                  <div>
                      <div className="Doctors-Name">Vital Subject</div>
                      <div className="Report-Date">
                        {selected?.subject}
                      </div>
                    </div>
                    <br />
                    <div>
                      <div className="Doctors-Name">Body temp.</div>
                      <div className="Report-Date">
                        {selected?.body_temperature}&nbsp;<span>Celcius</span>
                      </div>
                    </div>
                    <br />

                    <div>
                      <div className="Doctors-Name">BP</div>
                      <div className="Report-Date">
                      {selected?.blood_pressure} &nbsp;<span>MMgH</span>
                      </div>
                    </div>
                    <br />

                    <div>
                      <div className="Doctors-Name">Height</div>
                      <div className="Report-Date">
                      {selected?.height} &nbsp;<span>Mtrs</span>
                      </div>
                    </div>
                    <br />

                    <div>
                      <div className="Doctors-Name">Body Weight</div>
                      <div className="Report-Date">
                        {selected?.body_weight} &nbsp;<span>Kg</span>
                      </div>
                    </div>
                    <br />

                    <div>
                      <div className="Doctors-Name">SpO2</div>
                      <div className="Report-Date">
                        {selected?.sp02} &nbsp;<span>%</span>
                      </div>
                    </div>
                    <br />

                    <div>
                      <div className="Doctors-Name">Pulse Rate</div>
                      <div className="Report-Date">
                        {selected?.pulse_rate} &nbsp;<span>bpm</span>
                      </div>
                    </div>
                    <br />

                    <div>
                      <div className="Doctors-Name">Respiration</div>
                      <div className="Report-Date">
                       {selected?.respiration} &nbsp;<span>Resp.</span>
                      </div>
                    </div>
                    <br />

                    <div>
                      <div className="Doctors-Name">Pain Score</div>
                      <div className="Report-Date">
                        {selected?.pain_score} &nbsp;<span>NRS</span>
                      </div>
                    </div>
                  </div>
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ViewVitalsModals;
