import { React, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import "../../Component/Modals/Modal.css";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { fetchPatientLapComments } from "../../features/patients/patientLapCommentsSlice";
import { fetchPatientTreatmentLaps } from "../../features/patients/patientTreatmentLapsSlice";
import { useParams } from "react-router-dom";
import TreatementNumber from "../../Component/TreatementNumber";
import ViewUploadedResult from "./ViewUploadedResult";

function ViewLabResult({ show, onClose, selected }) {
  const [viewUploadShow, setViewUploadShow] = useState(false);
  const handleViewUpload = () => setViewUploadShow(false);

  let { id } = useParams();
  const dispatch = useDispatch();
  const { patientLapComments, loading } = useSelector(
    (state) => state.patientLapComments
  );
  const { patientLaps } = useSelector((state) => state.patientTreatmentLaps);
  const [selected2, setSelected2] = useState({});


  useEffect(() => {
    if (show) {
      dispatch(
        fetchPatientLapComments({
          treatment: selected?.treatment_infos[0]?._id,
        })
      );
      dispatch(
        fetchPatientTreatmentLaps({
          patientId: id,
          treatmentId: selected?.treatment_infos[0]?._id,
        })
      );
    }
  }, [show]);

  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };

  return (
    <div>
      <div>
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                   

                    <div className="Patient-View-Box">
                      <div className="Vital-Box-2">
                        <div className="Vital-Box-Heading">
                          <div className="FormSect">
                            Laboratory Test result Details
                          </div>
                          <div className="FormSect-Sub">
                            View each uploaded result using the button below.
                            Each result displays the specific outcome for its
                            corresponding test.
                          </div>
                        </div>
                      </div>
                      <br /> <br />
                      {loading ? <div>Loading...</div> : null}
                      {patientLaps?.map((item, index) => (
                        <div className="Solera__Headings__Note">
                          <div className="Vital-Box-Heading">
                            <div className="FormSect">
                              Investigation {index + 1}
                            </div>
                          </div>
                          <div>
                            <div className="FormSect-Note">
                              Test Name : &nbsp;
                              <span>{item?.name}</span>
                            </div>
                            {/* <div className="FormSect-Note">
                                    Test Code : &nbsp;
                                    <span>
                                      {item?.code}
                                    </span>
                                  </div> */}
                            {/* <div className="FormSect-Note">
                                    Treatement Number : &nbsp;
                                    <span>
                                      {show ? <TreatementNumber treamentNum={item?.treatment?.code.toUpperCase()} /> : null}
                                    </span>
                                  </div> */}
                            <div className="FormSect-Note">
                              Result : &nbsp;
                              <span>
                                {show ? (
                                  <TreatementNumber
                                    treamentNum={item?.result}
                                  />
                                ) : null}
                              </span>
                            </div>
                            <div className="FormSect-Note">
                              Result Date : &nbsp;
                              <span>{showDate(item?.updated_at)}</span>
                            </div>
                            <div className="FormSect-Note">
                              Lab Scientist Name : &nbsp;
                              <span>{item?.user?.full_name}</span>
                            </div>
                            <br />
                            <button
                              className="FileSubmit1"
                              onClick={() => {
                                setSelected2(item)
                                setViewUploadShow(true);
                              }}
                            >
                              View Lab Result
                            </button>
                          </div>
                          <br />
                        </div>
                      ))}
                    </div>

                    {loading ? <div>Loading...</div> : null}
                    {patientLapComments?.map((item, index) => (
                      <div key={index} className="Solera__Headings__Note">
                        <div className="Vital-Box-Heading">
                          <div className="FormSect">Comment {index + 1}</div>
                        </div>
                        <div className="FormSect-Note">
                          Doctor : <span>{item?.user?.full_name}</span>
                        </div>
                        <div className="FormSect-Note">
                          Date : <span>{showDate(item?.created_at)}</span>
                        </div>

                        <br />
                        <br />
                        <div className="FormSect-Note">
                          Comments :{/* &nbsp;<span>{item?.comment}</span> */}
                        </div>
                        <div className="Report-Date4">
                          <div
                            dangerouslySetInnerHTML={{ __html: item?.comment }}
                          ></div>
                        </div>
                        <br />
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
                <br />
                <br />
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>

      <ViewUploadedResult
        selected={selected2}
        show={viewUploadShow}
        onClose={handleViewUpload}
        onOpen={() => setViewUploadShow(true)}
      />
    </div>
  );
}

export default ViewLabResult;
