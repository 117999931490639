import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientRecord } from '../../services/PatientRecordService'
import { listPatientSurgicalSafeties } from '../../services/PatientSurgicalSafetyService'

const initialState = {
    loading: false,
    safeties: [],
    paginator: {},
    isSuccessful: false
}

export const fetchPatientsSurgicalSafety = createAsyncThunk('fetchPatientsSurgicalSafety', (initData) => {
    return listPatientSurgicalSafeties(initData)
    .then((response) => response.data.data)
})


const patientsSurgicalSafetySlice = createSlice({
    name: 'patientsSurgicalSafety',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientsSurgicalSafety.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatientsSurgicalSafety.fulfilled, (state, action ) => {
        state.loading = false
        state.safeties = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientsSurgicalSafety.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientsSurgicalSafetySlice.reducer 