import axios from '../axiosinstance';

      
export function listFluidChartItems(initData){
        return axios.get('/fluid-chart-items', { params: initData })
}

export function addFluidChartItem(data){
        return axios.post('/fluid-chart-items', data)
}

export function getFluidChartItem(initData){
        return axios.get('/fluid-chart-items/'+initData?.id)
}

export function updateFluidChartItem(initData){
        return axios.patch('/fluid-chart-items/'+initData.id, initData)
}

export function FluidChartItemCount(search){
        return axios.get('/fluid-chart-items/stat/count', { params: search })
}

