import { React, useEffect, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import AddUltraSound from "./AddUltraSound";
import Dropdown from "react-bootstrap/Dropdown";
import Swal from "sweetalert2";
import PrescribedBy from "../../../../Component/PrescribedBy";
import ViewXrayReportModal from "./ViewXrayReportModal";
import ViewUltrasoundReport from "./ViewUltrasoundReport";
import TreatementNumber from "../../../../Component/TreatementNumber";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientUltrasounds } from "../../../../features/patients/docs/patientUltrasoundSlice";
import moment from "moment";
import { Bars } from "react-loader-spinner";
import { fetchUltransoundCount } from "../../../../features/patients/docs/ultransoundCountSlice";
import Paginations from "../../../../Component/Pagination";


function UntraSoundScan() {
  let { id } = useParams();
  const dispatch = useDispatch()
  const {listUltrasounds, loading, paginator} = useSelector((state) => state.patientUltrasounds)
  const ultransoundCount = useSelector((state) => state.ultransoundCount)

  const [selected, setSelected] = useState({});

  const [viewXrayShow, setviewXrayShow] =
  useState(false);
const handleviewXray = () => setviewXrayShow(false);

const [viewXrayReportShow, setViewXrayReportShow] =
useState(false);
const handleViewXrayReport = () => setViewXrayReportShow(false);

const [search2, setSearch2] = useState('');
const [page, setPage] = useState(paginator?.currentPage || 1);
const [pageLimit, setPageLimit] = useState(10);
const [pageCount, setPageCount] = useState(paginator?.pageCount || 0);

useEffect((  ) => {
  if (id) {
    handleFetchPatientUltrasounds()
    dispatch(fetchUltransoundCount({patient: id, type: 'ultrasound'}))
  }
}, [id])


const handleFetchPatientUltrasounds = (search = "", limit = 10, page = 1) => {
  dispatch(fetchPatientUltrasounds({patient: id, type: 'ultrasound', name: search, page_size: limit, page:page }))
}

const handleFetchUpdate = (search = search2, limit = pageLimit, page = 1) => {
  dispatch(fetchPatientUltrasounds({patient: id, type: 'ultrasound', name: search, page_size: limit, page:page }));
}


const showDate = (passDate) => {
  return moment(passDate).format('DD-MM-YYYY')
}


const handleSubComment = () => {
  Swal.fire({
    customClass: {
      title: "title-class",
      icon: "icon-class",
    },

    icon: "success",
    title: "Successful",
    showConfirmButton: false,
    timer: 2000,
  });
};

return (
  <div>
    <div className="Report-Notes">
      <div className="ReportHeader">
        <div className="Report-Heading"> Ultrasound Scan</div>
        <div className="Report-Count">
          <span>{ultransoundCount?.loading ? "Loading..." : ultransoundCount?.count}</span>&nbsp;Ultrasound Scan{ ultransoundCount?.count > 1 ? "s" : null}

        </div>
        <AddUltraSound />
      </div>
    </div>
    <br />
    <Paginations 
      handleFetchTransSearch={handleFetchUpdate} 
      page={page} 
      setPage={setPage} 
      pageCount={paginator?.pageCount || 0} 
      pageLimit={pageLimit}
      setPageLimit={setPageLimit}
    />
    {loading ? (
          <div className="d-flex justify-content-center">
            <Bars
              heigth="30"
              width="30"
              color="gray"
              ariaLabel="loading-indicator"
            />
          </div>
      ) : null
    }
    {
      listUltrasounds?.map((item, index) => (
        <div key={index} className="Patient-Record-Container">
          <div className="Patient-Record-Wrapper">
          <div className="PatientNote-C">
            <div className="Wallet-Ref-Heading">Treatment ID</div>
            <div className="Wallet-Ref-Result"><TreatementNumber treamentNum={item?.code} /></div>
          </div>
          {/* <div className="PatientNote-C">
            <div className="Wallet-Ref-Heading">Instruction</div>
            <div className="Wallet-Ref-Result">
              <PrescribedBy name={item?.name?.length < 21 ? item?.name?.substr(0, 20) : item?.name?.substr(0, 20)+'...' } />
            </div>
          </div> */}
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Doctor's Name</div>
              <div className="Wallet-Ref-Result">
                <PrescribedBy name={item?.user?.full_name} />
              </div>
            </div>
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Date</div>
              <div className="Wallet-Ref-Result">{showDate(item?.created_at)}</div>
            </div>
            <div className="PatientNote-C">
              <div className="Wallet-Ref-Heading">Status</div>
              <div className="Wallet-Ref-Result">{item?.status}</div>
            </div>
            <div className="Action-Drop-Down">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
                  Manage UltraSound
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {setSelected(item); setviewXrayShow(true)}}
                    className="Solera-Patients"
                  >
                    View UltraSound to conduct
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {setSelected(item); setViewXrayReportShow(true)}}
                    className="Solera-Patients"
                  >
                    Upload UltraSound Report 
                  </Dropdown.Item>
               
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      ))
    }
    <Paginations 
      handleFetchTransSearch={handleFetchUpdate} 
      page={page} 
      setPage={setPage} 
      pageCount={paginator?.pageCount || 0} 
      pageLimit={pageLimit}
      setPageLimit={setPageLimit}
    />

    <div>
      <ViewUltrasoundReport
        selected={selected}
        show={viewXrayShow}
        onClose={handleviewXray}
        onOpen={() => setviewXrayShow(true)}
      />

      <ViewXrayReportModal
        named={"ultrasound"}
        selected={selected}
        show={viewXrayReportShow}
        onClose={handleViewXrayReport}
        onOpen={() => setViewXrayReportShow(true)}
      />
    </div>
  </div>

        
     
  );
}

export default UntraSoundScan;
