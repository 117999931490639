import axios from '../axiosinstance';

      
export function listPatientVitals(initData){
        return axios.get('/patient-vitals', { params: { name: initData?.search, patient: initData?.patientId } })
}

export function addPatientVitals(data){
        return axios.post('/patient-vitals', data)
}

export function getPatientVitals(initData){
        return axios.get('/patient-vitals/'+initData?.id)
}

export function updatePatientVitals(initData){
        return axios.patch('/patient-vitals/'+initData.id, initData)
}

export function patientVitalsCount(search){
        return axios.get('/patient-vitals/stat/count', { params: search })
}

