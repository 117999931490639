import React, { useState } from "react";
import Xray from "../Documents/Xray";
import CTScan from "../Documents/CTScan";
import MRI from "../Documents/MRI";
import UntraSoundScan from "../Documents/UntraSoundScan";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";

import {
  CiLineHeight,
  CiMail,
  CiMemoPad,
  CiMicrochip,
  CiPickerHalf,
  CiPill,
  CiSun,
  CiUser,
  CiViewList,
  CiViewTable,
  CiVirus,
  CiWheat,
} from "react-icons/ci";
import Assessment from "./Assessment";
import TreatmentPlan from "./TreatmentPlan";
import ProgressNote from "./ProgressNote";
import MedicatioSideEffect from "./MedicatioSideEffect";
import ColaboDoc from "./ColaboDoc";
import DischargeSummary from "./DischargeSummary";
import Assessment2 from "./Assessment2";
import Assessment3 from "./Assessment3";

function SocialWelfare() {
  const [active, setActive] = useState("Assessment");
  return (
    <div>
      <div className="ReportHeader">
        <div className="Report-Heading">Social Welfare</div>

        <div className="SociaL-Welfare-Cont">
          <div className="DocumemtBtn">
            <div
              className={
                active === "Assessment" ? "ActiveIconX" : "InactiveIcon"
              }
              onClick={() => setActive("Assessment")}
            >
              <CiMemoPad className="Solera-Icons" />
              <div> Assessment </div>
            </div>
            {/* <div
              className={
                active === "Assessment2" ? "ActiveIconX" : "InactiveIcon"
              }
              onClick={() => setActive("Assessment2")}
            >
              <CiMemoPad className="Solera-Icons" />
              <div> Assessment2 </div>
            </div>
            <div
              className={
                active === "Assessment3" ? "ActiveIconX" : "InactiveIcon"
              }
              onClick={() => setActive("Assessment3")}
            >
              <CiMemoPad className="Solera-Icons" />
              <div> Assessment3 </div>
            </div> */}
              <div
              className={
                active === "MedicatioSideEffect"
                  ? "ActiveIconX"
                  : "InactiveIcon"
              }
              onClick={() => setActive("MedicatioSideEffect")}
            >
              <CiMemoPad className="Solera-Icons" />
              <div> Medication </div>
            </div>
            <div
              className={
                active === "TreatmentPlan" ? "ActiveIconX" : "InactiveIcon"
              }
              onClick={() => setActive("TreatmentPlan")}
            >
              <CiMemoPad className="Solera-Icons" />
              <div> Treatment Plan </div>
            </div>
          </div>
          <div className="DocumemtBtn">
            <div
              className={
                active === "ProgressNote" ? "ActiveIconX" : "InactiveIcon"
              }
              onClick={() => setActive("ProgressNote")}
            >
              <CiMemoPad className="Solera-Icons" />
              <div> Progress Note </div>
            </div>
          

            <div
              className={
                active === "ColaboDoc" ? "ActiveIconX" : "InactiveIcon"
              }
              onClick={() => setActive("ColaboDoc")}
            >
              <CiMemoPad className="Solera-Icons" />
              <div> Collaborative Document </div>
            </div>
            <div
              className={
                active === "DischargeSummary" ? "ActiveIconX" : "InactiveIcon"
              }
              onClick={() => setActive("DischargeSummary")}
            >
              <CiMemoPad className="Solera-Icons" />
              <div> Discharge Summary </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {active === "Assessment" && <Assessment />}
        {/* {active === "Assessment3" && <Assessment3 />}
        {active === "Assessment2" && <Assessment2 />} */}
        {active === "TreatmentPlan" && <TreatmentPlan />}
        {active === "ProgressNote" && <ProgressNote />}
        {active === "MedicatioSideEffect" && <MedicatioSideEffect />}
        {active === "ColaboDoc" && <ColaboDoc />}
        {active === "DischargeSummary" && <DischargeSummary />}
      </div>
    </div>
  );
}

export default SocialWelfare;
