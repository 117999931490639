import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientClinicalNote } from '../../../services/PatientClinicalNoteService'

const initialState = {
    loading: false,
    docNotes: [],
    isSuccessful: false
}

export const fetchPatientDocNotes = createAsyncThunk('fetchPatientDocNotes', (initData) => {
    return listPatientClinicalNote(initData)
    .then((response) => response.data.data)
})


const patientDocNotesSlice = createSlice({
    name: 'patientDocNotes',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientDocNotes.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchPatientDocNotes.fulfilled, (state, action ) => {
        state.loading = false
        state.docNotes = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientDocNotes.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientDocNotesSlice.reducer 