import { React, useState } from "react";
// import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Bars } from "react-loader-spinner";
import Form from "react-bootstrap/Form";
import RichTextEditor from "react-rte"; // Copy Editor
import Swal from "sweetalert2";

function ViewSheet({ show, onClose }) {
  return (
    <div>
      <div>
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container30">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                      Intraoperative Sheet Details
                    </div>
                    <div className="Solera-Settings-Note">
                      Intraoperative Sheet
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div>
                  <div className="FormSect">MONITORING</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <br />
                <div className="Solera-Settings-Note">
                  Display Ticked Items (Ticked items to display here)
                </div>
                <br />
                <br />
                <br />
                <div>
                  <div className="FormSect">
                    VOIE VEINEUSE PERIPHERIQUE/PERIPHERAL VENOUS WAY
                  </div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <div className="Solera-Settings-Note">
                  Lieu Type: Display Lieu Type
                </div>
                <div className="Solera-Settings-Note">
                  Others: Display Others
                </div>
                <br />
                <br />
                <div>
                  <div className="FormSect">TYPE CHIRURGIE/SURGERY TYPE</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <br />
                <div className="Solera-Settings-Note">
                  Display Ticked Items (Ticked items to display here)
                </div>
                <br />
                <br />
                <div>
                  <div className="FormSect">Position Patient</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <br />
                <div className="Solera-Settings-Note">
                  Display Ticked Items (Ticked items to display here)
                </div>
                <br />
                <br />
                <br />
                <div>
                  <div className="FormSect">TYPE ANESTHESIE</div>
                  <div className="FormSect">Loco - regionale</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <br />
                <div className="Solera-Settings-Note">
                  Display Ticked Items (Ticked items to display here)
                </div>
                <br />
                <br />
                <br />
                <div>
                  <div className="FormSect">Generale</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <br />
                <div className="Solera-Settings-Note">
                  Display Ticked Items (Ticked items to display here)
                </div>
                <br />
                <br />
                <div>
                  <div className="FormSect">INCUBATION</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <br />
                <div className="Solera-Settings-Note">OT: Display OT</div>
                <div className="Solera-Settings-Note">NT: Display NT</div>
                <div className="Solera-Settings-Note">N: Display N</div>
                <div className="Solera-Settings-Note">
                  Difficile: Display Difficile
                </div>
                <br />
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-6 CheckSheet">
                      {" "}
                      <div>
                        <div className="FormSect">MASQUE LARYNGE</div>
                        <div className="FormSect-Note">
                          {/* Please fill all the required field */}
                        </div>
                      </div>
                      <br />
                      <div className="Solera-Settings-Note">N: Display N</div>
                      <br />
                    </div>
                    <div className="col-lg-6 CheckSheet">
                      {" "}
                      <div>
                        <div className="FormSect">FIBROSCOPIE</div>
                        <div className="FormSect-Note">
                          {/* Please fill all the required field */}
                        </div>
                      </div>
                      <br />
                      <div className="Solera-Settings-Note">DR: Display DR</div>
                      <br />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-6 CheckSheet">
                      <div>
                        <div className="FormSect">VENTILATION</div>
                        <div className="FormSect-Note">
                          {/* Please fill all the required field */}
                        </div>
                      </div>
                      <br />
                      <div className="Solera-Settings-Note">
                        Display Ticked Items (Ticked items to display here)
                      </div>
                      <br />
                    </div>
                    <div className="col-lg-6 ckSheet">
                      <div>
                        <div className="FormSect">OYGENOTHERAPIE</div>
                        <div className="FormSect-Note">
                          {/* Please fill all the required field */}
                        </div>
                      </div>
                      <br />
                      <div className="Solera-Settings-Note">
                        Display Ticked Items (Ticked items to display here)
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="FormSect">RESPIRATEUR</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <br />
                <div className="Solera-Settings-Note">
                  Display Ticked Items (Ticked items to display here)
                </div>
                <br />
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-6 CheckSheet">
                      <div>
                        <div className="FormSect">Circuit</div>
                        <div className="FormSect-Note"></div>
                      </div>
                      <br />
                      <div className="Solera-Settings-Note">
                        Display Ticked Items (Ticked items to display here)
                      </div>
                      <br />
                    </div>
                    <div className="col-lg-6 ckSheet">
                      <div>
                        <div className="FormSect">SALLE DE REVEIL</div>
                        <div className="FormSect-Note"></div>
                      </div>
                      <br />
                      <div className="Solera-Settings-Note">
                        Display Ticked Items (Ticked items to display here)
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div>
                  <div className="FormSect">Temps Operatoire</div>
                  <div className="FormSect">ANESTHESIE</div>
                  <div className="FormSect-Note"></div>
                </div>
                <br />
                <div className="Solera-Settings-Note">
                  Display Ticked Items (Ticked items to display here)
                </div>
                <br /> <br />
                <div className="FormSect-Contianer">
                  <div className="FormSect">Note</div>
                  <div className="Solera-Settings-Note">Note Display</div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ViewSheet;
