import React, { useEffect, useState } from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import SideBar from "../../Component/SideBar";
import StaffGreetings from "../../Component/StaffGreetings";
import PatientName from "./PatientName";
import PatientNumber from "./PatientNumber";
import {
  CiLineHeight,
  CiMail,
  CiMemoPad,
  CiPickerHalf,
  CiPill,
  CiUser,
  CiViewList,
  CiViewTable,
  CiWheat,
  CiFaceSmile,
  CiBeaker1,
  CiBowlNoodles,
} from "react-icons/ci";
import PatientAge from "./PatientAge";
import Drugs from "./PatientRecords/Prescription/Prescription";
import Medications from "./PatientRecords/Medication/Medications";
import Vitals from "./PatientRecords/Vitals/Vitals";
import Notes from "./PatientRecords/Notes/Notes";
import Allergies from "./PatientRecords/Allergies/Allergies";
import ClinicalNote from "./PatientRecords/ClinicalNote/ClinicalNote";
import LabResult from "./PatientRecords/Lab/LabResult";
import Documents from "./PatientRecords/Documents/Documents";
import Xray from "./PatientRecords/Documents/Xray";
import MRI from "./PatientRecords/Documents/MRI";
import CTScan from "./PatientRecords/Documents/CTScan";
import Theatre from "./PatientRecords/Theatre/Theatre";
import SocialWelfare from "./PatientRecords/SocialWelfare/SocialWelfare";
import Prescription from "./PatientRecords/Prescription/Prescription";
import MedicalRecords from "./PatientRecords/MedicalRecords/MedicalRecords";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatient } from "../../features/patients/patientSlice";
import { fetchPatientRecords } from "../../features/patients/patientsRecordsSlice";
import { getSys } from "../../services/AuthService";
import { matronRole, socialWorkerRole } from "../../services/extras";
import PatientFluid from "./PatientRecords/PatientFluid/PatientFluid";
import SurgicalSafety from "./PatientRecords/SurgicalSafety/SurgicalSafety";
import { fetchPatientAllergies } from "../../features/patients/patientAllergiesSlice";

function PatientRecord() {
  let { id } = useParams();
  const sys = getSys();
  const location = useLocation();
  const dispatch = useDispatch();
  const { patient, loading, isSuccessful } = useSelector(
    (state) => state.patient
  );
  const { allergies } = useSelector(
    (state) => state.patientAllergies
  );

  const [active, setActive] = useState("MedicalRecords");
  const [patientShow, setPatientShow] = useState(location.state?.patient);

  console.log("STATE LOCA", patientShow);

  useEffect(() => {
    if (id) {
      dispatch(fetchPatient({ id: id }));
      dispatch(fetchPatientRecords({ patient: id }));
      dispatch(fetchPatientAllergies({ patientId: id }));
    }
  }, [id]);
  return (
    <div className="Solera-Main-Container">
      <div className="NavBarContainer">
        <nav className="navbar navbar-expand-lg Solera-Nav">
          <div className="container-fluid">
            <div className="Solera-Admin-Heading"> Patient Records</div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarText">
              <div className="navbar-nav me-auto mb-2 mb-lg-0"></div>
              <span className="navbar-text">
                <StaffGreetings />
              </span>
            </div>
          </div>
        </nav>
        <div className="Solera-Main-Dash">
          <div>
            <div>
              <div className="container-fluid overflow-hidden Solera-Dash-section1">
                <div className="ReportHeader30">
                  <div className="Report-Heading">
                    <div className="Solera-Patient-Activities">
                      {/* <div className="Solera-Patient-Activities-Report">
                   
                  </div> */}
                      <div className="Solera-Patient-Name">
                        <PatientName
                          name={patient?.full_name || patientShow?.full_name}
                        />
                      </div>
                      <div className="Solera-Patient-Number20">
                        <PatientNumber
                          pId={patient?.cus_code || patientShow?.cus_code}
                        />
                      </div>
                      <div className="Solera-Patient-Number">
                        Ward : <span>2</span>
                      </div>
                      <div className="Solera-Patient-Number">
                        Sex :{" "}
                        <span>{patient?.gender || patientShow?.gender}</span>
                      </div>
                      <div className="Solera-Patient-Number">
                        Age :
                        <span>
                          <PatientAge age={patient?.age || patientShow?.age} />
                        </span>
                      </div>
                    </div>
                    <div>
                      <div>Allergies : </div>
                      {
                        allergies[0]?.note ? (
                          <div  className="Report-Heading40" dangerouslySetInnerHTML={{ __html: allergies[0]?.note }}></div>
                        ) : (
                          <div className="Report-Heading40" >{'No allergies added'}</div>
                        )
                      }
                      
                    </div>
                  </div>

                  <div className="SociaL-Welfare-Cont">
                    <div className="DocumemtBtn">
                      <div
                        className={
                          active === "MedicalRecords"
                            ? "ActiveIconX"
                            : "InactiveIcon"
                        }
                        onClick={() => setActive("MedicalRecords")}
                      >
                        <CiViewList className="Solera-Icons" />
                        <div>Medicals Records</div>
                      </div>

                      {sys?.role === "sys-admin" ||
                      sys?.role === "sys-lap" ||
                      sys?.role === "sys-doctor" ? (
                        <div
                          className={
                            active === "LabResult"
                              ? "ActiveIconX"
                              : "InactiveIcon"
                          }
                          onClick={() => setActive("LabResult")}
                        >
                          <CiMail className="Solera-Icons" />
                          <div> Laboratory </div>
                        </div>
                      ) : null}

                      {sys?.role === "sys-admin" ||
                      sys?.role === "sys-pharmacy" ? (
                        <div
                          className={
                            active === "Prescription"
                              ? "ActiveIconX"
                              : "InactiveIcon"
                          }
                          onClick={() => setActive("Prescription")}
                        >
                          <CiPill className="Solera-Icons" />
                          <div> Prescription </div>
                        </div>
                      ) : null}

                      {sys?.role === "sys-admin" ||
                      sys?.role === "sys-nurse" ||
                      sys?.role === "sys-doctor" ||
                      sys?.role === "sys-matron" ? (
                        <div
                          className={
                            active === "Medications"
                              ? "ActiveIconX"
                              : "InactiveIcon"
                          }
                          onClick={() => setActive("Medications")}
                        >
                          <CiPickerHalf className="Solera-Icons" />
                          <div> Medications </div>
                        </div>
                      ) : null}

                      {sys?.role === "sys-admin" ||
                      sys?.role === "sys-doctor" ||
                      sys?.role === "sys-nurse" ||
                      sys?.role === "sys-physiotherapist" ? (
                        <div
                          className={
                            active === "ClinicalNote"
                              ? "ActiveIconX"
                              : "InactiveIcon"
                          }
                          onClick={() => setActive("ClinicalNote")}
                        >
                          <CiMemoPad className="Solera-Icons" />
                          <div> Clinical Notes </div>
                        </div>
                      ) : null}

                      {sys?.role === "sys-admin" ||
                      sys?.role === "sys-doctor" ||
                      sys?.role === "sys-matron" ? (
                        <div
                          className={
                            active === "SurgicalSafety"
                              ? "ActiveIconX"
                              : "InactiveIcon"
                          }
                          onClick={() => setActive("SurgicalSafety")}
                        >
                          <CiBowlNoodles className="Solera-Icons" />
                          <div>Surgical Safety Checklist</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="DocumemtBtn">
                      {sys?.role === "sys-admin" ||
                      sys?.role === "sys-doctor" ||
                      sys?.role === "sys-nurse" ||
                      sys?.role === "sys-matron" ? (
                        <div
                          className={
                            active === "Vitals" ? "ActiveIconX" : "InactiveIcon"
                          }
                          onClick={() => setActive("Vitals")}
                        >
                          <CiUser className="Solera-Icons" />
                          <div> Vitals </div>
                        </div>
                      ) : null}

                      {sys?.role === "sys-admin" ||
                      sys?.role === "sys-doctor" ||                     
                      sys?.role === " sys-anaesthetist" ||
                      // sys?.role === "sys-dentist" ||
                      sys?.role === "sys-dietician" ? (
                        <div
                          className={
                            active === "Notes" ? "ActiveIconX" : "InactiveIcon"
                          }
                          onClick={() => setActive("Notes")}
                        >
                          <CiViewList className="Solera-Icons" />
                          <div> Theatre/Notes </div>
                        </div>
                      ) : null}

                      {sys?.role === "sys-admin" ||
                      sys?.role === "sys-doctor" ||
                      sys?.role === "sys-matron" ? (
                        <div
                          className={
                            active === "Documents"
                              ? "ActiveIconX"
                              : "InactiveIcon"
                          }
                          onClick={() => setActive("Documents")}
                        >
                          <CiViewTable className="Solera-Icons" />
                          <div> Radiology </div>
                        </div>
                      ) : null}

                      {sys?.role === "sys-admin" ||
                      sys?.role === "sys-doctor" ||
                      sys?.role === "sys-nurse" ||
                      sys?.role === "sys-matron" ? (
                        <div
                          className={
                            active === "Allergies"
                              ? "ActiveIconX"
                              : "InactiveIcon"
                          }
                          onClick={() => setActive("Allergies")}
                        >
                          <CiWheat className="Solera-Icons" />
                          <div> Allergies </div>
                        </div>
                      ) : null}

                      {
                      sys?.role === "sys-admin" ||
                      sys?.role === "sys-socialworker" ? (
                        <div
                          className={
                            active === "SocialWelfare"
                              ? "ActiveIconX"
                              : "InactiveIcon"
                          }
                          onClick={() => setActive("SocialWelfare")}
                        >
                          <CiFaceSmile className="Solera-Icons" />
                          <div> Social Worker </div>
                        </div>
                      ) : null}
                      {sys?.role === "sys-admin" ||
                      sys?.role === "sys-doctor" ||
                      sys?.role === "sys-nurse" ||
                      sys?.role === "sys-matron" ? (
                        <div
                          className={
                            active === "PatientFluid"
                              ? "ActiveIconX"
                              : "InactiveIcon"
                          }
                          onClick={() => setActive("PatientFluid")}
                        >
                          <CiBeaker1 className="Solera-Icons" />
                          <div> Fluid Chart</div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div className="Solera-Dash-section1">
                <div className="container-fluid ">
                  <div className="row g-5 ">
                    <div className="col-12">
                      <div className="Solera-Activity-Table2">
                        <div className="Reports"></div>
                      </div>
                      <div className="Reports-Container">
                        {active === "ClinicalNote" && <ClinicalNote />}
                        {active === "LabResult" && <LabResult />}
                        {active === "Notes" && <Notes />}
                        {active === "Medications" && <Medications />}
                        {active === "Documents" && <Documents />}
                        {active === "Prescription" && <Prescription />}
                        {active === "Allergies" && <Allergies />}
                        {active === "Vitals" && <Vitals />}
                        {active === "Theatre" && <Theatre />}
                        {active === "SocialWelfare" && <SocialWelfare />}
                        {active === "PatientFluid" && <PatientFluid />}
                        {active === "MedicalRecords" && <MedicalRecords />}
                        {active === "SurgicalSafety" && <SurgicalSafety />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientRecord;
