import React from 'react'
import "../SoleraCss/Responsive.css";
import "../SoleraCss/Solera.css";

function TreatementNumber({treamentNum}) {
  return (
    <div>{treamentNum}</div>
  )
}

export default TreatementNumber