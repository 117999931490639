import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { releaseConsumablesCount } from '../../services/ReleaseConsumableService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchReleasedConsumableCount = createAsyncThunk('fetchReleasedConsumableCount', (initData) => {
    return releaseConsumablesCount(initData)
    .then((response) =>  response.data.data)
})


const releasedConsumableCountSlice = createSlice({
    name: 'releasedConsumableCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchReleasedConsumableCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchReleasedConsumableCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default releasedConsumableCountSlice.reducer 