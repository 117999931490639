import { React, useEffect, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import AddPreOpNote from "../ClinicalNote/AddPreOpNote";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import Dropdown from "react-bootstrap/Dropdown";
import EnterPatientPrescription from "../Medication/EnterPatientPrescription";
import EditPrescription from "../Prescription/EditPrescription";
import ViewPrescriptionModal from "../Prescription/ViewPrescriptionModal";
import AllPatientMedictionModal from "./AllPatientMedictionModal";
import AdministerMedicationModal from "./AdministerMedicationModal";
import PrescribedBy from "../../../../Component/PrescribedBy";
import TreatementNumber from "../../../../Component/TreatementNumber";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientDrugs } from "../../../../features/patients/patientDrugsSlice";
import moment from "moment";
import { Bars } from "react-loader-spinner";
import SampleNumber from "../../../../Component/SampleNumber";
import { getUser } from "../../../../services/AuthService";
import ViewAllMedications from "./ViewAllMedications";
import AllMedictionModal from "./AllMedicationModal";
import UploadMedDoc from "./UploadMedDoc";
import ViewUploadMedModal from "./ViewUploadMedModal";
import { conCatName } from "../../../../services/extras";
// import ViewPrescriptionModal from "./ViewPrescriptionModal";
// import EditPrescription from "./EditPrescription";

function Medications() {
  let { id } = useParams();
  const user = getUser();
  const dispatch = useDispatch()

  const {listDrugs, loading} = useSelector((state) => state.patientDrugs)
  const [selected, setSelected] = useState({});
  const [day, setDay] = useState('');

  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);
  

  const [administerMedicationModalShow, setAdministerMedicationModalShow] = useState(false);
  const handleAdministerMedicationModal = () => setAdministerMedicationModalShow(false);


  const [allPatientMedictionShow, setAllPatientMedictionShow] = useState(false);
  const handleAllPatientMediction = () => setAllPatientMedictionShow(false);

  const [editPreOpNoteDetailsShow, setEditPreOpNoteDetailsShow] =
    useState(false);
  const handleEditPreOpNoteDetails = () => setEditPreOpNoteDetailsShow(false);

  const [uploadMedShow, setUploadMedShow] =
    useState(false);
  const handleUploadMedDetails = () => setUploadMedShow(false);

  const [viewUploadMedShow, setViewUploadMedShow] =
    useState(false);
  const handleViewUploadMed = () => setViewUploadMedShow(false);

  useEffect(() => {
    if (id) {
      dispatch(fetchPatientDrugs({patient: id}))
    }
  }, [id]);

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }

  return (
    <div>
      <div className="Report-Notes">
        <div className="ReportHeader">
          <div className="Report-Heading">Medications</div>
          <div className="Report-Count">
            <span>{listDrugs?.length || 0}</span>&nbsp;Medication
          </div>
         < ViewAllMedications/> 
        </div>
      </div>
      <br />
      {loading ? (
              <div className="d-flex justify-content-center">
                <Bars
                  heigth="30"
                  width="30"
                  color="gray"
                  ariaLabel="loading-indicator"
                />
              </div>
          ) : null}
      {
        listDrugs?.map((item, index) => (
          <div key={index} className="Patient-Record-Container">
            <div className="Patient-Record-Wrapper">
              {/* <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading"> Number</div>
                <div className="Wallet-Ref-Result">{item?.code}</div>
              </div> */}
               <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Treatement Number</div>
                <div className="Wallet-Ref-Result"><TreatementNumber treamentNum={item?.treatment_infos[0]?.code} /></div>
              </div>

              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Prescribed by</div>
                <div className="Wallet-Ref-Result"><PrescribedBy name={conCatName(
                    item?.user_infos[0]?.first_name,
                    item?.user_infos[0]?.last_name
                  )} /></div>
              </div>

             
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Number of Drugs</div>
                <div className="Wallet-Ref-Result">
                  <SampleNumber code={item?.count} />
                </div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Date</div>
                <div className="Wallet-Ref-Result">{showDate(item?.created_at)}</div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Status</div>
                <div className="Wallet-Ref-Result">{item?.status}</div>
              </div>
              <div className="Action-Drop-Down">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
                    Manage medication
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {setSelected(item); setViewPreOpNoteDetailsShow(true)}}
                      className="Solera-Patients"
                    >
                      View prescription
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {setSelected(item); setAdministerMedicationModalShow(true)}}
                      className="Solera-Patients"
                    >
                      Administer medication
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      onClick={() => {setSelected(item); setEditPreOpNoteDetailsShow(true)}}
                      className="Solera-Patients"
                    >
                      Update Medication
                    </Dropdown.Item> */}

                    <Dropdown.Item
                      onClick={() => {setSelected(item); setAllPatientMedictionShow(true)}}
                      className="Solera-Patients"
                    >
                      View all patient medications
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {setSelected(item); setUploadMedShow(true)}}
                      className="Solera-Patients"
                    >
                      Upload medications
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {setSelected(item); setViewUploadMedShow(true)}}
                      className="Solera-Patients"
                    >
                      View uploaded medications
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        ))
      }
      <div>
        <ViewPrescriptionModal
          selected={selected}
          show={viewPreOpNoteDetailsShow}
          onClose={handleViewPreOpNoteDetails}
          onOpen={() => setViewPreOpNoteDetailsShow(true)}
        />

        <EditPrescription
          selected={selected}
          show={editPreOpNoteDetailsShow}
          onClose={handleEditPreOpNoteDetails}
          onOpen={() => setEditPreOpNoteDetailsShow(true)}
        />

        <AdministerMedicationModal
          selected={selected}
          show={administerMedicationModalShow}
          onClose={handleAdministerMedicationModal}
          onOpen={() => setAdministerMedicationModalShow(true)}
        />

        <AllMedictionModal
          all={false}
          selected={selected}
          show={allPatientMedictionShow}
          onClose={handleAllPatientMediction}
          onOpen={() => setAllPatientMedictionShow(true)}
        />

          <UploadMedDoc 
            named={'Medication'}
            selected={selected}
            show={uploadMedShow}
            onClose={handleUploadMedDetails}
            onOpen={() => setUploadMedShow(true)}
          /> 

          <ViewUploadMedModal 
            selected={selected}
            show={viewUploadMedShow}
            onClose={handleViewUploadMed}
            onOpen={() => setViewUploadMedShow(true)}
          />    
      </div>
    </div>
  );
}

export default Medications;
