import axios from '../axiosinstance';

      
export function listPatientMedications(initData){
        return axios.get('/patient-medications', { params: initData })
}

export function addPatientMedications(data){
        return axios.post('/patient-medications', data)
}

export function getPatientMedications(initData){
        return axios.get('/patient-medications/'+initData?.id)
}

export function updatePatientMedications(id, initData){
        return axios.patch('/patient-medications/'+id, initData)
}

