import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import moment from "moment";

function ViewMedicationModal({ show, onClose, selected }) {

    const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
      useState(false);
    const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);
  
    const handleSubComment = () => {
      Swal.fire({
        customClass: {
          title: "title-class",
          icon: "icon-class",
        },
  
        icon: "success",
        title: "Successful",
        showConfirmButton: false,
        timer: 2000,
      });
    };
  
    const handleSub = () => {
      onClose();
      setViewPreOpNoteDetailsShow(false);
  
      Swal.fire({
        customClass: {
          title: "title-class",
          icon: "icon-class",
        },
  
        icon: "success",
        title: "Successful",
        showConfirmButton: false,
        timer: 2000,
      });
    };

    const showDate = (passDate) => {
      return moment(passDate).format('DD-MM-YYYY')
    }
  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera-Modal-Container">
                <br />
                <div className="Report-Display-Heading">Medication</div>
                <div className="Report-Tittle">Medication</div>
                <div className="Solera-Modal-Container">
                  <div>
                    <div>
                      <div className="Report-Notes2">
                        <div>
                          <div className="Doctors-Name">
                            Social welfare's name: &nbsp;&nbsp;
                            <span>{selected?.user?.full_name}</span>
                          </div>
                          <div className="Report-Date">
                            Date : &nbsp;&nbsp;<span>{showDate(selected?.created_at)}</span>
                          </div>
                          <div className="Report-Date">
                            Discharge Summary Number : &nbsp;&nbsp;
                            <span> {selected?.code}</span>
                          </div>
                        </div>


                      
                        <div className="Report-Display">Side Effects</div>
                       

                        <div className="Report-Date4">
                      <div dangerouslySetInnerHTML={{__html: selected?.side_effect}}></div>
                      </div>
                        <br />
                        <div className="Report-Display">Concerns</div>
                       

                        <div className="Report-Date4">
                      <div dangerouslySetInnerHTML={{__html: selected?.concerns}}></div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default ViewMedicationModal