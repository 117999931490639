import React from "react";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";

function NumberOfOutOfStock({loading, num}) {
  return (
    <div className="Count-Result">{loading ? "loading..." : num }</div>
  )
}

export default NumberOfOutOfStock