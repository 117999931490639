import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { patientCount } from '../../services/PatientService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchPatientQuarantineCount = createAsyncThunk('fetchPatientQuarantineCount', (initData) => {
    return patientCount(initData)
    .then((response) =>  response.data.data)
})


const patientQuarantineCountSlice = createSlice({
    name: 'patientQuarantineCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientQuarantineCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatientQuarantineCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default patientQuarantineCountSlice.reducer 