import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientLapComments } from '../../services/PatientLapsCommentService'

const initialState = {
    loading: false,
    patientLapComments: [],
    paginator: {},
    isSuccessful: false
}

export const fetchPatientLapComments = createAsyncThunk('fetchPatientLapComments', (initData) => {
    return listPatientLapComments(initData)
    .then((response) => response.data.data)
})


const patientLapCommentsSlice = createSlice({
    name: 'patientLapComments',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientLapComments.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatientLapComments.fulfilled, (state, action ) => {
        state.loading = false
        state.patientLapComments = action.payload.data
        state.paginator = action.payload.paginator
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientLapComments.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientLapCommentsSlice.reducer 