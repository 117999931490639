import { React, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ViewExpiredItems from "./ViewExpiredItems";
import moment from "moment";

function ExpiredList({consume}) {
  const [drugsDetailsShow, setDrugsDetailsShow] = useState(false);
  const handleDrugsDetails = () => setDrugsDetailsShow(false);

  const [editDrugsShow, setEditDrugsShow] = useState(false);
  const handleEditDrugs = () => setEditDrugsShow(false);
  const [selected, setSelected] = useState({});

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }

  return (
    <div>
      <div className="Patient-Displays-Details2">
        <div className="PatientNumber-C">
          <div className="Wallet-Ref-Heading">Item's Name</div>
          <div className="Wallet-Ref-Result">{consume?.name}</div>
        </div>

        <div className="Position-Cont-P">
          <div className="Wallet-Ref-Heading">Expiry Date </div>
          <div className="Wallet-Ref-Result">{showDate(consume?.expiry_date)}</div>
        </div>
        <div className="Role-Cont-P">
          <div className="Wallet-Ref-Heading">Quantity</div>
          <div className="Wallet-Ref-Result">{consume?.qty}</div>
        </div>
        <div className="Dept-Cont-P">
          <div className="Wallet-Ref-Heading">Status</div>
          <div className="Wallet-Ref-Result">{consume?.status}</div>
        </div>

        <div className="Action-Drop-Down">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
              Manage Expired Drugs
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                className="Solera-Patients"
                onClick={() => {setSelected(consume); setDrugsDetailsShow(true)}}
              >
                View Details
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div>
        <ViewExpiredItems
          selected={selected}
          show={drugsDetailsShow}
          onClose={handleDrugsDetails}
          onOpen={() => setDrugsDetailsShow(true)}
        />
      </div>
    </div>
  );
}

export default ExpiredList;
